<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <!-- Ресепшн -->
            <div class="item_title card">
                <div class="hstack justify-content-between">
                    <div class="title">
                        <div class="fw-normal">Ресепшн</div>
                        <div class="fw-bold">
                            <span v-if="users.reception">{{ users.reception }}</span>
                            <span v-else>Не авторизован</span>
                        </div>
                    </div>
                    <div class="hstack">
                        <span class="material-symbols-rounded text-success" v-if="users.reception">check_circle</span>
                        <span class="material-symbols-rounded text-danger" v-else>cancel</span>
                    </div>
                </div>
            </div>

            <!-- СБ -->
            <div class="item_title card">
                <div class="hstack justify-content-between">
                    <div class="title">
                        <div class="fw-normal">СБ</div>
                        <div class="fw-bold">
                            <span v-if="users.sb">{{ users.sb }}</span>
                            <span v-else>Не авторизован</span>
                        </div>
                    </div>
                    <div class="hstack">
                        <span class="material-symbols-rounded text-success" v-if="users.sb">check_circle</span>
                        <span class="material-symbols-rounded text-danger" v-else>cancel</span>
                    </div>
                </div>
            </div>

            <!-- ФиС -->
            <div class="item_title card">
                <div class="hstack justify-content-between">
                    <div class="title">
                        <div class="fw-normal">ФиС</div>
                        <div class="fw-bold">
                            <span v-if="users.kredit">{{ users.kredit }}</span>
                            <span v-else>Не авторизован</span>
                        </div>
                    </div>
                    <div class="hstack">
                        <div class="hstack">
                            <span class="material-symbols-rounded text-success" v-if="users.kredit">check_circle</span>
                            <span class="material-symbols-rounded text-danger" v-else>cancel</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Начальник отдела -->
            <div v-if="users.chief && users.chief.length > 0">
                <div class="item_title card" v-for="(chief, index) in users.chief" :key="index">
                    <div class="hstack justify-content-between">
                        <div class="title">
                            <div class="fw-normal">Начальник отдела</div>
                            <div class="fw-bold">{{ chief }}</div>
                        </div>
                        <div class="hstack">
                            <span class="material-symbols-rounded text-success">check_circle</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="item_title card">
                    <div class="hstack justify-content-between">
                        <div class="title">
                            <div class="fw-normal">Начальник отдела</div>
                            <div class="fw-bold">Не авторизован</div>
                        </div>
                        <div class="hstack">
                            <span class="material-symbols-rounded text-danger">cancel</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner.vue";
import BreadCrumbs from "../../../Page/BreadCrumbs.vue";
import axios from "axios";

export default {
    name: "AuthorizedEmployees",
    components: {
        BreadCrumbs, spinner
    },
    data(){
        return {
            users: [],
            isLoading: false,
            breadCrumbs: true,
            title: 'Авторизованные пользователи',
            back_title: 'Меню выдачи',
            link: 'issuance/settings',
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/issuances/settings/authorized', config);
                this.users = response.data.data.users;
                this.isLoading = false;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <h5 class="my-3">{{ $t('request.new_memo') }}</h5>

                    <Form ref="docs">
                        <div class="mb-3">
                            <label class="form-label">Выберите организацию*</label>
                            <VueMultiselect
                                @open="clearErrors('organization')"
                                v-model:modelValue="organization"
                                placeholder="Выберите организацию"
                                label="name"
                                track-by="id"
                                :options="organizations"
                                name="organization"
                                :show-labels="false"
                                :searchable="false"
                                :class="errors.organization.length ? 'error' : ''"
                                @select="selectOrganization($event)"
                            ></VueMultiselect>
                            <span role="alert" v-if="errors.organization.length > 0">{{errors.organization}}</span>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.select_memos') }}*</label>
                            <VueMultiselect
                                @open="clearErrors('memos')"
                                v-model="docs.memos"
                                :placeholder="$t('request.select_memos')"
                                label="name"
                                track-by="id"
                                :options="documents"
                                :searchable="false"
                                :show-labels="false"
                                ></VueMultiselect>
                            <span role="alert" v-if="errors.memos.length > 0">{{errors.memos}}</span>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.content') }}</label>
                            <editor
                                v-model="docs.contents"
                                api-key="ajnlcvm8d7ksy8utl3jh996fabxk5qia3phpxm0zrityonjq"
                                :init="{

                                     height: 300,
                                     menubar: false,
                                     plugins: [
                                       'advlist autolink lists link image charmap print preview anchor',
                                       'searchreplace visualblocks code fullscreen',
                                       'insertdatetime media table paste code help wordcount'
                                     ],
                                     toolbar:
                                       'undo redo | bullist numlist'
                                   }"
                            />
                            <ErrorMessage name="contents" style="color: red;"/>
                        </div>

                        <div>
                            <label for="formFileMultiple" class="form-label">{{ $t('request.attach_files') }}</label>
                            <input ref="files" class="form-control" type="file" id="formFileMultiple" multiple
                                v-on:change="handleFileUpload()"
                            >
                        </div>

                        <div class="mt-5">
                            <div class="col col-lg-2">
                                <button class="btn btn-green w-100"
                                    @click="create"
                                >
                                    {{ $t('request.button.save') }}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../../components/ToastAlert";
import Editor from '@tinymce/tinymce-vue'
import axios from "axios";
import * as req from "../../../services/request";

export default {
    name: "MemosCreate",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert, Editor
    },
    data() {
        return {
            type: '',
            message: '',
            is_alert: false,
            docs: {
                memos: null,
                contents: null,
            },
            errors: {
                memos: '',
                contents: '',
                organization: '',
            },
            documents: [],
            organizations: [],
            organization: null,
            error: [],
            files: '',
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.to_create'),
            back_title: this.$t('request.memos'),
            link: 'request/memos',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        clearErrors(value){
            this.errors[value] = '';
            this.error = [];
        },
        handleFileUpload(){
            this.files = this.$refs.files.files;
        },
        closeAlert(){
            this.is_alert = false;
        },
        validateForm(){
            if(this.docs.memos == null || this.docs.memos == ''){
                this.errors.memos = this.$t('validate.required');
                this.error.push('Поле служебная записка обязательна!');
            }

            if(this.organization == null || this.organization == ''){
                this.errors.organization = this.$t('validate.required');
                this.error.push('Поле организация обязательно!');
                this.type = 'red';
                this.message = this.error;
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }

            if (!this.error.length) {
                return true;
            }else{
                return false;
            }
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/memos/create', config);
                this.documents = response.data.data.documents;
                this.organizations = response.data.data.organizations;
                this.organization = response.data.data.organization;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        create(e){
            e.preventDefault();
            if(this.validateForm()){
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token'), 'Content-Type': 'multipart/form-data'}};
                const formData = new FormData();
                for( var i = 0; i < this.files.length; i++ ){
                    let file = this.files[i];
                    formData.append('files[' + i + ']', file);
                }
                formData.append('memos_id', this.docs.memos.id);
                formData.append('contents', this.docs.contents);
                formData.append('organization_id', this.organization.id);
                axios.post('/api/admin/memos/store', formData, config)
                    .then(response => {
                        this.isLoading = false;
                        if (response.status == 200) {
                            this.$router.push('/request/memos/index')
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        switch (errors.response.status) {
                            case 422:
                                this.$refs.docs.setErrors(errors.response.data.errors);
                                this.errors.memos = errors.response.data.errors.memos ?? 0;
                                this.errors.contents = errors.response.data.errors.contents ?? 0;
                                break;
                            case 404:
                                this.type = 'red';
                                this.message = errors.response.data.data.message;
                                this.is_alert = true;
                                setTimeout(() => {this.closeAlert(); }, 5000);
                        }
                    });
            }
        },
        async selectOrganization(event){
            this.isLoading = true;
            this.docs.memos = null;
            let result = await req.selectMemosOrganization(event.id);
            this.documents = result.categorys;
            this.organization = result.organization;
            this.isLoading = false;
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">

                <h5 class="mb-3">Статистика</h5>

                <VueMultiselect
                    v-model="month"
                    placeholder="Выберите месяц"
                    label="name"
                    track-by="id"
                    :options="months"
                    :searchable="false"
                    :show-labels="false"
                    @select="selectMonth"
                >
                </VueMultiselect>

                <div class="item_title mb-3">
                    <div class="chart" v-if="is_chart">
                        <apexchart
                            type="donut"
                            :options="chartOptions"
                            :series="series"
                            :height="2000"
                            :width="318"></apexchart>
                        <hr/>

                        <div class="apexcharts-legend-series" style="padding: 0 10px;">
                            <span
                                class="apexcharts-legend-marker"
                                rel="2"
                                style="background: rgb(250,3,52) !important; color: rgb(250,3,52); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span>
                            <span
                                class="apexcharts-legend-text"
                                style="color: rgb(55, 61, 63);">
                                Из них клиенты сервиса &mdash;
                                {{ fromService }}
                            </span>
                        </div>
                        <hr/>
                        <div class="apexcharts-legend-series" style="padding: 0 10px;">
                            <span
                                class="apexcharts-legend-marker"
                                style="background: rgb(250,3,52) !important; color: rgb(250,3,52); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span>
                            <span
                                class="apexcharts-legend-text"
                                style="color: rgb(55, 61, 63);">
                                Всего &mdash;
                                {{ allCount }}
                            </span>
                        </div>
                    </div>
                    <div v-if="!is_chart">
                        <div class="item_title fw-semibold">
                            Нет данных за выбранный месяц.
                        </div>
                    </div>
                </div>

                <div class="check_btns col-12 hstack justify-content-between my-3">
                    <div class="btn gray col-5 col-lg-3 col-md-4" @click="downloadPDF('brief')">
                        <fa class="icon me-1 opacity-50" icon="cloud-arrow-down"></fa>
                        Скачать отчёт
                    </div>
                    <div class="btn gray col-5 col-lg-3 col-md-4" @click="downloadPDF('full')">
                        <fa class="icon me-1 opacity-50" icon="cloud-arrow-down"></fa>
                        Развернутый отчёт
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";
import ToastAlert from "../../../components/ToastAlert";
import ApexCharts from "vue3-apexcharts";
import VueMultiselect from "vue-multiselect";

export default {
    name: "TDChart",
    components: {
        spinner, BreadCrumbs, ToastAlert, ApexCharts, VueMultiselect
    },
    data() {
        return {
            isLoading: false,
            is_chart: false,
            breadCrumbs: true,
            title: 'Статистика',
            back_title: 'Список договоров',
            link: 'test-drive',
            months: [],
            month: null,
            allCount: null,
            fromService: null,
            numberMonth: ("0" + ((new Date()).getMonth() + 1)).slice(-2),
            series: [],
            chartOptions: {

            },
        }
    },
    mounted(){
        this.getDataChart(this.numberMonth)
    },
    methods: {
        downloadPDF(type){
            let element = document.createElement("a");
            let url = "/api/download/pdf/" + this.numberMonth +"/"+ type;
            element.setAttribute("href", url);
            element.setAttribute("download", "TestDrive.pdf");
            element.setAttribute("target", "_blank");
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        selectMonth(value){
            this.numberMonth = value.id;
            this.getDataChart(this.numberMonth);
        },
        async getDataChart(number){
            try {
                this.isLoading = true;
                this.is_chart = false;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/testdrives/chart/${number}`, config);
                this.months = response.data.data.months;
                this.month = response.data.data.month;
                this.chartOptions.labels = response.data.data.manager;
                this.series = response.data.data.count;
                this.fromService = response.data.data.fromService;
                this.allCount = response.data.data.allCount;
                if(this.series.length > 0){
                    this.is_chart = true;
                }else{
                    this.is_chart = false;
                }
                this.updateOptions(this.chartOptions.labels);
                console.log(response.data.data);
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        updateOptions(e) {
            this.chartOptions = {
                chart: {
                    type: 'donut',
                    height: 1000,
                    plotOptions: {
                        pie: {
                            customScale: 0.8
                        }
                    }
                },
                labels: e,
                plotOptions: {
                    pie: {
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '14px'
                    }
                },
                fill: {
                    //type: 'gradient',
                },
                legend: {
                    formatter: function(val, opts) {
                        return val + " &mdash; " + opts.w.globals.series[opts.seriesIndex]
                    },
                    position: 'bottom',
                    horizontalAlign: 'left',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'semibold',
                    fontSize: '14px',
                    itemMargin: {
                        horizontal: 0,
                        vertical: 10
                    },
                    verticalAlign: 'bottom',
                },
            }
        },
        addMonth(newItem){
            const item = {
                title: newItem,
            }
            this.months.push(item)
            this.month.push(item)
        }
    }
}
</script>

<style scoped>

</style>

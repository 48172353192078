<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div v-if="Object.keys(this.defects).length > 0" class="mt-3">
                    <h5 class="my-3">Неисправленные дефекты</h5>
                    <div class="d-flex justify-content-around gap-2 flex-wrap">
                        <div class="item_title col-12 col-md-5 mb-0"
                            v-for="defect in this.defects"
                            :key="defect.id"
                            @click="next(defect.id)"
                        >
                            <div class="gn_row d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <div class="gn_img me-3" :style="{backgroundImage: 'url(' + defect.avatar + ')'}"></div>
                                    <div class="gn_worker_name">{{ defect.name }}</div>
                                </div>
                                <h6 class="gn_defects_count">
                                    <span class="badge rounded-pill text-bg-danger">{{ defect.count }}</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text_end" v-else>
                    <div class="item_title text-center border-green mb-4">
                        Все несоответствия устранены
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import axios from "axios";
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "DefectsLists",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            defects: [],
            breadCrumbs: true,
            title: 'Список дефектов',
            back_title: 'Дефекты',
            link: 'defects',
        }
    },
    created() {
        this.getAllDefects();
    },
    methods: {
        next(id){
            this.$router.push(`/defects/${this.$route.params.name}/${id}`)
        },
        async getAllDefects(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tsm/all/defects/${this.$route.params.name}`, config);
                this.defects = response.data.data.defects;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>

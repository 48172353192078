export default {
    request: {
        button: {
            save: 'Сохранить',
            delete: 'Удалить',
            history: 'История',
            processing: 'Обработать',
            modified_amount: 'Изменить сумму',
            approve: 'Одобрить',
            deny: 'Отказать',
            approve_with_change: 'Одобрить с изменением',
            cancel: 'Отменить',
            accept_to_work: 'В работу',
            download_document: 'Скачать документ',
            to_perform: 'Выполнить',
            revision: 'Доработать',
            for_revision: 'На доработку',
            reject: 'Отклонить',
            filter: 'Фильтр',
            reset: 'Сброс',
            complete: 'Завершить',
            imprest_complete: 'Аванс выдан',
            approval: 'Утвердить',
            approval_in_order: 'Утвердить в приказ',
            allow_purchase: 'Разрешить закуп',
            to_send: 'Отправить',
            executed: 'Исполнено',
            agreement: 'Согласование',
            edit: 'Редактировать',
            add: 'Добавить',
            return_to_work: 'Вернуть в работу',
            print: 'Печать',
            download: 'Скачать',
            create: 'Создать',
            statistics: 'Статистика',
            issue_advance: 'Выдать аванс'
        },
        photos: 'Фотографии',
        search_by_number: 'Поиск по №',
        advance: 'Аванс',
        request: 'Заявка',
        request_menu: 'Меню заявок',
        advance_section: 'Аванс',
        get_advance: 'Заявка на аванс',
        my_requests: 'Мои заявки на аванс',
        awaiting_processing: 'Ожидают обработки',
        awaiting_approval: 'Ожидают одобрения',
        awaiting_chief_accountant: 'Ожидают одобрения гл. бухгалтера',
        list_requests: 'Список заявок',
        journal: 'Журнал',
        time_reception: 'Заявка на выдачу аванса принимается с Пн по Сб, с 8:00 до 17:00.',
        advance_payment_amount: 'Сумма аванса',
        method_obtaining: 'Способ получения',
        specify_method_obtaining: 'Укажите способ выдачи аванса',
        money: 'Наличными',
        card:' На карту',
        total_requests: 'Всего заявок',
        the_amount: 'На сумму',
        created: 'Создана',
        amount: 'Сумма',
        debt: 'Задолженность',
        earned: 'Заработано',
        current_status: 'Текущий статус',
        list_requests_is_empty: 'Список заявлений пуст',
        worker: 'Сотрудник',
        earned_today: 'Заработано на сегодня',
        arrears_today: 'Задолженность на сегодня',
        comment: 'Комментарий',
        modified_amount: 'Введите новую сумму',
        new: 'Новые',
        in_work: 'В работе',
        сompleted: 'Завершено',
        no_new_requests: 'Новых заявлений нет',
        in_work_requests: 'Заявлений в работе нет',
        completed_requests: 'Выполненных заявлений нет',
        status: 'Статус',
        payments: 'Платежи',
        to_create: 'Создать',
        my_payments: 'Мои платежи',
        waiting: 'Ожидают',
        create_payment: 'Создать платеж',
        edit_payment: 'Редактировать платеж',
        new_request: 'Новая заявка',
        urgent_payment: 'Срочный платеж',
        request_payment_document: 'Запросить платежный документ',
        request_power_attorney: 'Запросить доверенность',
        select_counterparty: 'Выберите контрагента',
        payment_amount: 'Сумма платежа',
        select_currency: 'Выберите валюту',
        payment_type: 'Тип оплаты',
        select_category: 'Выберите категорию',
        attach_files: 'Прикрепить файлы',
        attach: 'Прикрепить',
        counterparty: 'Контрагент',
        category: 'Категория',
        categories: 'Категории',
        category_name: 'Название категории',
        pay: 'Оплата',
        provide_payment_card: 'Предоставить платежку',
        payment_document: 'Платежный документ',
        provide_power_attorney: 'Предоставить доверенность',
        attorney: 'Доверенность',
        applicant: 'Заявитель',
        attachments: 'Вложения',
        attachments_from: 'Вложения от',
        appointment: 'Назначение',
        edit: 'Редактировать',
        actions: 'Действия',
        payment_number: 'Номер платежа',
        memos: 'Служебные записки',
        my_memos: 'Мои служебные записки',
        new_memo: 'Новая служебная записка',
        select_memos: 'Выбрать служебную записку',
        content: 'Содержание',
        select_employee: 'Выберите сотрудника',
        routes: 'Маршруты',
        advance_imprest_settings: 'Настройки аванса',
        memo_settings: 'Настройки служебных записок',
        settings: 'Настройки',
        title: 'Название',
        document_name: 'Название документа',
        list_responsible_persons: 'Список ответственных',
        role_chief_accountant: 'Роль главного бухгалтера',
        role_accountant: 'Роль бухгалтера',
        cashier_role: 'Роль кассира',
        hr_role: 'Роль отдела кадров',
        supplier_role: 'Роль снабженца',
        add: 'Добавить',
        add_responsible: 'Добавить ответственного',
        choose_responsible: 'Выберите ответственное лицо',
        responsible: 'ответственный',
        settings_saved: 'Настройки сохранены',
        memo_approved: 'Служебная записка одобрена!',
        memo_return: 'Служебная записка отправлена на доработку!',
        memo_rejected: 'Служебная записка отклонена!',
        memo_executed: 'Служебная записка исполнена!',
        memo_accept: 'Служебная записка Утверждена!',
        memo_sent: 'Служебная записка отправлена!',
        request_sent: 'Заявка отправлена!',
        memo_approval: 'Служебная записка отправлена на  согласование!',
        memo_deleted: 'Служебная записка удалена!',
        not_possible_apply_time: 'Извините, в данное время не возможно подать заявку!',
        request_delete: 'Заявка успешно удалена!',
        request_added: 'Заявка добавлена!',
        request_approved: 'Заявка одобрена!',
        request_rejected: 'Заявка отклонена!',
        request_revision: 'Заявка отправлена на доработку!',
        request_completed: 'Заявка выполнена!',
        request_work: 'Заявка принята в работу!',
        information_provided: 'Спасибо за предоставленную информацию!',
        list_counterparties: 'Список контрагентов',
        application_restored: 'Заявка восстановлена в работу',
        kzt: 'KZT',
        tenge: '₸',
        notifications: 'Уведомления',
        notification: 'Уведомление',
        main_menu: 'Главное меню',
        organization: 'Организация'
    }
}

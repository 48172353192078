<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h2 class="mb-4">Добавить права</h2>
                <Form ref="permission" @submit="create">

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Модуль*</label>
                        <div class="col-sm-8 position-relative ">
                            <VueMultiselect
                                v-model="permission.module"
                                placeholder="Выберите модуль"
                                label="title"
                                track-by="id"
                                :options="modules"
                                :taggable="true"
                                :searchable="false"
                                :show-labels="false"
                                @tag="addModule"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="module" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Название права*</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="permission.title"
                                type="text"
                                name="title"
                                class="form-control"
                                :class="[errors.title.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="title" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Роут*</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="permission.path"
                                type="text"
                                name="path"
                                class="form-control"
                                :class="[errors.path.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="path" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Иконка*</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="permission.icon"
                                type="text"
                                name="icon"
                                class="form-control"
                                :class="[errors.icon.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="icon" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Номер сортировки</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="permission.sort"
                                type="text"
                                name="sort"
                                class="form-control"
                                :class="[errors.sort.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="sort"/>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end"></label>
                        <div class="col-sm-8 position-relative">
                            <button class="btn btn-green">
                                Сохранить
                            </button>
                        </div>
                    </div>

                </Form>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import { Field, Form, ErrorMessage } from 'vee-validate';
import spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";
import VueMultiselect from "vue-multiselect";

export default {
    name: "CreatePermission",
    components: {
        spinner, Field, Form, ErrorMessage, BreadCrumbs, VueMultiselect
    },
    data(){
        return {
            permission:{
                module: null,
                title: null,
                path: null,
                icon: null,
                sort: null,
            },
            errors:{
                module: '',
                title: '',
                path: '',
                icon: '',
                sort: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: 'Добавить право',
            back_title: 'Список прав',
            link: 'admins/permissions',
            modules: [],
        }
    },
    created() {
        this.getModules();
    },
    methods: {
        async getModules() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get('/api/admin/permissions/get/modules', config);
                this.modules = response.data.data.modules;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        create(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/permissions', {
                module: this.permission.module,
                title: this.permission.title,
                path: this.permission.path,
                icon: this.permission.icon,
                sort: this.permission.sort,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if(response.status == 200){
                        this.$router.push('/admins/permissions');
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.permission.setErrors(errors.response.data.errors);
                            this.errors.module = errors.response.data.errors.module_id ?? 0;
                            this.errors.title = errors.response.data.errors.title ?? 0;
                            this.errors.path = errors.response.data.errors.path ?? 0;
                            this.errors.icon = errors.response.data.errors.icon ?? 0;
                            this.errors.sort = errors.response.data.errors.sort ?? 0;
                            break;
                    }
                });
        },
        addModule(newModule){
            const module = {
                title: newModule,
            }
            this.module.push(module)
            this.permission.module.push(module)
        },
    },
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page ">
                <h5 class="my-3">Чек-лист выдачи автомобиля</h5>

                <div class="item_title col-12 col-md-5 mb-0" v-if="list">
                    <div class="gn_row d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column">
                            <div class="gn_worker_name text-start">
                                <fa class="icon pe-1 text-black-50" icon="car"></fa>
                                {{ list.info.car }}
                            </div>
                        </div>
                    </div>

                    <div class="gn_row d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column">
                            <div class="gn_worker_name text-start">
                                <fa class="icon pe-1 text-black-50" icon="user"></fa>
                                {{ list.info.client }}
                            </div>
                        </div>
                    </div>

                    <div class="gn_row d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column">
                            <div class="gn_worker_name text-start">
                                <fa class="icon pe-1 text-black-50" icon="user-tie"></fa>
                                {{ list.user.surname }} {{ list.user.name }}
                            </div>
                        </div>
                    </div>

                    <div class="gn_row d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column">
                            <div class="gn_worker_name text-start">
                                <fa class="icon pe-1 text-black-50" icon="clock"></fa>
                                {{ list.time_period[0] }} -
                                {{ list.time_period[1] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block_select d-flex flex-column justify-content-center mt-2" v-if="list">

                    <div class="mb-2"
                         v-for="item in list.manager_checklist.data"
                         :key="item.id"
                         @click="updateCheckItem(list.id, item.id)"
                    >
                        <div class="btn cl_item pad btn_vw"
                             :class="goClass(item.done)"
                        >
                            {{ item.text }}
                        </div>
                    </div>
                </div>

                <div class="comments">
                    <div class="check_answer mb-3">
                        Комментарий:
                    </div>
                    <textarea name="comment" v-model="comment" class="form-control">
                            </textarea>
                </div>

                <div class="check_btns col-12 hstack justify-content-between my-3">
                    <div class="btn btn-red col-5 col-lg-3 col-md-4"
                         @click="chiefAbort(list.id)"
                    >НЕТ</div>
                    <div class="btn btn-green col-5 col-lg-3 col-md-4"
                         @click="chiefAccept(list.id)"
                    >ДА</div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import CreateModal from "./CreateModal";
import axios from "axios";

export default {
    name: "CheckPageChief",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs, ToastAlert, CreateModal
    },
    data(){
        return {
            list: null,
            car: null,
            client: null,
            user: null,
            comment: null,
            message: '',
            bg: 'bg-primary',
            is_alert: false,
            isLoading: false,
            breadCrumbs: true,
            title: 'Чек лист автомобиля',
            back_title: 'Меню выдачи',
            link: 'issuance',
        }
    },
    created() {
        this.getDate();
    },
    methods: {
        chiefAccept(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/chief/checklist/accept', {
                issue_id: id,
                comment: this.comment,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push(`/issuance/chief`)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        chiefAbort(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/chief/checklist/abort', {
                issue_id: id,
                comment: this.comment,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push(`/issuance/chief`)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async getDate() {
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/issuances/manager/checklist/${this.$route.params.issue_id}`, config);
                this.list = response.data.data.list;
                this.car = response.data.data.list.info.car;
                this.client = response.data.data.list.info.client;
                this.manager = response.data.data.list.info.client;
                this.user = response.data.data.list.user;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        goClass(status){
            if(status === false){
                return '';
            }else if(status === true){
                return 'type_accept';
            }
        }
    }
}
</script>

<style scoped>

</style>

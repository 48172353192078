<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="d-flex justify-content-around gap-3 flex-wrap" v-if="this.lists.length > 0">
                    <h5 class="ps-3 mb-3"><fa icon="list-check" class="icon" style="padding-right: 10px; color: #c00;"></fa>Список выдач</h5>

                    <div  class="col-12 item_title mb-3"
                          v-for="list in lists"
                          :key="list.id"
                    >
                        <h5 class="text-center mb-3">Договор № {{ list.info.contract_num }}</h5>
                        <h6 class="text-center mb-3">Разрешение на выдачу</h6>
                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="car"></fa>
                                    {{ list.info.car }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <span>VIN:</span>
                                    {{ list.info.vin }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="user"></fa>
                                    {{ list.info.client }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="user-tie"></fa>
                                    {{ list.user.surname }} {{ list.user.name }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="calendar"></fa>
                                    {{ list.formatDate }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="clock"></fa>
                                    {{ list.time_period[0] }} -
                                    {{ list.time_period[1] }}
                                </div>
                            </div>
                        </div>

                        <div class="comments">
                            <div class="check_answer mb-3">
                                Комментарий:
                            </div>
                            <textarea name="comment" v-model="comment" class="form-control">
                            </textarea>
                        </div>

                        <div class="check_btns col-12 hstack justify-content-between my-3">
                            <div class="btn btn-red col-5 col-lg-3 col-md-4"
                                 @click="abort(list.id)"
                            >НЕТ</div>
                            <div class="btn btn-green col-5 col-lg-3 col-md-4"
                                 @click="allow(list.id)"
                            >ДА</div>
                        </div>

                    </div>
                </div>
                <div class="text_end" v-else>
                    <div class="item_title text-center border-green mb-4">
                        Все выдачи оформлены
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />

</template>

<script>
import ToastAlert from "../../components/ToastAlert.vue";
import spinner from "../../components/Spinner.vue";
import BreadCrumbs from "../../Page/BreadCrumbs.vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import CreateModal from "./CreateModal.vue";
import axios from "axios";

export default {
    name: "ChecklistDirector",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs, ToastAlert, CreateModal
    },
    data(){
        return {
            lists: [],
            message: '',
            type: '',
            is_alert: false,
            isLoading: false,
            comment: null,
            breadCrumbs: true,
            title: 'Список выдач',
            back_title: 'Меню выдачи',
            link: 'issuance',
        }
    },
    created() {
        this.getDate();
    },
    methods: {
        closeAlert() {
            this.is_alert = false;
        },
        async getDate(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/issuances/director/checklist`, config);
                this.lists = response.data.data.lists;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        allow(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/chief/director/allow', {
                issue_id: id,
                comment: this.comment,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.type = 'green'
                        this.is_alert = true;
                        this.message = 'Выдача разрешена!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.getDate();
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        abort(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/chief/director/rejection', {
                issue_id: id,
                comment: this.comment,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.type = 'green'
                        this.is_alert = true;
                        this.message = 'Выдача запрещена';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.getDate();
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>

</style>

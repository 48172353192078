<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">

                <!-- Управление -->
                <div class="d-flex col-12">
                    <div class="col-12">
                        <input type="date" class="form-control" v-model="selectDate" @change="handler">
                    </div>
                </div>
                <div class="col-12 hstack gap-3 mb-3">
                    <div class="col">
                        <router-link to="/test-drive/chart" v-if="!is_sb" class="btn w-100 gray px-2">
                            <fa icon="chart-pie" class="icon opacity-50 me-1"/>
                            {{ $t('request.button.statistics') }}
                        </router-link>
                    </div>
                    <div class="col">
                        <router-link to="/test-drive/create" v-if="!is_sb" class="btn w-100 btn-green px-2">
                            <fa icon="plus" class="icon opacity-50 me-1"/>
                            {{ $t('request.button.create') }}
                        </router-link>
                    </div>
                </div>

            <!-- Список тест-драйвов -->
            <div v-if="Object.keys(this.contracts).length > 0" >
                <div
                    v-for="contract in contracts"
                    :key="contract.id"
                    class="item_title mb-2"
                >
                    <div @click="selectContract(contract.id)">
                        <!-- номер, дата, статус -->
                        <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                            <div class="fs-6 fw-bold">№{{ contract.id }}</div>
                            <div class="fs-7">{{ contract.date_created }}</div>
                            <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block denied" v-if="contract.status == 'is_create'">Создано</div>
                            <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block in_work" v-if="contract.status == 'is_start'">В пути</div>
                            <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block completed" v-if="contract.status == 'is_finish'">Завершено</div>
                        </div>

                        <!-- Старт, Финиш  -->
                        <div class="col-12 border-bottom d-flex justify-content-between mb-3 pb-2">
                            <div>
                                <fa class="icon opacity-50 me-2" icon="car-on"></fa>
                                <span class="fs-7 fw-normal pe-2 text-black-50">Старт:</span>
                                <span class="fw-bold">{{ contract.date_start }}</span>
                            </div>
                            <div>
                                <fa class="icon opacity-50 me-2" icon="flag-checkered"></fa>
                                <span class="fs-7 fw-normal pe-2 text-black-50">Финиш:</span>
                                <span class="fw-bold" v-if="contract.date_end == '...'">ожидается</span>
                                <span class="fw-bold" v-else>{{ contract.date_end }}</span>
                            </div>
                        </div>

                        <div class="col-12 border-bottom mb-3 pb-3">
                            <div class="mb-2">
                                <fa class="icon opacity-50 me-2" icon="car"></fa>
                                <span class="fs-6 fw-bold">{{ contract.car }}</span>
                            </div>
                            <div>
                                <fa class="icon opacity-50 me-2" icon="barcode"></fa>
                                <span class="fs-6 fw-bold">{{ contract.car_number }}</span>
                            </div>
                        </div>

                        <div class="col-12">
                            <div  class="mb-2">
                                <fa class="icon opacity-50 me-2" icon="user"></fa>
                                <span class="fw-normal">{{ contract.fullNameClient }}</span>
                            </div>
                            <div>
                                <fa class="icon opacity-50 me-2" icon="user-tie"></fa>
                                <span class="fw-normal">{{ contract.fullNameManager }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm position-relative border-top mt-3 pt-3" v-if="contains(actions, 'resepsn') && !contract.keys_issued">
                        <button class="btn w-100 btn-green"
                                @click="keysIssued(contract.id)"
                        >
                            <span class="material-symbols-rounded">car_rental</span>
                            Выдать ключи
                        </button>
                    </div>
                    <div class="col-sm position-relative border-top mt-3 pt-3" v-if="contains(actions, 'resepsn') && contract.is_started && !contract.is_finished">
                        <button class="btn btn_vw btn-red" @click="finishTestDrive(contract.id)">
                            <fa class="icon opacity-50 me-1" icon="flag-checkered"></fa> ЗАВЕРШИТЬ
                        </button>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="item_title fw-semibold">
                    На указанную дату договоров нет.
                </div>
            </div>

            </div>


        </div>


    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import axios from "axios";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import ToastAlert from '../../../components/ToastAlert';
import {getUserPermissionActions} from "../../../services/helpers";
import * as req from "../../../services/td";

export default {
    name: "TDIndex",
    components: {
        spinner, BreadCrumbs, ToastAlert
    },
    data(){
        return {
            selectDate: null,
            message: '',
            type: '',
            is_alert: false,
            is_modal: false,
            testDriveId: null,
            contracts: [],
            actions: [],
            isLoading: false,
            breadCrumbs: true,
            title: 'Список договоров',
            back_title: 'Категории',
            link: 'td',
        }
    },
    created(){
        this.getContracts(this.selectDate);
        this.getUserActions();
    },
    methods: {
        async getUserActions(){
            this.isLoading = true;
            this.actions = await getUserPermissionActions('/test-drive');
            this.isLoading = false;
        },
        contains(arr, elem){
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].alias === elem) {
                    return true;
                }
            }
            return false;
        },
        async keysIssued(id){
            this.isLoading = true;
            let result = await req.keysIssued(id);
            if(result.status == 201){
                this.isLoading = false;
                this.type = 'red';
                this.is_alert = true;
                this.message = result.data.data.message;
                setTimeout(() => {this.closeAlert(); }, 3000);
            }
            if(result.status == 200){
                this.type = 'green';
                this.is_alert = true;
                this.message = result.data.data.message;
                setTimeout(() => {this.closeAlert(); }, 3000);
                this.getContracts(this.selectDate);
            }
        },
        handler(){
            this.getContracts(this.selectDate);
        },
        closeAlert(){
            this.is_alert = false;
        },
        closeModal(){
            this.is_modal = false;
        },
        async getContracts(date) {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/testdrives/index/${date}`, config);
                this.contracts = response.data.data.contracts;
                this.selectDate = response.data.data.selectDate;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        selectContract(id){
            if(!this.is_sb){
                this.$router.push(`/test-drive/contract/${id}/${false}`)
            }
        },
        finishTestDrive(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/testdrives/finish', {
                id: id,
                date: this.selectDate,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.bg = 'bg-success';
                        this.is_alert = true;
                        this.message = 'Тест-драйв завершён';
                        this.is_modal = false;
                        this.selectDate = response.data.data.selectDate;
                        this.getContracts(this.selectDate);
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(() => {
                });
        }
    }
}
</script>

<style scoped>

</style>

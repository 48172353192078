<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="d-flex flex-column justify-content-center mt-0">
                <div class="mt-2 mb-2 text-center">
                    <router-link class="btn btn-red btn_vw" to="/request/imprest/index">
                        {{ $t("request.my_requests") }}
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center" v-if="accountant > 0">
                    <router-link class="btn btn-red btn_vw" to="/request/imprest/waiting">
                        {{ $t("request.awaiting_processing") }}
                        <span class="badge rounded-pill text-bg-white ms-2">{{ accountant }}</span>
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center" v-if="chief > 0">
                    <router-link class="btn btn-red btn_vw" to="/request/imprest/chief/list">
                        {{ $t("request.awaiting_approval") }}
                        <span class="badge rounded-pill text-bg-white ms-2">{{ chief }}</span>
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center" v-if="chief_accountant > 0">
                    <router-link class="btn btn-red btn_vw" to="/request/imprest/chief/chief-accountant/list">
                        {{ $t("request.awaiting_approval") }}
                        <span class="badge rounded-pill text-bg-white ms-2">{{ chief_accountant }}</span>
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center" v-if="is_cashier || is_bh">
                    <router-link class="btn btn-red btn_vw" to="/request/imprest/cashier/list/first">
                        {{ $t("request.list_requests") }}
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center" v-if="contains(actions, 'prosmotr-zurnala-avans')">
                    <router-link class="btn btn-red btn_vw" to="/request/imprest/journal">
                        {{ $t("request.journal") }}
                    </router-link>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import axios from "axios";
import {getUserPermissionActions} from "../../../services/helpers";

export default {
    name: "ImprestPage",
    components: {
        spinner, BreadCrumbs, Modules
    },
    data() {
        return {
            actions: [],
            is_cashier: false,
            is_bh: false,
            accountant: 0,
            chief: 0,
            chief_accountant: 0,
            cashier: 0,
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.advance_section'),
            back_title: this.$t('request.request_menu'),
            link: 'request',
        }
    },
    created() {
        this.getData();
        this.getUserActions();
        this.eventBus.emit('page-width', '1180px');
    },
    methods: {
        async getUserActions(){
            this.isLoading = true;
            this.actions = await getUserPermissionActions(this.$route.path);
            this.isLoading = false;
        },
        contains(arr, elem){
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].alias === elem) {
                    return true;
                }
            }
            return false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/imprest/waiting', config);
                this.accountant = response.data.data.accountant.length;
                this.chief = response.data.data.chief.length;
                this.chief_accountant = response.data.data.chief_accountant.length;
                this.cashier = response.data.data.cashier.length;
                this.is_cashier = response.data.data.is_cashier;
                this.is_bh = response.data.data.is_bh;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page ">
                <h5 class="my-3">Пропуск служба безопасности</h5>

                <div class="item_title col-12 col-md-5 mb-0" v-if="list">
                    <div class="gn_row d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column">
                            <div class="gn_worker_name text-start">
                                <fa class="icon pe-1 text-black-50" icon="car"></fa>
                                {{ list.info.car }}
                            </div>
                        </div>
                    </div>

                    <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                        <div class="d-flex flex-column">
                            <div class="gn_worker_name text-start">
                                <span>VIN:</span>
                                {{ list.info.vin }}
                            </div>
                        </div>
                    </div>

                    <div class="gn_row d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column">
                            <div class="gn_worker_name text-start">
                                <fa class="icon pe-1 text-black-50" icon="user"></fa>
                                {{ list.info.client }}
                            </div>
                        </div>
                    </div>

                    <div class="gn_row d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column">
                            <div class="gn_worker_name text-start">
                                <fa class="icon pe-1 text-black-50" icon="user-tie"></fa>
                                {{ list.user.surname }} {{ list.user.name }}
                            </div>
                        </div>
                    </div>

                    <div class="gn_row d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column">
                            <div class="gn_worker_name text-start">
                                <fa class="icon pe-1 text-black-50" icon="clock"></fa>
                                {{ list.time_period[0] }} -
                                {{ list.time_period[1] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive-md mt-2" v-if="list">
                    <table class="table table-row table-app">
                        <thead>
                        <tr>
                            <th class="text-muted">Название</th>
                            <th class="text-muted">Кол-во</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="item in list.additions"
                            :key="item.id"
                        >
                            <td>
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.count }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="comments">
                    <div class="check_answer mb-3">
                        Комментарий:
                    </div>
                    <textarea name="comment" v-model="comment" class="form-control">
                            </textarea>
                </div>

                <div class="check_btns col-12 hstack justify-content-between my-3">
                    <div class="btn btn-red col-5 col-lg-3 col-md-4"
                        @click="abort(list.id)"
                    >НЕТ</div>
                    <div class="btn btn-green col-5 col-lg-3 col-md-4"
                        @click="accept(list.id)"
                    >ДА</div>
                </div>

            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import spinner from "../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import CreateModal from "./CreateModal";
import axios from "axios";

export default {
    name: "CheckPageSb",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs, ToastAlert, CreateModal
    },
    data(){
        return {
            list: null,
            comment: null,
            message: '',
            type: '',
            is_alert: false,
            isLoading: false,
            breadCrumbs: true,
            title: 'Чек лист автомобиля',
            back_title: 'Список выдач',
            link: 'issuance/sb',
        }
    },
    created() {
        this.getDate();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        accept(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/sb/checklist/accept', {
                issue_id: id,
                comment: this.comment,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.type = 'green';
                        this.message = 'Аксессуары приняты!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.$router.push('/issuance/sb');
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        abort(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/sb/checklist/abort', {
                issue_id: id,
                comment: this.comment,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.type = 'red';
                        this.message = 'Аксессуары не приняты!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.$router.push('/issuance/sb');
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        async getDate() {
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/issuances/sb/checklist/${this.$route.params.issue_id}`, config);
                this.list = response.data.data.list;
            } catch (e) {
                this.isLoading = false;
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        goClass(status){
            if(status === false){
                return '';
            }else if(status === true){
                return 'type_accept';
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <!-- Организация -->
    <label class="form-label">Организация</label>
    <VueMultiselect
        v-model:modelValue="organization"
        placeholder="Выберите организацию"
        label="name"
        track-by="id"
        :options="organizations"
        name="organization"
        :class="errors.organization.length ? 'error' : ''"
        @select="clearInput('organization')"
        :show-labels="false"
    />

    <!-- brand_id -->
    <label class="form-label">Бренд</label>
    <VueMultiselect
        v-model:modelValue="brand"
        placeholder="Бренд"
        label="name_brand"
        track-by="id"
        :options="brands"
        name="brand"
        :class="errors.brand.length ? 'error' : ''"
        @select="selectBrand($event, 'brand')"
        @clear="clearInput($event)"
        :show-labels="false"
    />

    <label class="form-label">Модель</label>
    <VueMultiselect
        v-model:modelValue="model"
        placeholder="Модель"
        label="name_model"
        track-by="id"
        :options="models"
        name="model"
        :class="errors.model.length ? 'error' : ''"
        @select="selectModel($event, 'model')"
        @clear="clearInput($event)"
        :show-labels="false"
    />

    <!-- year_release -->
    <Input
        label="Год выпуска"
        name="year_release"
        type="number"
        placeholder="Год выпуска"
        id="year_release"
        v-model:modelValue="year_release"
        :error="[errors.year_release.length ? 'error' : '']"
        @clear="clearInput($event)"
    />

    <!-- configuration -->
    <Input
        label="Конфигурация"
        name="configuration"
        add_class="textUppercase"
        type="text"
        placeholder="Конфигурация"
        id="configuration"
        v-model:modelValue="configuration"
        @clear="clearInput($event)"
    />

    <!-- vin_code -->
    <Input
        label="VIN код"
        name="vin_code"
        add_class="textUppercase"
        type="text"
        maxlength="17"
        placeholder="VIN код"
        id="vin_code"
        v-model:modelValue="vin_code"
        :error="[errors.vin_code.length ? 'error' : '']"
        @clear="clearInput($event)"
    />

    <!-- passport -->
    <Input
        label="Паспорт"
        name="passport"
        type="text"
        placeholder="Паспорт"
        id="passport"
        v-model:modelValue="passport"
        @clear="clearInput($event)"
    />

    <!-- passport_from -->
    <Input
        label="Годен до"
        name="passport_from"
        type="date"
        placeholder="Годен до"
        id="passport_from"
        v-model:modelValue="passport_from"
        @clear="clearInput($event)"
    />

    <!-- car_number -->
    <Input
        label="Гос. номер"
        name="car_number"
        add_class="textUppercase"
        type="text"
        placeholder="Гос. номер"
        id="car_number"
        v-model:modelValue="car_number"
        :error="[errors.car_number.length ? 'error' : '']"
        @clear="clearInput($event)"
    />

    <!-- mileage -->
    <Input
        label="Пробег"
        name="mileage"
        type="number"
        placeholder="Пробег"
        id="mileage"
        v-model:modelValue="mileage"
        @clear="clearInput($event)"
    />

    <!-- color_id -->
    <label class="form-label">Цвет</label>
    <VueMultiselect
        v-model:modelValue="color"
        placeholder="Цвет"
        label="name_color"
        track-by="id"
        :options="colors"
        name="color"
        :class="errors.color.length ? 'error' : ''"
        @select="clearInput('color')"
        :show-labels="false"
    />

    <!-- motor_id -->
    <label class="form-label">Тип двигателя</label>
    <VueMultiselect
        v-model:modelValue="motor"
        placeholder="Тип двигателя"
        label="name"
        track-by="id"
        :options="motors"
        name="motor"
        @clear="clearInput($event)"
        :show-labels="false"
    />

    <!-- bodywork_id -->
    <label class="form-label">Тип кузова</label>
    <VueMultiselect
        v-model:modelValue="bodywork"
        placeholder="Тип кузова"
        label="name"
        track-by="id"
        :options="bodyworks"
        name="bodywork"
        @clear="clearInput($event)"
        :show-labels="false"
    />

    <!-- kpp_id -->
    <label class="form-label">КПП</label>
    <VueMultiselect
        v-model:modelValue="kpp"
        placeholder="КПП"
        label="name"
        track-by="id"
        :options="kpp_ar"
        name="kpp"
        @clear="clearInput($event)"
        :show-labels="false"
    />

    <!-- actuator -->
    <Input
        label="Привод"
        name="actuator"
        type="text"
        placeholder="Привод"
        id="actuator"
        v-model:modelValue="actuator"
        @clear="clearInput($event)"
    />

    <!-- engine_capacity -->
    <Input
        label="Объем двигателя"
        name="engine_capacity"
        type="text"
        placeholder="Объем двигателя"
        id="engine_capacity"
        v-model:modelValue="engine_capacity"
        @clear="clearInput($event)"
    />

    <div class="hstack justify-content-between py-2">
        <div class="title hstack">
            <span class="fw-bold">Тестдрайв</span>
        </div>
        <div class="hstack">
            <div class="fs-7">
                <Checkbox
                    :id="testdrive"
                    group="true"
                    name="checkbox"
                    type="checkbox"
                    :checked="testdrive"
                    @updateCheckboxGroup="updateActiveCheck($event, 'testdrive')"
                />
            </div>
        </div>
    </div>

    <div class="hstack justify-content-between py-2">
        <div class="title hstack">
            <span class="fw-bold">Подменка</span>
        </div>
        <div class="hstack">
            <div class="fs-7">
                <Checkbox
                    :id="spoof"
                    group="true"
                    name="checkbox"
                    type="checkbox"
                    :checked="spoof"
                    @updateCheckboxGroup="updateActiveCheck($event, 'spoof')"
                />
            </div>
        </div>
    </div>

    <Button
        caption="Сохранить"
        color="btn-green"
        add_class=""
        @click="save"
    />
</template>

<script>
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Checkbox from '../../../components/Checkbox';
import VueMultiselect from "vue-multiselect";

export default {
    name: "TDFormCar",
    components: {
        Input, Button, Modal, VueMultiselect, Checkbox
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        id: {
            type: Number,
            default: 0
        },
        brand: {
            type: Array,
            default: [],
        },
        brands: {
            type: Array,
            default: [],
        },
        organization: null,
        organizations: {
            type: Array,
            default: [],
        },
        model: {
            type: Array,
            default: [],
        },
        models: {
            type: Array,
            default: [],
        },
        year_release: {
            type: Number,
            default: 2022,
        },
        configuration: {
            type: String,
            default: '',
        },
        vin_code: {
            type: String,
            default: '',
        },
        passport: {
            type: String,
            default: '',
        },
        passport_from: '',
        car_number: {
            type: String,
            default: '',
        },
        mileage: {
            type: Number,
            default: 0,
        },
        color: {
            type: Array,
            default: [],
        },
        colors: {
            type: Array,
            default: [],
        },
        motor: {
            type: Array,
            default: [],
        },
        motors: {
            type: Array,
            default: [],
        },
        bodywork: {
            type: Array,
            default: [],
        },
        bodyworks: {
            type: Array,
            default: [],
        },
        kpp: {
            type: Array,
            default: [],
        },
        kpp_ar: {
            type: Array,
            default: [],
        },
        actuator: {
            type: String,
            default: '',
        },
        engine_capacity: {
            type: Number,
            default: 0,
        },
        testdrive: {
            type: Boolean,
            default: false,
        },
        spoof: {
            type: Boolean,
            default: false,
        },
        errors: {
            brand: '',
            model: '',
            year_release: '',
            configuration: '',
            vin_code: '',
            passport: '',
            passport_from: '',
            car_number: '',
            mileage: '',
            color: '',
            motor: '',
            bodywork: '',
            kpp: '',
            actuator: '',
            engine_capacity: '',
            organization: '',
        },
    },
    methods: {
        updateActiveCheck(event, field){
            this.$emit('update-check', {
                checked: event.checked,
                field: field,
                id: this.id,
                organization: this.organization,
                brand: this.brand,
                model: this.model,
                year_release: this.year_release,
                configuration: this.configuration,
                price: this.price,
                vin_code: this.vin_code,
                passport: this.passport,
                passport_from: this.passport_from,
                car_number: this.car_number,
                mileage: this.mileage,
                color: this.color,
                motor: this.motor,
                bodywork: this.bodywork,
                kpp: this.kpp,
                actuator: this.actuator,
                engine_capacity: this.engine_capacity,
            })
        },
        save(){
            this.$emit('create-car', {
                id: this.id,
                organization: this.organization,
                brand: this.brand,
                model: this.model,
                year_release: this.year_release,
                configuration: this.configuration,
                price: this.price,
                vin_code: this.vin_code,
                passport: this.passport,
                passport_from: this.passport_from,
                car_number: this.car_number,
                mileage: this.mileage,
                color: this.color,
                motor: this.motor,
                bodywork: this.bodywork,
                kpp: this.kpp,
                actuator: this.actuator,
                engine_capacity: this.engine_capacity,
                testdrive: this.testdrive,
                spoof: this.spoof,
            })
        },
        selectBrand(event, value){
            this.errors[value] = '';
            this.$emit('select-brand', {
                brand: event,
                brand_id: event.id,
                id: this.id,
                organization: this.organization,
                model: this.model,
                year_release: this.year_release,
                configuration: this.configuration,
                price: this.price,
                vin_code: this.vin_code,
                passport: this.passport,
                passport_from: this.passport_from,
                car_number: this.car_number,
                mileage: this.mileage,
                color: this.color,
                motor: this.motor,
                bodywork: this.bodywork,
                kpp: this.kpp,
                actuator: this.actuator,
                engine_capacity: this.engine_capacity,
                testdrive: this.testdrive,
                spoof: this.spoof,
            });
        },
        selectModel(event, value){
            this.errors[value] = '';
        },
        clearInput(e){
            this.errors[e] = '';
        },
    }
}
</script>

<style scoped>

</style>

import * as auth from "../services/auth";
import IndexPage from "../Modules/Boards/IndexPage.vue";
import PCIndex from "../Modules/Boards/PersonalConsultant/PCIndex.vue";
import PCIndicators from "../Modules/Boards/PersonalConsultant/PCIndicators.vue";
import PCDisplay from "../Modules/Boards/PersonalConsultant/PCDisplay.vue";
import TOIndex from "../Modules/Boards/BoardTO/TOIndex.vue";
import Price from "../Modules/Boards/BoardTO/Price.vue";
import Display from "../Modules/Boards/BoardTO/Display.vue";

export default [
    {
        name: 'IndexPage',
        path: '/boards',
        component: IndexPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'PCIndex',
        path: '/boards/personalnyi-konsultant',
        component: PCIndex,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'PCIndicators',
        path: '/boards/personalnyi-konsultant/indicators',
        component: PCIndicators,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'PCDisplay',
        path: '/boards/personalnyi-konsultant/display',
        component: PCDisplay,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TOIndex',
        path: '/boards/doska-to',
        component: TOIndex,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Price',
        path: '/boards/doska-to/price',
        component: Price,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Display',
        path: '/boards/doska-to/display',
        component: Display,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">

                <div class="notifications_list">

                    <ul class="nav nav-pills gap-1 mb-3">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link fs-7"
                                id="first-tab"
                                :class="goActive('first')"
                                @click="changeTab('first')"
                                data-bs-target="#home">
                                Новые
                                <span class="badge rounded-pill text-bg-white">
                                    {{ this.total }}
                                </span>
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link fs-7"
                                :class="goActive('all')"
                                @click="changeTab('all')"
                                id="all-tab">
                                Прочитано
                                <span class="badge rounded-pill text-bg-white">
                                    {{ this.all }}
                                </span >
                            </button>
                        </li>
                    </ul>
                    <!-- Вкладки -->
                    <div class="tab-content">
                        <!-- Новые -->
                        <div class="tab-pane fade show" id="first" :class="goTabActive('first')">

                            <div class="n_calendar" v-for="(items, index) in notifications" :key="index.id">
                                <div class="calendar_date hstack justify-content-center my-2">
                                    <div class="badge fw-normal">{{ items.date }}</div>
                                </div>
                                <div class="hstack item_title justify-content-between p-0 pe-1"
                                     v-for="notification in items.data"
                                     :key="notification.id"
                                >
                                    <div class="n_icon me-3 ms-2">
                                        <span class="material-symbols-rounded ic-icon ic-yellow">{{ notification.icon }}</span>
                                    </div>
                                    <div class="n_content vstack">
                                        <h6 class="fw-semibold mb-1 mt-2">{{ notification.data['title'] }}</h6>
                                        <span class="fw-normal">{{ notification.data.body }}</span>
                                        <span class="hstack mt-2">
                                            <span class="btn btn-small btn-green me-2" v-if="notification.data.action_url" @click="goOover(notification.id, notification.data.action_url)">Открыть</span>
                                            <span class="btn btn-small gray" @click="read(notification.id)">Прочитано</span>
                                        </span>
                                        <span class="n_date hstack justify-content-end">
                                            <span class="n_date_time">{{ notification.m_date }}</span>
                                            <span class="n_status material-symbols-rounded ms-1">done_all</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- все -->
                        <div class="tab-pane fade" id="all" :class="goTabActive('all')">
                            <div class="n_calendar" v-for="(items, index) in allNotifications" :key="index.id">
                                <div class="calendar_date hstack justify-content-center my-2">
                                    <div class="badge fw-normal">{{ items.date }}</div>
                                </div>
                                <div class="hstack item_title justify-content-between p-0 pe-1"
                                     v-for="notification in items.data"
                                     :key="notification.id"
                                >
                                    <div class="n_icon me-3 ms-2">
                                        <span class="material-symbols-rounded ic-icon ic-yellow">{{ notification.icon }}</span>
                                    </div>
                                    <div class="n_content vstack">
                                        <h6 class="fw-semibold mb-1 mt-2">{{ notification.data['title'] }}</h6>
                                        <span class="fw-normal">{{ notification.data.body }}</span>
                                        <span class="n_date hstack justify-content-end">
                                            <span class="n_date_time">{{ notification.m_date }}</span>
                                            <span class="n_status material-symbols-rounded ms-1 read">done_all</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>

</template>

<script>
import spinner from "../components/Spinner";
import BreadCrumbs from "./BreadCrumbs";
import axios from "axios";
export default {
    name: "Notifications",
    components: {
        spinner, BreadCrumbs
    },
    data() {
        return {
            total: 0,
            all: 0,
            notifications: [],
            allNotifications: [],
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.notifications'),
            back_title: this.$t('request.main_menu'),
            link: '/',
        }
    },
    mounted() {
        this.getData();
        this.eventBus.on('notification', this.getNotificationsAndTotal);
    },
    methods: {
        changeTab(tab) {
            this.$router.push({ params: { active: tab } });
        },
        goActive(tab) {
            if (this.$route.params.active == tab) {
                return "active";
            } else {
                return "";
            }
        },
        goTabActive(tab) {
            if (this.$route.params.active == tab) {
                return "show active";
            } else {
                return "";
            }
        },
        read(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/notifications/read', {
                id: id,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        goOover(id, url){
            this.isLoading = true;
            if(url == false){
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                axios.post('/api/notifications/read', {
                    id: id,
                }, config)
                    .then(response => {
                        this.isLoading = false;
                        if (response.status == 200) {
                            this.getData();
                            //this.eventBus.on('notification', this.getNotificationsAndTotal);
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        console.log(errors);
                    });
            }else{
                this.$router.push(url);
            }
        },
        getNotificationsAndTotal(data){
            this.total = data.total;
            this.notifications = data.notifications;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/notifications', config);
                this.notifications = response.data.data.notifications;
                this.allNotifications = response.data.data.allNotifications;
                this.total = response.data.data.total;
                this.all = response.data.data.all;
                this.eventBus.emit('notification', response.data.data);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        }
    }

}

</script>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div class="container-fluid login_top">
            <div class="row">
                <div class="login_page_wrap align-items-center d-flex h-100 justify-content-start position-absolute col-12 g-0" >
                    <div class="align-items-center col-12 col-lg-5 col-md-6 col-xl-4 col-xxl-3 d-flex h-100 justify-content-center login_box">
                        <div class="auth_box">
                            <Form ref="form" @submit="sendLink">
                                <div class="logo">
                                    <img class="mb-4 logo-img" src="/image/logo.png" alt="">
                                </div>
                                <div class="fs-7 fw-normal text-center mb-3 opacity-75">
                                    Введите Email на который будет<br>отправлена ссылка для сброса пароля.
                                </div>
                                <div class="form-signin">
                                    <div class="login_input_box">
                                        <Field
                                            name="email"
                                            type="email"
                                            class="form-control input_login"
                                            placeholder="Email..."
                                            @input="errorClear()"
                                            v-model="email"
                                            :rules="validateEmail"
                                            :class="[errors.email.length > 0 ? 'error' : '']"/>
                                        <ErrorMessage name="email"/>
                                    </div>
                                    <span v-if="email_error" role="alert" class="mt-3">{{ errors.email }}</span>
                                    <button class="w-100 btn btn-lg btn-red mt-3">
                                        <span class="material-symbols-rounded me-2 opacity-50">forward_to_inbox</span>
                                        Отправить
                                    </button>
                                    <a style="cursor:pointer;" @click="back()" class="mt-3 btn gray">
                                        <span class="material-symbols-rounded me-2 opacity-50">undo</span>
                                        Вернуться
                                    </a>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            type="type"/>
    </transition>

</template>

<script>
import {Field, Form, ErrorMessage} from 'vee-validate';
import axios from "axios";
import ToastAlert from "../components/ToastAlert";
import spinner from "../components/Spinner";
export default {
    name: "Email",
    components: {
        Field, Form, ErrorMessage, ToastAlert, spinner
    },
    data() {
        return {
            type: 'green',
            message: '',
            isLoading: false,
            is_alert: false,
            email: null,
            errors: {
                email: '',
            },
            email_error: false,
        }
    },
    methods: {
        back(){
            this.$router.push('/')
        },
        errorClear(){
            this.errors.email = false;
        },
        validateEmail(value) {
            if (!value) {
                return 'Введите ваш Email';
            }
            return true;
        },
        closeAlert(){
            this.is_alert = false;
        },
        sendLink(){
            this.isLoading = true;

            axios.post('/api/reset-password', {
                email: this.email,
            })
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.email = null;
                        //this.is_alert = true;
                        //this.message = response.data.data.message;
                        //setTimeout(() => {this.closeAlert(); }, 4000);
                        this.$router.push('/reset-password/sended');
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
    }
}
</script>

<style scoped>

</style>

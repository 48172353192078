<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <Form ref="docs" @submit="save">
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">{{ $t('request.select_category') }}*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model="docs.category"
                                    :placeholder="$t('request.select_category')"
                                    label="name"
                                    track-by="id"
                                    :options="categories"
                                    :taggable="true"
                                >
                                </VueMultiselect>
                                <ErrorMessage name="item" style="color: red;"/>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">{{ $t('request.document_name') }}*</label>
                            <div class="col-sm-8 position-relative">
                                <Field
                                    v-model="docs.name"
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    :class="[errors.name.length > 0 ? 'error' : '']"
                                />
                                <ErrorMessage name="name" style="color: red;"/>
                            </div>
                        </div>

                        <div class="row mb-3"
                             v-if="is_route"
                             v-for="(user,idx) in newRoute"
                        >
                            <label class="col-sm-3 col-form-label required text-end"  v-text="`${idx+1}` +' '+ $t('request.responsible')"></label>
                            <div class="col-sm-8 position-relative">
                                <div class="remove-btn" @click="removeItem(idx)">
                                    <fa icon="circle-minus" class="delete_responsible"/>
                                </div>
                                <VueMultiselect
                                    v-model="newRoute[idx]"
                                    :placeholder="$t('request.choose_responsible')"
                                    label="fullname"
                                    track-by="id"
                                    :options="users"
                                    :taggable="true"
                                    :show-labels="false"
                                >
                                </VueMultiselect>
                                <ErrorMessage name="item" style="color: red;"/>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">{{ $t('request.add_responsible') }}</label>
                            <div class="col-sm-8 position-relative">
                                <fa icon="circle-plus" class="add_responsible" @click="addRoute"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-lg-2">
                                <button class="btn btn-green">
                                    {{ $t('request.button.save') }}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import axios from "axios";
import VueMultiselect from "vue-multiselect";

export default {
    name: "DocumentCreate",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect
    },
    data() {
        return {
            is_route: false,
            docs: {
                category: null,
                name: null,
            },
            organizations: [],
            organization: null,
            categories: [],
            newRoute: [],
            users: [],
            errors: {
                category: '',
                name: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.add'),
            back_title: this.$t('request.routes'),
            link: 'request/settings/documents',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        save(){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/settings/documents/store', {
                category: this.docs.category,
                name: this.docs.name,
                routs: this.newRoute,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push('/request/settings/documents')
                    }
                })
                .catch(errors => {
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.check.setErrors(errors.response.data.errors);
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            break;
                    }
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/settings/documents/create', config);
                this.categories = response.data.data.categories;
                this.users = response.data.data.users;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        addRoute() {
            this.is_route = true;
            this.newRoute.push({
                user: ''
            });
        },
        addItem() {
            this.newRoute.data.push(null)
        },
        removeItem(index){
            this.newRoute.splice(index,1)
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <template v-if="is_generalnyi">
                    <div v-if="Object.keys(lists).length">
                        <div class="accordion accordion-flush mb-2" :id="'accordionFlushExample-'+ items.id" v-for="(items) in lists" :key="items.id">
                            <div class="accordion-item p-3 rounded">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed p-0 fs-7" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne-'+ items.id" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <h3>{{ items.name }}</h3>
                                    </button>
                                </h2>
                                <div :id="'flush-collapseOne-'+ items.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" :data-bs-parent="'#accordionFlushExample-'+ items.id">
                                    <div v-if="items.requests.length">
                                        <div class="item_title mb-2" v-for="list in items.requests" :key="list.id">
                                            <!-- Номер и статус -->
                                            <div class="d-flex justify-content-between fs-7 mb-2">
                                                <div class="fs-5 fw-bold lh-base">№{{ list.id }}</div>
                                                <div
                                                    :class="list.status"
                                                    class="badge fs-7 fw-normal lh-base px-3 rounded-pill"
                                                >{{ list.status_title }}</div>
                                            </div>

                                            <div class="mt-3 fw-normal">

                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.created') }}:</div>
                                                    <div class="fw-bold text-end">{{ list.date_create }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.applicant') }}:</div>
                                                    <div class="fw-bold text-end" v-if="list.user !== null">{{ list.user.surname }} {{ list.user.name }}</div>
                                                    <div class="fw-bold text-end text-danger" v-else>Автор заявки удален!</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.category') }}:</div>
                                                    <div class="fw-bold text-end">{{ list.category.name }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.status') }}:</div>
                                                    <div class="fw-bold text-end">{{ list.status_text }}</div>
                                                </div>

                                                <div class="vstack justify-content-between mb-4">
                                                    <div class="pt-2 pb-1 fs-7">{{ $t('request.content') }}:</div>
                                                    <div class="alert-attachments fw-semibold lh-base rounded-3 w-100"
                                                         :class="is_hidden ? 'min-block' : 'max-block'"
                                                         v-html="list.contents"
                                                    ></div>
                                                    <div class="btn btn-small gray w-100 mt-1" @click="showHideContent" v-if="list.contents != null && list.contents.length > 300">
                                                        <span v-if="is_hidden">Читать полностью</span>
                                                        <span v-if="!is_hidden">Свернуть</span>
                                                    </div>
                                                </div>

                                                <!-- Вложения -->
                                                <div class="attchmts mb-4" v-if="(list.files.length > 0) || (Object.keys(list.user_files).length > 0)">
                                                    <h6>Прикрепленные файлы</h6>
                                                    <div v-if="list.files.length > 0">
                                                        <!-- От заявителя -->
                                                        <div class="alert alert-attachments mb-2 rounded-3" role="alert" v-if="list.user !== null">
                                                            <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ list.user.surname }} {{ list.user.name }}</div>
                                                            <div v-for="file in list.files" :key="file.id">
                                                                <a data-fancybox :href="file.link" class="attachment mb-1" v-if="file.type">
                                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                                </a>
                                                                <a @click="downloadURL(file.link, file.name)" class="attachment mb-1" v-else>
                                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- От сотрудников -->
                                                    <div v-if="Object.keys(list.user_files).length > 0">
                                                        <div v-for="(items, name) in list.user_files" :key="name">
                                                            <div class="alert alert-attachments mb-2 rounded-3" role="alert">
                                                                <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ name }}</div>
                                                                <div v-for="item in items" :key="item">
                                                                    <a data-fancybox :href="item.link" class="attachment mb-1" style="cursor: pointer;" v-if="item.type">
                                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                                    </a>
                                                                    <a @click="downloadURL(item.link, item.name)" class="attachment mb-1" style="cursor: pointer;" v-else>
                                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div v-if="list.in_final_step">
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn w-100 btn-green"
                                                            @click="showModal(list.id, 'acceptFinal')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                                        {{ $t('request.button.approval') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn w-100 btn-green"
                                                            @click="showModal(list.id, 'acceptToOrder')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="file-circle-check"></fa>
                                                        {{ $t('request.button.approval_in_order') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2" v-if="list.document_id != 4">
                                                    <button class="btn w-100 btn-green"
                                                            @click="showModal(list.id, 'authProc')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="cart-shopping"></fa>
                                                        {{ $t('request.button.allow_purchase') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn w-100 btn-yellow"
                                                            @click="showModal(list.id, 'return')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                                        {{ $t('request.button.agreement') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn w-100 btn-red"
                                                            @click="showModal(list.id, 'denied')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="ban"></fa>
                                                        {{ $t('request.button.reject') }}
                                                    </button>
                                                </div>
                                            </div>

                                            <div v-if="!list.return_to && !list.in_final_step && !list.in_hr_work">
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn_vw btn-green"
                                                            @click="showModal(list.id, 'approve')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                                        {{ $t('request.button.approve') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn_vw btn-yellow"
                                                            @click="showModal(list.id, 'return')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                                        {{ $t('request.button.agreement') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn_vw btn-red"
                                                            @click="showModal(list.id, 'denied')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="ban"></fa>
                                                        {{ $t('request.button.reject') }}
                                                    </button>
                                                </div>
                                            </div>

                                            <div v-if="list.return_to">
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn_vw btn-green"
                                                            :disabled="list.is_under_review"
                                                            @click="showModal(list.id, 'send_corrected')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                                        {{ $t('request.button.to_send') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn_vw btn-yellow"
                                                            :disabled="list.is_under_review"
                                                            @click="showModal(list.id, 'return')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                                        {{ $t('request.button.agreement') }}
                                                    </button>
                                                </div>
                                            </div>

                                            <div v-if="list.in_hr_work || list.in_supply_work">
                                                <hr/>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn w-100 gray"
                                                            @click="downloadMemos(list.id)"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="cloud-arrow-down"></fa>
                                                        {{ $t('request.button.download_document') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2" v-if="list.in_hr_work">
                                                    <button class="btn w-100 btn-green"
                                                            @click="showModal(list.id, 'completed')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                                        {{ $t('request.button.executed') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2" v-if="list.in_supply_work">
                                                    <button class="btn w-100 btn-green"
                                                            @click="showModal(list.id, 'completed_sp')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                                        {{ $t('request.button.executed') }}
                                                    </button>
                                                </div>
                                            </div>

                                            <hr/>

                                            <div class="col-sm position-relative mb-2 mt-3">
                                                <button class="btn w-100 gray"
                                                        @click="getHistory(list.historys, list.id, list.is_under_review)"
                                                >
                                                    <fa class="icon me-2 opacity-50" icon="file-lines"></fa>
                                                    {{ $t('request.button.history') }}
                                                    <span class="badge rounded-pill text-bg-white ms-2" v-if="list.is_under_review">
                                                        <span class="material-symbols-rounded icon-custom">question_mark</span>
                                                    </span>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="mb-3">
                                            <div class="item_title fw-semibold">
                                                {{ $t('request.list_requests_is_empty') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="lists.length">
                        <div class="item_title" v-for="list in lists" :key="list.id">
                            <!-- Номер и статус -->
                            <div class="d-flex justify-content-between fs-7 mb-2">
                                <div class="fs-5 fw-bold lh-base">№{{ list.id }}</div>
                                <div
                                    :class="list.status"
                                    class="badge fs-7 fw-normal lh-base px-3 rounded-pill"
                                >{{ list.status_title }}</div>
                            </div>

                            <div class="mt-3 fw-normal">

                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.created') }}:</div>
                                    <div class="fw-bold text-end">{{ list.date_create }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.applicant') }}:</div>
                                    <div class="fw-bold text-end" v-if="list.user !== null">{{ list.user.surname }} {{ list.user.name }}</div>
                                    <div class="fw-bold text-end text-danger" v-else>Автор заявки удален!</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.category') }}:</div>
                                    <div class="fw-bold text-end">{{ list.category.name }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.status') }}:</div>
                                    <div class="fw-bold text-end">{{ list.status_text }}</div>
                                </div>

                                <div class="vstack justify-content-between mb-4">
                                    <div class="pt-2 pb-1 fs-7">{{ $t('request.content') }}:</div>
                                    <div class="alert-attachments fw-semibold lh-base rounded-3 w-100"
                                         :class="is_hidden ? 'min-block' : 'max-block'"
                                         v-html="list.contents"
                                    ></div>
                                    <div class="btn btn-small gray w-100 mt-1" @click="showHideContent" v-if="list.contents != null && list.contents.length > 300">
                                        <span v-if="is_hidden">Читать полностью</span>
                                        <span v-if="!is_hidden">Свернуть</span>
                                    </div>
                                </div>

                                <!-- Вложения -->
                                <div class="attchmts mb-4" v-if="(list.files.length > 0) || (Object.keys(list.user_files).length > 0)">
                                    <h6>Прикрепленные файлы</h6>
                                    <div v-if="list.files.length > 0">
                                        <!-- От заявителя -->
                                        <div class="alert alert-attachments mb-2 rounded-3" role="alert" v-if="list.user !== null">
                                            <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ list.user.surname }} {{ list.user.name }}</div>
                                            <div v-for="file in list.files" :key="file.id">
                                                <a data-fancybox :href="file.link" class="attachment mb-1" v-if="file.type">
                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                </a>
                                                <a @click="downloadURL(file.link, file.name)" class="attachment mb-1" v-else>
                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- От сотрудников -->
                                    <div v-if="Object.keys(list.user_files).length > 0">
                                        <div v-for="(items, name) in list.user_files" :key="name">
                                            <div class="alert alert-attachments mb-2 rounded-3" role="alert">
                                                <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ name }}</div>
                                                <div v-for="item in items" :key="item">
                                                    <a data-fancybox :href="item.link" class="attachment mb-1" style="cursor: pointer;" v-if="item.type">
                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                    </a>
                                                    <a @click="downloadURL(item.link, item.name)" class="attachment mb-1" style="cursor: pointer;" v-else>
                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div v-if="list.in_final_step">
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn w-100 btn-green"
                                            @click="showModal(list.id, 'acceptFinal')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                        {{ $t('request.button.approval') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn w-100 btn-green"
                                            @click="showModal(list.id, 'acceptToOrder')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="file-circle-check"></fa>
                                        {{ $t('request.button.approval_in_order') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2" v-if="list.document_id != 4">
                                    <button class="btn w-100 btn-green"
                                            @click="showModal(list.id, 'authProc')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="cart-shopping"></fa>
                                        {{ $t('request.button.allow_purchase') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn w-100 btn-yellow"
                                            @click="showModal(list.id, 'return')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                        {{ $t('request.button.agreement') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn w-100 btn-red"
                                            @click="showModal(list.id, 'denied')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="ban"></fa>
                                        {{ $t('request.button.reject') }}
                                    </button>
                                </div>
                            </div>


                            <div v-if="!list.return_to && !list.in_final_step && !list.in_hr_work">
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn_vw btn-green"
                                            @click="showModal(list.id, 'approve')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                        {{ $t('request.button.approve') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn_vw btn-yellow"
                                            @click="showModal(list.id, 'return')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                        {{ $t('request.button.agreement') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn_vw btn-red"
                                            @click="showModal(list.id, 'denied')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="ban"></fa>
                                        {{ $t('request.button.reject') }}
                                    </button>
                                </div>
                            </div>


                            <div v-if="list.return_to">
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn_vw btn-green"
                                            :disabled="list.is_under_review"
                                            @click="showModal(list.id, 'send_corrected')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                        {{ $t('request.button.to_send') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn_vw btn-yellow"
                                            :disabled="list.is_under_review"
                                            @click="showModal(list.id, 'return')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                        {{ $t('request.button.agreement') }}
                                    </button>
                                </div>
                            </div>

                            <div v-if="list.in_hr_work || list.in_supply_work">
                                <hr/>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn w-100 gray"
                                            @click="downloadMemos(list.id)"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="cloud-arrow-down"></fa>
                                        {{ $t('request.button.download_document') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2" v-if="list.in_hr_work">
                                    <button class="btn w-100 btn-green"
                                            @click="showModal(list.id, 'completed')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                        {{ $t('request.button.executed') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2" v-if="list.in_supply_work">
                                    <button class="btn w-100 btn-green"
                                            @click="showModal(list.id, 'completed_sp')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                        {{ $t('request.button.executed') }}
                                    </button>
                                </div>
                            </div>

                            <hr/>

                            <div class="col-sm position-relative mb-2 mt-3">
                                <button class="btn w-100 gray"
                                        @click="getHistory(list.historys, list.id, list.is_under_review)"
                                >
                                    <fa class="icon me-2 opacity-50" icon="file-lines"></fa>
                                    {{ $t('request.button.history') }}
                                    <span class="badge rounded-pill text-bg-white ms-2" v-if="list.is_under_review">
                                                        <span class="material-symbols-rounded icon-custom">question_mark</span>
                                                    </span>
                                </button>
                            </div>

<!--
                            <transition-group name="list">
                                <div class="modal d-block" v-if="is_modal">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title"></h5>
                                                <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="mb-3" v-if="is_approval">
                                                    <label class="form-label fw-normal">{{ $t('request.select_employee') }}*</label>
                                                    <VueMultiselect
                                                        v-model="user"
                                                        :placeholder="$t('request.select_employee')"
                                                        label="name"
                                                        track-by="id"
                                                        :options="users"
                                                        name="user"
                                                        @select="selectUser"
                                                        :show-labels="false"
                                                    >
                                                    </VueMultiselect>
                                                    <div class="invalid-feedback" :class="errors.user.length > 0 ? 'visible' : ''">{{ errors.user }}</div>

                                                </div>
                                                <div class="mb-3" v-if="is_completed || send_corrected">
                                                    <label for="formFileMultiple" class="form-label fw-normal">{{ $t('request.attach_files') }}</label>
                                                    <input ref="files" class="form-control" type="file" id="formFileMultiple" multiple
                                                           v-on:change="handleFileUpload()"
                                                    >
                                                </div>
                                                <div class="mb-3 mt-3">
                                                    <label class="form-label fw-normal">{{ $t('request.comment') }}</label>
                                                    <textarea class="form-control" rows="3" v-model="comment"></textarea>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn w-100 btn-green"
                                                            @click="save"
                                                    >
                                                        {{ $t('request.button.save') }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
-->

                        </div>
                    </div>
                    <div v-else>
                        <div class="mb-3">
                            <div class="item_title fw-semibold">
                                {{ $t('request.list_requests_is_empty') }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </transition>

    <transition-group name="list">
        <div class="modal d-block" v-if="is_modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3" v-if="is_approval">
                            <label class="form-label fw-normal">{{ $t('request.select_employee') }}*</label>
                            <VueMultiselect
                                v-model="user"
                                :placeholder="$t('request.select_employee')"
                                label="name"
                                track-by="id"
                                :options="users"
                                name="user"
                                @select="selectUser"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <div class="invalid-feedback" :class="errors.user.length > 0 ? 'visible' : ''">{{ errors.user }}</div>

                        </div>
                        <div class="mb-3" v-if="is_completed || send_corrected">
                            <label for="formFileMultiple" class="form-label fw-normal">{{ $t('request.attach_files') }}</label>
                            <input ref="files" class="form-control" type="file" id="formFileMultiple" multiple
                                   v-on:change="handleFileUpload()"
                            >
                        </div>
                        <div class="mb-3 mt-3">
                            <label class="form-label fw-normal">{{ $t('request.comment') }}</label>
                            <textarea class="form-control" rows="3" v-model="comment"></textarea>
                        </div>
                        <div class="col-sm position-relative mb-2 mt-2">
                            <button class="btn w-100 btn-green"
                                    @click="save"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition-group>

    <transition-group name="list">
        <history
            v-if="is_history"
            :historis="historis"
            @close-form="close"
            :is_print="true"
            :id="id"
        />
    </transition-group>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import History from "../Imprest/History";
import ToastAlert from "../../../components/ToastAlert";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import * as req from "../../../services/request.js";

export default {
    name: "MemosChiefList",
    components: {
        History, ToastAlert,
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect
    },
    data(){
        return {
            is_hidden: true,
            scroll: 0,
            is_generalnyi: false,
            is_modal: false,
            is_approval: false,
            is_completed: false,
            send_corrected: false,
            type_method: null,
            id_memos: null,
            message: '',
            type: 'green',
            is_alert: false,
            is_history: false,
            historis: [],
            id:null,
            lists: [],
            link_image: '',
            comment: '',
            files: '',
            nameDoc: '',
            users: [],
            user:null,
            errors: {
                user: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.list_requests'),
            back_title: this.$t('request.memos'),
            link: 'request/memos',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        showHideContent(){
            this.is_hidden = !this.is_hidden;
            if(!this.is_hidden){
                this.scroll = window.scrollY;
            }else{
                window.scrollTo(0, this.scroll);
            }
        },
        selectUser(value){
            this.errors.user = '';
        },
        handleFileUpload(){
            this.files = this.$refs.files.files;
        },
        closeModal(){
            this.is_modal = false;
            this.is_approval = false;
            this.send_corrected = false;
            this.is_completed = false;
            this.type_method = null;
            this.comment = '';
        },
        showModal(id, type){
            if(type == 'return'){
                this.is_approval = true;
            }
            if(type == 'completed'){
                this.is_completed = true;
            }
            if(type == 'send_corrected'){
                this.send_corrected = true;
            }
            this.id_memos = id;
            this.type_method = type;
            this.is_modal = true;
        },
        closeAlert(){
            this.is_alert = false;
        },
        close(){
            this.is_history = false;
        },
        async getHistory(data, id, is_under_review){
            if(is_under_review){
                let result = await req.viewingHistory(id);
                if(result.message === 'success'){
                    this.getData();
                }
            }
            this.historis = data;
            this.id = id;
            this.is_history = true;
        },
        downloadURL(url, name){
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.onload = function(e) {
                if (this.status == 200) {
                    var myBlob = this.response;
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(myBlob);
                    link.download = name;
                    link.click();
                }
            };
            xhr.send();
        },
        async downloadMemos(id){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/memos/download/${id}`, config);
                this.url = response.data.data.url;
                this.nameDoc = response.data.data.nameDoc;
                this.downloadURL(this.url, this.nameDoc);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/memos/waiting', config);
                this.lists = response.data.data.lists;
                this.users = response.data.data.users;
                this.is_generalnyi = response.data.data.is_generalnyi;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        save(){
            if(this.type_method == 'denied'){
                this.denied();
            }else if(this.type_method == 'return'){
                this.returnForApproval();
            }else if(this.type_method == 'approve'){
                this.approve();
            }else if(this.type_method == 'send_corrected'){
                this.sendCorrected();
            }else if(this.type_method == 'acceptFinal'){
                this.accept();
            }else if(this.type_method == 'acceptToOrder'){
                this.accept();
            }else if(this.type_method == 'authProc'){
                this.accept();
            }else if(this.type_method == 'completed'){
                this.completed();
            }else if(this.type_method == 'completed_sp'){
                this.completed();
            }
        },
        completed(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            const formData = new FormData();
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }
            formData.append('comment', this.comment);
            formData.append('id', this.id_memos);
            axios.post('/api/admin/memos/completed', formData, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.memo_executed');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.is_approval = false;
                        this.send_corrected = false;
                        this.is_completed = false;
                        this.type_method = '';
                        this.comment = '';
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        accept(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/memos/accept', {
                comment: this.comment,
                id: this.id_memos,
                type_method: this.type_method,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.memo_accept');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.is_approval = false;
                        this.type_method = '';
                        this.comment = '';
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 400:
                            this.type = 'red';
                            this.message = errors.response.data.data.message;
                            this.is_alert = true;
                            setTimeout(() => {this.closeAlert(); }, 5000);
                    }
                });
        },
        sendCorrected(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            const formData = new FormData();
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }

            formData.append('comment', this.comment);
            formData.append('id', this.id_memos);
            axios.post('/api/admin/memos/send', formData, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.memo_sent');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.is_approval = false;
                        this.type_method = '';
                        this.comment = '';
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        approve(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/memos/approve', {
                comment: this.comment,
                id: this.id_memos,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.memo_approved');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.is_approval = false;
                        this.type_method = '';
                        this.comment = '';
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        returnForApproval(){
            if(this.is_approval && this.user == null) {
                this.errors.user = this.$t('request.select_employee');
            }else if(this.is_approval && this.user != null){
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                axios.post('/api/admin/memos/return', {
                    user: this.user,
                    comment: this.comment,
                    id: this.id_memos,
                }, config)
                    .then(response => {
                        this.isLoading = false;
                        if (response.status == 200) {
                            this.is_alert = true;
                            this.type = 'yellow';
                            this.message = this.$t('request.memo_approval');
                            setTimeout(() => {this.closeAlert(); }, 3000);
                            this.is_modal = false;
                            this.is_approval = false;
                            this.type_method = '';
                            this.comment = '';
                            this.getData();
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        console.log(errors);
                    });
            }
        },
        denied(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/memos/denied', {
                comment: this.comment,
                id: this.id_memos,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.type = 'red'
                        this.message = this.$t('request.memo_rejected');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.is_approval = false;
                        this.type_method = '';
                        this.comment = '';
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>
.visible {
    display: unset!important;
}
.min-block {
    max-height: 300px;
    overflow: hidden;
    transition: max-height 0.6s ease-out;
}

.max-block {
    transition: max-height 0.3s ease-in;
    max-height: 100%;
}
</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page">
                <!-- Accordion Flash -->
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item p-3 rounded">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed p-0 fs-7" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <span class="material-symbols-outlined me-2 text-blue">settings</span>
                                <span class="text-black">Управление</span>
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="d-flex justify-content-between mt-4">
                                <Button
                                    v-if="is_admin"
                                    caption="Список показателей"
                                    color="gray"
                                    add_class="mb-3 btn-small"
                                    @click="showForm('list_indicators')"
                                />
                                <Button
                                    caption="Закрыть месяц"
                                    color="gray"
                                    add_class="mb-3 btn-small"
                                    @click="showForm('close_month')"
                                />
                                <Button
                                    v-if="is_admin"
                                    caption="Добавить показатель"
                                    color="gray"
                                    add_class="mb-3 btn-small"
                                    @click="showForm('add_indicator')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-2">
                    <div class="d-flex justify-content-between">
                        <h1 class="text-center font-weight-bold" style="color: #b3b6ba">
                            {{ boardTitle }}
                        </h1>
                    </div>

                    <div class="bg-light mb-5 p-4">
                        <table class="table table-hover">
                            <thead>
                            <tr>
                                <th scope="col"></th>
                                <th v-for="(header, index) in tableHeaders" :key="index" class="text-center">
                                    {{ header }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- Общие планы -->
                            <tr>
                                <td colspan="10">
                                    <h6 class="text-center mt-1">Общие планы</h6>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-nowrap">Текущий год</th>
                                <td v-for="(field, index) in yearPlanFields" :key="index">
                                    <input
                                        type="number"
                                        class="form-control"
                                        v-model="field.plan"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-nowrap">Текущий месяц</th>
                                <td v-for="(field, index) in monthPlanFields" :key="index">
                                    <input
                                        v-if="field"
                                        type="number"
                                        class="form-control"
                                        v-model="field.plan"
                                    />
                                </td>
                            </tr>

                            <!-- Планы сотрудников -->
                            <tr>
                                <td colspan="10">
                                    <h6 class="text-center mt-1">Планы сотрудников</h6>
                                </td>
                            </tr>
                            <tr v-for="user in users" :key="user.id" class="plan-tr">
                                <th scope="row" class="text-nowrap align-middle">{{ user.user_name }}</th>
                                <td v-for="(field, index) in user.plans" :key="index" class="align-middle" style="background-color: rgba(209, 224, 238, 0.3);">
                                    <div class="d-flex flex-column">
                                        <div class="col-12" v-if="field.has_plan">
                                            <small class="form-text text-center text-muted text-truncate" style="white-space: nowrap;">{{ field.title }} план</small>
                                            <input type="number" class="form-control" v-model="field.plan">
                                        </div>
                                        <div class="col-12" v-if="field.has_fact">
                                            <small class="form-text text-center text-muted text-truncate" style="white-space: nowrap;">{{ field.title }} факт</small>
                                            <input type="number" class="form-control" v-model="field.fact">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <hr class="pb-3" />
                        <div class="actions d-flex pb-3">
                            <div class="col text-right">
                                <button type="button" class="btn btn-success col-4" @click="saveData">
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{name_form}}
            </template>
            <template v-slot:body>
                <template v-if="action === 'list_indicators'">
                    <div class="table-responsive-md" v-if="indicators.length > 0">
                        <table class="table table-row table-app">
                            <thead>
                            <tr>
                                <th class="text-muted">Название</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in indicators" :key="item.id">
                                <td>
                                    {{ item.name }}
                                </td>
                                <td class="text-end" style="width: 5%;">
                                    <div class="dropdown">
                                        <button
                                            class="btn btn_drop px-0"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            id="dropdownMenuButton">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="#ccc"
                                                class="bi bi-three-dots-vertical"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                            </svg>
                                        </button>
                                        <ul
                                            class="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton">
                                            <li>
                                                <button
                                                    type="button"
                                                    @click="editItem(item)"
                                                    class="dropdown-item">Редактировать</button>
                                            </li>
                                            <li>
                                                <hr class="dropdown-divider"></li>
                                            <li>
                                                <button
                                                    type="button"
                                                    @click="deleteItem(item.id)"
                                                    class="dropdown-item">Удалить</button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive-md" v-else>
                        <h3>Данных нет!</h3>
                    </div>
                </template>

                <div v-if="action === 'add_indicator'">
                    <Input
                        v-model:modelValue="name"
                        @clear="clearInput($event)"
                        :error="[errors.name.length ? 'error' : '']"
                        label="Название показателя"
                        name="name"
                        type="text"
                        placeholder="Название показателя"
                        id="name"
                    />

                    <!-- Чекбокс для плана -->
                    <div class="row">
                        <div class="col-md">
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckHasPlan">
                                    <input
                                        id="flexCheckHasPlan"
                                        class="form-check-input border_ckeckbox"
                                        type="checkbox"
                                        v-model="has_plan"
                                    />
                                    План
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- Чекбокс для факта -->
                    <div class="row">
                        <div class="col-md">
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckHasFact">
                                    <input
                                        id="flexCheckHasFact"
                                        class="form-check-input border_ckeckbox"
                                        type="checkbox"
                                        v-model="has_fact"
                                    />
                                    Факт
                                </label>
                            </div>
                        </div>
                    </div>

                    <Button
                        caption="Сохранить"
                        color="btn-green"
                        add_class=""
                        @click="save()"
                    />
                </div>

                <div style="width: 350px; margin: 0 auto;" v-if="action === 'close_month'">
                    <h5 class="mb-3">Вы уверены что хотите закрыть месяц?</h5>

                    <div class="d-flex justify-content-between mt-5">
                        <Button
                            caption="Нет"
                            color="btn-red"
                            add_class=""
                            @click="closeModal()"
                        />

                        <Button
                            caption="Да"
                            color="btn-green"
                            add_class=""
                            @click="closeMonth()"
                        />
                    </div>
                </div>
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import BreadCrumbs from "../../../Page/BreadCrumbs.vue";
import Spinner from "../../../components/Spinner.vue";
import * as req from "../../../services/boards";
import Button from "../../../components/Button.vue";
import Modal from "../../../components/Modal.vue";
import Input from "../../../components/Input.vue";
import ToastAlert from "../../../components/ToastAlert.vue";

export default {
    name: "PCIndicators",
    components: {
        ToastAlert,
        Input, Modal,
        Spinner, BreadCrumbs, Button
    },
    data() {
        return {
            is_alert: false,
            isModal: false,
            is_admin: false,
            action: '',
            message: '',
            type: '',
            name_form: '',
            isLoading: false,
            breadCrumbs: true,
            title: 'Индикаторы',
            back_title: 'Персональный консультант',
            link: 'boards/personalnyi-konsultant',
            boardTitle: '',
            users: [],
            name: '',
            id: null,
            has_plan: true,
            has_fact: true,
            tableHeaders: [],
            yearPlanFields: [],
            monthPlanFields: [],
            indicators: [],
            errors: {
                name: ''
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        async getData(){
            this.isLoading = true;
            let result = await req.fetchBoardData('personalnyi-konsultant');
            this.boardTitle = result.data.boardTitle;
            this.users = result.data.users;
            this.tableHeaders = result.data.tableHeaders;
            this.yearPlanFields = result.data.yearPlanFields;
            this.monthPlanFields = result.data.monthPlanFields;
            this.is_admin = result.is_admin;
            this.isLoading = false;
        },
        async showForm(action){
            this.action = action;
            if(action === 'add_indicator'){
                this.name_form = 'Добавить';
                this.isModal = true;
            }
            if(action === 'list_indicators'){
                this.name_form = 'Список инидикаторов';
                let result = await req.getIndicators('personalnyi-konsultant');
                this.indicators = result.indicatorsList;
                this.isModal = true;
            }
            if(action === 'close_month'){
                this.name_form = 'Закрыть месяц';
                this.isModal = true;
            }
        },
        clearInput(e){
            this.errors[e] = '';
        },
        async closeMonth(){
            this.isLoading = true;
            let items = {
                alias: 'personalnyi-konsultant',
            }
            let result = await req.closeIndicatorMonth(items);
            this.message = result.message;
            this.type = 'green';
            this.getData();
            this.closeModal();
            this.is_alert = true;
            setTimeout(() => {this.closeAlert(); }, 3000);
        },
        async save(){
            this.isLoading = true;
            let items = {
                alias: 'personalnyi-konsultant',
                name: this.name,
                id: this.id,
                has_plan: this.has_plan,
                has_fact: this.has_fact,
            }
            let result = await req.saveIndicator(items);
            if(result.status === 422){
                this.errors = result.data.errors;
                this.isLoading = false;
                this.type = 'red';
                this.message = errorMessages(this.errors)
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }else {
                this.type = 'green';
                this.isLoading = false;
                this.closeModal();
                this.is_alert = true;
                this.message = result.message;
                this.users = result.data.users;
                this.tableHeaders = result.data.tableHeaders;
                this.yearPlanFields = result.data.yearPlanFields;
                this.monthPlanFields = result.data.monthPlanFields;
                setTimeout(() => {this.closeAlert(); }, 3000);
            }
        },
        closeModal() {
            this.name = '';
            this.id = null;
            this.has_plan = true;
            this.has_fact = true;
            this.isModal = false;
        },
        async saveData(){
            this.isLoading = true;
            const data = {
                yearPlan: this.yearPlanFields,
                monthPlan: this.monthPlanFields,
                userPlans: this.users,
                alias: 'personalnyi-konsultant',
            }
            let result = await req.saveValueIndicator(data);
            if(result.status === 422){
                this.errors = result.data.errors;
                this.isLoading = false;
                this.type = 'red';
                this.message = errorMessages(this.errors)
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }else {
                this.type = 'green';
                this.closeModal();
                this.is_alert = true;
                this.message = result.message;
                this.users = result.data.users;
                this.yearPlanFields = result.data.yearPlanFields;
                this.monthPlanFields = result.data.monthPlanFields;
                setTimeout(() => {this.closeAlert(); }, 3000);
                this.isLoading = false;
            }
        },
        editItem(item){
            this.id = item.id;
            this.name = item.name;
            this.has_plan = Boolean(item.has_plan); // Преобразуем в boolean
            this.has_fact = Boolean(item.has_fact); // Преобразуем в boolean
            this.action = 'add_indicator';
        },
        async deleteItem(id){
            const data = {
                id: id,
                alias: 'personalnyi-konsultant',
            }

            let result = await req.deleteIndicator(data);
            this.type = 'green';
            this.is_alert = true;
            this.message = result.message;
            this.users = result.data.users;
            this.yearPlanFields = result.data.yearPlanFields;
            this.monthPlanFields = result.data.monthPlanFields;
            setTimeout(() => {this.closeAlert(); }, 3000);
            this.closeModal();
            this.isLoading = false;
        }
    }
}
</script>

<style scoped>

</style>

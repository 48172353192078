<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h5 class="py-2">Список заявок на аванс</h5>

                <template v-if="is_generalnyi">
                    <div v-if="Object.keys(lists).length">
                        <div class="accordion accordion-flush mb-2" :id="'accordionFlushExample-'+ items.id" v-for="(items) in lists" :key="items.id">
                            <div class="accordion-item p-3 rounded">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed p-0 fs-7" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne-'+ items.id" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <h3>{{ items.name }}</h3>
                                    </button>
                                </h2>
                                <div :id="'flush-collapseOne-'+ items.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" :data-bs-parent="'#accordionFlushExample-'+ items.id">
                                    <div v-if="items.requests.length">
                                        <div class="item_title mb-2"
                                            v-for="list in items.requests"
                                            :key="list.id"
                                        >
                                            <!-- Номер, дата -->
                                            <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                                            <div class="fs-6 fw-bold">№{{ list.id }}</div>
                                            <div>{{ moment(list.created_at).format("DD.MM.YYYY hh:mm") }}</div>
                                            </div>
                                            <!-- Сотрудник -->
                                            <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                            <span class="fw-normal">{{ $t("request.worker") }}</span>
                                            <span class="text-end fw-semibold">{{ list.user.surname }} {{ list.user.name }}</span>
                                            </div>
                                            <!-- Сумма -->
                                            <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                            <span class="fw-normal">{{ $t("request.amount") }}</span>
                                            <span class="text-end fw-semibold">{{ cur(list.payment_amount) }}</span>
                                            </div>
                                            <!-- Способ получения -->
                                            <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
                                                <span class="text-end fw-semibold" v-if="list.payment_type == 'money'">Наличные</span>
                                                <span class="text-end fw-semibold" v-else>На карту</span>
                                            </div>
                                            <div class="mt-4 mb-2">
                                                <router-link class="btn btn-yellow btn_vw" :to="`/request/imprest/waiting/${list.id}`">
                                                    <span class="material-symbols-rounded opacity-50 me-1">low_priority</span>
                                                    {{ $t('request.button.processing') }}
                                                </router-link >
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="item_title mb-3">
                                            {{ $t('request.list_requests_is_empty') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="lists.length">
                        <div class="item_title mb-2"
                             v-for="list in lists"
                             :key="list.id"
                        >
                            <!-- Номер, дата -->
                            <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                                <div class="fs-6 fw-bold">№{{ list.id }}</div>
                                <div>{{ moment(list.created_at).format("DD.MM.YYYY hh:mm") }}</div>
                            </div>
                            <!-- Сотрудник -->
                            <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                <span class="fw-normal">{{ $t("request.worker") }}</span>
                                <span class="text-end fw-semibold">{{ list.user.surname }} {{ list.user.name }}</span>
                            </div>
                            <!-- Сумма -->
                            <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                <span class="fw-normal">{{ $t("request.amount") }}</span>
                                <span class="text-end fw-semibold">{{ cur(list.payment_amount) }}</span>
                            </div>
                            <!-- Способ получения -->
                            <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
                                <span class="text-end fw-semibold" v-if="list.payment_type == 'money'">Наличные</span>
                                <span class="text-end fw-semibold" v-else>На карту</span>
                            </div>
                            <div class="mt-4 mb-2">
                                <router-link class="btn btn-yellow btn_vw" :to="`/request/imprest/waiting/${list.id}`">
                                    <span class="material-symbols-rounded opacity-50 me-1">low_priority</span>
                                    {{ $t('request.button.processing') }}
                                </router-link >
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="item_title mb-3">
                            {{ $t('request.list_requests_is_empty') }}
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import axios from "axios";
import VueMultiselect from "vue-multiselect";
import moment from "moment";

export default {
    name: "ImprestAccountantList",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect
    },
    data(){
        return {
            lists: [],
            isLoading: false,
            is_generalnyi: false,
            breadCrumbs: true,
            title: this.$t('request.list_requests'),
            back_title: this.$t('request.advance_section'),
            link: 'request/imprest',
        }
    },
    created(){
        this.getData();
    },
    methods: {
        moment,
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/imprest/list/accountant', config);
                this.lists = response.data.data.lists;
                this.is_generalnyi = response.data.data.is_generalnyi;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        cur(val) {
            if (!val) {
                return "0 KZT";
            }
            const format = new Intl.NumberFormat("ru-Ru", {
                style: "currency",
                currency: "KZT",
                minimumFractionDigits: 0,
            });
            return format.format(parseInt(val));
        },
    }
}
</script>

<style scoped>

</style>

import Index from '../../Modules/Users/Index';
import * as auth from '../../services/auth';
import CreateUser from "../../Modules/Users/CreateUser";
import EditUser from "../../Modules/Users/EditUser";
import Profile from "../../Modules/Users/Profile";


export default [
    {
        name: 'users',
        path: '/admins/users',
        component: Index,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CreateUser',
        path: '/admins/users/create',
        component: CreateUser,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'EditUser',
        path: '/admins/users/edit/:id',
        component: EditUser,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Profile',
        path: '/profile',
        component: Profile,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        },
    },
]

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <h5 class="my-3">{{ $t('request.edit') }}</h5>

                    <div class="d-flex justify-content-between fs-7 mb-2">
                        <div class="fs-5 fw-bold lh-base">№{{ docs.id }}</div>
                        <div
                            :class="docs.status"
                            class="badge fs-7 fw-normal lh-base px-3 rounded-pill"
                        >{{ docs.status_title }}</div>
                    </div>

                    <Form ref="docs">

                        <div class="mb-3 vstack mt-3">
                            <div class="form-check">
                                <input class="form-check-input border_ckeckbox" type="checkbox" value="" id="flexCheckDefault"
                                    @click="toggleUrgent"
                                    :checked="docs.urgent"
                                >
                                <label class="form-check-label fs-7 fw-bold" for="flexCheckDefault">
                                    {{ $t('request.urgent_payment') }}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input border_ckeckbox" type="checkbox" value="" id="PaymentDocument"
                                    @click="togglePaymentDocument"
                                    :checked="docs.payment_document"
                                >
                                <label class="form-check-label fs-7 fw-bold" for="PaymentDocument">
                                    {{ $t('request.request_payment_document') }}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input border_ckeckbox" type="checkbox" value="" id="LetterAttorney"
                                    @click="toggleLetterAttorney"
                                    :checked="docs.letter_attorney"
                                >
                                <label class="form-check-label fs-7 fw-bold" for="LetterAttorney">
                                    {{ $t('request.request_power_attorney') }}
                                </label>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.select_counterparty') }}</label>
                            <VueMultiselect
                                v-model="docs.counteragent"
                                :placeholder="$t('request.select_counterparty')"
                                label="name"
                                track-by="id"
                                :options="counteragents"
                                :taggable="true"
                                :show-labels="false"></VueMultiselect>
                            <ErrorMessage name="counteragent" style="color: red;"/>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.payment_amount') }}</label>
                            <Field
                                v-model="docs.payment_amount"
                                type="number"
                                name="payment_amount"
                                class="form-control"/>
                            <ErrorMessage name="payment_amount" style="color: red;"/>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.select_currency') }}</label>
                            <VueMultiselect
                                v-model="docs.currency"
                                :placeholder="$t('request.select_currency')"
                                label="name"
                                track-by="id"
                                :options="currencys"
                                :taggable="true"
                                :show-labels="false"></VueMultiselect>
                            <ErrorMessage name="currency" style="color: red;"/>
                        </div>

                        <div class=" mb-3">
                            <label class="form-label">{{ $t('request.payment_type') }}</label>
                            <VueMultiselect
                                v-model="docs.payment_type"
                                :placeholder="$t('request.payment_type')"
                                label="name"
                                track-by="id"
                                :options="typePayments"
                                :taggable="true"
                                :show-labels="false"></VueMultiselect>
                            <ErrorMessage name="typePayment" style="color: red;"/>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.select_category') }}</label>
                            <VueMultiselect
                                v-model="docs.category"
                                :placeholder="$t('request.select_category')"
                                label="name"
                                track-by="id"
                                :options="categorys"
                                :taggable="true"
                                :show-labels="false"></VueMultiselect>
                            <ErrorMessage name="document" style="color: red;"/>
                        </div>

                        <div class="mb-3 mt-3">
                            <label class="form-label">{{ $t('request.comment') }}</label>
                            <textarea class="form-control" v-model="docs.comment"></textarea>
                        </div>

                        <div class="mb-3">
                            <label for="formFileMultiple" class="form-label">{{ $t('request.attach_files') }}</label>
                            <input
                                ref="files"
                                class="form-control"
                                type="file"
                                id="formFileMultiple"
                                multiple="multiple"
                                v-on:change="handleFileUpload()">
                        </div>

                        <div class="col col-md-2 mt-5">
                            <button class="btn btn-green w-100" @click="update">
                                {{ $t("request.button.save") }}
                            </button>
                        </div>
                        
                    </Form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../../components/ToastAlert";
import axios from "axios";

export default {
    name: "PaymentsEdit",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data() {
        return {
            docs: {
                id: null,
                comment: null,
                status_title: null,
                status: null,
                payment_amount: null,
                counteragent: null,
                category: null,
                currency: null,
                payment_type: null,
                urgent: false,
                letter_attorney: false,
                payment_document: false,
            },
            errors: {
                payment_amount: '',
                counteragent: '',
                category: '',
                currency: '',
                payment_type: '',
            },
            counteragents: [],
            categorys: [],
            currencys: [],
            typePayments: [],
            files: '',
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.edit_payment'),
            back_title: this.$t('request.payments'),
            link: 'request/payments',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        togglePaymentDocument(){
            this.docs.payment_document = !this.docs.payment_document
        },
        toggleLetterAttorney(){
            this.docs.letter_attorney = !this.docs.letter_attorney
        },
        toggleUrgent() {
            this.docs.urgent = !this.docs.urgent
        },
        handleFileUpload(){
            this.files = this.$refs.files.files;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/payments/edit/${this.$route.params.id}`, config);
                this.counteragents = response.data.data.counteragents;
                this.categorys = response.data.data.categorys;
                this.currencys = response.data.data.currencys;
                this.typePayments = response.data.data.typePayments;
                this.docs.currency = response.data.data.payment.currency;
                this.docs.comment = response.data.data.payment.comment;
                this.docs.payment_amount = response.data.data.payment.payment_amount;
                this.docs.counteragent = response.data.data.payment.counteragent;
                this.docs.letter_attorney = response.data.data.payment.letter_attorney;
                this.docs.payment_document = response.data.data.payment.payment_document;
                this.docs.urgent = response.data.data.payment.urgent;
                this.docs.category = response.data.data.payment.category;
                this.docs.payment_type = response.data.data.payment.payment_type;
                this.docs.id = response.data.data.payment.id;
                this.docs.status_title = response.data.data.payment.status_title;
                this.docs.status = response.data.data.payment.status;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        validateTime(){
            let currentD = new Date();
            let startHappyHourD = new Date();
            startHappyHourD.setHours(8,0,0);
            let endHappyHourD = new Date();
            endHappyHourD.setHours(24,0,0);

            if(currentD.getDay() == 0){
                return false;
            }else if(currentD >= startHappyHourD && currentD < endHappyHourD ){
                return true;
            }else{
                return false;
            }
        },
        update(e){
            e.preventDefault();

            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token'), 'Content-Type': 'multipart/form-data'}};
            const formData = new FormData();
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }
            formData.append('payment_amount', this.docs.payment_amount);
            formData.append('comment', this.docs.comment);
            formData.append('counteragent_id', this.docs.counteragent.id);
            formData.append('category_id', this.docs.category.id);
            formData.append('currency', this.docs.currency.id);
            formData.append('payment_type', this.docs.payment_type.id);
            formData.append('urgent', this.docs.urgent);
            formData.append('closeDocs', this.docs.closeDocs);
            formData.append('id', this.$route.params.id);
            axios.post('/api/admin/payments/update', formData, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.$router.push('/request/payments/index')
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.docs.setErrors(errors.response.data.errors);
                            this.errors.payment_amount = errors.response.data.errors.payment_amount ?? 0;
                            this.errors.counteragent = errors.response.data.errors.counteragent_id ?? 0;
                            this.errors.category = errors.response.data.errors.category ?? 0;
                            this.errors.currency = errors.response.data.errors.currency ?? 0;
                            this.errors.payment_type = errors.response.data.errors.payment_type ?? 0;
                            break;
                    }
                });

        }
    }
}
</script>

<style scoped>

</style>

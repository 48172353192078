<template>
    <div class="d-flex header position-fixed">
        <div class="container-fluid d-flex justify-content-between">
            <div class="d-flex align-self-center">
                <a href="/">
                    <img class="logo-header-img" src="/image/logo.png" alt="">
                </a>
            </div>
            <div class="header_user_block hstack" @click="show">
                <div class="header_user_info">
                    <div class="user_name">{{ user.name }} {{ user.surname }}</div>
                </div>
                <div class="avatar"
                     :style="styleObject(user.avatar)"
                >
                </div>
            </div>
        </div>
        <div class="user_menu"
             v-if="is_user_menu"
        >
            <div class="d-flex justify-content-between">
                <div class="d-flex user_menu_name">{{ user.name }} {{ user.surname }}</div>
                <div class="d-flex user_menu_close"
                     @click="close"
                >
                    <fa icon="xmark" size="1x"/>
                </div>
            </div>

            <div class="d-flex flex-column user_menu_block">
                <div class="d-flex menu_item" @click="clearAllCache">Очистить весь кэш</div>
                <a href="/profile" class="d-flex menu_item">{{ $t('header.profile') }}</a>
                <div class="d-flex menu_item" @click="logout">{{ $t('header.exit') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Header",
    data() {
        return {
            is_user_menu: false,
        }
    },
    props: {
        user: {
            type: Array,
            default: [],
        },
    },
    methods: {
        show(){
            this.is_user_menu = true;
        },
        close(){
            this.is_user_menu = false;
        },
        logout(){
            axios.post('/api/logout').then(()=>{
                this.$cookie.removeCookie('token');
                window.location.href = '/';
            })
        },
        styleObject(url){
            return 'background-image: url('+ url +')';
        },
        clearAllCache() {
            caches.keys().then(
                cacheNames => {
                    cacheNames.forEach(cacheName =>
                    {
                        caches.delete(cacheName);
                        console.log("Cache clean!");
                        window.location = '/';
                    });
                }
            );
        }
    }
}
</script>

<style scoped>

</style>

<template>
  <spinner v-if="isLoading"></spinner>
  <transition name="fade">
    <div>
      <BreadCrumbs
        :breadCrumbs="breadCrumbs"
        :title="title"
        :back_title="back_title"
        :link="link"
      />
      <div class="page">
          <div class="mt-2 mb-2 text-center">
              <router-link class="btn btn-green btn_vw" to="/request/imprest/create">
                  {{ $t("request.to_create") }}
              </router-link>
<!--              <button class="btn btn-green btn_vw" @click="showNewModal">{{ $t("request.to_create") }}</button>-->
          </div>
        <h5 class="py-2">Мои заявки на аванс</h5>

        <!-- Totals -->
        <div class="item_title">
          <div class="border-bottom pb-2 mb-2 hstack justify-content-between">
            <span class="fw-normal">{{ $t("request.total_requests") }}:</span>
            <span class="fw-semibold">{{ count }}</span>
          </div>
          <div class="hstack justify-content-between">
            <span class="fw-normal">{{ $t("request.the_amount") }}:</span>
            <span class="fw-semibold"
              >{{ summ.toLocaleString() }} {{ $t("request.tenge") }}</span
            >
          </div>
        </div>

        <!-- Cards -->
          <template v-if="is_generalnyi">
              <div v-if="Object.keys(lists).length">
                  <div class="accordion accordion-flush mb-2" :id="'accordionFlushExample-'+ items.id" v-for="(items) in lists" :key="items.id">
                      <div class="accordion-item p-3 rounded">
                          <h2 class="accordion-header" id="flush-headingOne">
                              <button class="accordion-button collapsed p-0 fs-7" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne-'+ items.id" aria-expanded="false" aria-controls="flush-collapseOne">
                                  <h3>{{ items.name }}</h3>
                              </button>
                          </h2>
                          <div :id="'flush-collapseOne-'+ items.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" :data-bs-parent="'#accordionFlushExample-'+ items.id">
                              <div v-if="items.requests.length">
                                  <div class="item_title" v-for="list in items.requests" :key="list.id">
                                      <!-- Номер, Дата, Статус -->
                                      <div
                                          class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                                          <div class="fs-6 fw-bold">№{{ list.id }}</div>
                                          <div class="fs-7">{{ list.date_create }}</div>
                                          <div
                                              class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block denied"
                                              v-if="list.status == 'denied'">
                                              Отказано
                                          </div>
                                          <div
                                              class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block in_work"
                                              v-if="list.status == 'in_work'">
                                              В работе
                                          </div>
                                          <div
                                              class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block completed"
                                              v-if="list.status == 'completed'">
                                              Завершено
                                          </div>
                                      </div>
                                      <!-- Сумма -->
                                      <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.amount") }}</span>
                                          <span class="text-end fw-semibold">{{ cur(list.payment_amount) }}</span >
                                      </div>
                                      <!-- Задолженность -->
                                      <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.debt") }}</span>
                                          <span class="text-end fw-semibold">{{ list.debt.toLocaleString() }}
                                              {{ $t("request.tenge") }}</span >
                                      </div>
                                      <!-- Заработано -->
                                      <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.earned") }}</span>
                                          <span class="text-end fw-semibold">{{ list.earned.toLocaleString() }}
                                              {{ $t("request.tenge") }}</span >
                                      </div>
                                      <!-- Статус -->
                                      <div class="hstack justify-content-between border-bottom pb-2 mb-4">
                                          <span class="fw-normal">{{ $t("request.status") }}</span>
                                          <span class="text-end fw-semibold">{{ list.status_text }}</span>
                                      </div>

                                      <!-- Кнопки управления -->
                                      <div
                                          class="col-sm position-relative mb-2 mt-2"
                                          v-if="list.status === 'in_work'">
                                          <button class="btn btn_vw btn-red" @click="deleteImprest(list.id)">
                                              <span class="material-symbols-rounded pe-1 opacity-75">delete</span>
                                              {{ $t("request.button.delete") }}
                                          </button>
                                      </div>
                                      <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'denied'">
                                          <button class="btn w-100 btn-green" @click="showModal(list.id)">
                                              {{ $t("request.button.return_to_work") }}
                                          </button>
                                      </div>
                                      <div class="col-sm position-relative mb-2 mt-2">
                                          <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                                              <span class="material-symbols-rounded pe-1 opacity-75">history</span>
                                              {{ $t("request.button.history") }}
                                          </button>
                                      </div>

                                      <transition-group name="list">
                                          <div class="modal d-block" v-if="is_modal">
                                              <div class="modal-dialog modal-dialog-centered">
                                                  <div class="modal-content">
                                                      <div class="modal-header">
                                                          <h5 class="modal-title"></h5>
                                                          <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                                                      </div>
                                                      <div class="modal-body">
                                                          <div class="row mb-3 mt-3">
                                                              <label class="col-sm-12 col-form-label required text-start">{{
                                                      $t("request.comment")
                                                      }}</label>
                                                              <div class="col-sm position-relative">
                                                                  <textarea class="form-control" rows="3" v-model="comment"></textarea>
                                                              </div>
                                                          </div>
                                                          <div class="col-sm position-relative mb-2 mt-2">
                                                              <button class="btn btn_vw btn-red" @click="returnToWork(list.id)">
                                                                  {{ $t("request.button.save") }}
                                                              </button>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </transition-group>
                                  </div>
                              </div>
                              <div v-else>
                                  <div class="item_title">
                                      {{ $t("request.list_requests_is_empty") }}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </template>
          <template v-else>
              <div v-if="lists.length">
                  <div class="item_title" v-for="list in lists" :key="list.id">
                      <!-- Номер, Дата, Статус -->
                      <div
                          class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                          <div class="fs-6 fw-bold">№{{ list.id }}</div>
                          <div class="fs-7">{{ list.date_create }}</div>
                          <div
                              class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block denied"
                              v-if="list.status == 'denied'">
                              Отказано
                          </div>
                          <div
                              class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block in_work"
                              v-if="list.status == 'in_work'">
                              В работе
                          </div>
                          <div
                              class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block completed"
                              v-if="list.status == 'completed'">
                              Завершено
                          </div>
                      </div>
                      <!-- Сумма -->
                      <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                          <span class="fw-normal">{{ $t("request.amount") }}</span>
                          <span class="text-end fw-semibold">{{ cur(list.payment_amount) }}</span >
                      </div>
                      <!-- Задолженность -->
                      <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                          <span class="fw-normal">{{ $t("request.debt") }}</span>
                          <span class="text-end fw-semibold">{{ list.debt.toLocaleString() }}
                                              {{ $t("request.tenge") }}</span >
                      </div>
                      <!-- Заработано -->
                      <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                          <span class="fw-normal">{{ $t("request.earned") }}</span>
                          <span class="text-end fw-semibold">{{ list.earned.toLocaleString() }}
                                              {{ $t("request.tenge") }}</span >
                      </div>
                      <!-- Статус -->
                      <div class="hstack justify-content-between border-bottom pb-2 mb-4">
                          <span class="fw-normal">{{ $t("request.status") }}</span>
                          <span class="text-end fw-semibold">{{ list.status_text }}</span>
                      </div>

                      <!-- Кнопки управления -->
                      <div
                          class="col-sm position-relative mb-2 mt-2"
                          v-if="list.status === 'in_work'">
                          <button class="btn btn_vw btn-red" @click="deleteImprest(list.id)">
                              <span class="material-symbols-rounded pe-1 opacity-75">delete</span>
                              {{ $t("request.button.delete") }}
                          </button>
                      </div>
                      <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'denied'">
                          <button class="btn w-100 btn-green" @click="showModal(list.id)">
                              {{ $t("request.button.return_to_work") }}
                          </button>
                      </div>
                      <div class="col-sm position-relative mb-2 mt-2">
                          <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                              <span class="material-symbols-rounded pe-1 opacity-75">history</span>
                              {{ $t("request.button.history") }}
                          </button>
                      </div>

                      <transition-group name="list">
                          <div class="modal d-block" v-if="is_modal">
                              <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                      <div class="modal-header">
                                          <h5 class="modal-title"></h5>
                                          <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                                      </div>
                                      <div class="modal-body">
                                          <div class="row mb-3 mt-3">
                                              <label class="col-sm-12 col-form-label required text-start">{{
                                                      $t("request.comment")
                                                  }}</label>
                                              <div class="col-sm position-relative">
                                                  <textarea class="form-control" rows="3" v-model="comment"></textarea>
                                              </div>
                                          </div>
                                          <div class="col-sm position-relative mb-2 mt-2">
                                              <button class="btn btn_vw btn-red" @click="returnToWork(list.id)">
                                                  {{ $t("request.button.save") }}
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </transition-group>
                  </div>
              </div>
              <div v-else>
                  <div class="item_title">
                      {{ $t("request.list_requests_is_empty") }}
                  </div>
              </div>
          </template>
        </div>
      </div>
  </transition>

  <transition-group name="list">
    <history v-if="is_history" :historis="historis" @close-form="close" />
  </transition-group>

    <transition name="slide-fade">
        <toast-alert v-if="is_alert" @close_alert="closeAlert" :message="message"  />
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import { ErrorMessage, Field, Form } from "vee-validate";
import axios from "axios";
import VueMultiselect from "vue-multiselect";
import History from "./History";
import ToastAlert from "../../../components/ToastAlert";

export default {
  name: "ImprestIndex",
  components: {
    History,
    ToastAlert,
    spinner,
    BreadCrumbs,
    Modules,
    Field,
    Form,
    ErrorMessage,
    VueMultiselect,
  },
  data() {
    return {
      is_new_modal: false,
      is_modal: false,
      comment: "",
      message: "",
      type: "type",
      is_alert: false,
      count: 0,
      summ: 0,
        is_generalnyi: false,
      is_history: false,
      historis: [],
      lists: [],
      isLoading: false,
      breadCrumbs: true,
      title: this.$t("request.my_requests"),
      back_title: this.$t("request.advance_section"),
      link: "request/imprest",
    };
  },
  created() {
    this.getData();
  },
  methods: {
      showNewModal() {
          this.is_new_modal = true;
      },
      closeNewModal(){
          this.is_new_modal = false;
      },
    showModal(id) {
      this.id = id;
      this.is_modal = true;
    },
    closeModal() {
      this.is_modal = false;
      this.comment = "";
    },
    returnToWork() {
      this.isLoading = true;
      const config = {
        headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
      };
      axios
        .post(
          "/api/admin/imprest/return/work",
          {
            id: this.id,
            comment: this.comment,
          },
          config
        )
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            this.is_alert = true;
            this.is_modal = false;
            this.comment = "";
            this.message = this.$t("request.application_restored");
            setTimeout(() => {
              this.closeAlert();
            }, 3000);
            this.getData();
          }
        })
        .catch((errors) => {
          this.isLoading = false;
          console.log(errors);
        });
    },
    closeAlert() {
      this.is_alert = false;
    },
    close() {
      this.is_history = false;
    },
    getHistory(data) {
      this.historis = data;
      this.is_history = true;
    },
    async getData() {
      try {
        this.isLoading = true;
        const config = {
          headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
        };
        const response = await axios.get("/api/admin/imprest/index", config);
        this.is_generalnyi = response.data.data.is_generalnyi;
        this.lists = response.data.data.lists;
        this.count = response.data.data.count;
        this.summ = response.data.data.summ;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    deleteImprest(id) {
      this.isLoading = true;
      const config = {
        headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
      };
      axios
        .post(
          "/api/admin/imprest/delete",
          {
            id: id,
          },
          config
        )
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            this.is_alert = true;
            this.message = this.$t("request.request_delete");
            setTimeout(() => {
              this.closeAlert();
            }, 3000);
            this.getData();
          }
        })
        .catch((errors) => {
          this.isLoading = false;
          console.log(errors);
        });
    },
    cur(val) {
      if (!val) {
        return "0 KZT";
      }
      const format = new Intl.NumberFormat("ru-Ru", {
        style: "currency",
        currency: "KZT",
        minimumFractionDigits: 0,
      });
      return format.format(parseInt(val));
    },
  },
};
</script>

<style scoped></style>

import * as auth from "../../services/auth";
import IndexModule from "../../Modules/Module/IndexModule";
import CreateModule from "../../Modules/Module/CreateModule";
import EditModule from "../../Modules/Module/EditModule";
import ModulePage from "../../Modules/Module/ModulePage";

export default [
    {
        name: 'modules',
        path: '/modules',
        component: ModulePage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        },
    },
    {
        name: 'module',
        path: '/admins/module',
        component: IndexModule,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        },
    },
    {
        name: 'create',
        path: '/admins/module/create',
        component: CreateModule,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'edit',
        path: '/admins/module/edit/:id',
        component: EditModule,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]

import Index from '../../Modules/Role/Index';
import * as auth from '../../services/auth';


export default [
    {
        name: 'roles',
        path: '/admins/roles',
        component: Index,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
            />
            <Modules
                :modules="sortedList"
            />
        </div>
    </transition>
</template>

<script>
import spinner from "../components/Spinner";
import BreadCrumbs from "./BreadCrumbs";
import Modules from "./Modules";
import axios from "axios";

export default {
    name: "MainPage",
    components: {
        spinner, BreadCrumbs, Modules
    },
    data() {
        return {
            isLoading: false,
            breadCrumbs: false,
            title: 'Главное меню',
            modules: [],
        }
    },
    computed:{
        sortedList () {
            switch('sort'){
                case 'sort':
                    return this.modules.sort(this.sortBySort());
                default: return this.modules;
            }
        }
    },
    created() {
        this.getModules();
    },
    methods: {
        sortBySort(){
            var sortBySort = function (d1, d2) { return (d1.sort > d2.sort) ? 1 : -1; };

            return sortBySort;
        },
        async getModules(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/users/get/modules', config);
                this.modules = response.data.data.modules;
            } catch (e) {
                //this.$cookie.removeCookie('token');
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }

}
</script>

<style scoped>

</style>

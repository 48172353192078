<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="this.title"
                :back_title="this.back_title"
                :link="this.link" />

            <div class="page">
            
                <h5 class="my-3 hstack">
                    <Buying :count="4"/>
                    История обслуживание
                </h5>

                <!-- Download from 1C -->
                <div class="item_title">
                    <div class="hstack border-bottom pb-3 mb-3">
                        <span class="material-symbols-rounded me-2 ic-icon ic-gray">construction</span>
                        <span>
                            {{ car.brand }} {{ car.model }}, {{ car.year }} г.<br>
                            {{ car.vin_code }}
                        </span>
                    </div>

                    <div class="fw-normal fs-7 text-center mb-3">Отсутствует подключение к 1С.</div>

                    <button class="btn btn-yellow w-100" disabled>
                        <span class="material-symbols-rounded opacity-50 me-2">cloud</span>
                        <span>Загрузить из 1С</span>
                    </button>
                </div>

                <!-- History -->
                <!-- 
                <template v-if="services">
                    <div class="item_title" v-for="service in services" :key="service.id">
                        <div>
                            <span class="fw-normal fs-7 text-black-50 pe-2">Дата:</span>
                            <span>{{ service.date_execution }}</span>
                        </div>
                        <div class="mb-2">
                            <span class="fw-normal fs-7 text-black-50 pe-2">Пробег:</span>
                            <span>{{ service.mileage.toLocaleString() }} км</span>
                        </div>
                        <div class="fw-normal fs-7">Замена масла. Замена воздушного фильтра. Замена масляного фильтра.</div>
                    </div>
                </template>
                <template v-else>
                    <div class="item_title">
                        <span class="fw-normal text-black-50 fs-7">Нет данных</span>
                    </div>
                </template>
                -->

                <!-- Save -->
                <div class="my-4">
                    <button class="btn btn-green w-100" @click="saveStep">Сохранить</button>
                </div>

            </div>
            
        </div>
    </transition>
    
</template>

<script>
import Buying from "./Buying";
import axios from "axios";
import Spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";


export default {
    name: "Step_4",
    components: {Buying, Spinner, BreadCrumbs},
    data: () => ({
        step: 'carphoto',
        back_step: 'purity',
        isLoading: false,
        services: [],
        car: [],
        breadCrumbs: true,
        title: "Сервис",
        back_title: "Этапы приёма",
        link: "",
    }),
    created() {
        this.getServices();
        this.getBackLink();
    },
    methods: {
        getBackLink() {
            this.link = this.link = `tradeinlist/${this.$route.params.id}`
        },
        saveStep(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/save/step`, {
                id: this.$route.params.id,
                step_number: 4,
                status: 'accept',
            }, config)

                .then(response =>{
                    if(response.status == 200){
                        /* this.$flashMessage.show({
                             type: 'success',
                             text: 'Пункт меню успешно удален!',
                         });*/
                        this.$router.push(`/buying/carphoto/${this.$route.params.id}`);
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        async getServices(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/services/${this.$route.params.id}`, config);
                this.services = response.data.data.services;
                this.car = response.data.data.car;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page d-flex flex-column justify-content-center mt-0">
                <div class="block_select">
                    <div v-for="item in items"
                         :key="item.id">
                        <button class="btn cl_item pad mb-2 btn_vw"
                            v-if="contains(actions, item.alias)"
                            @click="create(item.id)"
                            :disabled="item.subitems.length == 0"
                            :class="item.subitems.length == 0 ? 'type_accept' : ''"
                        >
                            {{ item.name }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";
import {getUserPermissionActions} from "../../../services/helpers";

export default {
    name: "TSMItemPage",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            items: [],
            breadCrumbs: true,
            title: 'Список вопросов',
            back_title: 'Список чек листов',
            link: 'check-list',
        }
    },
    created() {
        this.getChecklists();
        this.getUserActions();
    },
    methods: {
        async getUserActions(){
            this.isLoading = true;
            this.actions = await getUserPermissionActions('/check-list');
            this.isLoading = false;
        },
        contains(arr, elem){
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].alias === elem) {
                    return true;
                }
            }
            return false;
        },
        create(id) {
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/tsm/create', {
                item_id: id,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.$router.push(`/tsm/item/${id}`);
                    }
                })
                .catch(errors => {
                   console.log(errors);
                });

        },
        async getChecklists() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get('/api/admin/tsm/get/items', config);
                this.items = response.data.data.items;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>
/*.type_accept {
    background: #B4E28F !important;
}*/
</style>

<template>
    <!-- Название организации -->
    <Input v-model:modelValue="name" @clear="clearInput($event)" :error="[errors.name.length ? 'error' : '']" label="Название организации" name="name" type="text" placeholder="Название организации" id="name"/>
    <!-- Адрес организации -->
    <Input v-model:modelValue="address" @clear="clearInput($event)" :error="[errors.address.length ? 'error' : '']" label="Адрес организации" name="address" type="text" placeholder="Адрес организации" id="address"/>
    <!-- Телефон организации -->
    <Input v-model:modelValue="phone"  label="Телефон организации" name="phone" type="tel" placeholder="+7 (___) ___-__-__" id="phone"/>
    <!-- РНН организации -->
    <Input v-model:modelValue="rnn" label="РНН организации" name="rnn" type="text" placeholder="РНН организации" id="rnn"/>
    <!-- BIN организации -->
    <Input v-model:modelValue="bin" label="БИН организации" name="bin" type="text" placeholder="БИН организации" id="bin"/>
    <!-- Руководитель организации -->
    <Input v-model:modelValue="director_name" @clear="clearInput($event)" :error="[errors.director_name.length ? 'error' : '']" label="Руководитель организации" name="director_name" type="text" placeholder="Руководитель организации" id="director_name"/>

    <Button
        caption="Сохранить"
        color="btn-green"
        add_class=""
        @click="save()"
    />
</template>

<script>

import Input from "../../components/Input.vue";
import Button from "../../components/Button.vue";

export default {
    name: "NewOrganizationForm",
    components: {
        Input, Button
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        address: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        },
        rnn: {
            type: String,
            default: ''
        },
        bin: {
            type: String,
            default: ''
        },
        director_name: {
            type: String,
            default: ''
        },
        id: 0,
        errors: {
            name: '',
            address: '',
            director_name: ''
        }
    },
    methods: {
        save() {
            this.$emit('save',{
                id: this.id,
                name: this.name,
                address: this.address,
                phone: this.phone,
                rnn: this.rnn,
                bin: this.bin,
                director_name: this.director_name
            })
        },
        clearInput(e){
            this.errors[e] = '';
        },
    }
}
</script>

<style scoped>

</style>

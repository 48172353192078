<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page">
                <div class="row mb-3">
                    <div class="col">
                        <h2>Список организаций</h2>
                    </div>
                    <div class="col-auto">
                        <Button
                            caption="Добавить"
                            color="gray"
                            add_class="mb-3"
                            @click="showForm"
                        />
                    </div>
                </div>

                <div v-if="organizations.length > 0">
                    <div v-for="item in organizations" :key="organizations.id"

                        class="item_title mb-2">

                        <!-- название организации -->
                        <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                            <div class="fs-6 fw-bold">{{ item.name }}</div>
                        </div>

                        <div class="check_btns col-12 hstack justify-content-between my-3">
                            <div class="btn gray col-5 col-lg-3 col-md-4" @click="editOrganization(item)">Редактировать</div>
                            <div class="btn btn-red col-5 col-lg-3 col-md-4" @click="deleteOrganization(item.id)">Удалить</div>
                        </div>

                    </div>
                </div>

                <div class="table-responsive-md" v-else>
                    <h3>Данных нет!</h3>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{name_form}}
            </template>
            <template v-slot:body>
                <NewOrganizationForm
                    :errors="errors"
                    :id="id"
                    :name="name"
                    :address="address"
                    :phone="phone"
                    :rnn="rnn"
                    :bin="bin"
                    :director_name="director_name"
                    @save="save($event)"
                />
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import axios from "axios";
import ToastAlert from '../../components/ToastAlert';
import Spinner from '../../components/Spinner';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import BreadCrumbs from "../../Page/BreadCrumbs";
import {fetchPermisson, errorMessages} from "../../services/helpers";
import * as req from "../../services/organizations";
import NewOrganizationForm from "./NewOrganizationForm.vue";

export default {
    name: "IndexOrganizations",
    components: {
        Spinner, BreadCrumbs, Button, Modal, NewOrganizationForm, ToastAlert
    },
    data(){
        return {
            message: '',
            type: 'green',
            is_alert: false,
            isModal: false,
            isLoading: false,
            organizations: [],
            breadCrumbs: true,
            name_form: '',
            title: '',
            back_title: '',
            link: '',
            modules: [],
            id: 0,
            name: '',
            address: '',
            phone: '',
            rnn: '',
            bin: '',
            director_name: '',
            errors: {
                name: '',
                address: '',
                director_name: ''
            }
        }
    },
    mounted() {
        this.getData();
        this.module();
    },
    methods: {
        editOrganization(item){
            this.name = item.name;
            this.address = item.address;
            this.phone = item.phone;
            this.rnn = item.rnn;
            this.bin = item.bin;
            this.director_name = item.director_name;
            this.id = item.id;
            this.name_form = 'Редактировать';
            this.isModal = true;
        },
        async deleteOrganization(id){
            this.isLoading = true;
            let result = await req.destroyOrganization(id);
            this.type = result.type;
            this.is_alert = true;
            this.message = result.message;
            this.organizations = result.organizations;
            setTimeout(() => {this.closeAlert(); }, 3000);
            this.isLoading = false;
        },
        async getData(){
            this.isLoading = true;
            let result = await req.getOrganizations();
            this.organizations = result.organizations;
            this.isLoading = false;
        },
        async module() {
            this.isLoading = true;
            this.permission = await fetchPermisson(this.$route.path);
            this.title = this.permission.title;
            this.back_title = this.permission.modules.title;
            this.link = this.permission.modules.path;
            this.isLoading = false;
        },
        closeModal() {
            this.reset();
            this.isModal = false;
        },
        showForm() {
            this.name_form = 'Добавить';
            this.isModal = true;
        },
        async save(event) {
            this.isLoading = true;
            let result = await req.saveOrganization(event);
            if(result.status === 422){
                this.errors = result.data.errors;
                this.isLoading = false;
                this.type = 'red';
                this.message = errorMessages(this.errors)
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }else {
                this.type = 'green';
                this.isLoading = false;
                this.reset();
                this.closeModal();
                this.is_alert = true;
                this.message = result.message;
                this.organizations = result.organizations;
                setTimeout(() => {this.closeAlert(); }, 3000);
            }
        },
        closeAlert(){
            this.is_alert = false;
        },
        reset() {
            this.name = '';
            this.address = '';
            this.phone =  '';
            this.rnn = '';
            this.bin = '';
            this.director_name = '';
            this.id = 0;
        }
    }
}
</script>

<style scoped>

</style>

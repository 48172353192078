import * as auth from "./auth";
import axios from "axios";

export const selectOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/settings/select/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const selectRequestOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/payments/category/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const selectMemosOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/memos/category/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const selectImprestOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/imprest/category/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const viewingHistory = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        let items = {id: id};
        const response = await axios.post('/api/admin/memos/view/history', items, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const viewingPaymentsHistory = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        let items = {id: id};
        const response = await axios.post('/api/admin/payments/view/history', items, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

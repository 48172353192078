<template>
    <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="btn-close close_modal" @click="close"></button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md">
                            <div class="row">
                                <div class="col-md">
                                    <label class="form-label">Название действия*</label>
                                    <Field
                                        v-model="name"
                                        type="text"
                                        name="name"
                                        class="form-control"/>
                                    <ErrorMessage name="name"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn gray" @click="close">Закрыть</button>
                    <button type="button" class="btn btn-green" @click="create">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";

export default {
    name: "ActionsModal",
    components: {
        Field, Form, ErrorMessage, VueMultiselect
    },
    props: {
        title: {
            type: String,
            default: 'Модальное окно',
        },
        name: null,
    },
    methods: {
        close() {
            this.$emit('close-modal')
        },
        create(){
            this.$emit('add-action', {name: this.name})
        }
    }
}
</script>

<style scoped>

</style>

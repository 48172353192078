<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page">

                <h5 class="my-3">Этапы приёма автомобиля</h5>

                <hr>
                <div class="legend">
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #4c586d;">help</span> &ndash; не проверено
                    </div>
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #00c43c;">check_circle</span> &ndash; проверено, нет дефектов
                    </div>
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #cc0000;">error</span> &ndash; проверено, есть дефекты
                    </div>
                </div>
                <hr>

                <div class="vstack justify-content-center mt-0 cursor-pointer" v-for="list in lists" :key="list.id" @click="next(list.path)">

<!--                    <template v-if="list.number == 12">
                        <div class="btn cl_item cl_status pad mb-2 w-100 text-center vstack">
                            <div>{{ list.number }}. {{ list.title }}</div>
                            <div
                                v-if="list.status_price == 'agreement'"
                                class="step_status fs-7"
                                :class="list.status_price == 'agreement' ? 'agreement' : ''">
                                На согласовании
                            </div>
                            <div
                                v-if="list.status_price == 'rejection'"
                                class="step_status fs-7"
                                :class="list.status_price == 'rejection' ? 'rejection' : ''">
                                Отказано
                            </div>
                            <div
                                v-if="list.status_price == 'approved'"
                                class="step_status fs-7"
                                :class="list.status_price == 'approved' ? 'approved' : ''">
                                Одобрено
                            </div>
                        </div>
                    </template>-->


                        <div
                            class="btn cl_item cl_status pad mb-2 w-100 text-center hstack"
                            :class="'type_' + list.status">
                            <span
                                v-if="list.status == 'accept'"
                                class="material-symbols-rounded me-2 opacity-50 cl_status_icon">check_circle</span>
                            <span
                                v-else-if="list.status == 'defect'"
                                class="material-symbols-rounded me-2 opacity-50 cl_status_icon">error</span>
                            <span
                                v-else
                                class="material-symbols-rounded me-2 opacity-50 cl_status_icon">help</span>
                            {{ list.number }}. {{ list.title }}

                            <span class="item_check badge bg-danger rounded-pill px-2" :class="list.status">{{ list.count }}</span>
                        </div>

                </div>

                <hr/>

                <div class="col-sm position-relative mb-2 mt-2">
                    <button class="w-100 btn btn-lg btn-yellow mt-3"
                            @click="goEvaluationSheet()"
                    >
                        <span class="material-symbols-rounded me-2 opacity-50">rule</span>
                        Оценочный лист
                    </button>
                </div>

                <div class="col-sm position-relative mb-2 mt-2" v-if="status === 'warehouse'">
                    <button class="w-100 btn btn-lg btn-green mt-3"
                            @click="showModal()"
                    >
                        <span class="material-symbols-rounded me-2 opacity-50">money</span>
                        Ценник
                    </button>
                </div>

                <div class="col-sm position-relative mb-2 mt-2">
                    <button class="w-100 btn btn-lg gray mt-3"
                        @click="getHistory(historis)"
                    >
                        <span class="material-symbols-rounded me-2 opacity-50">history</span>
                        История
                    </button>
                </div>

            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <div class="popup" v-if="is_modal">
            <div class="popup-content">
                Печать прайса
                <div class="close float-end" @click="closeModal">
                    <span class="material-symbols-rounded">close</span>
                </div>
                <div class="mb-3">
                    <label class="form-label">Цена*</label>
                    <div class="fs-4 ps-2 fw-bold"></div>
                    <Field
                        v-model="price"
                        type="number"
                        class="form-control"
                    />
                    <ErrorMessage name="summ" style="color: red;"/>
                </div>

                <div class="col-sm position-relative mb-2 mt-2">
                    <button class="btn btn_vw btn-green"
                            :disabled="!this.price.length"
                            @click="pricePrint"
                    >
                        <span class="material-symbols-rounded me-2 opacity-50">print</span>
                        Печать
                    </button>
                </div>
            </div>
        </div>
    </transition>

    <transition-group name="list">
        <history
            v-if="is_history"
            :historis="historis"
            @close-form="close"
            :is_print="false"
            :id="id"
        />
    </transition-group>

</template>

<script>
import axios from "axios";
import {getTradeInStatusStep} from "./../../services/helpers";
import BreadCrumbs from "../../Page/BreadCrumbs";
import History from "../Request/Imprest/History";
import spinner from "../../components/Spinner";
import { Field, Form, ErrorMessage } from "vee-validate";

export default {
    name: "TradeInList",
    components: {
        BreadCrumbs, History, spinner, Field, Form, ErrorMessage
    },
    props: {
        count: {
            type: Number,
            default: 0,
        },
        step: {
            default: 'client',
        },
        back_step: {
            default: '',
        },
    },
    data() {
        return {
            price: '',
            is_modal: false,
            is_price: false,
            isLoading: false,
            lists: [],
            historis: [],
            id: null,
            tradein_id: null,
            is_history: false,
            breadCrumbs: true,
            title: "Этапы приёма",
            back_title: "Журнал",
            link: "journal",
        }
    },
    created() {
        this.getStepLists();
    },
    methods: {
        pricePrint(){
            this.closeModal();
            let element = document.createElement("a");
            let url = "/api/tradein/print/price/" + this.$route.params.id +"/"+ this.price
            element.setAttribute("href", url);
            element.setAttribute("download", "price.pdf");
            element.setAttribute("target", "_blank");
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.getStepLists();
        },
        closeModal(){
            this.is_modal = false;
        },
        showModal(){
            this.is_modal = true;
        },
        getHistory(data, id){
            this.historis = data;
            this.id = id;
            this.is_history = true;
        },
        close(){
            this.is_history = false;
        },
        stepNext(step){
            this.$router.push(`/buying/${step}/${this.$route.params.id}`)
        },
        stepBack(back_step){
            this.$router.push(`/buying/${back_step}/${this.$route.params.id}`)
        },
        goEvaluationSheet(){
            this.$router.push(`/buying/estimation/${this.tradein_id}`)
        },
        async getStepLists() {
            this.isLoading = true;
            let result = await getTradeInStatusStep(this.$route.params.id);
            this.lists = result.steps;
            this.status = result.tradein.status;
            this.historis = result.historys;
            this.tradein_id = result.tradein_id;
            if(result.tradein.car != null){
                this.price = result.tradein.car.price;
            }
            this.isLoading = false;
        },
        showStepLists(){
            if(this.isHidden){
                this.isHidden = false;
            }else{
                this.isHidden = true;
            }
        },
        next(path){
            this.$router.push(`/buying/${path}/${this.$route.params.id}`)
        }
    }
}
</script>

<style scoped>

</style>

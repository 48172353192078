<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <div class="col-auto mb-2">
                        <Button
                            caption="Добавить"
                            color="gray"
                            add_class="mb-3"
                            @click="showModal()"
                        />
                    </div>
                </div>
                <div class="table-responsive-md">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-muted">Организация</th>
                            <th class="text-muted">Название авто</th>
                            <th class="text-muted">Цвет</th>
                            <th class="text-muted" style="width: 15%;">VIN</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="item in cars"
                            :key="item.id"
                        >
                            <td>
                                {{ item.organization.name }}
                            </td>
                            <td>
                                {{ item.brands.name_brand }} {{ item.models.name_model }}
                            </td>
                            <td v-if="item.colors">
                                {{ item.colors.name_color }}
                            </td>
                            <td v-else>
                                Не указано
                            </td>
                            <td>
                                {{ item.vin_code }}
                            </td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                        <li><button type="button" @click="editCar(item)" class="dropdown-item">Редактировать</button></li>
                                        <li><hr class="dropdown-divider"></li>
                                        <li>
                                            <button type="button" @click="deleteCar(item.id)" class="dropdown-item">Удалить</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{ name_form }}
            </template>
            <template v-slot:body>
                <TDFormCar
                    :errors="errors"
                    :id="id"

                    :organization="organization"
                    :organizations="organizations"

                    :brand="brand"
                    :brands="brands"

                    :model="model"
                    :models="models"

                    :year_release="year_release"
                    :configuration="configuration"
                    :vin_code="vin_code"
                    :passport="passport"
                    :passport_from="passport_from"
                    :car_number="car_number"
                    :mileage="mileage"

                    :color="color"
                    :colors="colors"

                    :motor="motor"
                    :motors="motors"

                    :bodywork="bodywork"
                    :bodyworks="bodyworks"

                    :kpp="kpp"
                    :kpp_ar="kpp_ar"

                    :actuator="actuator"
                    :engine_capacity="engine_capacity"
                    :testdrive="testdrive"
                    :spoof="spoof"

                    @create-car="createCar($event)"
                    @select-brand="selectBrand($event)"
                    @update-check="updateCheck($event)"
                />
            </template>
        </Modal>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import ToastAlert from '../../../components/ToastAlert';
import * as req from "../../../services/td";
import * as help from "../../../services/helpers";
import TDFormCar from "./TDFormCar.vue";
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import * as carHelp from "../../../services/cars";

export default {
    name: "TDCar",
    components: {
        spinner, BreadCrumbs, ToastAlert, TDFormCar, Modal, Button
    },
    data() {
        return {
            type: '',
            message: '',
            is_alert: false,
            isModal: false,
            isLoading: false,
            breadCrumbs: true,
            title: 'Категории',
            back_title: 'Настройки',
            link: 'td/settings',
            cars: [],
            organizations: [],
            organization: null,
            name_form: '',
            brands: [],
            brand: [],

            colors: [],
            color: [],

            bodyworks: [],
            bodywork: [],

            kpp_ar: [],
            kpp: [],

            motors: [],
            motor: [],

            models: [],
            model: [],

            id: 0,
            brand_id: 0,
            model_id: 0,
            year_release: '',
            configuration: '',
            vin_code: '',
            passport: '',
            passport_from: '',
            car_number: '',
            mileage: 0,
            color_id: 1,
            motor_id: 1,
            bodywork_id: 1,
            kpp_id: 1,
            actuator: '',
            engine_capacity: 0,
            testdrive: false,
            spoof: false,
            errors: {
                brand: '',
                model: '',
                year_release: '',
                configuration: '',
                vin_code: '',
                passport: '',
                passport_from: '',
                car_number: '',
                mileage: '',
                color: '',
                motor: '',
                bodywork: '',
                kpp: '',
                actuator: '',
                engine_capacity: '',
                testdrive: '',
                spoof: '',
                organization: '',
            },
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        updateCheck(event){
            this[event.field] = event.checked;
            this.id = event.id;
            this.organization = event.organization;
            this.brand = event.brand;
            this.model = event.model;
            this.year_release = event.year_release;
            this.configuration = event.configuration;
            this.price = event.price;
            this.vin_code = event.vin_code;
            this.passport = event.passport;
            this.passport_from = event.passport_from;
            this.car_number = event.car_number;
            this.mileage = event.mileage;
            this.color = event.color;
            this.motor = event.motor;
            this.bodywork = event.bodywork;
            this.kpp = event.kpp;
            this.actuator = event.actuator;
            this.engine_capacity = event.engine_capacity;
        },
        async createCar(event) {

            this.isLoading = true;
            let result = await req.createCar(event);
            if(result.status === 422){
                this.errors = result.data.errors;
                this.isLoading = false;
                this.type = 'red';
                this.message = help.errorMessages(result.data.errors)
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }else{
                this.type = 'green';
                this.isLoading = false;
                this.closeModal();
                this.is_alert = true;
                this.message = result.message;
                this.cars = result.cars;
                setTimeout(() => {this.closeAlert(); }, 3000);
            }
        },
        formatDate(date) {
            // Пример: "05.05.2021" -> "2021-05-05"
            const parts = date.split('.');
            if (parts.length === 3) {
                return `${parts[2]}-${parts[1]}-${parts[0]}`;
            }
            return date; // Вернуть как есть, если формат не соответствует
        },
        closeModal() {
            this.isModal = false;
        },
        showModal(){
            this.isModal = true
        },
        async getData() {
            this.isLoading = true;
            let result = await req.getCars();
            this.cars = result.cars;
            this.brands = result.brands;
            this.models = result.models;
            this.colors = result.colors;
            this.bodyworks = result.bodyworks;
            this.kpp_ar = result.kpp;
            this.motors = result.motors;
            this.organizations = result.organizations;

            this.isLoading = false;
        },
        editCar(car) {
            this.id = car.id;
            this.organization = car.organization;
            this.brand = car.brands;
            this.model = car.models;
            this.year_release = car.year_release;
            this.configuration = car.configuration;
            this.vin_code = car.vin_code;
            this.passport = car.passport;
            this.passport_from = car.passport_from ? this.formatDate(car.passport_from) : null;
            this.car_number = car.car_number;
            this.mileage = car.mileage;
            this.color = car.colors;
            this.motor = car.motor;
            this.bodywork = car.bodywork;
            this.kpp = car.kpp;
            this.steering_wheel = car.steering_wheel;
            this.actuator = car.actuator;
            this.engine_capacity = car.engine_capacity;
            this.price = car.price;
            this.testdrive = car.testdrive;
            this.spoof = car.spoof;

            this.name_form = this.$t('edit');
            this.showModal();
        },
        async selectBrand(event){
            this.isLoading = true;
            this.brand = event.brand;
            let result = await carHelp.getModel(event);
            this.models = result.models;
            this.id = event.id;
            this.organization = event.organization;
            this.brand = event.brand;
            this.model = event.model;
            this.year_release = event.year_release;
            this.configuration = event.configuration;
            this.price = event.price;
            this.vin_code = event.vin_code;
            this.passport = event.passport;
            this.passport_from = event.passport_from;
            this.car_number = event.car_number;
            this.mileage = event.mileage;
            this.color = event.color;
            this.motor = event.motor;
            this.bodywork = event.bodywork;
            this.kpp = event.kpp;
            this.actuator = event.actuator;
            this.engine_capacity = event.engine_capacity;
            this.testdrive = event.testdrive;
            this.spoof = event.spoof;
            this.isLoading = false;
        },
    }
}
</script>

<style scoped>

</style>

<template>
  <spinner v-if="isLoading"></spinner>
  <transition name="fade">
    <div>
      <BreadCrumbs
        :breadCrumbs="breadCrumbs"
        :title="title"
        :back_title="back_title"
        :link="link"
      />
      <div class="page">
        <h5 class="py-2">Утверждение аванса</h5>
        <div class="item_title mb-2">
          <!-- Номер, Дата -->
          <div
            class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3"
          >
            <div class="fs-6 fw-bold">№{{ id }}</div>
            <div>{{ moment(created_at).format("DD.MM.YYYY hh:mm") }}</div>
          </div>

          <!-- Сотрудник -->
          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
            <span class="fw-normal">{{ $t("request.worker") }}</span>
            <span class="text-end fw-semibold">{{ fullname }}</span>
          </div>

          <!-- Сумма -->
          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
            <span class="fw-normal">{{ $t("request.amount") }}</span>
            <span class="text-end fw-semibold">{{ cur(payment_amount) }}</span>
          </div>

          <!-- Долг -->
          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
            <span class="fw-normal">{{ $t("request.debt") }}</span>
            <span class="text-end fw-semibold">{{ cur(debt) }}</span>
          </div>

          <!-- Заработано -->
          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
            <span class="fw-normal">{{ $t("request.earned") }}</span>
            <span class="text-end fw-semibold">{{ cur(earned) }}</span>
          </div>

          <!-- Способ  -->
          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
            <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
            <span class="text-end fw-semibold">{{ payment_type }}</span>
          </div>
        </div>

        <!-- Формы -->
        <div class="mb-4 mt-3" v-if="is_modified">
          <label class="form-label">{{ $t("request.modified_amount") }}</label>
          <Field
            :placeholder="$t('request.modified_amount')"
            v-model="imprest.modified_amount"
            type="tel"
            name="modified_amount"
            class="form-control"
            :class="[errors.modified_amount.length > 0 ? 'error' : '']"
          />
          <ErrorMessage name="modified_amount" style="color: red" />
        </div>
        <div class="mb-4 mt-3">
          <label class="form-label">{{ $t("request.comment") }}</label>
          <textarea class="form-control" rows="3" v-model="imprest.comment"></textarea>
        </div>

        <!-- Кнопки -->
        <div v-if="!is_modified">
          <div class="col-sm position-relative mb-2 mt-2">
            <button class="btn btn_vw btn-green" @click="approve">
              <span class="material-symbols-rounded opacity-50 me-1">check</span>
              {{ $t("request.button.approve") }}
            </button>
          </div>
          <div class="col-sm position-relative mb-2 mt-2">
            <button class="btn btn_vw btn-yellow" @click="modifiedAmount">
              <span class="material-symbols-rounded opacity-50 me-1">refresh</span>
              {{ $t("request.button.modified_amount") }}
            </button>
          </div>
          <div class="col-sm position-relative mb-2 mt-2">
            <button class="btn btn_vw btn-red" @click="denied">
              <span class="material-symbols-rounded opacity-50 me-1">block</span>
              {{ $t("request.button.deny") }}
            </button>
          </div>
          <div class="col-sm position-relative mb-2 mt-2">
                <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                    <span class="material-symbols-rounded opacity-50 me-1">history</span>
                    {{ $t("request.button.history") }}
                </button>
            </div>
        </div>
        <div v-if="is_modified">
            <div class="mb-2 mt-2" v-if="is_modified">
                <button class="btn btn_vw btn-green" @click="approve">
                    <span class="material-symbols-rounded opacity-50 me-1">check</span>
                    {{ $t("request.button.approve_with_change") }}
                </button>
            </div>
            <div class="col-sm position-relative mb-2 mt-2" v-if="is_modified">
                <button class="btn btn_vw btn-red" @click="cancel">
                    <span class="material-symbols-rounded opacity-50 me-1">close</span>
                    {{ $t("request.button.cancel") }}
                </button>
            </div>
        </div>
      </div>
    </div>
  </transition>

  <transition-group name="list">
    <history v-if="is_history" :historis="historis" @close-form="close" />
  </transition-group>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import { ErrorMessage, Field, Form } from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import History from "./History";
import moment from "moment";

export default {
  name: "ImprestChiefPage",
  components: {
    History,
    spinner,
    BreadCrumbs,
    Modules,
    Field,
    Form,
    ErrorMessage,
    VueMultiselect,
  },
  data() {
    return {
      is_history: false,
      historis: [],
      is_modified: false,
      imprest: {
        comment: null,
        modified_amount: null,
      },
      errors: {
        comment: "",
        modified_amount: "",
      },
      id: null,
      fullname: null,
      payment_amount: null,
      debt: null,
      earned: null,
      isLoading: false,
      breadCrumbs: true,
      title: this.$t("request.request") + " №" + this.$route.params.id,
      back_title: this.$t("request.list_requests"),
      link: "request/imprest/chief/list",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    moment,
    close() {
      this.is_history = false;
    },
    getHistory(data) {
      this.historis = data;
      this.is_history = true;
    },
    denied() {
      this.isLoading = true;
      const config = {
        headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
      };
      axios
        .post(
          "/api/admin/imprest/chief/denied",
          {
            comment: this.imprest.comment,
            id: this.$route.params.id,
          },
          config
        )
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            this.$router.push("/request/imprest/chief/list");
          }
        })
        .catch((errors) => {
          this.isLoading = false;
          console.log(errors);
        });
    },
    approve() {
      this.isLoading = true;
      const config = {
        headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
      };

      let data = {};

      if (!this.is_modified) {
        data = {
          comment: this.imprest.comment,
          id: this.$route.params.id,
        };
      } else {
        data = {
          is_modified: this.is_modified,
          modified_amount: this.imprest.modified_amount,
          comment: this.imprest.comment,
          id: this.$route.params.id,
        };
      }
      axios
        .post("/api/admin/imprest/chief/approve", data, config)
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            this.$router.push("/request/imprest/chief/list");
          }
        })
        .catch((errors) => {
          this.isLoading = false;
          console.log(errors);
        });
    },
    cancel() {
      this.is_modified = false;
    },
    modifiedAmount() {
      this.is_modified = true;
    },
    closeAlert() {
      this.is_alert = false;
    },
    async getData() {
      try {
        this.isLoading = true;
        const config = {
          headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
        };
        const response = await axios.get(
          `/api/admin/imprest/one/${this.$route.params.id}`,
          config
        );
        this.list = response.data.data.list;
        this.id = this.list.id;
        this.debt = this.list.debt;
        this.earned = this.list.earned;
        this.fullname = this.list.user.surname + " " + this.list.user.name;
        this.payment_amount = this.list.payment_amount;
        this.payment_type = this.list.payment_type;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    cur(val) {
      if (!val) {
        return "0 KZT";
      }
      const format = new Intl.NumberFormat("ru-Ru", {
        style: "currency",
        currency: "KZT",
        minimumFractionDigits: 0,
      });
      return format.format(parseInt(val));
    },
  },
};
</script>

<style scoped></style>

<template>
    <div class="treeHTML">
        <div class="subtree">

            <span class="tree_line hstack">
                <span class="tree_title">{{ node.id}} - {{ node.title}}</span>
                <span class="material-symbols-rounded" @click="editRole(node)">edit</span>
                <span v-if="!node.children.length" class="material-symbols-rounded" @click="deleteRole(node.id)">remove</span>
            </span>

            <details v-if="node.children && node.children.length"><summary></summary>
                <role-tree
                    v-for="child in node.children"
                    :key="child.id"
                    :node="child"
                    :parentСhild="child"
                    @edit-role="editRole($event)"
                    @delete-role="deleteRole($event)"
                ></role-tree>
            </details>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import Input from '../../components/Input';
import Button from '../../components/Button';
import Modal from '../../components/Modal';

export default {
    name: "RoleTree",
    components: {
        Field, Form, ErrorMessage, VueMultiselect, Button, Input, Modal
    },
    props: {
        roles: [],
        node: Object,
        organizations: [],
        organization: null,
    },
    methods: {
        editRole(item){
            this.$emit('edit-role', item)
        },
        deleteRole(id){
            this.$emit('delete-role', id)
        },
    }
}
</script>

<style scoped>
.treeHTML {
    line-height: normal;
}
.treeHTML details {
    display: block;
}
.treeHTML .subtree {
    border-left: 1px solid #ccc;
    position: relative;
    margin: 0 0 0 .5em;
    padding: 0 0 0 1.2em;
}
.treeHTML div:not(:last-child) { /* необязательно */

}
.treeHTML .subtree:before { /* необязательно */
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1.1em;
    height: .5em;
    border-bottom: 1px solid #ccc;
}
.treeHTML .subtree:last-child:before { /* необязательно */
    /*border-left: 1px solid #ccc;*/
}
.treeHTML summary { /* стилями можно задать любую форму, например, тот же плюс. Я специально не стала усложнять, чтобы можно было проще разобраться в коде */
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}
.treeHTML details[open] summary { /* убрать рамку при фокусе */
    outline: none;
}
.cursor{
    cursor: pointer;
}
</style>

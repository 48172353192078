<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page d-flex flex-column justify-content-center mt-0">
                <div class="block_select">
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red btn_vw" to="/car-setting">
                            Автомойка
                        </router-link>
                    </div>
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red btn_vw" to="/tsm-setting">
                            TSM
                        </router-link>
                    </div>
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red btn_vw" to="/quality-setting">
                            Ежедневные
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "SettingsCheckPage",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: true,
            breadCrumbs: true,
            title: 'Настройки чек листов',
            back_title: 'Чек листы TSM',
            link: 'tsm',
        }
    },
    created() {
        setTimeout(() => {
            this.isLoading = false;
        }, 200);
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <div class="row mb-2">
                        <label class="col-sm-3 col-form-label required text-end">Организация*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="organization"
                                placeholder="Фильтр по организации"
                                label="name"
                                track-by="id"
                                :options="organizations"
                                :show-labels="false"
                                @select="filterOrganization($event)"
                                @remove="reset($event)"
                            ></VueMultiselect>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <label class="col-sm-3 col-form-label required text-end">Директор*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="settings.director"
                                placeholder="Выбрать..."
                                label="fullname"
                                track-by="id"
                                :options="users"
                                :taggable="true"
                                :multiple="true"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="director"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-lg-2">
                            <button class="btn btn-green"
                                @click="saveSettings(settings.director, 'director')"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>

                    <hr/>

                    <div class="row mb-2">
                        <label class="col-sm-3 col-form-label required text-end">Начальники отделов*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="settings.chief"
                                placeholder="Выбрать..."
                                label="fullname"
                                track-by="id"
                                :options="users"
                                :taggable="true"
                                :multiple="true"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="chief"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-lg-2">
                            <button class="btn btn-green"
                                @click="saveSettings(settings.chief, 'chief')"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>

                    <hr/>

                    <div class="row mb-2">
                        <label class="col-sm-3 col-form-label required text-end">Заместитель начальников отделов*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="settings.deputy_chief"
                                placeholder="Выбрать..."
                                label="fullname"
                                track-by="id"
                                :options="users"
                                :taggable="true"
                                :multiple="true"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="deputy_chief"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-lg-2">
                            <button class="btn btn-green"
                                    @click="saveSettings(settings.deputy_chief, 'deputy_chief')"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>

                    <hr/>

                    <div class="row mb-2">
                        <label class="col-sm-3 col-form-label required text-end">Служба безопасности*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="settings.sb"
                                placeholder="Выбрать..."
                                label="fullname"
                                track-by="id"
                                :options="users"
                                :taggable="true"
                                :multiple="true"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="cashier"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-lg-2">
                            <button class="btn btn-green"
                                @click="saveSettings(settings.sb, 'sb')"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>

                    <hr/>

                    <div class="row mb-2">
                        <label class="col-sm-3 col-form-label required text-end">Кредитный менеджер*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="settings.kredit"
                                placeholder="Выбрать..."
                                label="fullname"
                                track-by="id"
                                :options="users"
                                :taggable="true"
                                :multiple="true"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="cashier"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-lg-2">
                            <button class="btn btn-green"
                                @click="saveSettings(settings.kredit, 'kredit')"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>

                    <hr/>

                    <div class="row mb-2">
                        <label class="col-sm-3 col-form-label required text-end">Ресепшн*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="settings.reception"
                                placeholder="Выбрать..."
                                label="fullname"
                                track-by="id"
                                :options="users"
                                :taggable="true"
                                :multiple="true"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="cashier"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-lg-2">
                            <button class="btn btn-green"
                                @click="saveSettings(settings.reception, 'reception')"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>

                    <hr/>

                </div>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../../components/ToastAlert";
import axios from "axios";
import * as req from "../../../services/issuance";

export default {
    name: "Settings",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data() {
        return {
            type: '',
            message: '',
            is_alert: false,
            users: [],
            organizations: [],
            organization: null,
            settings: {
                director:null,
                chief:null,
                sb:null,
                kredit:null,
                reception:null,
            },
            isLoading: false,
            breadCrumbs: true,
            title: 'Настройки',
            back_title: 'Меню выдачи',
            link: 'issuance/settings',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/issuances/settings/data', config);
                this.users = response.data.data.users;
                this.settings = response.data.data.settings;
                this.organizations = response.data.data.organizations;
                this.organization = response.data.data.organization;
                this.isLoading = false;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        saveSettings(items, type){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/settings/save', {
                data: items,
                type: type,
                organization: this.organization
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.type = 'green';
                        this.message = 'Успешно сохранено!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.organization = response.data.data.organization;
                        this.settings = response.data.data.settings;
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        async filterOrganization(event){
            this.isLoading = true;
            let result = await req.filterOrganization(event.id);
            this.settings = result.settings;
            this.organization = result.organization;
            this.users = result.users;
            this.isLoading = false;
        },
        reset(event){
            this.getData();
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">

                <div class="item_title" v-if="contract">
                    <h5 class="mb-3">Договор № {{ contract_number }}</h5>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2" v-if="contract.organization">
                        <div class="fs-7 fw-normal text-black-50">Организация</div>
                        <div class="fw-semibold">{{ contract.organization.name }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Автомобиль</div>
                        <div class="fw-semibold">{{ car }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Гос. номер</div>
                        <div class="fw-semibold">{{ car_number }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Менеджер</div>
                        <div class="fw-semibold">{{ manager }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Клиент</div>
                        <div class="fw-semibold">{{ client }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Телефон</div>
                        <div class="fw-semibold">{{ phone }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Уд.л.</div>
                        <div class="fw-semibold">{{ id_number }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">ИИН</div>
                        <div class="fw-semibold">{{ IIN }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Вод.уд.</div>
                        <div class="fw-semibold">{{ driver_id_number }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">За рулём</div>
                        <div class="fw-semibold">{{ in_btw }}</div>
                    </div>

                    <div>
                        <div class="fs-7 fw-normal text-black-50 w-100">Примечание</div>
                        <div class="bg-opacity-25 fw-normal mt-1 p-2 pay_journal_comment w-100 mb-2 lh-base">{{ comment }}</div>
                    </div>

                    <h6 class="border-top mt-3 pt-3">{{ $t('request.button.history')}}</h6>

                    <div class="col-12">
                        <div v-for="item in history" :key="item" class="hstack justify-content-between fs-7 py-1 border-bottom">
                            <div class="col-5">{{ item.date }}</div>
                            <div class="col-7 fw-normal text-end">{{ item.text }}</div>
                        </div>
                    </div>

                </div>

                <div class="my-3">
                    <div class="col-sm position-relative mb-2" v-if="contains(actions, 'menedzer')">
                        <button class="btn w-100 gray"
                            @click="downloadContract(contract_number)"
                        >
                            <fa class="icon me-1 opacity-50" icon="cloud-arrow-down"></fa>
                            Скачать договор<br>
                        </button>
                    </div>
                    <div class="col-sm position-relative mb-2" v-if="contains(actions, 'menedzer') && !is_drive && contract.keys_issued">
                        <button class="btn w-100 btn-green"
                            @click="startTestDrive(contract_number)"
                        >
                            <fa class="icon opacity-50 me-1" icon="car-on"></fa>
                            Начать Test-Drive
                        </button>
                    </div>
                    <div class="col-sm position-relative mb-2" v-if="contains(actions, 'menedzer') && is_change">
                        <button class="btn w-100 btn-yellow"
                                @click="driverСhange(contract_number)"
                        >
                            <fa class="icon opacity-50 me-1" icon="car-on"></fa>
                            Смена водителя
                        </button>
                    </div>
                    <div class="col-sm position-relative mb-2" v-if="!is_drive">
                        <button class="btn w-100 btn-red"
                            @click="deleteContract(contract_number)"
                        >
                            <fa class="icon opacity-50 me-1" icon="ban"></fa>
                            Отменить Test-Drive
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :bg="bg"
    />
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";
import ToastAlert from '../../../components/ToastAlert';
import {getUserPermissionActions} from "../../../services/helpers";
import * as req from "../../../services/td";

export default {
    name: "TDContract",
    components: {
        spinner, BreadCrumbs, ToastAlert
    },
    data() {
        return {
            type: '',
            message: '',
            actions: [],
            contract: [],
            is_alert: false,
            is_drive: false,
            is_change: false,
            phone: null,
            client_btw: false,
            client_from_service	: false,
            contract_number: null,
            car: null,
            car_number: null,
            client: null,
            manager: null,
            IIN: null,
            id_number: null,
            driver_id_number: null,
            comment: null,
            history: [],
            in_btw: null,
            url: '',
            url_orbis: '',
            isLoading: false,
            breadCrumbs: true,
            title: 'Договор',
            back_title: 'Список договоров',
            link: 'test-drive',
        }
    },
    created() {
        this.getContract();
        this.getUserActions();
    },
    methods: {
        async getUserActions(){
            this.isLoading = true;
            this.actions = await getUserPermissionActions('/test-drive');
            this.isLoading = false;
        },
        contains(arr, elem){
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].alias === elem) {
                    return true;
                }
            }
            return false;
        },
        closeAlert(){
            this.is_alert = false;
        },
        async downloadContract(id){
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/testdrives/contract/download/${id}`, config);
                let element = document.createElement("a");
                element.setAttribute("href", response.data.data.url);
                element.setAttribute("download", response.data.data.name);
                element.setAttribute("target", "_blank");
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        driverСhange(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/testdrives/change', {
                id: id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.is_alert = true;
                        this.type = 'green';
                        this.message = 'Вы сменили водителя';
                        this.history = response.data.data.history;
                        this.in_btw = response.data.data.in_btw;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        startTestDrive(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/testdrives/start', {
                id: id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.is_alert = true;
                        this.message = response.data.data.message;
                        this.history = response.data.data.history;
                        this.is_drive = !this.is_drive;
                        this.is_change = response.data.data.is_change;;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        deleteContract(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/testdrives/contract/delete', {
                id: id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.$router.push('/test-drive')
                    }
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        async getContract(){
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/testdrives/contract/${this.$route.params.id}`, config);
                this.contract = response.data.data.contract;
                this.history = response.data.data.contract.history;
                this.phone = response.data.data.contract.phone;
                this.contract_number = response.data.data.contract.contract_number;
                this.car = response.data.data.contract.car;
                this.car_number = response.data.data.contract.car_number;
                this.client = response.data.data.contract.client;
                this.manager = response.data.data.contract.manager;
                this.IIN = response.data.data.contract.IIN;
                this.id_number = response.data.data.contract.id_number;
                this.driver_id_number = response.data.data.contract.driver_id_number;
                this.comment = response.data.data.contract.comment;
                this.in_btw = response.data.data.contract.in_btw;
                this.url = response.data.data.url;
                this.url_orbis = response.data.data.url_orbis;
                this.is_drive = response.data.data.is_drive;
                this.is_change = response.data.data.is_change;
                if(this.$route.params.download == 'true'){
                        window.location.href = this.url;
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <Form ref="imprest" @submit="create">

                        <!-- Организация -->
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">Организация*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model:modelValue="organization"
                                    placeholder="Выберите организацию"
                                    label="name"
                                    track-by="id"
                                    :options="organizations"
                                    name="organization"
                                    :class="errors.organization.length ? 'error' : ''"
                                    @select="selectOrganization($event)"
                                    :show-labels="false"
                                />
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">{{ $t('request.role_chief_accountant') }}*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model="imprest.chief_accountant"
                                    :placeholder="$t('request.role_chief_accountant')"
                                    label="fullname"
                                    track-by="id"
                                    :options="users"
                                    :taggable="true"
                                    :show-labels="false"
                                >
                                </VueMultiselect>
                                <ErrorMessage name="chief_accountant"/>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">{{ $t('request.role_accountant') }}*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model="imprest.accountant"
                                    :placeholder="$t('request.role_accountant')"
                                    label="fullname"
                                    track-by="id"
                                    :options="users"
                                    :taggable="true"
                                    :show-labels="false"
                                >
                                </VueMultiselect>
                                <ErrorMessage name="accountant"/>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">{{ $t('request.cashier_role') }}*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model="imprest.cashier"
                                    :placeholder="$t('request.cashier_role')"
                                    label="fullname"
                                    track-by="id"
                                    :options="users"
                                    :taggable="true"
                                    :multiple="true"
                                    :show-labels="false"
                                >
                                </VueMultiselect>
                                <ErrorMessage name="cashier"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-lg-2">
                                <button class="btn btn-green">
                                    {{ $t('request.button.save') }}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :bg="bg"
    />
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import ToastAlert from "../../../components/ToastAlert";
import * as req from "../../../services/request";

export default {
    name: "SettingImprest",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data() {
        return {
            bg: 'bg-primary',
            message: '',
            is_alert: false,
            users: [],
            organizations: [],
            organization: null,
            imprest: {
                chief_accountant: null,
                accountant: null,
                cashier: null,
            },
            errors: {
                chief_accountant: '',
                accountant: '',
                cashier: '',
                organization: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.advance'),
            back_title: this.$t('request.settings'),
            link: 'request/settings',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        clearInput(e){
            this.errors[e] = '';
        },
        closeAlert(){
            this.is_alert = false;
        },
        create(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/settings/memos', {
                chief_accountant: this.imprest.chief_accountant,
                accountant: this.imprest.accountant,
                cashier: this.imprest.cashier,
                organization: this.organization
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.settings_saved');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.imprest.setErrors(errors.response.data.errors);
                            this.errors.chief_accountant = errors.response.data.errors.chief_accountant ?? 0;
                            this.errors.accountant = errors.response.data.errors.accountant ?? 0;
                            this.errors.cashier = errors.response.data.errors.cashier ?? 0;
                            break;
                    }
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/settings/data', config);
                this.users = response.data.data.users;
                this.imprest = response.data.data.imprest;
                this.organizations = response.data.data.organizations;
                this.organization = response.data.data.firstOrganization;

            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        async selectOrganization(event){
            this.isLoading = true;
            let result = await req.selectOrganization(event.id);
            this.imprest = result.imprest;
            this.organization = result.organization;
            this.users = result.users;
            this.isLoading = false;
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <nav class="bottom-menu-bar navbar fixed-bottom bg-light pb-4 pt-3">
        <div class="container-fluid px-0">
            <div class="hstack justify-content-around w-100">
                <div>
                    <a href="/" class="bm-link vstack text-center text-decoration-none cursor-pointer">
                        <span class="material-symbols-rounded">home</span>
                    </a>
                </div>
                <div>
                    <a class="bm-link vstack text-center text-decoration-none cursor-pointer" @click="scrollToTop()">
                        <span class="material-symbols-rounded fa-flip-both">system_update</span>
                    </a>
                </div>
                <div>
                    <a class="bm-link vstack text-center text-decoration-none cursor-pointer" @click="messageClick()">
                        <span class="material-symbols-rounded">chat</span>
                    </a>
                </div>
                <div>
                    <a href="/notifications/first" class="bm-link vstack text-center text-decoration-none position-relative cursor-pointer">
                        <span class="material-symbols-rounded">notifications</span>
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" v-if="hasUnread">{{ total }}</span>
                    </a>
                </div>
            </div>
        </div>
    </nav>
    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
            />
    </transition>
</template>

<script>
import axios from "axios";
import ToastAlert from "../components/ToastAlert";

export default {
    name: 'BottomMenu',
    components: {
        ToastAlert
    },
    props: {
        user: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            total: 0,
            authUser: [],
            message: '',
            is_alert: false
        }
    },
    computed: {
        hasUnread () {
            return this.total > 0
        }
    },
    mounted () {
        this.getNotifications();
        this.eventBus.on('auth-user', this.getAuthUser);
        this.eventBus.on('notification', this.getNotificationsAndTotal);
    },
    methods: {
        getAuthUser(user){
            this.authUser = user;
            if (window.Echo) {
                this.listen()
            }
        },
        getNotificationsAndTotal(data){
            this.total = data.total;
        },
         closeAlert(){
             this.is_alert = false;
         },
         messageClick() {
             this.is_alert = true;
             this.message = 'Чат в разработке';
             this.type = '';
             setTimeout(() => {this.closeAlert(); }, 1000000);
         },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        async getNotifications(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/notifications', config);
                this.eventBus.emit('notification', response.data.data);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },

        /**
         * Listen for Echo push notifications.
         */
        listen () {
            window.Echo.private(`App.Modules.Admin.User.Models.User.${this.authUser.id}`)
                .notification(notification => {
                    this.getNotifications()
                    let audio = new Audio('/sound/notification.mp3');
                    audio.play();
                })
                .listen('NotificationRead', (e) => {
                    this.getNotifications()
                })
                /*.listen('NotificationReadAll', () => {
                    this.total = 0
                    this.notifications = []
                })*/
        },
    }

}
</script>

<style scoped>


</style>

import * as auth from "./auth";
import axios from "axios";

export const filterOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/issuances/settings/filter/organization/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

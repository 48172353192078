<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">

                <div class="row">
                    <div class="d-flex justify-between flex-wrap my-4">
                        <div class="col-12 col-sm-4 col-lg-3">
                            <button class="btn gray btn_add w-100"
                                @click="showModal"
                            >Добавить</button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive-md">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-muted">Организация</th>
                            <th class="text-muted">Название</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="list in lists"
                                :key="list.id"
                            >
                                <td>
                                    {{ list.organization.name }}
                                </td>
                                <td>
                                    {{ list.name }}
                                </td>
                                <td class="text-end" style="width: 5%;">
                                    <div class="dropdown">
                                        <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                            </svg>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                            <li><button type="button" @click="editPoint(list)" class="dropdown-item">Редактировать</button></li>
                                            <li><hr class="dropdown-divider"></li>
                                            <li>
                                                <button type="button" @click="deletePoint(list.id)" class="dropdown-item">Удалить</button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="is_modal"
            @close="closeModal"
        >
            <template v-slot:title>
                Добавить пункт
            </template>
            <template v-slot:body>
                <div class="row mb-3 mt-3">
                    <label class="col-sm-12 col-form-label required text-start">Организация</label>
                    <div class="col-sm position-relative">
                        <VueMultiselect
                            v-model="organization"
                            placeholder="Выберите организацию"
                            label="name"
                            track-by="id"
                            :options="organizations"
                            :show-labels="false"
                        ></VueMultiselect>
                        <span v-if="errors.organization.length > 0" role="alert" class="mt-3">{{ errors.organization }}</span>
                    </div>
                </div>
                <Input
                    v-model:modelValue="name"
                    @clear="clearInput($event)"
                    :error="[errors.name.length ? 'error' : '']"
                    label="Название организации"
                    name="name"
                    type="text"
                    placeholder="Название организации"
                    id="name"
                />

                <Button
                    caption="Сохранить"
                    color="btn-green"
                    add_class=""
                    @click="create()"
                />
            </template>
        </Modal>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import axios from "axios";
import ToastAlert from "../../../components/ToastAlert";
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import VueMultiselect from "vue-multiselect";
import Button from "../../../components/Button.vue";

export default {
    name: "CheckPoint",
    components: {
        spinner, BreadCrumbs, ToastAlert, Modal, Input, VueMultiselect, Button
    },
    data() {
        return {
            message: '',
            type: '',
            is_alert: false,
            id: null,
            name: null,
            organizations: [],
            organization: null,
            errors: {
                name: '',
                organization: '',
            },
            actions: [],
            lists: [],
            is_modal: false,
            isLoading: false,
            breadCrumbs: true,
            title: 'Пункты проверок',
            back_title: 'Категории',
            link: 'issuance/settings',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        clearInput(e){
            this.errors[e] = '';
        },
        closeAlert(){
            this.is_alert = false;
        },
        showModal(){
            this.is_modal = true;
        },
        closeModal(){
            this.id = null;
            this.name = null;
            this.organization = null;
            this.is_modal = false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/issuances/settings/points', config);
                this.lists = response.data.data.lists;
                this.organizations = response.data.data.organizations;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        editPoint(item){
            this.id = item.id;
            this.name = item.name;
            this.organization = item.organization;
            this.showModal();
        },
        create(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/settings/points/save', {
                id: this.id,
                name: this.name,
                organization: this.organization,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.is_alert = true;
                        this.type = 'green';
                        this.message = response.data.data.message;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.name = null;
                        this.organization = null;
                        this.closeModal();
                        this.lists = response.data.data.lists;
                        this.isLoading = false;
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.point.setErrors(errors.response.data.errors);
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            break;
                    }
                });
        },
        deletePoint(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/settings/points/delete', {
                id: id,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.is_alert = true;
                        this.type = 'green';
                        this.message = 'Успешно удалено';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.closeModal();
                        this.lists = response.data.data.lists;
                        this.isLoading = false;
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <h5 class="my-3">{{ $t('request.new_request') }}</h5>

                    <Form ref="docs">

                        <div class="mb-3 vstack mt-3">
                            <div class="form-check">
                                <input
                                    class="form-check-input border_ckeckbox"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    @click="toggleUrgent"
                                    :checked="docs.urgent">
                                    <label class="form-check-label fs-7 fw-bold" for="flexCheckDefault">
                                        {{ $t('request.urgent_payment') }}
                                    </label>
                            </div>
                            <div class="form-check">
                                <input
                                    class="form-check-input border_ckeckbox"
                                    type="checkbox"
                                    value=""
                                    id="PaymentDocument"
                                    @click="togglePaymentDocument"
                                    :checked="docs.payment_document">
                                    <label class="form-check-label fs-7 fw-bold" for="PaymentDocument">
                                        {{ $t('request.request_payment_document') }}
                                    </label>
                            </div>
                            <div class="form-check">
                                <input
                                    class="form-check-input border_ckeckbox"
                                    type="checkbox"
                                    value=""
                                    id="LetterAttorney"
                                    @click="toggleLetterAttorney"
                                    :checked="docs.letter_attorney">
                                    <label class="form-check-label fs-7 fw-bold" for="LetterAttorney">
                                        {{ $t('request.request_power_attorney') }}
                                    </label>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Выберите организацию*</label>
                            <VueMultiselect
                                @open="clearErrors('organization')"
                                v-model:modelValue="organization"
                                placeholder="Выберите организацию"
                                label="name"
                                track-by="id"
                                :options="organizations"
                                name="organization"
                                :show-labels="false"
                                :searchable="false"
                                @select="selectOrganization($event)"
                            ></VueMultiselect>
                            <span role="alert" v-if="errors.organization.length > 0">{{errors.organization}}</span>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.select_category') }}*</label>
                            <VueMultiselect
                                @open="clearErrors('category')"
                                v-model="docs.category"
                                :placeholder="$t('request.select_category')"
                                label="name"
                                track-by="id"
                                :options="categorys"
                                :show-labels="false"
                                :searchable="false"
                            ></VueMultiselect>
                            <span role="alert" v-if="errors.category.length > 0">{{errors.category}}</span>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.select_counterparty') }}*</label>
                            <VueMultiselect
                                @open="clearErrors('counteragent')"
                                v-model="docs.counteragent"
                                :placeholder="$t('request.select_counterparty')"
                                label="name"
                                track-by="id"
                                :options="counteragents"
                                :show-labels="false"
                                ></VueMultiselect>
                            <span role="alert" v-if="errors.counteragent.length > 0">{{errors.counteragent}}</span>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.payment_amount') }}*</label>
                            <div class="fs-4 ps-2 fw-bold">{{ cur(docs.payment_amount) }} <template v-if="docs.currency">{{ docs.currency['id'] }}</template></div>
                            <Field
                                @input="paymentAmountErrorClear()"
                                v-model="docs.payment_amount"
                                type="number"
                                name="payment_amount"
                                class="form-control"/>
                            <span role="alert" v-if="errors.payment_amount.length > 0">{{errors.payment_amount}}</span>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.select_currency') }}*</label>
                            <VueMultiselect
                                @open="clearErrors('currency')"
                                v-model="docs.currency"
                                :placeholder="$t('request.select_currency')"
                                label="name"
                                track-by="id"
                                :options="currencys"
                                :show-labels="false"
                                :searchable="false"
                                ></VueMultiselect>
                            <span role="alert" v-if="errors.currency.length > 0">{{errors.currency}}</span>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.payment_type') }}*</label>
                            <VueMultiselect
                                @open="clearErrors('payment_type')"
                                v-model="docs.payment_type"
                                :placeholder="$t('request.payment_type')"
                                label="name"
                                track-by="id"
                                :options="typePayments"
                                :show-labels="false"
                                :searchable="false"
                                ></VueMultiselect>
                            <span role="alert" v-if="errors.payment_type.length > 0">{{errors.payment_type}}</span>
                        </div>

                        <div class="mb-3 mt-3">
                            <label class="form-label">{{ $t('request.comment') }}</label>
                            <textarea class="form-control" v-model="docs.comment"></textarea>
                        </div>

                        <div class="mb-3">
                            <label for="formFileMultiple" class="form-label">{{ $t('request.attach_files') }}</label>
                            <input
                                ref="files"
                                class="form-control"
                                type="file"
                                id="formFileMultiple"
                                multiple="multiple"
                                v-on:change="handleFileUpload()">
                        </div>

                        <div class="col col-md-2 mt-5">
                            <button class="btn btn-green w-100"
                                    @click="create"
                            >
                                {{ $t("request.button.save") }}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../../components/ToastAlert";
import axios from "axios";
import * as req from "../../../services/request";
import {selectRequestOrganization} from "../../../services/request";
import {errorMessages} from "../../../services/helpers";

export default {
    name: "PaymentsCreate",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data() {
        return {
            message: '',
            type: '',
            is_alert: false,
            docs: {
                comment: null,
                payment_amount: null,
                counteragent: null,
                category: null,
                currency: null,
                payment_type: null,
                urgent: false,
                letter_attorney: false,
                payment_document: false,
            },
            errors: {
                payment_amount: '',
                counteragent: '',
                category: '',
                currency: '',
                payment_type: '',
                organization: '',
            },
            counteragents: [],
            categorys: [],
            currencys: [],
            typePayments: [],
            organizations: [],
            organization: null,
            error: [],
            files: '',
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.create_payment'),
            back_title: this.$t('request.payments'),
            link: 'request/payments/index',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        paymentAmountErrorClear(){
            this.errors.payment_amount = '';
        },
        clearErrors(value){
            this.errors[value] = '';
        },
        closeAlert(){
            this.is_alert = false;
        },
        togglePaymentDocument(){
            this.docs.payment_document = !this.docs.payment_document
        },
        toggleLetterAttorney(){
            this.docs.letter_attorney = !this.docs.letter_attorney
        },
        toggleUrgent() {
            this.docs.urgent = !this.docs.urgent
        },
        handleFileUpload(){
            this.files = this.$refs.files.files;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/payments/create', config);
                this.counteragents = response.data.data.counteragents;
                this.categorys = response.data.data.categorys;
                this.currencys = response.data.data.currencys;
                this.typePayments = response.data.data.typePayments;
                this.organizations = response.data.data.organizations;
                this.organization = response.data.data.organization;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        validateForm(){
            this.error = [];
            if(this.docs.counteragent == null || this.docs.counteragent == ''){
                this.errors.counteragent = this.$t('validate.required');
                this.error.push(this.$t('validate.required'));
            }
            if(this.docs.category == null || this.docs.category == ''){
                this.errors.category = this.$t('validate.required');
                this.error.push(this.$t('validate.required'));
            }
            if(this.docs.currency == null || this.docs.currency == ''){
                this.errors.currency = this.$t('validate.required');
                this.error.push(this.$t('validate.required'));
            }
            if(this.docs.payment_type == null || this.docs.payment_type == ''){
                this.errors.payment_type = this.$t('validate.required');
                this.error.push(this.$t('validate.required'));
            }
            if(this.docs.payment_amount == null || this.docs.payment_amount == ''){
                this.errors.payment_amount = this.$t('validate.required');
                this.error.push(this.$t('validate.required'));
            }
            if(this.organization == null || this.organization == ''){
                this.errors.organization = this.$t('validate.required');
                this.error.push(this.$t('Поле организация обязательно!'));
            }

            if (!this.error.length) {
                return true;
            }else{
                return false;
            }
        },
        create(e){
            e.preventDefault();
            if(this.validateForm()){
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token'), 'Content-Type': 'multipart/form-data'}};
                const formData = new FormData();
                if(this.docs.counteragent != null){
                    formData.append('counteragent_id', this.docs.counteragent.id);
                }else{
                    formData.append('counteragent_id', null);
                }

                if(this.docs.category != null){
                    formData.append('category_id', this.docs.category.id);
                }else{
                    formData.append('category_id', null);
                }

                if(this.docs.currency != null){
                    formData.append('currency', this.docs.currency.id);
                }else{
                    formData.append('currency', null);
                }

                if(this.docs.payment_type != null){
                    formData.append('payment_type', this.docs.payment_type.id);
                }else{
                    formData.append('payment_type', null);
                }

                for( var i = 0; i < this.files.length; i++ ){
                    let file = this.files[i];
                    formData.append('files[' + i + ']', file);
                }
                formData.append('payment_amount', this.docs.payment_amount);
                formData.append('comment', this.docs.comment);
                formData.append('urgent', this.docs.urgent);
                formData.append('payment_document', this.docs.payment_document);
                formData.append('letter_attorney', this.docs.letter_attorney);
                formData.append('organization_id', this.organization.id);
                axios.post('/api/admin/payments/store', formData, config)
                    .then(response => {
                        this.isLoading = false;
                        if (response.status == 200) {
                            this.$router.push('/request/payments/index')
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        switch (errors.response.status) {
                            case 422:
                                this.$refs.docs.setErrors(errors.response.data.errors);
                                this.errors.payment_amount = errors.response.data.errors.payment_amount ?? 0;
                                this.errors.counteragent = errors.response.data.errors.counteragent_id ?? 0;
                                this.errors.category = errors.response.data.errors.category_id ?? 0;
                                this.errors.currency = errors.response.data.errors.currency ?? 0;
                                this.errors.payment_type = errors.response.data.errors.payment_type ?? 0;
                                break;
                            case 404:
                                this.type = 'red';
                                this.message = errors.response.data.data.message;
                                this.is_alert = true;
                                setTimeout(() => {this.closeAlert(); }, 5000);
                        }
                    });
            }
        },
        cur(val) {
            if (!val) {
                return "";
            }
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        async selectOrganization(event){
            this.isLoading = true;
            this.docs.category = null;
            let result = await req.selectRequestOrganization(event.id);
            this.categorys = result.categorys;
            this.organization = result.organization;
            this.isLoading = false;
        }
    }
}
</script>

<style scoped>

</style>

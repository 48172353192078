<template>
    <div class="toast scroll-behavior" :class="this.type" @click="close_alert">
        <div class="d-flex d-flex justify-content-center">
            <div class="toast-body align-items-center hstack">
                <span class="material-symbols-rounded pe-2 text-white-50 pb-1" style="font-size: 30px;">{{ getType() }}</span>
                <div class="t-message">{{ message }}</div>
            </div>
            <button type="button" class="btn-close"></button>
        </div>
    </div>
</template>

<script>

/*
    TYPE
    red - красный
    yellow - желтый
    green - зелёный
    gray - серый
*/

/*
    ICONS
    red - warning
    yellow -
    green -
    gray -
*/

export default {
    name: "ToastAlert",
    props: {
        message: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: ''
        }
    },
    computed: {

    },
    methods: {
        close_alert(){
            this.$emit('close_alert')
        },
        getType() {
          if(this.type == 'green') {
            return 'check_circle'
          } else if(this.type == 'yellow') {
            return 'warning'
          } else if(this.type == 'red') {
            return 'error'
          } else if(!this.type) {
            return 'info'
          }

        }
    }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 1, 1, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(60px);
  /* opacity: 0; */
}
</style>

<template>
  <spinner v-if="isLoading"></spinner>
  <transition name="fade">
    <div>
        <BreadCrumbs
            :breadCrumbs="breadCrumbs"
            :title="title"
            :back_title="back_title"
            :link="link"/>

      <div class="page">
        <h5 class="my-3 hstack">
          <Buying :count="2" />
          Информация об автомобиле
        </h5>

        <Form ref="cars" @submit="SaveCar">

            <div class="mb-3">
                <label class="form-label">VIN-код</label>
                <Field
                    style="text-transform: uppercase"
                    placeholder="VIN"
                    maxlength="17"
                    v-model="search"
                    type="text"
                    name="vin_code"
                    class="form-control"
                    :class="[errors.vin_code.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="vin_code"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Марка</label>
                <VueMultiselect
                    v-model="cars.brand"
                    placeholder="Марка"
                    label="name"
                    track-by="id"
                    :options="brands"
                    :show-labels="false"
                    @select="selectBrand"
                    :class="errors.brand.length ? 'error' : ''"></VueMultiselect>
                <ErrorMessage name="brand"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Модель</label>
                <VueMultiselect
                    v-model="cars.model"
                    placeholder="Модель"
                    label="name"
                    track-by="id"
                    :options="models"
                    :show-labels="false"
                    @select="selectModel()"
                    :class="errors.model.length ? 'error' : ''"></VueMultiselect>
                <ErrorMessage name="model"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Год выпуска</label>
                <Field
                    placeholder="Год выпуска"
                    v-model="cars.year_release"
                    type="text"
                    name="year_release"
                    class="form-control"
                    :class="[errors.year_release.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="year_release"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Код комплектации<br>(Если не известно, оставьте пустым)</label>
                <Field
                    placeholder="Комплектация"
                    v-model="cars.configuration"
                    type="text"
                    name="configuration"
                    class="form-control"
                    :class="[errors.configuration.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="configuration"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Двигатель</label>
                <VueMultiselect
                    v-model="cars.motor"
                    placeholder="Двигатель"
                    label="name"
                    track-by="id"
                    :options="motors"
                    :show-labels="false"
                    :searchable="false"
                    :class="[errors.motor.length > 0 ? 'error is-invalid' : '']"></VueMultiselect>
                <ErrorMessage name="motor"/>
            </div>

            <div class="mb-3">
                <label class="form-label">КПП</label>
                <VueMultiselect
                    v-model="cars.kpp"
                    placeholder="КПП"
                    label="name"
                    track-by="id"
                    :options="kpp_array"
                    :searchable="false"
                    :show-labels="false"
                    :class="[errors.kpp.length > 0 ? 'error is-invalid' : '']"></VueMultiselect>
                <ErrorMessage name="kpp"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Гос. номер</label>
                <Field
                    style="text-transform: uppercase"
                    placeholder="Гос. номер"
                    v-model="cars.car_number"
                    type="text"
                    name="car_number"
                    class="form-control"
                    :class="[errors.car_number.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="car_number"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Пробег, км</label>
                <Field
                    placeholder="Пробег автомобиля"
                    v-model="cars.mileage"
                    type="text"
                    name="mileage"
                    class="form-control"
                    :class="[errors.mileage.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="mileage"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Цвет кузова</label>
                <VueMultiselect
                    v-model="cars.color"
                    placeholder="Цвет кузова"
                    label="name"
                    track-by="id"
                    :options="colors"
                    :searchable="false"
                    :show-labels="false"
                    :class="[errors.color.length > 0 ? 'error is-invalid' : '']"></VueMultiselect>
                <ErrorMessage name="color"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Тип кузова</label>
                <VueMultiselect
                    v-model="cars.bodywork"
                    placeholder="Кузов"
                    label="name"
                    track-by="id"
                    :options="bodyworks"
                    :searchable="false"
                    :show-labels="false"
                    :class="[errors.bodywork.length > 0 ? 'error is-invalid' : '']"></VueMultiselect>
                <ErrorMessage name="bodywork"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Привод</label>
                <VueMultiselect
                    v-model="cars.actuator"
                    placeholder="Привод"
                    label="name"
                    track-by="id"
                    :options="actuators"
                    :searchable="false"
                    :show-labels="false"
                    :class="[errors.actuator.length > 0 ? 'error is-invalid' : '']"></VueMultiselect>
                <ErrorMessage name="actuator"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Объем двигателя, л</label>
                <Field
                    placeholder="Объем двигателя, л"
                    v-model="cars.engine_capacity"
                    type="number"
                    name="engine_capacity"
                    class="form-control"
                    :class="[errors.engine_capacity.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="engine_capacity"/>
            </div>

            <div class="my-5 save_step">
                <button class="btn btn-green w-100">Сохранить</button>
            </div>
        </Form>
      </div>
    </div>
  </transition>

  <toast-alert v-if="is_alert" @close_alert="closeAlert" :message="message" :bg="bg" />
</template>

<script>
import Buying from "./Buying";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { Field, Form, ErrorMessage } from "vee-validate";
import { getTradeInStatusStep } from "../../services/helpers";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../components/ToastAlert";
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
  name: "Step_2",
  components: {
    Buying,
    Spinner,
    Field,
    Form,
    ErrorMessage,
    VueMultiselect,
    ToastAlert,
    BreadCrumbs,
  },
  data: () => ({
    type: "gray",
    message: "",
    is_alert: false,
    isLoading: false,
    is_search: true,
    step: "purity",
    back_step: "client",
    search: "",
    cars: {
      year_release: null,
      configuration: null,
      car_number: null,
      mileage: 0,
      engine_capacity: null,
      brand: null,
      model: null,
      color: null,
      kpp: null,
      motor: null,
      bodywork: null,
      actuator: null,
    },
    errors: {
      brand: "",
      model: "",
      color: "",
      year_release: "",
      configuration: "",
      vin_code: "",
      car_number: "",
      mileage: "",
      kpp: "",
      motor: "",
      bodywork: "",
      actuator: "",
      engine_capacity: "",
    },
    brands: [],
    colors: [],
    models: [],
    kpp_array: [],
    motors: [],
    bodyworks: [],
    actuators: [],
    breadCrumbs: true,
    title: "Автомобиль",
    back_title: "Этапы приёма",
    link: "",
  }),
  watch: {
    search(after, before) {
      this.search_car();
    },
  },
  created() {
    this.getData();
    this.getStepLists();
    this.getBackLink();
  },
  methods: {
    getBackLink() {
        this.link = this.link = `tradeinlist/${this.$route.params.id}`
    },
    selectBrand(value) {
      this.errors.brand = "";
      this.getCarModels(value.id);
    },
    selectModel(value) {
      this.errors.model = "";
    },
    async getStepLists() {
      this.lists = await getTradeInStatusStep(this.$route.params.id);
    },
    closeAlert() {
        this.is_alert = false;
    },
    async search_car() {
      try {
          if(this.search.length < 17){
              this.is_search = true;
          }
          if (this.search.length == 17 && this.is_search) {
              const config = {headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") }};
              const response = await axios.get(`/api/admin/tradein/search/car/${this.search}`, config);
              if (response.data.data.car != null) {
                  this.is_search = false;
                  this.cars.brand = response.data.data.car.brand;
                  this.cars.model = response.data.data.car.car_model;
                  this.cars.color = response.data.data.car.color;
                  this.cars.motor = response.data.data.car.motor;
                  this.cars.kpp = response.data.data.car.car_kpp;
                  this.cars.bodywork = response.data.data.car.car_bodywork;
                  this.cars.year_release = response.data.data.car.year_release;
                  this.cars.configuration = response.data.data.car.configuration;
                  this.search = response.data.data.car.vin_code;
                  this.cars.car_number = response.data.data.car.car_number;
                  this.cars.mileage = response.data.data.car.mileage;
                  this.cars.actuator = response.data.data.car.actuator;
                  this.cars.engine_capacity = response.data.data.car.engine_capacity;

                  this.is_alert = true;
                  this.type = 'gray';
                  this.message = "Автомобиль найден";
              }else {
                  this.cars.brand = null;
                  this.cars.model = null;
                  this.cars.color = null;
                  this.cars.motor = null;
                  this.cars.kpp = null;
                  this.cars.bodywork = null;
                  this.cars.year_release = null;
                  this.cars.configuration = null;
                  this.cars.car_number = null;
                  this.cars.mileage = null;
                  this.cars.actuator = null;
                  this.cars.engine_capacity = null;
                  this.errors.vin_code = '';
                  this.is_alert = true;
                  this.type = 'gray';
                  this.message = "Автомобиль не найден";
              }
              setTimeout(() => {
                  this.closeAlert();
              }, 3000);
          }
      } catch (e) {
          console.log(e);
      }
    },
    async getData() {
      try {
        this.isLoading = true;
        const config = {
          headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
        };
        const response = await axios.get(
          `/api/admin/tradein/cars/${this.$route.params.id}`,
          config
        );
        this.brands = response.data.data.brands;
        this.colors = response.data.data.colors;
        this.models = response.data.data.models;
        this.bodyworks = response.data.data.bodyworks;
        this.motors = response.data.data.motors;
        this.kpp_array = response.data.data.kpp;
        this.actuators = response.data.data.actuators;
        if(response.data.data.car != null){
            this.is_search = false;
            this.cars.brand = response.data.data.car.brand;
            this.cars.model = response.data.data.car.car_model;
            this.cars.color = response.data.data.car.color;
            this.cars.motor = response.data.data.car.motor;
            this.cars.kpp = response.data.data.car.car_kpp;
            this.cars.bodywork = response.data.data.car.car_bodywork;
            this.cars.year_release = response.data.data.car.year_release;
            this.cars.configuration = response.data.data.car.configuration;
            this.search = response.data.data.car.vin_code;
            this.cars.car_number = response.data.data.car.car_number;
            this.cars.mileage = response.data.data.car.mileage;
            this.cars.actuator = response.data.data.car.actuator;
            this.cars.engine_capacity = response.data.data.car.engine_capacity;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    changeModel() {
      this.errors.model = "";
    },
    changeColor() {
      this.errors.color = "";
    },
    changeKpp() {
      this.errors.kpp = "";
    },
    changeMotor() {
      this.errors.motor = "";
    },
    changeBodywork() {
      this.errors.bodywork = "";
    },
    changeWheel() {
      this.errors.wheel = "";
    },
    changeActuator() {
      this.errors.actuator = "";
    },
    async getCarModels(id) {
      try {
        const config = {
          headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
        };
        const response = await axios.get(`/api/admin/tradein/models/${id}`, config);
        this.models = response.data.data.models;
      } catch (e) {
        console.log(e);
      }
    },
    SaveCar() {
      this.isLoading = true;
      const config = {
        headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
      };
      axios
        .post(
          "/api/admin/tradein/car/save",
          {
            brand: this.cars.brand,
            model: this.cars.model,
            color: this.cars.color,
            bodywork: this.cars.bodywork,
            motor: this.cars.motor,
            kpp: this.cars.kpp,
            actuator: this.cars.actuator,
            engine_capacity: this.cars.engine_capacity,
            year_release: this.cars.year_release,
            configuration: this.cars.configuration,
            vin_code: this.search,
            car_number: this.cars.car_number,
            mileage: this.cars.mileage,
            id: this.$route.params.id,
            step_number: 2,
          },
          config
        )
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            this.$router.push(`/buying/purity/${this.$route.params.id}`);
          }
        })
        .catch((errors) => {
          this.isLoading = false;
          switch (errors.response.status) {
            case 422:
              this.$refs.cars.setErrors(errors.response.data.errors);
              this.errors.brand = errors.response.data.errors.brand_id ?? 0;
              this.errors.model = errors.response.data.errors.model_id ?? 0;
              this.errors.color = errors.response.data.errors.color_id ?? 0;
              this.errors.bodywork = errors.response.data.errors.bodywork_id ?? 0;
              this.errors.motor = errors.response.data.errors.motor_id ?? 0;
              this.errors.kpp = errors.response.data.errors.kpp_id ?? 0;
              this.errors.year_release = errors.response.data.errors.year_release ?? 0;
              this.errors.configuration = errors.response.data.errors.configuration ?? 0;
              this.errors.vin_code = errors.response.data.errors.vin_code ?? 0;
              this.errors.car_number = errors.response.data.errors.car_number ?? 0;
              this.errors.mileage = errors.response.data.errors.mileage ?? 0;
              this.errors.actuator = errors.response.data.errors.actuator ?? 0;
              break;
          }
        });
    },
  },
};
</script>

<style scoped>

</style>

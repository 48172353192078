<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
            :breadCrumbs="breadCrumbs"
            :title="title"
            :back_title="back_title"
            :link="link"
            />

            <div class="page">

                <h5 class="my-3">Журнал приёма</h5>

                <!-- Кнопка создать -->
                <div class="mb-3">
                    <button class="btn btn-green w-100 hstack" @click="CreateAcceptance">
                        <span class="material-symbols-rounded me-2 opacity-50">add</span>
                        Создать новый
                    </button>
                </div>
<!--                <div>
                    <label class="form-label">Фильтр по организации</label>
                    <VueMultiselect
                        v-model:modelValue="organization"
                        placeholder="Выберите организацию"
                        label="name"
                        track-by="id"
                        :options="organizations"
                        name="organization"
                        @select="selectOrganization($event)"
                    />
                </div>-->

                <!-- Поиск -->
                <div class="hstack justify-content-between gap-2 mb-1">
                    <div>
                        <label class="form-label">Поиск по телефону</label>
                        <input type="search" class="form-control mb-0" v-model="phone" placeholder="Телефон..."/>
                    </div>
                    <div>
                        <label class="form-label">Поиск по VIN-коду</label>
                        <input type="search" class="form-control mb-0" v-model="vin_code" placeholder="VIN-код..."/>
                    </div>
                </div>

                <!-- Фильтры по статусу -->
                <div class="mb-3">
                    <label class="form-label">Фильтр по статусу</label>
                    <VueMultiselect
                        placeholder="Все"
                        v-model="status"
                        name="deal"
                        label="title"
                        track-by="id"
                        :options="statuses"
                        :searchable="false"
                        :show-labels="false"
                        @select="selectStatus"
                        @remove="resmoveStatus"
                    ></VueMultiselect>
                </div>

                <!-- Карточки выкупа -->
                <div v-if="filteredList.length">
                    <div class="item_title mb-2" v-for="item in filteredList" :key="item.id">
                        <div class="d-flex flex-wrap cursor-pointer" @click="showAppeal(item.id)" >

                            <!-- Номер, Дата, Статус -->
                            <div class="col-12 d-flex fw-semibold justify-content-between pay_number pb-3">
                                <div class="fs-6 fw-bold">№{{ item.number }}</div>
                                <div class="fs-7">{{ item.date_appeal }}</div>
                                <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block" :class="item.status">{{ this.statusTranslate(item.status) }}</div>
                            </div>

                            <!-- Автомобиль -->
                            <div class="border-bottom col-12 col-md-6 mb-3 pb-3" v-if="item.car">
                                <div class="steps_line hstack gap-1 w-100 border-bottom mb-3">
                                    <div class="status_box col" :class="step.status" v-for="step in item.steps.steps"></div>
                                </div>
                                <div>
                                    <fa class="icon opacity-25 me-2" icon="car"></fa>
                                    <span class="fs-6 fw-bold">
                                    {{ item.car.brands.name_brand }} {{ item.car.models.name_model }}
                                    <span class="fs-7 fw-semibold">&mdash; {{ item.car.year_release }} г.</span>
                                </span>
                                    <div class="fs-7 fw-normal text-black-50 ps-4">{{ item.car.vin_code }}</div>
                                </div>

                                <!-- Цена -->
                                <div class="mt-1" v-if="item.price">
                                <span class="fs-5 ps-4">
                                    {{ item.price.toLocaleString() }} ₸
                                </span>
                                    <div class="fs-7 fw-normal ps-4 position-relative">
                                    <span
                                        :class="item.status_price"
                                        class="p-1 position-absolute rounded-circle translate-middle price-status"
                                        style="top: 10px;left: 12px;height: 10px;width: 10px;"></span>
                                        {{ this.statusTranslate(item.status_price) }}
                                    </div>
                                </div>
                                <div class="mt-1" v-else>
                                <span class="fs-6 ps-4">
                                    не оценена
                                </span>
                                </div>
                            </div>
                            <div class="border-bottom col-12 col-md-6 mb-3 pb-3" v-else>
                                <fa class="icon opacity-25 me-2" icon="car"></fa>
                                <span class="fs-6 fw-bold">Авто не добавлен</span>
                            </div>

                            <!-- Клиент -->
                            <div class="border-bottom col-12 col-md-6 mb-3 pb-3 hstack" v-if="item.client != null">
                                <div>
                                    <fa class="icon opacity-25 me-2" icon="user"></fa>
                                </div>
                                <div>
                                    <div class="mb-1 vstack">
                                        <span class="fs-7 fw-normal text-black-50">Клиент</span>
                                        <span>
                                        {{ item.client.surname }}
                                        {{ item.client.name }}
                                        {{ item.client.patronymic }}
                                    </span>
                                    </div>
                                    <div>
                                        <span class="fw-normal">{{ item.client.phone.replace(/([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})/g, '($2) $3-$4-$5') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 border-bottom mb-3 pb-3" v-else>
                                <div class="mb-2">
                                    <fa class="icon opacity-25 me-2" icon="user"></fa>
                                    <span>Клиент не добавлен</span>
                                </div>
                            </div>

                            <!-- Менеджер -->
                            <div class="col-12 hstack">
                            <span>
                                <fa class="icon opacity-25 me-2" icon="user-tie"></fa>
                            </span>
                                <span class="vstack">
                                <span class="fs-7 fw-normal text-black-50">Менеджер</span>
                                <span class="fw-normal">{{ item.manager.surname }} {{ item.manager.name }}</span>
                            </span>
                            </div>
                        </div>
                        <div class="col-12 mt-2" v-if="!item.client">
                            <div class="btn btn-small btn-red" @click="remoweRequest(item.id)">Удалить</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="mb-3">
                        <div class="item_title fw-semibold">
                            Данных нет!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>

</template>

<script>
import spinner from "../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import axios from "axios";
import BreadCrumbs from "../../Page/BreadCrumbs";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../components/ToastAlert";

import {getTradeInStatusStep} from "./../../services/helpers";

export default {
    name: "Journal",
    components: {
        spinner, Field, Form, ErrorMessage, BreadCrumbs, VueMultiselect, ToastAlert
    },
    data(){
        return {
            is_allow: false,
            isLoading: false,
            tradein: [],
            statuses: [],
            //organizations: [],
            //organization: null,
            //status: [{id: 'all', title: 'Все'}],
            status: null,
            phone: '',
            vin_code: '',
            message: '',
            type: 'green',
            is_alert: false,
            breadCrumbs: true,
            title: 'Журнал ',
            back_title: 'Trade In',
            link: 'tradein',
            steps: null,
        }
    },
    computed: {
        filteredList(){
            if(this.phone.length > 0){
                const filterValue = this.phone.toLowerCase();
                return this.tradein.filter(function (item) {
                    if(item.client !== null){
                        return item.client.phone.toLowerCase().includes(filterValue);
                    }
                });
            }else if(this.vin_code.length > 0){
                const filterValue = this.vin_code.toLowerCase();
                return this.tradein.filter(function (item) {
                    if(item.car !== null){
                        return item.car.vin_code.toLowerCase().includes(filterValue);
                    }
                });
            }else{
                return this.tradein;
            }

        },
    },
    mounted() {
        this.getTradein();
    },
    methods: {
        async getSteps(idx) {
            var sts_list = getTradeInStatusStep(idx);
        },
        closeAlert(){
            this.is_alert = false;
        },
        showAppeal(id){
            //this.$router.push(`/buying/client/${id}`);
            this.$router.push(`/tradeinlist/${id}`);
        },
        async getTradein(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/tradein', config);
                this.tradein = response.data.data.tradein;
                this.statuses = response.data.data.statuses;
                //this.organizations = response.data.data.organizations;
                this.is_allow = true;
            } catch (e) {
                const response = e.response;
                if (response.status === 503) {
                    this.is_allow = false;
                    this.message = response.data.data.message;
                    this.type = 'red';
                    this.is_alert = true;
                    setTimeout(() => {this.closeAlert(); }, 3000);
                }
            } finally {
                this.isLoading = false;
            }
        },
        CreateAcceptance(){
            if (!this.is_allow) {
                this.message = 'Вы не привязаны к организации! Обратитесь к администратору';
                this.type = 'red';
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 3000);
            } else {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                axios.post('/api/admin/tradein', {
                }, config)
                    .then(response => {
                        if(response.status == 200){
                            this.$router.push(`/buying/client/${response.data.data.tradein.id}`);
                        }
                    })
                    .catch(errors => {
                        console.log(errors.response.status);
                    });
            }
        },
        statusTranslate(status){
            if(status === 'in_work'){
                return 'в работе';
            }else if(status === 'rejection'){
                return 'отказ';
            }else if(status === 'approved'){
                return 'утверждено';
            }else if(status === 'preliminary'){
                return 'предварительная';
            }else if(status === 'adjustment'){
                return 'корректировка';
            }else if(status === 'remaking'){
                return 'доработка';
            }else if(status === 'warehouse'){
                return 'склад';
            }
        },
        selectStatus(value){
            this.status = value;
            this.filter();
        },
        resmoveStatus(){
            this.status = null;
            this.filter();
        },
        selectOrganization(value){
            this.organization = value;
            this.filter();
        },
        filter(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/tradein/filter', {
                status: this.status,
                vin_code: this.vin_code,
                phone: this.phone,
                //organization: this.organization,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.tradein = response.data.data.tradein;
                        //this.organization = response.data.data.organization;
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        remoweRequest(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/tradein/delete', {
                id: id,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.tradein = response.data.data.tradein;
                        this.is_alert = true;
                        this.message = response.data.data.message;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>

</style>

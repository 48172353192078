<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row mb-5">
                    <div class="col">
                        <h2 class="fs-4">Меню</h2>
                    </div>
                    <div class="col-auto">
                        <router-link to="/admins/module/create">
                            <button class="btn gray btn_add">
                                Добавить
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="table-responsive-md">
                    <table class="table table-row table-app">
                        <thead>
                        <tr>
                            <th class="text-muted">Заголовок</th>
                            <th class="text-muted">Путь</th>
                            <th class="text-muted">Иконка</th>
                            <th class="text-muted">Сортировка</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="module in modules"
                            :key="module.id"
                        >
                            <td>
                                {{ module.title }}
                            </td>
                            <td>
                                {{ module.path }}
                            </td>
                            <td style="width: 7%" v-html="module.icon">

                            </td>
                            <td>
                                {{ module.sort }}
                            </td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                        <li><button type="button" @click="$router.push(`/admins/module/edit/${module.id}`)" class="dropdown-item">Редактировать</button></li>
                                        <li><hr class="dropdown-divider"></li>
                                        <li>
                                            <button type="button" @click="deleteModule(module.id)" class="dropdown-item">Удалить</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import Spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "IndexModule",
    components: {
        Spinner, BreadCrumbs
    },
    data(){
        return {
            modules:{},
            isLoading: false,
            breadCrumbs: true,
            title: 'Список модулей',
            back_title: 'Настройки',
            link: 'admins',
        }
    },
    mounted() {
        this.getModules();
    },
    methods: {
        async getModules(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/modules', config);
                this.modules = response.data.data.modules;
                console.log(this.modules);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        deleteModule(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.delete(`/api/admin/modules/${id}`, config)
                .then(response =>{
                    if(response.status == 200){
                        /* this.$flashMessage.show({
                             type: 'success',
                             text: 'Пункт меню успешно удален!',
                         });*/
                        this.getModules();
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        }
    },
}
</script>

<style scoped>

</style>

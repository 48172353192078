<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="cl_form all_reports">
                    <div class="container">
                        <div class="row">
                            <div class="cl_report col-12 p-0">
                                <div class="col-12 login_form">
                                    <div class="item_title">
                                        <input type="date" class="form-control mb-1" v-model="date"
                                               style="margin-bottom: unset"
                                               @change="dateChange"
                                        >
                                        <input type="text" class="form-control" v-model="search" placeholder="Номер договора..."
                                               style="margin-bottom: unset"
                                        >
                                    </div>
                                    <div class="tab-content">
                                        <div id="issues_list" class="check_list_rows">
                                            <template v-for="(issue, index) in issueList">
                                                <template v-if="issue.id">
                                                    <div class="cl_row issue_card mb-2 curp" :class="[statusClass(issue)]" @click="openEditModal(issue)" :key="issue.id">
                                                        <div class="card-body d-flex flex-wrap no-gutters align-items-center pl-3">
                                                            <div class="issue_info col-12 no-gutters d-flex flex-wrap align-items-center">
                                                                <span class="col-6 col-lg-2 col-md-3 font-weight-bold issue_time mr-lg-n4 p-0 pl-md-3">{{ issue.time[0] }}<br />{{ issue.time[1] }}</span>
                                                                <span class="align-items-center col-6 col-lg-4 col-md-4 d-flex flex-wrap issue_car no-gutters">
                                                                <span class="font-weight-bold col-12 col-lg-6"><i class="fa fa-car pr-2"></i>{{ issue.info.car }}</span>
                                                                <span class="text-black-50 col-12 col-lg-6"><i class="fas fa-file-alt pr-2"></i><span class="small mr-1">Договор: </span>{{issue.info.contract_num}}</span>
                                                            </span>
                                                                <span class="align-items-center col-12 col-lg-6 col-md-5 d-flex flex-wrap issue_client_worker mt-3 mt-md-0 no-gutters pt-3 pt-md-0">
                                                                <span class="issue_client col-12 col-lg-6">
                                                                    <fa class="icon pe-1 text-black-50" icon="user"></fa>
                                                                        {{ issue.info.client }}
                                                                </span>
                                                                <span class="issue_worker col-12 col-lg-6">
                                                                    <fa class="icon pe-1 text-black-50" icon="user-tie"></fa>
                                                                        {{ issue.user.surname }} {{ issue.user.name }}
                                                                </span>
                                                            </span>
                                                            </div>
                                                            <div class="status_wrap">
                                                                <span class="status">{{ issue.status_text }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="cl_row issue_card mb-2 gray curp" @click="openCreateModal(issue, index)" v-if="!isMoving">
                                                        <div class="card-body d-flex flex-wrap no-gutters align-items-center pl-3">
                                                            <span class="col-3 col-lg-2 col-sm-2 font-weight-bold issue_time p-0 pl-md-3">{{ issue[0] }}<br />{{ issue[1] }}</span>
                                                            <div class="col-9 d-flex flex-wrap issue_info no-gutters ml-n3">
                                                            <span class="col-12 issue_car ml-lg-n4 ml-sm-n3 ml-xl-n5 text-center" v-if="checkFreePosition(index)" style="font-size:16px;color:#777">
                                                                <fa icon="pencil" /> Свободно
                                                            </span>
                                                                <span class="col-12 issue_car ml-lg-n4 ml-sm-n3 ml-xl-n5 text-center" v-if="!checkFreePosition(index)" style="font-size:16px;color:#777">
                                                                <fa icon="lock" /> Закрыто
                                                            </span>
                                                            </div>
                                                            <div class="status_wrap">
                                                                <span class="status">{{checkFreePosition(index) ? "свободно":"закрыто" }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cl_row issue_card mb-2 gray curp" @click="saveMoving(index)" v-if="isMoving">
                                                        <div class="card-body d-flex flex-wrap no-gutters align-items-center pl-3">
                                                            <span class="col-4 col-lg-2 font-weight-bold issue_time">{{ issue[0] }}<br />{{ issue[1] }}</span>
                                                            <div class="issue_info col-8 no-gutters d-flex flex-wrap col-lg-10">
                                                            <span class="issue_car col-12 col-lg-4" v-if="checkFreePosition(index)">
                                                                <i class="fas fa-pencil-alt pr-2"></i> Выберите новое время
                                                            </span>
                                                                <span class="issue_car col-12 col-lg-4" v-if="!checkFreePosition(index)">
                                                                <fa icon="lock" /> Закрыто
                                                            </span>
                                                            </div>
                                                            <div class="status_wrap">
                                                                <span class="status">{{checkFreePosition(index) ? "свободно":"закрыто" }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <transition name="fade">
                <edit-modal
                    v-if="editIssue"
                    :issue="editIssue"
                    :user="user"
                    @close-edit-modal="closeEditModal"
                    @update-check="updateCheck($event)"
                    @update-check-list="updateCheckList"
                    @move="startMove($event)">
                </edit-modal>
            </transition>

            <transition name="fade">
                <create-modal
                    v-if="createData.position"
                    :data="createData"
                    :date="date"
                    @close-create-modal="closeCreateModal"
                    @created_issuance="issueCreated($event)">
                </create-modal>
            </transition>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :bg="bg"
    />
</template>

<script>
import spinner from "../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../Page/BreadCrumbs";
import axios from "axios";
import moment from "moment";
import ToastAlert from "../../components/ToastAlert";
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";

export default {
    name: "Issuance",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs, ToastAlert, CreateModal, EditModal

    },
    data(){
        return {
            message: '',
            is_alert: false,
            issueList: [],
            createData: {
                position: null,
                positionNum: null,
            },
            date: moment(new Date()).format("YYYY-MM-DD"),
            today: moment(new Date()).format("YYYY-MM-DD"),
            editIssue: null,
            isMoving: false,
            moveIssue: null,
            positions: [
                ["09:00", "10:30"],
                ["10:30", "12:00"],
                ["12:00", "13:30"],
                ["13:30", "15:00"],
                ["15:00", "16:30"],
                ["16:30", "18:00"],
                ["18:00", "19:30"],
                ["19:30", "21:00"],
                ["21:00", "21:30"],
                ["21:30", "22:00"],
            ],
            selectDate: '',
            search: '',
            isLoading: false,
            interval: null,
            user: null,
            breadCrumbs: true,
            title: 'Выдача автомобилей',
            back_title: 'Меню выдачи',
            link: 'issuance',
        }
    },
    watch: {
        search(after, before) {
            this.searchIssuance();
        }
    },
    mounted() {
        this.dateChange();
    },
    beforeUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    },
    methods: {
        updateCheckList(){
            this.dateChange();
            this.closeEditModal();
        },
        async searchIssuance(){
            try {
                if(this.search.length > 0){
                    clearInterval( this.interval );
                    const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                    const response = await axios.get(`/api/admin/issuances/search/${this.search}`, config);
                    if(response.data.data.is_search){
                        this.issueList = response.data.data.issueList;
                        this.is_alert = true;
                        this.message = 'Выдача найдена';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }else {
                        this.issueList = response.data.data.issueList;
                        this.is_alert = true;
                        this.message = 'Выдача не найдена';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                }else{
                    this.dateChange();
                }
            } catch (e) {
                console.log(e);
            } finally {
            }
        },
        closeAlert(){
            this.is_alert = false;
        },
        saveMoving(index) {
            if (!this.checkFreePosition(index)) {
                this.is_alert = true;
                this.message = 'На данное время нельзя перенести выдачу!';
                setTimeout(() => {this.closeAlert(); }, 3000);
                return;
            }
            this.isMoving = false;
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/move', {
                position: index,
                date: this.date,
                id: this.moveIssue,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = 'Выдача успешно перенесена на другое время!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.dateChange();
                        this.isLoading = false;
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        startMove(event) {
            this.editIssue = null;
            this.isMoving = true;
            this.moveIssue = event.id;
        },
        openEditModal(issue) {
            this.isMoving = false;
            this.moveIssue = null;
            this.editIssue = issue;
            document.body.classList.add('modal-open');
        },
        async dateChange() {
            try {
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/issuances/index/${this.date}`, config);
                this.issueList = response.data.data.issueList;
                this.user = response.data.data.user;
                if (this.editIssue) {
                    this.editIssue = this.issueList[this.editIssue.position];
                }
                if(this.search.length == 0){
                    clearInterval( this.interval );
                    this.interval = setInterval(()=>{ this.dateChange() },5000);
                }
            } catch (e) {
                console.log(e);
            }finally {
            }
        },
        updateCheck(event){
            if(event.type == 'ch_sb'){
                this.updateSb(event);
            }else if(event.type == 'ch_chief'){
                this.updateChief(event);
            }else if(event.type == 'access_chief'){
                this.updateAccessChief(event);
            }
        },
        updateAccessChief(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/chief/checklist/update/issued', {
                issue_id: event.id,
                comment: event.comment,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.dateChange();
                        this.closeEditModal();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updateChief(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/chief/checklist/update', {
                issue_id: event.id,
                comment: event.comment,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.dateChange();
                        this.closeEditModal();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updateSb(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/sb/checklist/update', {
                issue_id: event.id,
                comment: event.comment,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.dateChange();
                        this.closeEditModal();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        issueCreated(event) {
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances', {
                contract: event.contract,
                comment: event.comment,
                date: event.date,
                position: event.position,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.dateChange();
                        this.closeCreateModal();
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        closeCreateModal() {
            this.createData = {
                position: null,
                positionNum: null,
            };
        },
        closeEditModal(){
            this.editIssue = null
        },
        checkFreePosition(index) {
            if (
                moment(new Date()).isAfter(this.date + " " + this.positions[index][0])
            ) {
                return false;
            }
            return true;
        },
        openCreateModal(position, index) {
            if (!this.checkFreePosition(index)) {
                this.bg = 'bg-warning'
                this.is_alert = true;
                this.message = 'На данное время нельзя создать выдачу!';
                setTimeout(() => {this.closeAlert(); }, 3000);
                return;
            }

            this.createData.position = position;
            this.createData.positionNum = index;
            document.body.classList.add('modal-open');
        },
        iByPos(pos) {
            var issueRet = null;
            this.issueList.forEach(function (issue) {
                if (issue.position === pos) {
                    issueRet = issue;
                    return issue;
                }
            });
            return issueRet;
        },
        checkPos(position) {
            let inc = false;
            this.issueList.forEach(function (issue) {
                if (issue.position === position) {
                    inc = true;
                }
            });
            return inc;
        },
        statusClass(issue) {
            if (issue.access_chief == 'agreed' && issue.access_sb == 'agreed' && issue.status !== 'issued') {
                return "ready";
            }
            if (issue.access_chief == 'agreed' && issue.access_sb == 'agreed' && issue.status === 'issued') {
                return "green ok";
            }
            if (issue.status === 'rejection') {
                return "red";
            }
            return "orange";
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <div class="d-flex flex-column align-items-center text-center ">
                            <label for="files" style="cursor: pointer;">
                                <div class="mt-4 profile_settings_photo" :style="styleObject(user.avatar)">
                                    <input type="file" id="files" class="inputfile"
                                           accept="image/*"
                                           ref="files"
                                           @change="onAttachmentChange"
                                           style="display: none"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 col-md-6 pe-lg-5">
                        <div>
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h4>Профиль</h4>
                            </div>
                            <Form ref="user" @submit="update">
                                <div class="row">
                                    <div class="col-md">
                                        <label for="surname" class="form-label">Фамилия*</label>
                                        <Field
                                            name="surname"
                                            type="text"
                                            class="form-control"
                                            id="surname"
                                            v-model="user.surname"
                                            :class="[errors.surname.length > 0 ? 'is-invalid' : '']"
                                        />
                                        <ErrorMessage name="surname" style="color: red;"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md">
                                        <label for="name" class="form-label">Имя*</label>
                                        <Field
                                            name="name"
                                            type="text"
                                            class="form-control"
                                            id="name"
                                            v-model="user.name"
                                            :class="[errors.name.length > 0 ? 'is-invalid' : '']"
                                        />
                                        <ErrorMessage name="name" style="color: red;"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md">
                                        <label for="patronymic" class="form-label">Отчество*</label>
                                        <Field
                                            name="patronymic"
                                            type="text"
                                            class="form-control"
                                            id="patronymic"
                                            v-model="user.patronymic"
                                            :class="[errors.patronymic.length > 0 ? 'is-invalid' : '']"
                                        />
                                        <ErrorMessage name="patronymic" style="color: red;"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md">
                                        <label for="phone" class="form-label">Телефон*</label>
                                        <Field
                                            placeholder="+7(___)___-__-__"
                                            name="phone"
                                            type="text"
                                            class="form-control"
                                            id="phone"
                                            v-maska="mask"
                                            v-model="user.phone"
                                            :class="[errors.phone.length > 0 ? 'is-invalid' : '']"
                                        />
                                        <ErrorMessage name="phone" style="color: red;"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md">
                                        <label for="email" class="form-label">E-mail*</label>
                                        <Field
                                            name="email"
                                            type="text"
                                            class="form-control"
                                            id="email"
                                            v-model="user.email"
                                            :class="[errors.email.length > 0 ? 'is-invalid' : '']"
                                        />
                                        <ErrorMessage name="email" style="color: red;"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md">
                                        <label for="email" class="form-label">Телеграмм ID*</label>
                                        <Field
                                            name="telegram_user_id"
                                            type="number"
                                            class="form-control"
                                            id="telegram_user_id"
                                            v-model="user.telegram_user_id"
                                            :class="[errors.telegram_user_id.length > 0 ? 'is-invalid' : '']"
                                            readonly="readonly"
                                        />
                                        <ErrorMessage name="telegram_user_id" style="color: red;"/>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6 mb-4 mt-3">
                                    <button class="btn btn-green w-100">Сохранить</button>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <div class="col-12 col-lg-4 col-md-6 pe-lg-5">
                        <div class="mb-3">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h4>Уведомления</h4>
                            </div>
                            <div class="row">
                                <div class="col-md">
                                    <div class="form-check">
                                        <input class="form-check-input border_ckeckbox" type="checkbox" value="" id="flexCheckEmail"
                                               @click="toggleCheck('mail')"
                                               :checked="user.mail"
                                        >
                                        <label class="form-check-label" for="flexCheckEmail">
                                            Email
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md">
                                    <div class="form-check">
                                        <input class="form-check-input border_ckeckbox" type="checkbox" value="" id="flexCheckTelegram"
                                               @click="toggleCheck('telegram')"
                                               :checked="user.telegram"
                                        >
                                        <label class="form-check-label" for="flexCheckTelegram">
                                            Telegram
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <!--                                    <div class="row">
                                                                    <div class="col-md">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input border_ckeckbox" type="checkbox" value="" id="flexCheckPush"
                                                                                   @click="toggleCheck('push')"
                                                                                   :checked="user.push"
                                                                            >
                                                                            <label class="form-check-label" for="flexCheckPush">
                                                                                Push
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>-->
                        </div>
                        <div>
<!--                            <button
                                type="button"
                                id="foo"
                                class="btn btn-success btn-send" @click="sendNotification"
                            >
                                Send Notification
                            </button>-->

                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h4>Пароль</h4>
                            </div>
                            <Form ref="pass" @submit="changePassword">
                                <div class="row">
                                    <div class="col-md">
                                        <label for="validationCustom01" class="form-label">Старый пароль</label>
                                        <Field
                                            name="old_password"
                                            type="password"
                                            class="form-control"
                                            id="validationCustom01"
                                            v-model="pass.old_password"
                                            :class="[errors.old_password.length > 0 ? 'is-invalid' : '']"
                                        />
                                        <ErrorMessage name="old_password" style="color: red;"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md">
                                        <label for="validationCustom02" class="form-label">Новый пароль</label>
                                        <Field
                                            name="password"
                                            type="password"
                                            class="form-control"
                                            id="validationCustom02"
                                            v-model="pass.password"
                                            :class="[errors.password.length > 0 ? 'is-invalid' : '']"
                                        />
                                        <ErrorMessage name="password" style="color: red;"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md">
                                        <label for="validationCustom03" class="form-label">Подтвердите пароль</label>
                                        <Field
                                            name="password_confirmation"
                                            type="password"
                                            class="form-control"
                                            id="validationCustom03"
                                            v-model="pass.password_confirmation"
                                            :class="[errors.password_confirmation.length > 0 ? 'is-invalid' : '']"
                                        />
                                        <ErrorMessage name="password_confirmation" style="color: red;"/>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6 mb-4 mt-3">
                                    <button class="btn btn-green w-100">Сохранить</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :bg="bg"
    />

</template>

<script>
import spinner from "../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import BreadCrumbs from "../../Page/BreadCrumbs";
import axios from "axios";
import Header from "../../Page/Header";
import { mask } from 'maska';
import ToastAlert from "../../components/ToastAlert";

export default {
    name: "Profile",
    components: {
        spinner, Field, Form, ErrorMessage, BreadCrumbs, Header, ToastAlert
    },
    data(){
        return {
            mask: '+#(###)###-##-##',
            message: '',
            bg: 'bg-primary',
            is_alert: false,
            user:{
                surname: null,
                name: null,
                patronymic: null,
                phone: null,
                email: null,
                telegram_user_id: null,
                mail: false,
                telegram: false,
                push: false,
            },
            pass: {
                old_password: null,
                password: null,
                password_confirmation: null,
            },
            errors:{
                old_password: '',
                password: '',
                password_confirmation: '',
                surname: '',
                name: '',
                patronymic: '',
                phone: '',
                email: '',
                telegram_user_id: '',
            },
            isPushEnabled: false,
            isLoading: false,
            searchUser: '',
            breadCrumbs: true,
            title: 'Профиль',
            back_title: 'Главное меню',
            link: 'main',

        }
    },
    created() {
        this.getProfile();
    },
    methods: {
        sendNotification () {

            /*if (!("Notification" in window)) {
                // Check if the browser supports notifications
                alert("This browser does not support desktop notification");
            } else if (Notification.permission === "granted") {
                // Check whether notification permissions have already been granted;
                // if so, create a notification
                console.log(Notification.permission);
                //const notification = new Notification("Hi there!");

                // …
            } else if (Notification.permission !== "denied") {
                // We need to ask the user for permission
                Notification.requestPermission().then((permission) => {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        const notification = new Notification("Hi there!");
                        // …
                    }
                });
            }*/

                // At last, if the user has denied notifications, and you
                // want to be respectful there is no need to bother them anymore.

            // var checkRemotePermission = function (permissionData) {
            //     console.log('1', permissionData);
            //     if (permissionData.permission === 'default') {
            //         console.log('2', permissionData);
            //         // This is a new web service URL and its validity is unknown.
            //         window.safari.pushNotification.requestPermission(
            //             'https://t-net.app', // The web service URL.
            //             'web.tnet.push',     // The Website Push ID.
            //             {}, // Data that you choose to send to your server to help you identify the user.
            //             checkRemotePermission         // The callback function.
            //         );
            //     }
            //     else if (permissionData.permission === 'denied') {
            //         // The user said no.
            //         console.log('3', permissionData);
            //     }
            //     else if (permissionData.permission === 'granted') {
            //         // The web service URL is a valid push provider, and the user said yes.
            //         // permissionData.deviceToken is now available to use.
            //         console.log('4', permissionData);
            //         console.log(permissionData.deviceToken, 'YEAH!');
            //     }
            // };
            //
            // if ('safari' in window && 'pushNotification' in window.safari) {
            //     console.log('Мы в Safari');
            //     var permissionData = window.safari.pushNotification.permission('web.tnet.push');
            //     console.log('5', permissionData);
            //     checkRemotePermission(permissionData);
            // }else{
            //     console.log('Тут обработка для Chrome');
            // }

            this.loading = true

            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/send/push', {}, config)
                .then(() => { this.loading = false })
        },

        closeAlert(){
            this.is_alert = false;
        },
        toggleCheck(type){
            if(type == 'mail'){
                this.user.mail = !this.user.mail;
                this.update();
            }else if(type == 'telegram'){
                this.user.telegram = !this.user.telegram;
                this.update();
            }else if(type == 'push'){
                if(this.user.push){
                    this.user.push = false;
                    this.unsubscribe()
                }else{
                    this.user.push = true;
                    this.subscribe()
                }
                this.update();
            }
        },

        /**
         * Subscribe for push notifications.
         */
        subscribe () {
            navigator.serviceWorker.ready.then(registration => {
                const options = { userVisibleOnly: true }
                const vapidPublicKey = window.Laravel.vapidPublicKey
                console.log(vapidPublicKey);
                if (vapidPublicKey) {
                    options.applicationServerKey = this.urlBase64ToUint8Array(vapidPublicKey)
                }

                registration.pushManager.subscribe(options)
                    .then(subscription => {
                        //this.isPushEnabled = true

                        this.updateSubscription(subscription)
                    })
                    .catch(e => {
                        if (Notification.permission === 'denied') {
                            console.log('Permission for Notifications was denied')
                        } else {
                            console.log('Unable to subscribe to push.', e)
                        }
                    })
            })
        },

        /**
         * Unsubscribe from push notifications.
         */
        unsubscribe () {
            navigator.serviceWorker.ready.then(registration => {
                registration.pushManager.getSubscription().then(subscription => {
                    if (!subscription) {
                        //this.isPushEnabled = false
                        return
                    }

                    subscription.unsubscribe().then(() => {
                        this.deleteSubscription(subscription)

                        //this.isPushEnabled = false
                    }).catch(e => {
                        console.log('Unsubscription error: ', e)
                    })
                }).catch(e => {
                    console.log('Error thrown while unsubscribing.', e)
                })
            })
        },
        /**
         * Send a requst to the server to delete user's subscription.
         *
         * @param {PushSubscription} subscription
         */
        deleteSubscription (subscription) {
            this.loading = true
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/push/subscriptions/delete', { endpoint: subscription.endpoint }, config)
                .then(() => { this.loading = false })
        },
        /**
         * Send a request to the server to update user's subscription.
         *
         * @param {PushSubscription} subscription
         */
        updateSubscription (subscription) {
            const key = subscription.getKey('p256dh')
            const token = subscription.getKey('auth')
            const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

            const data = {
                endpoint: subscription.endpoint,
                publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
                authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
                contentEncoding
            }

            this.loading = true
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/push/subscriptions', data, config)
                .then(() => { this.loading = false })
        },
        /**
         * https://github.com/Minishlink/physbook/blob/02a0d5d7ca0d5d2cc6d308a3a9b81244c63b3f14/app/Resources/public/js/app.js#L177
         *
         * @param  {String} base64String
         * @return {Uint8Array}
         */
        urlBase64ToUint8Array (base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4)
            const base64 = (base64String + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/')

            const rawData = window.atob(base64)
            const outputArray = new Uint8Array(rawData.length)

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i)
            }

            return outputArray
        },
        async getProfile(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/profile', config);
                this.user = response.data.data.user;
                this.user.mail = response.data.data.user.notification_type.mail;
                this.user.push = response.data.data.user.notification_type.push;
                this.user.telegram = response.data.data.user.notification_type.telegram;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        onAttachmentChange(){
            this.file = this.$refs.files.files[0];
            let avatar;
            if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
                this.addAvatar(this.file)
            }

            function compressImage(img) {
                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                let initSize = img.src.length
                let width = img.width
                let height = img.height
                canvas.width = width
                canvas.height = height
                ctx.fillStyle = '#fff'
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(img, 0, 0, width, height)
                let ndata = canvas.toDataURL('image/jpeg')
                return ndata
            }
        },
        addAvatar(avatar){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            const formData = new FormData();
            formData.append('avatar', avatar);
            axios.post('/api/admin/users/add/avatar', formData, config)
                .then(response => {
                    if(response.status == 200){
                        window.location.href = '/profile';
                        //this.user = response.data.data.user;
                        this.isLoading = false;
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        update(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/profile/update', {
                surname: this.user.surname,
                name: this.user.name,
                patronymic: this.user.patronymic,
                phone: this.user.phone,
                email: this.user.email,
                telegram_user_id: this.user.telegram_user_id,
                mail: this.user.mail,
                telegram: this.user.telegram,
                push: this.user.push,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.is_alert = true;
                        this.message = this.$t('users.profile.update_profile');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.getProfile();
                        this.isLoading = false;
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.user.setErrors(errors.response.data.errors);
                            this.errors.surname = errors.response.data.errors.surname ?? 0;
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            this.errors.patronymic = errors.response.data.errors.patronymic ?? 0;
                            this.errors.phone = errors.response.data.errors.phone ?? 0;
                            this.errors.email = errors.response.data.errors.email ?? 0;
                            this.errors.telegram_user_id = errors.response.data.errors.telegram_user_id ?? 0;
                            break;
                    }
                });
        },

        changePassword(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/change/password', {
                old_password: this.pass.old_password,
                password: this.pass.password,
                password_confirmation: this.pass.password_confirmation,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.is_alert = true;
                        this.message = this.$t('users.profile.update_password');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.pass.old_password = null,
                        this.pass.password = null,
                        this.pass.password_confirmation = null,
                        this.isLoading = false;

                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.pass.setErrors(errors.response.data.errors);
                            this.errors.old_password = errors.response.data.errors.old_password ?? 0;
                            this.errors.password = errors.response.data.errors.password ?? 0;
                            this.errors.password_confirmation = errors.response.data.errors.password_confirmation ?? 0;
                            break;
                    }
                });
        },
        logout(){
            axios.post('/api/logout').then(()=>{
                this.$cookie.removeCookie('token');
                window.location.href = '/';
            })
        },
        styleObject(url){
            return 'background-image: url('+ url +')';
        },
    },
}
</script>

<style scoped>

</style>

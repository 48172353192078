<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page">

                <Button
                    caption="Добавить"
                    color="btn-green"
                    add_class="w-100 mb-2"
                    @click="showForm"
                />

                <div v-if="items.length">
                    <div class="av_card" v-for="item in items" :key="item.id">
                        <div class="mt-3 fw-normal">
                            <div class="hstack d-flex justify-content-between item_title pb-2 mb-2">
                                <div class="fs-4 fw-bold">{{ item.models.name_model }}</div>
                                <div class="d-flex">
                                    <div class="me-2">
                                        <Button
                                            color="gray"
                                            btn_icon="edit"
                                            @click="editPrice(item)"
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            color="btn-red"
                                            btn_icon="delete"
                                            @click="deletePrice(item.id)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <transition name="slide-fade">
                    <Modal
                        v-if="isModalVisible"
                        @close="closeModal"
                    >
                        <template v-slot:body>
                            <FormPrice
                                :title="name_form"
                                :is_route="is_route"
                                :id="id"
                                :brands="brands"
                                :brand="brand"
                                :models="models"
                                :model="model"
                                :errors="errors"
                                :newPrice="newPrice"
                                @select-brand="selectBrand($event)"
                                @create-to="createPrice($event)"
                                @is_route="showPrice($event)"
                                @remove_price="removePrice($event)"
                            />
                        </template>
                    </Modal>
                </transition>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import * as req from "../../../services/boards";
import Spinner from "../../../components/Spinner.vue";
import BreadCrumbs from "../../../Page/BreadCrumbs.vue";
import Button from "../../../components/Button.vue";
import FormPrice from "./FormPrice.vue";
import Modal from "../../../components/Modal.vue";
import * as carHelp from "../../../services/cars";
import ToastAlert from "../../../components/ToastAlert.vue";

export default {
    name: "Price",
    components: {
        Spinner, BreadCrumbs, Button, FormPrice, Modal, ToastAlert
    },
    data() {
        return {
            message: '',
            type: 'green',
            is_alert: false,
            is_route: false,
            brands: [],
            models: [],
            items: [],
            newPrice: [],
            brand: null,
            model: null,
            id: 0,
            errors: {
                brand: '',
                model: '',
                capacity: '',
            },
            name_form: '',
            isLoading: false,
            isModalVisible: false,
            breadCrumbs: true,
            title: 'Прайс',
            back_title: 'Доска ТО',
            link: 'boards/doska-to',
            boardTitle: '',
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        removePrice(event){
            this.newPrice.splice(event.index,1)
        },
        showPrice(event){
            this.is_route = event.is_route;
            this.model = event.model;
            let obj = {};
            for( let i = 0; i < 11; i++ ){
                obj[i] = i == 0 ? {title: 'Объем, л', capacity: ''} : {title: i +'0 000 км', price: ''};
            }
            this.newPrice.push(obj);
        },
        editPrice(item){
            this.id = item.id;
            this.brand = item.brands;
            this.model = item.models;
            this.newPrice = item.data;
            this.is_route = true;
            this.name_form = 'Редактировать';
            this.showModal()
        },
        closeAlert(){
            this.is_alert = false;
        },
        async deletePrice(id){
            this.isLoading = true;
            let result = await req.deletePrice(id);
            this.message = result.message;
            this.items = result.items;
            this.type = 'red';
            this.is_alert = true;
            this.reset();
            setTimeout(() => {this.closeAlert(); }, 5000);
            this.isLoading = false;
        },
        async createPrice(event){
            this.isLoading = true;
            let result = await req.createPrice(event);
            this.closeModal();
            this.message = result.message;
            this.items = result.items;
            this.brand = [];
            this.model = [];
            this.newPrice = [];
            this.id = 0;
            this.is_route = false;
            this.type = 'green';
            this.is_alert = true;
            setTimeout(() => {this.closeAlert(); }, 5000);
            this.isLoading = false;
        },
        async selectBrand(event){
            this.isLoading = true;
            this.brand = event.brand;
            let result = await carHelp.getModel(event);
            this.models = result.models;
            this.isLoading = false;
        },
        async getData(){
            this.isLoading = true;
            let result = await req.getData();
            this.brands = result.brands;
            this.models = result.models;
            this.items = result.items;
            this.isLoading = false;
        },
        getBackLink() {
            this.isLoading = true;
            this.link = `main/${this.$route.params.category}/to`
            this.isLoading = false;
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.reset();
            this.isModalVisible = false;
        },
        showForm(){
            this.name_form = 'Добавить';
            this.showModal();
        },
        reset(){
            this.name_form = '';
            this.id = 0;
            this.brand = null;
            this.model = null;
            this.newPrice = [];
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="rules_modal">

        <svg @click="close" style="position: absolute; right: 2%; z-index: 1000;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 9.40764L21.075 0.332642L23.6673 2.92498L14.5923 12L23.6673 21.075L21.075 23.6673L12 14.5923L2.92498 23.6673L0.332642 21.075L9.40764 12L0.332642 2.92498L2.92498 0.332642L12 9.40764Z" fill="#AFBBCC"/>
        </svg>
        <div class="rules_text">
            <h2 class="mt-3">ОБЯЗАННОСТИ</h2>
            <h3 class="rules_title mt-3 mb-3">Клиент обязуется:</h3>
            <p>Соблюдать правила эксплуатации Автомобиля, правила дорожного движения и другие нормативные
                и законодательные акты обязательные для всех участников дорожного движения.
            </p>
            <p>
                Эксплуатировать Автомобиль в обычном режиме использования, не устраивать испытания
                Автомобиля в экстремальных условиях, выходящих за рамки обычной эксплуатации автомобиля.
            </p>
            <p>
                Принимать все меры по предотвращению дорожно-транспортных происшествий, по обеспечению
                сохранности имущества Автодилера (в том числе и предупреждение хищения, угона, актов вандализма и т.п.)
            </p>
            <p>
                Возвратить Автомобиль после истечения срока безвозмездного пользования в состоянии,
                в котором он был принят в безвозмездное пользование (с учетом естественного износа).
            </p>
            <p>
                Клиент обязуется возместить Автодилеру все административные штрафы,
                убытки, которые были наложены в период пользования автомобилем клиентом,
                даже в случае, если информация о наложенном во время использования Автомобиля Клиентом
                административном взыскании поступает к Автодилеру в течении года после использования автомобиля Клиентом.
            </p>
            <button class="btn btn-red mb-5 mt-3 w-100"
                    @click="showSign"
            >
                Подпись клиента
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Rules",
    methods: {
        close() {
            this.$emit('close-form')
        },
        showSign(){
            this.$emit('show-sign')
        }
    }
}
</script>

<style scoped>

</style>

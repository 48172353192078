<template>
    <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">История</h5>
                    <button type="button" class="btn-close close_modal" @click="close"></button>
                </div>
                <div class="modal-body">

                    <div class="gap-3 hstack justify-content-between" v-if="is_print">
                        <button class="btn w-100 gray px-2" v-print="'#printMe'">
                            <fa class="icon me-2 opacity-50" icon="print"></fa>
                            {{ $t('request.button.print') }}
                        </button>
                        <button class="btn w-100 gray px-2" @click="downloadPDF">
                            <fa class="icon me-2 opacity-50" icon="cloud-arrow-down"></fa>
                            {{ $t('request.button.download') }}
                        </button>
                    </div>

                    <hr v-if="is_print"/>

                    <div id="printMe">
                        <div class="history-cards-list">

                            <h5 style="text-align: center;"  class="mb-3" v-if="is_print">Служебная записка №{{ id }} </h5>

                            <div class="history-card-wrap" v-for="history in historis" :key="history">
                                <div class="history-card" :class="goStatus(history.status)">
                                    <div class="fs-7 fw-semibold">
                                        <fa class="icon me-2 opacity-25" icon="calendar"></fa>{{ history.date }}
                                    </div>
                                    <div class="fs-7 fw-semibold">
                                        <fa class="icon me-2 opacity-25" icon="circle-chevron-right"></fa>{{ history.message }}
                                    </div>
                                    <div class="fs-7 fw-semibold">
                                        <fa class="icon me-2 opacity-25" icon="user-tie"></fa>{{ history.fullname }}
                                    </div>
                                    <div class="fs-7 fw-normal mt-3 mb-2" v-if="history.comment">
                                        <fa class="icon me-2 opacity-25" icon="message"></fa>{{ history.comment }}
                                    </div>
                                    <div v-if="history.records">
                                        <div class="fs-7 fw-normal mt-3 mb-2">
                                            <fa class="icon me-2 opacity-25" icon="volume-up"></fa>
                                        </div>
                                        <div v-for="record in history.records">
                                            <audio :src="record" controls />
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex history-next-card justify-content-center my-1">
                                    <fa class="icon fa-2xs" icon="angle-down"></fa>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-green w-100 my-3" @click="close">Закрыть</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import spinner from "../../../components/Spinner";

export default {
    name: "History",
    components: {
        spinner
    },
    props: {
        historis: {
            type: Array,
            default: [],
        },
        is_print: false,
        id: null,
    },
    methods: {
        close() {
            this.$emit('close-form')
        },
        goStatus(status){
            return `history_status_` + status;
        },
        downloadPDF(){
            var element = document.createElement("a");
            let url = "/api/download/memos/pdf/" + this.id
            element.setAttribute("href", url);
            element.setAttribute("download", "History.pdf");
            element.setAttribute("target", "_blank");
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
    }
}
</script>

<style scoped>

</style>

<template>
  <spinner v-if="isLoading"></spinner>
  <transition name="fade">
    <div>
      <BreadCrumbs
        :breadCrumbs="breadCrumbs"
        :title="title"
        :back_title="back_title"
        :link="link"
      />
      <div class="page">
        <h5 class="my-3 hstack">
        <Buying :count="1" />
        Информация о клиенте
        </h5>

        <Form ref="client" @submit="SaveClient">

            <div class="form-check form-check-inline">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="option1"
                    @click="togglePhysical"
                    :checked="physical"/>
                <label class="form-check-label fs-7" for="inlineCheckbox1">Физическое лицо</label >
            </div>

            <div class="form-check form-check-inline">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox2"
                    value="option2"
                    @click="toggleJuridical"
                    :checked="juridical"/>
                <label class="form-check-label fs-7" for="inlineCheckbox2">Юридическое лицо</label >
            </div>

            <div class="mb-3">
                <label class="form-label">Телефон</label>
                <Field
                    placeholder="+7 (___) ___-__-__"
                    v-model="search"
                    type="tel"
                    name="phone"
                    class="form-control"
                    :class="[errors.phone.length > 0 ? 'error' : '']"
                    v-telmask
                    />
                <ErrorMessage name="phone" style="color: red"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Фамилия</label>
                <Field
                    placeholder="Фамилия"
                    v-model="client.surname"
                    type="text"
                    name="surname"
                    class="form-control"
                    :class="[errors.surname.length > 0 ? 'error is-invalid' : '']"/>
                <ErrorMessage name="surname" style="color: red"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Имя</label>
                <Field
                    placeholder="Имя"
                    v-model="client.name"
                    type="text"
                    name="name"
                    class="form-control"
                    :class="[errors.name.length > 0 ? 'error is-invalid' : '']"/>
                <ErrorMessage name="name" style="color: red"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Отчество</label>
                <Field
                    placeholder="Отчество"
                    v-model="client.patronymic"
                    type="text"
                    name="patronymic"
                    class="form-control"
                    :class="[errors.patronymic.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="patronymic"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Вид сделки</label>
                <VueMultiselect
                    v-model="client.deal"
                    placeholder="Вид сделки"
                    name="deal"
                    label="title"
                    track-by="id"
                    :options="deals"
                    @select="selectDeal"
                    :searchable="false"
                    :show-labels="false"></VueMultiselect>
                <span role="alert" v-if="errors.deal">{{errors.deal[0]}}</span>
            </div>

            <div class="mb-3">
                <label class="form-label">№ Уд.л.</label>
                <Field
                    placeholder="№"
                    v-model="client.id_number"
                    type="tel"
                    name="id_number"
                    class="form-control"
                    :class="[errors.id_number.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="id_number"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Кем выдано уд.л.</label>
                <div class="col-sm-8 position-relative">
                    <Field
                        placeholder="Кем выдано..."
                        v-model="client.id_issued"
                        type="text"
                        name="id_issued"
                        class="form-control"
                        :class="[errors.id_issued.length > 0 ? 'error' : '']"/>
                    <ErrorMessage name="id_issued"/>
                </div>
            </div>

            <div class="mb-3">
                <label class="form-label">Дата выдачи уд.л.</label>
                <Field
                    placeholder="__.__.____"
                    v-maska="date_mask"
                    v-model="client.id_date"
                    type="tel"
                    name="id_date"
                    class="form-control"
                    :class="[errors.id_date.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="id_date" style="color: red"/>
            </div>

            <div class="mb-3">
                <label class="form-label">№ вод.уд.</label>
                <Field
                    placeholder="Водительское удостоверение"
                    v-model="client.driver_id_number"
                    type="text"
                    name="driver_id_number"
                    class="form-control"
                    :class="[errors.driver_id_number.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="driver_id_number"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Дата выдачи вод.уд.</label>
                <Field
                    placeholder="__.__.____"
                    v-maska="date_mask"
                    v-model="client.driver_id_date"
                    type="tel"
                    name="driver_id_date"
                    class="form-control"
                    :class="[errors.driver_id_date.length > 0 ? 'error' : '']"/>
                <ErrorMessage name="driver_id_date" style="color: red"/>
            </div>

            <div class="mb-3">
                <label class="form-label">ИИН</label>
                <Field
                    placeholder="____________"
                    v-maska="iin_mask"
                    v-model="client.IIN"
                    type="tel"
                    name="IIN"
                    class="form-control"
                    :class="[errors.IIN.length > 0 ? 'error is-invalid' : '']"/>
                <ErrorMessage name="IIN" style="color: red"/>
            </div>

            <div class="my-5">
                <button class="btn btn-green w-100">Сохранить</button>
            </div>
        </Form>
      </div>
    </div>
  </transition>
  <toast-alert v-if="is_alert" @close_alert="closeAlert" :message="message" :type="type" />
</template>

<script>
import Buying from "./Buying";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { Field, Form, ErrorMessage } from "vee-validate";
import spinner from "../../components/Spinner";
import { mask } from "maska";
import { getTradeInStatusStep } from "../../services/helpers";
import BreadCrumbs from "../../Page/BreadCrumbs";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../components/ToastAlert";

const telmask = {
    mounted: (el, binding) => {
        el.onfocus = function (e) {
            this.value = '+7 ('
            this.setSelectionRange(4, 4)
        }
        el.oninput = function (e) {
            if (!e.isTrusted) {
                return
            }
            const x = this
                .value
                .replace(/\D/g, '')
                .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
            x[1] = '+7'
            this.value = !x[3]
                ? x[1] + ' (' + x[2]
                : x[1] + ' (' + x[2] + ') ' + x[3] + (
                    x[4]
                        ? '-' + x[4]
                        : ''
                ) + (
                    x[5]
                        ? '-' + x[5]
                        : ''
                )
        }
    }
}

export default {
  name: "Step_1",
  components: {
    Buying,
    Spinner,
    Field,
    Form,
    ErrorMessage,
    BreadCrumbs,
    VueMultiselect,
    ToastAlert,
  },
  directives: {
        telmask
  },
  data: () => ({
    type: "gray",
    message: "",
    is_alert: false,
    step: "car",
    back_step: "client",
    deals: [],
    clients: {},
    isLoading: false,
    search: "",
    physical: false,
    juridical: false,
    mask: "+# (###) ###-##-##",
    date_mask: "##.##.####",
    iin_mask: "############",
    isSearch: false,
    client_id: null,
    client: {
      status: "",
      surname: null,
      name: null,
      patronymic: null,
      deal: null,
      id_number: null,
      id_issued: null,
      id_date: null,
      driver_id_number: null,
      driver_id_date: null,
      IIN: null,
    },
    errors: {
      phone: "",
      surname: "",
      name: "",
      deal: "",
      IIN: "",
      patronymic: "",
      driver_license: "",
      check: "",
      id_number: "",
      id_issued: "",
      id_date: "",
      driver_id_number: "",
      driver_id_date: "",
    },
    breadCrumbs: true,
    title: "Клиент",
    back_title: "Этапы приёма",
    link: "",
  }),
  watch: {
    search(after, before) {
      this.search_client();
    },
  },
  created() {
    this.getDeals();
    this.getClient();
    this.getBackLink();
  },
  methods: {
    closeAlert() {
      this.is_alert = false;
    },
    getBackLink() {
        this.link = this.link = `tradeinlist/${this.$route.params.id}`
    },
    async getStepLists() {
      this.lists = await getTradeInStatusStep(this.$route.params.id);
    },
    togglePhysical() {
      this.errors.check = "";
      this.physical = !this.physical;
      if (this.physical) {
        this.client.status = "physical";
        this.juridical = false;
      } else {
        this.client.status = "";
      }
      this.$emit("setPhysicalVal", this.physical);
    },
    toggleJuridical() {
      this.errors.check = "";
      this.juridical = !this.juridical;
      if (this.juridical) {
        this.client.status = "juridical";
        this.physical = false;
      } else {
        this.client.status = "";
      }
      this.$emit("setJuridicalVal", this.juridical);
    },
    selectDeal(value){
        this.errors.deal = "";
    },
    async getClient() {
      try {
        const config = {
          headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
        };
        const response = await axios.get(
          `/api/admin/tradein/client/${this.$route.params.id}`,
          config
        );
        if (response.data.data.client !== null) {
          this.client = response.data.data.client;
          this.client_id = response.data.data.client.id;
          this.search = response.data.data.client.phone;
          if (this.client.status === "physical") {
            this.physical = true;
          } else if (this.client.status === "juridical") {
            this.juridical = true;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async search_client() {
      try {
        this.isSearch = true;
        if (this.search.length == 18) {
          const config = {
            headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
          };
          const response = await axios.get(
            `/api/admin/tradein/search/${this.search}`,
            config
          );
          if (response.data.data.clients != null) {
            this.client_id = response.data.data.clients.id;
            this.client.surname = response.data.data.clients.surname;
            this.client.name = response.data.data.clients.name;
            this.client.patronymic = response.data.data.clients.patronymic;
            this.client.id_number = response.data.data.clients.id_number;
            this.client.id_issued = response.data.data.clients.id_issued;
            this.client.id_date = response.data.data.clients.id_date;
            this.client.driver_id_number = response.data.data.clients.driver_id_number;
            this.client.driver_id_date = response.data.data.clients.driver_id_date;
            this.client.IIN = response.data.data.clients.IIN;
            if (response.data.data.clients.status === "physical") {
              this.physical = true;
            } else if (response.data.data.clients.status === "juridical") {
              this.juridical = true;
            }
            this.is_alert = true;
            this.type = 'gray';
            this.message = "Клиент найден";
            this.errorReset();
          } else {
            this.is_alert = true;
            this.type = 'gray';
            this.message = "Клиент не найден";
          }
          setTimeout(() => {
            this.closeAlert();
          }, 3000);
        }
      } catch (e) {
        this.isSearch = false;
        console.log(e);
      } finally {
        this.isSearch = false;
      }
    },
    SaveClient() {
      if (this.physical === false && this.juridical === false) {
          this.is_alert = true;
          this.type = 'red';
          this.message = "Выберите физическое/юридическое лицо";
          setTimeout(() => {
              this.closeAlert();
          }, 4000);
      } else {
        this.isLoading = true;
        const config = {
          headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
        };
        axios
          .post(
            "/api/admin/tradein/save/client",
            {
              client_id: this.client_id,
              status: this.client.status,
              phone: this.search,
              surname: this.client.surname,
              name: this.client.name,
              patronymic: this.client.patronymic,
              deal: this.client.deal,
              id_number: this.client.id_number,
              id_issued: this.client.id_issued,
              id_date: this.client.id_date,
              driver_id_number: this.client.driver_id_number,
              driver_id_date: this.client.driver_id_date,
              IIN: this.client.IIN,
              id: this.$route.params.id,
              step_number: 1,
            },
            config
          )
          .then((response) => {
            this.isLoading = false;
            if (response.status == 200) {
              this.$router.push(`/buying/car/${this.$route.params.id}`);
            }
          })
          .catch((errors) => {
            this.isLoading = false;
              this.is_alert = true;
              this.type = 'red';
              this.message = "Есть не заполненные поля, проверьте форму";
              setTimeout(() => {
                  this.closeAlert();
              }, 3000);
            switch (errors.response.status) {
              case 422:
                this.$refs.client.setErrors(errors.response.data.errors);
                this.errors.phone = errors.response.data.errors.phone ?? 0;
                this.errors.surname = errors.response.data.errors.surname ?? 0;
                this.errors.name = errors.response.data.errors.name ?? 0;
                this.errors.patronymic = errors.response.data.errors.patronymic ?? 0;
                this.errors.deal = errors.response.data.errors.deal ?? 0;
                this.errors.id_number = errors.response.data.errors.id_number ?? 0;
                this.errors.id_issued = errors.response.data.errors.id_issued ?? 0;
                this.errors.id_date = errors.response.data.errors.id_date ?? 0;
                this.errors.driver_id_number =
                  errors.response.data.errors.driver_id_number ?? 0;
                this.errors.driver_id_date =
                  errors.response.data.errors.driver_id_date ?? 0;
                this.errors.IIN = errors.response.data.errors.IIN ?? 0;
                this.errors.driver_license =
                  errors.response.data.errors.driver_license ?? 0;
                break;
            }
          });
      }
    },
    errorReset() {
        this.errors.phone = "";
        this.errors.surname = "";
        this.errors.name = "";
        this.errors.deal = "";
        this.errors.IIN = "";
        this.errors.patronymic = "";
        this.errors.driver_license = "";
        this.errors.check = "";
        this.errors.id_number = "";
        this.errors.id_issued = "";
        this.errors.id_date = "";
        this.errors.driver_id_number = "";
        this.errors.driver_id_date = "";
    },
    async getDeals() {
      try {
        this.isLoading = true;
        const config = {
          headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
        };
        const response = await axios.get("/api/admin/tradein/deals", config);
        this.deals = response.data.data.deals;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>

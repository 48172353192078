<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="mt-2 mb-2 text-center">
                    <router-link class="btn btn-green btn_vw" to="/request/memos/create">
                        {{ $t("request.to_create") }}
                    </router-link>
                </div>
                <template v-if="is_generalnyi">
                    <div v-if="Object.keys(lists).length">
                        <div class="accordion accordion-flush mb-2" :id="'accordionFlushExample-'+ items.id" v-for="(items) in lists" :key="items.id">
                            <div class="accordion-item p-3 rounded">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed p-0 fs-7" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne-'+ items.id" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <h3>{{ items.name }}</h3>
                                    </button>
                                </h2>
                                <div :id="'flush-collapseOne-'+ items.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" :data-bs-parent="'#accordionFlushExample-'+ items.id">
                                    <div v-if="items.requests.length">
                                        <div class="item_title mb-2" v-for="list in items.requests" :key="list.id">

                                            <!-- Номер и статус -->
                                            <div class="d-flex justify-content-between fs-7 mb-2">
                                                <div class="fs-5 fw-bold lh-base">№{{ list.id }}</div>
                                                <div :class="list.status" class="badge fs-7 fw-normal lh-base px-3 rounded-pill">
                                                    {{ list.status_title }}
                                                </div>
                                            </div>

                                            <div class="mt-3 fw-normal">
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.created') }}:</div>
                                                    <div class="fw-bold">{{ list.date_create }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.applicant') }}:</div>
                                                    <div class="fw-bold">{{ list.user.surname }} {{ list.user.name }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.category') }}:</div>
                                                    <div class="fw-bold">{{ list.category.name }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.status') }}:</div>
                                                    <div class="fw-bold">{{ list.status_text }}</div>
                                                </div>
                                                <div class="vstack justify-content-between pb-3 mb-4">
                                                    <div class="pt-2 pb-1 fs-7">{{ $t('request.content') }}:</div>
                                                    <div class="alert-attachments fw-semibold lh-base rounded-3 w-100"
                                                         :class="is_hidden ? 'min-block' : 'max-block'"
                                                         v-html="list.contents"
                                                    ></div>
                                                    <div class="btn btn-small gray w-100 mt-1" @click="showHideContent" v-if="list.contents != null && list.contents.length > 200">
                                                        <span v-if="is_hidden">Читать полностью</span>
                                                        <span v-if="!is_hidden">Свернуть</span>
                                                    </div>
                                                </div>


                                                <!-- Вложения -->
                                                <div class="attchmts mb-4" v-if="(list.files.length > 0) || (Object.keys(list.user_files).length > 0)">
                                                    <h6>Прикрепленные файлы</h6>
                                                    <div v-if="list.files.length > 0">
                                                        <!-- От заявителя -->
                                                        <div class="alert alert-attachments mb-2 rounded-3" role="alert">
                                                            <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ list.user.surname }} {{ list.user.name }}</div>
                                                            <div v-for="file in list.files" :key="file.id">
                                                                <a data-fancybox :href="file.link" class="attachment mb-1" v-if="file.type">
                                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                                </a>
                                                                <a @click="downloadURL(file.link, file.name)" class="attachment mb-1" v-else>
                                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- От сотрудников -->
                                                    <div v-if="Object.keys(list.user_files).length > 0">
                                                        <div v-for="(items, name) in list.user_files" :key="name">
                                                            <div class="alert alert-attachments mb-2 rounded-3" role="alert">
                                                                <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ name }}</div>
                                                                <div v-for="item in items" :key="item">
                                                                    <a data-fancybox :href="item.link" class="attachment mb-1" style="cursor: pointer;" v-if="item.type">
                                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                                    </a>
                                                                    <a @click="downloadURL(item.link, item.name)" class="attachment mb-1" style="cursor: pointer;" v-else>
                                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'in_work'">
                                                <button class="btn btn_vw btn-red"
                                                        @click="deleteMemos(list.id)"
                                                >
                                                    <fa class="icon me-2 opacity-50" icon="ban"></fa>
                                                    {{ $t('request.button.delete') }}
                                                </button>
                                            </div>
                                            <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'denied'">
                                                <button class="btn w-100 btn-yellow"
                                                        @click="showModal(list.id)"
                                                >
                                                    <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                                    {{ $t('request.button.return_to_work') }}
                                                </button>
                                            </div>
                                            <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'approved'">
                                                <button class="btn btn_vw gray"
                                                        @click="downloadMemos(list.id)"
                                                >
                                                    <fa class="icon me-2 opacity-50" icon="cloud-arrow-down"></fa>
                                                    {{ $t('request.button.download_document') }}
                                                </button>
                                            </div>
                                            <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'completed'">
                                                <button class="btn btn_vw gray"
                                                        @click="downloadMemos(list.id)"
                                                >
                                                    <fa class="icon me-2 opacity-50" icon="cloud-arrow-down"></fa>
                                                    {{ $t('request.button.download_document') }}
                                                </button>
                                            </div>

                                            <hr/>
                                            <div class="col-sm position-relative mb-2 mt-2">
                                                <button class="btn btn_vw gray"
                                                        @click="getHistory(list.historys, list.id)"
                                                >
                                                    <fa class="icon me-2 opacity-50" icon="file-lines"></fa>
                                                    {{ $t('request.button.history') }}
                                                </button>
                                            </div>

<!--                                            <transition-group name="list">
                                                <div class="modal d-block" v-if="is_modal">
                                                    <div class="modal-dialog modal-dialog-centered">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title"></h5>
                                                                <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row mb-3 mt-3">
                                                                    <label class="col-sm-12 col-form-label required text-start">{{ $t('request.comment') }}</label>
                                                                    <div class="col-sm position-relative">
                                                                        <textarea class="form-control" rows="3" v-model="comment"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm position-relative mb-2 mt-2">
                                                                    <button class="btn btn_vw btn-red"
                                                                            @click="returnToWork(list.id)"
                                                                    >
                                                                        {{ $t('request.button.save') }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </transition-group>-->
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="mb-3">
                                            <div class="item_title fw-semibold">
                                                {{ $t('request.list_requests_is_empty') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="lists.length">
                        <div class="item_title mb-2" v-for="list in lists" :key="list.id">

                            <!-- Номер и статус -->
                            <div class="d-flex justify-content-between fs-7 mb-2">
                                <div class="fs-5 fw-bold lh-base">№{{ list.id }}</div>
                                <div :class="list.status" class="badge fs-7 fw-normal lh-base px-3 rounded-pill">
                                    {{ list.status_title }}
                                </div>
                            </div>

                            <div class="mt-3 fw-normal">
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.created') }}:</div>
                                    <div class="fw-bold">{{ list.date_create }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.applicant') }}:</div>
                                    <div class="fw-bold">{{ list.user.surname }} {{ list.user.name }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.category') }}:</div>
                                    <div class="fw-bold">{{ list.category.name }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.status') }}:</div>
                                    <div class="fw-bold">{{ list.status_text }}</div>
                                </div>
                                <div class="vstack justify-content-between pb-3 mb-4">
                                    <div class="pt-2 pb-1 fs-7">{{ $t('request.content') }}:</div>
                                    <div class="alert-attachments fw-semibold lh-base rounded-3 w-100"
                                         :class="is_hidden ? 'min-block' : 'max-block'"
                                         v-html="list.contents"
                                    ></div>
                                    <div class="btn btn-small gray w-100 mt-1" @click="showHideContent" v-if="list.contents != null && list.contents.length > 200">
                                        <span v-if="is_hidden">Читать полностью</span>
                                        <span v-if="!is_hidden">Свернуть</span>
                                    </div>
                                </div>


                                <!-- Вложения -->
                                <div class="attchmts mb-4" v-if="(list.files.length > 0) || (Object.keys(list.user_files).length > 0)">
                                    <h6>Прикрепленные файлы</h6>
                                    <div v-if="list.files.length > 0">
                                        <!-- От заявителя -->
                                        <div class="alert alert-attachments mb-2 rounded-3" role="alert">
                                            <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ list.user.surname }} {{ list.user.name }}</div>
                                            <div v-for="file in list.files" :key="file.id">
                                                <a data-fancybox :href="file.link" class="attachment mb-1" v-if="file.type">
                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                </a>
                                                <a @click="downloadURL(file.link, file.name)" class="attachment mb-1" v-else>
                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- От сотрудников -->
                                    <div v-if="Object.keys(list.user_files).length > 0">
                                        <div v-for="(items, name) in list.user_files" :key="name">
                                            <div class="alert alert-attachments mb-2 rounded-3" role="alert">
                                                <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ name }}</div>
                                                <div v-for="item in items" :key="item">
                                                    <a data-fancybox :href="item.link" class="attachment mb-1" style="cursor: pointer;" v-if="item.type">
                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                    </a>
                                                    <a @click="downloadURL(item.link, item.name)" class="attachment mb-1" style="cursor: pointer;" v-else>
                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'in_work'">
                                <button class="btn btn_vw btn-red"
                                        @click="deleteMemos(list.id)"
                                >
                                    <fa class="icon me-2 opacity-50" icon="ban"></fa>
                                    {{ $t('request.button.delete') }}
                                </button>
                            </div>
                            <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'denied'">
                                <button class="btn w-100 btn-yellow"
                                        @click="showModal(list.id)"
                                >
                                    <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                    {{ $t('request.button.return_to_work') }}
                                </button>
                            </div>
                            <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'approved'">
                                <button class="btn btn_vw gray"
                                        @click="downloadMemos(list.id)"
                                >
                                    <fa class="icon me-2 opacity-50" icon="cloud-arrow-down"></fa>
                                    {{ $t('request.button.download_document') }}
                                </button>
                            </div>
                            <div class="col-sm position-relative mb-2 mt-2" v-if="list.status === 'completed'">
                                <button class="btn btn_vw gray"
                                        @click="downloadMemos(list.id)"
                                >
                                    <fa class="icon me-2 opacity-50" icon="cloud-arrow-down"></fa>
                                    {{ $t('request.button.download_document') }}
                                </button>
                            </div>

                            <hr/>
                            <div class="col-sm position-relative mb-2 mt-2">
                                <button class="btn btn_vw gray"
                                        @click="getHistory(list.historys, list.id)"
                                >
                                    <fa class="icon me-2 opacity-50" icon="file-lines"></fa>
                                    {{ $t('request.button.history') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="mb-3">
                            <div class="item_title fw-semibold">
                                {{ $t('request.list_requests_is_empty') }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </transition>

    <transition-group name="list">
        <div class="modal d-block" v-if="is_modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3 mt-3">
                            <label class="col-sm-12 col-form-label required text-start">{{ $t('request.comment') }}</label>
                            <div class="col-sm position-relative">
                                <textarea class="form-control" rows="3" v-model="comment"></textarea>
                            </div>
                        </div>
                        <div class="col-sm position-relative mb-2 mt-2">
                            <button class="btn btn_vw btn-red"
                                    @click="returnToWork()"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition-group>

    <transition-group name="list">
        <history
            v-if="is_history"
            :historis="historis"
            @close-form="close"
            :is_print="true"
            :id="id"
        />
    </transition-group>
    <transition name="slide-fade">
        <toast-alert v-if="is_alert" @close_alert="closeAlert" :message="message" />
    </transition>

    <transition-group name="list">
        <div class="modal d-block" v-if="is_new_modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="btn-close close_modal" @click="closeNewModal"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <h5>Создание новых документов в модуле Служебные записки не доступено!</h5>
                            <a v-if="link" href="https://tkst-tasks.web.app/" target="_blank">Перейти</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition-group>
</template>

<script>
import History from "../Imprest/History";
import ToastAlert from "../../../components/ToastAlert";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";

export default {
    name: "MemosIndex",
    components: {
        History, ToastAlert,
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect
    },
    data(){
        return {
            is_new_modal: false,
            is_modal: false,
            is_generalnyi: false,
            message: '',
            is_hidden: true,
            scroll: 0,
            is_alert: false,
            id:null,
            is_history: false,
            historis: [],
            lists: [],
            link_image: '',
            comment: '',
            nameDoc: '',
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.list_requests'),
            back_title: this.$t('request.memos'),
            link: 'request/memos',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        showNewModal() {
            this.is_new_modal = true;
        },
        closeNewModal(){
            this.is_new_modal = false;
        },
        showHideContent(){
            this.is_hidden = !this.is_hidden;
            if(!this.is_hidden){
                this.scroll = window.scrollY;
            }else{
                window.scrollTo(0, this.scroll);
            }
        },
        showModal(id){
            this.id = id;
            this.is_modal = true;
        },
        closeModal(){
            this.is_modal = false;
            this.comment = '';
        },
        downloadURL(url, name){
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.onload = function(e) {
                if (this.status == 200) {
                    var myBlob = this.response;
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(myBlob);
                    link.download = name;
                    link.click();
                }
            };
            xhr.send();
        },
        async downloadMemos(id){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/memos/download/${id}`, config);
                this.url = response.data.data.url;
                this.nameDoc = response.data.data.nameDoc;
                this.downloadURL(this.url, this.nameDoc);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        returnToWork(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/memos/return/work', {
                id: this.id,
                comment: this.comment,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.is_modal = false;
                        this.comment = '';
                        this.message = this.$t('request.application_restored');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        deleteMemos(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/memos/delete', {
                id: id,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.memo_deleted');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        closeAlert(){
            this.is_alert = false;
        },
        close(){
            this.is_history = false;
        },
        getHistory(data, id){
            this.historis = data;
            this.id = id;
            this.is_history = true;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/memos/index', config);
                this.lists = response.data.data.lists;
                this.is_generalnyi = response.data.data.is_generalnyi;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>
.min-block {
    max-height: 300px;
    overflow: hidden;
    transition: max-height 0.8s ease-out;
}

.max-block {
    transition: max-height 0.3s ease-in;
    max-height: 100%;
}
</style>

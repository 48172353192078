<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h2 class="mb-5">Настройки профиля</h2>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Профиль</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Управление</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Пароль</button>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <h4 class="mb-4">Учётная запись</h4>
                        <Form ref="user" @submit="update">

                            <div class="mb-3">
                                <label class="form-label">Фамилия*</label>
                                <Field
                                    v-model="user.surname"
                                    type="text"
                                    name="surname"
                                    class="form-control"
                                    :class="[errors.surname.length > 0 ? 'error' : '']"/>
                                <ErrorMessage name="surname"/>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Имя*</label>
                                <Field
                                    v-model="user.name"
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    :class="[errors.name.length > 0 ? 'error' : '']"/>
                                <ErrorMessage name="name" style="color: red;"/>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Отчество</label>
                                <Field
                                    v-model="user.patronymic"
                                    type="text"
                                    name="patronymic"
                                    class="form-control"
                                    :class="[errors.patronymic.length > 0 ? 'error' : '']"/>
                                <ErrorMessage name="patronymic"/>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Телефон*</label>
                                <Field
                                    placeholder="+7(___)___-__-__"
                                    v-maska="mask"
                                    v-model="user.phone"
                                    type="text"
                                    name="phone"
                                    class="form-control"
                                    :class="[errors.phone.length > 0 ? 'error' : '']"/>
                                <ErrorMessage name="phone" style="color: red;"/>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">E-mail*</label>
                                <Field
                                    v-model="user.email"
                                    type="text"
                                    name="email"
                                    class="form-control"
                                    :class="[errors.email.length > 0 ? 'error' : '']"/>
                                <ErrorMessage name="email"/>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Телеграмм ID*</label>
                                <Field
                                    v-model="user.telegram_user_id"
                                    type="number"
                                    name="telegram_user_id"
                                    class="form-control"
                                    :class="[errors.telegram_user_id.length > 0 ? 'error' : '']"/>
                                <ErrorMessage name="telegram_user_id"/>
                            </div>

<!--                            <div class="mb-3">
                                <label class="form-label">CRM ID</label>
                                <Field
                                    v-model="user.crm_id"
                                    type="number"
                                    name="crm_id"
                                    class="form-control"
                                    :class="[errors.crm_id.length > 0 ? 'error' : '']"/>
                                <ErrorMessage name="crm_id"/>
                            </div>-->

<!--                            <div class="mb-3">
                                <label class="form-label">Статус*</label>
                                <VueMultiselect
                                    v-model="user.status"
                                    placeholder="Выберите статус"
                                    label="name"
                                    track-by="id"
                                    :options="status"
                                    :show-labels="false"
                                    @tag="addStatus"></VueMultiselect>
                                <ErrorMessage name="status"/>
                            </div>-->

                            <div class="hstack justify-content-between py-2">
                                <div class="title hstack">
                                    <span class="fw-bold">Статус*</span>
                                </div>
                                <div class="hstack">
                                    <div class="fs-7">
                                        <Checkbox
                                            :id="user.status"
                                            group="true"
                                            name="checkbox"
                                            type="checkbox"
                                            :checked="user.status"
                                            @updateCheckboxGroup="updateActiveCheck($event)"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md">
                                    <label class="form-label">Организация*</label>
                                    <VueMultiselect
                                        v-model="user.organization"
                                        placeholder="Выберите организацию"
                                        label="name"
                                        track-by="id"
                                        :options="organizations"
                                        :searchable="false"
                                        :show-labels="false"
                                        :multiple="true"
                                        @select="selectOrganization($event)"
                                        @remove="removeOrganization($event)"
                                    ></VueMultiselect>
                                    <ErrorMessage name="organizations"/>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Отдел*</label>
                                <VueMultiselect
                                    v-model="user.department"
                                    placeholder="Выберите отдел"
                                    label="name"
                                    track-by="id"
                                    :options="optionsDepartment"
                                    :show-labels="false"
                                    @tag="addDepartment"></VueMultiselect>
                                <ErrorMessage name="department"/>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Роль*</label>
                                <VueMultiselect
                                    v-model="user.role"
                                    placeholder="Выберите роль"
                                    label="title"
                                    track-by="id"
                                    :options="roles"
                                    :show-labels="false"
                                    :multiple="true"
                                    @tag="addRole"></VueMultiselect>
                                <ErrorMessage name="role"/>
                            </div>

                            <div class="col-12 mt-4 text-end">
                                <button class="btn btn-green col-12 col-md-3 col-xl-2">
                                    Сохранить
                                </button>
                            </div>

                        </Form>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="row d-flex justify-content-between align-items-center flex-wrap mb-3">
                            <div class="col-12 mb-3 mb-md-0 col-md-9 col-lg-10">
                                <h4 class="fw-bold">Настройки доступа</h4>
                            </div>
                            <div class="col-12 col-md-3 col-lg-2">
                                <div class="btn btn_add gray w-100" @click="showAddModule">
                                    Добавить
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive-md mt-2">
                            <table class="table table-hover">
                                <thead class="table-light">
                                <tr>
                                    <th></th>
                                    <th>Название модуля</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="item in userModules"
                                    :key="item.id"
                                >
                                    <td
                                        v-if="item.perms"
                                    >
                                        <a class="btn gray perm" href="#" @click="showPermission(item.permissions, item.id)">Права</a>
                                    </td>
                                    <td v-else>

                                    </td>
                                    <td>
                                        {{ item.title }}
                                    </td>
                                    <td class="pe-4 text-end">
                                        <div style="cursor: pointer;" class="btn btn_drop btn-red"
                                             @click="deleteUserModule(item.id)"
                                        >
                                            <fa icon="trash-can" class="icon"/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="col-12 mt-4 text-end">
                            <button class="btn btn-green col-12 col-md-3 col-xl-2"
                                @click="passwordReset"
                            >
                                Восстановить пароль
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition-group name="list">
        <add-module
            v-if="is_add_module"
            :modules="modules"
            @add-module="addModule($event)"
            @close-modal="closeModal"
        />
    </transition-group>

    <transition-group name="list">
        <permission-modal
            v-if="is_permission"
            :permissions="permissions"
            :moduleId="moduleId"
            @close-perms-modal="closePermModal"
            @show-add-perm="showAddPerm($event)"
            @save-perms-modal="savePermsModal($event)"
            @delete-permission="deletePermission($event)"
            @show-modal-action="showModalAction($event)"
        />
    </transition-group>

    <transition-group name="list">
        <add-permission
            v-if="is_add_perms"
            :all_permissions="all_permissions"
            :moduleId="moduleId"
            @close-add-permission="closeAddPerms"
            @save-permission="savePermission($event)"
        />
    </transition-group>

    <transition-group name="list">
        <action-modal
            v-if="is_action"
            :permissionId="permissionId"
            :actions="actions"
            @close-action-modal="closeActionModal"
            @show-add-action="showAddAction"
            @delete-action="deleteAction($event)"
        />
    </transition-group>

    <transition-group name="list">
        <add-action
            v-if="is_add_action"
            :all_actions="all_actions"
            :permissionId="permissionId"
            @close-add-action="closeAddAction"
            @save-action="saveAction($event)"
        />
    </transition-group>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :bg="bg"
    />

</template>

<script>
import axios from "axios";
import { Field, Form, ErrorMessage } from 'vee-validate';
import spinner from '../../components/Spinner';
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../Page/BreadCrumbs";
import AddModule from "./AddModule";
import PermissionModal from "./PermissionModal";
import AddPermission from "./AddPermission";
import AddAction from "./AddAction";
import ActionModal from "./ActionModal";
import ToastAlert from "../../components/ToastAlert";
import Checkbox from "../../components/Checkbox.vue";
import * as req from "../../services/users";

export default {
    name: "EditUser",
    components: {
        spinner, Field, Form, ErrorMessage,
        VueMultiselect, BreadCrumbs, AddModule,
        PermissionModal, AddPermission, AddAction, ActionModal, ToastAlert, Checkbox
    },
    data(){
        return {
            message: '',
            bg: 'bg-primary',
            is_alert: false,
            user:{
                surname: null,
                name: null,
                patronymic: null,
                phone: null,
                email: null,
                telegram_user_id: null,
                crm_id: null,
                status: null,
                mail: false,
                telegram: false,
                push: false,
                department: [],
                role: [],
                organization: null,
            },
            errors:{
                surname: '',
                name: '',
                patronymic: '',
                phone: '',
                email: '',
                telegram_user_id: '',
                crm_id: '',
                status: '',
                department: '',
                role: '',
            },
            roles: [],
            status: [{"id": 0, "name": "Не активный"}, {"id": 1, "name": "Активный"}],
            optionsDepartment: [],
            organizations: [],
            isLoading: false,
            breadCrumbs: true,
            title: 'Редактирование',
            back_title: 'Пользователи',
            link: 'admins/users',
            modules: [],
            userModules: {},
            is_add_module: false,
            is_permission: false,
            is_add_perms: false,
            is_add_action: false,
            is_action: false,
            permissions: [],
            all_permissions: [],
            actions: [],
            all_actions: [],
            permissionId: null,
            moduleId: null,
            selectDepartments: [],
        }
    },
    created() {
        this.getUser();
    },
    methods: {
        updateActiveCheck(event){
            this.user.status = event.checked;
        },
        closeAlert(){
            this.is_alert = false;
        },
        closeActionModal(){
            this.is_action = false;
        },
        closeAddAction(){
            this.is_add_action = false;
        },
        passwordReset(){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/password/reset', {
                user_id: this.$route.params.id,
            },config)
                .then(response =>{
                    if(response.status == 200){
                        this.is_alert = true;
                        this.message = 'Пароль успешно восстановлен!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        },
        deleteAction(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/delete/action', {
                id: event.id,
                permissionId: event.permissionId,
                user_id: this.$route.params.id,
            },config)
                .then(response =>{
                    if(response.status == 200){
                        this.actions = response.data.data.actions;
                        this.getUserActions(this.$route.params.id, event.permissionId);
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        },
        async showModalAction(event){
            this.permissionId = event.id;
            await this.getUserActions(this.$route.params.id, this.permissionId);
            this.is_action = true;
        },
        async showAddAction(event){
            await this.getActions(event.id);
            this.permissionId = event.id;
            this.is_add_perms = false;
            this.is_add_action = true;
        },
        async getUserActions(user_id, id){
            try {
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/users/get/user/actions/${user_id}/${id}`, config);
                this.actions = response.data.data.actions;
            } catch (e) {
                console.log(e);
            } finally {

            }
        },
        saveAction(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/add/action', {
                event,
                user_id: this.$route.params.id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.getUserActions(this.$route.params.id, event.permissionId);
                        this.is_add_action = false;
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        toggleCheck(type){
            if(type == 'mail'){
                this.user.mail = !this.user.mail;
            }else if(type == 'telegram'){
                this.user.telegram = !this.user.telegram;
            }else if(type == 'push'){
                this.user.push = !this.user.push;
            }
        },
        deletePermission(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/delete/permission', {
                id: event.id,
                user_id: this.$route.params.id,
            },config)
                .then(response =>{
                    if(response.status == 200){
                        this.all_permissions = response.data.data.permissions;
                        this.getPermission(event.moduleId);
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        },
        async getPermission(id){
            try {
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/users/get/user/permissions/${this.$route.params.id}/${id}`, config);
                this.permissions = response.data.data.permissions;
            } catch (e) {
                console.log(e);
            } finally {

            }
        },
        async getActions(id){
            try {
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/users/get/actions/${id}/${this.$route.params.id}`, config);
                this.all_actions = response.data.data.actions;
            } catch (e) {
                console.log(e);
            } finally {

            }
        },
        savePermsModal(){
            this.getModules();
            this.is_permission = false;
        },
        deleteUserModule(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/delete/module', {
                id: id,
                user_id: this.$route.params.id,
            },config)
                .then(response =>{
                    if(response.status == 200){
                        this.modules = response.data.data.modules;
                        this.getModules();
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        },
        async getModules(){
            try {
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/users/get/user/modules/${this.$route.params.id}`, config);
                this.userModules = response.data.data.userModules;
            } catch (e) {
                console.log(e);
            } finally {

            }
        },
        savePermission(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/users/add/permission`, {
                event,
                user_id: this.$route.params.id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.getPermission(event.moduleId);
                        //this.permissions = response.data.data.permissions;
                        this.is_add_perms = false;
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        closeAddPerms(){
            this.is_add_perms = false;
        },
        showAddPerm(event){
            this.getPermissionIdModule(event, this.$route.params.id);
            this.is_add_perms = true;
        },
        async getPermissionIdModule(event, id){
            try {
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/users/get/permission/${event.moduleId}/${id}`, config);
                this.all_permissions = response.data.data.permissions;
            } catch (e) {
                console.log(e);
            } finally {
            }
        },
        closePermModal(){
            this.is_permission = false;
        },
        showPermission(permission, id){
            this.moduleId = id;
            this.permissions = permission;
            this.is_permission = true;
        },
        addModule(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/users/add/module`, {
                event,
                user_id: this.$route.params.id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.all_permissions = response.data.data.all_permissions;
                        if(this.all_permissions.length > 0){
                            this.moduleId = response.data.data.moduleId;
                            this.modules = response.data.data.modules;
                            this.permissions = [];
                            this.is_permission = true;
                            this.is_add_module = false;
                        }else{
                            this.getModules();
                            this.is_add_module = false;
                        }
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        closeModal(){
            this.is_add_module = false;
        },
        showAddModule(){
            this.is_add_module = true;
        },
        async getUser() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/users/${this.$route.params.id}`, config);
                this.user = response.data.data.user;
                this.user.role = response.data.data.role;
                this.roles = response.data.data.roles;
                this.optionsDepartment = response.data.data.departments;
                this.organizations = response.data.data.organizations;
                this.user.department = response.data.data.department;
                this.user.organization = response.data.data.user.organization;
                this.user.mail = response.data.data.user.notification_type.mail;
                this.user.push = response.data.data.user.notification_type.push;
                this.user.telegram = response.data.data.user.notification_type.telegram;
                this.modules = response.data.data.modules;
                this.userModules = response.data.data.userModules;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        update(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.put(`/api/admin/users/${this.$route.params.id}`, {
                surname: this.user.surname,
                name: this.user.name,
                patronymic: this.user.patronymic,
                phone: this.user.phone,
                email: this.user.email,
                crm_id: this.user.crm_id,
                telegram_user_id: this.user.telegram_user_id,
                status: this.user.status,
                roles: this.user.role,
                department: this.user.department,
                mail: this.user.mail,
                telegram: this.user.telegram,
                push: this.user.push,
                organization: this.user.organization
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.$router.push('/admins/users');
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.user.setErrors(errors.response.data.errors);
                            this.errors.surname = errors.response.data.errors.surname ?? 0;
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            this.errors.patronymic = errors.response.data.errors.patronymic ?? 0;
                            this.errors.phone = errors.response.data.errors.phone ?? 0;
                            this.errors.email = errors.response.data.errors.email ?? 0;
                            this.errors.telegram_user_id = errors.response.data.errors.telegram_user_id ?? 0;
                            this.errors.crm_id = errors.response.data.errors.crm_id ?? 0;
                            this.errors.status = errors.response.data.errors.status ?? 0;
                            this.errors.role = errors.response.data.errors.role ?? 0;
                            this.errors.department = errors.response.data.errors.department ?? 0;
                            break;
                    }
                });
        },
        addRole(newRole){
            const role = {
                title: newRole,
            }
            this.roles.push(role)
            this.user.role.push(role)
        },
        addStatus(newStatus){
            const status = {
                name: newStatus,
            }
            this.status.push(status)
            this.user.status.push(status)
        },
        addDepartment(newDepartment){
            const dep = {
                name: newDepartment,
            }
            this.optionsDepartment.push(dep)
            this.user.department.push(dep)
        },
        selectOrganization(event){
            if(this.user.organization.length){
                this.selectDepartments.push(this.user.organization[0].id);
            }
            this.selectDepartments.push(event.id);
            this.getDepartments(this.selectDepartments);
        },
        async getDepartments(data) {
            this.isLoading = true;
            let result = await req.getDepartments(data);
            this.optionsDepartment = result.departments;
            this.roles = result.roles;
            this.isLoading = false;
        },
        removeOrganization(event) {
            const organizationIdToRemove = event.id;
            const indexToRemove = this.selectDepartments.indexOf(organizationIdToRemove);

            if (indexToRemove !== -1) {
                this.selectDepartments.splice(indexToRemove, 1);
                this.getDepartments(this.selectDepartments);
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <Modules
                :modules="modules"
            />
            <div class="col-12 d-flex flex-wrap modules"
                 v-if="is_auth"
                @click="getStarted"
            >
                <div class="col-12 col-lg-4 col-sm-6 col-xl-3 module_box pb-3">
                    <div class="d-flex h-100 justify-content-between module">
                        <div class="d-flex module_icon">
                            <fa icon="briefcase" class="icon"/>
                        </div>
                        <div class="d-flex flex-column module_titles text-end">
                            <div class="module_title mb-1">Начать работу</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import Modules from "../../Page/Modules";
import axios from "axios";

export default {
    name: "IssuancePage",
    components: {
        spinner, BreadCrumbs, Modules
    },
    data() {
        return {
            isLoading: false,
            is_auth: false,
            breadCrumbs: true,
            title: this.$t("module.categories"),
            back_title: this.$t("module.main_menu"),
            link: 'main',
            modules: [],
        }
    },
    created() {
        this.getSubModules();
        this.getAuthIssuanse();
    },
    methods: {
        async getSubModules(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/users/get/modules/issuance', config);
                this.modules = response.data.data.modules;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        async getAuthIssuanse(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/issuances/auth/work/module', config);
                this.is_auth = response.data.data.is_auth;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        getStarted(){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/auth/work/module', {
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.getAuthIssuanse();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>

</style>

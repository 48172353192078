<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div>
                    <h5 class="my-3">{{ $t('request.get_advance') }}</h5>
                    <div class="item_title" role="alert">
                        {{ $t('request.time_reception') }}
                    </div>
                    <Form ref="imprest" @submit="create">

                        <div class="mb-3">
                            <label class="form-label">Выберите организацию*</label>
                            <VueMultiselect
                                v-model:modelValue="organization"
                                placeholder="Выберите организацию"
                                label="name"
                                track-by="id"
                                :options="organizations"
                                name="organization"
                                :show-labels="false"
                                :searchable="false"
                                @select="selectOrganization($event)"
                            ></VueMultiselect>
                            <ErrorMessage name="organization_id" style="color: red;"/>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.advance_payment_amount') }}*</label>
                                <Field
                                    v-model="imprest.summ"
                                    type="tel"
                                    name="summ"
                                    class="form-control"
                                    placeholder='Сумма аванса'
                                />
                                <ErrorMessage name="summ" style="color: red;"/>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.method_obtaining') }}*</label>
                                <VueMultiselect
                                    v-model="imprest.type"
                                    :placeholder="$t('request.method_obtaining')"
                                    label="name"
                                    track-by="id"
                                    :options="types"
                                    :searchable="false"
                                    :show-labels="false"
                                    :allow-empty="false"
                                >
                                </VueMultiselect>
                                <ErrorMessage name="type" style="color: red;"/>
                        </div>

                        <div class="row">
                            <div class="col col-lg-2 mt-3">
                                <button class="btn btn-green w-100">
                                    <span class="material-symbols-rounded opacity-50 me-1">check</span>
                                    {{ $t('request.button.save') }}
                                </button>
                            </div>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import axios from "axios";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../../components/ToastAlert";
import * as req from "../../../services/request";

export default {
    name: "ImprestCreate",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data() {
        return {
            type: '',
            message: '',
            is_alert: false,
            imprest: {
                summ: null,
                type: null,
            },
            types: [
                { name: this.$t('request.money'), id: "money" },
                { name: this.$t('request.card'), id: "card" },
            ],
            errors: {
                summ: '',
                type: '',
                organization: '',
            },
            organizations: [],
            error: [],
            organization: null,
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.get_advance'),
            back_title: this.$t('request.advance_section'),
            link: 'request/imprest',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/imprest/create', config);
                this.organizations = response.data.data.organizations;
                this.organization = response.data.data.organization;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        closeAlert(){
            this.is_alert = false;
        },
        validateTime(){
            let currentD = new Date();
            let startHappyHourD = new Date();
            startHappyHourD.setHours(8,0,0);
            let endHappyHourD = new Date();
            endHappyHourD.setHours(18,0,0);

            if(currentD.getDay() == 0){
                return false;
            }else if(currentD >= startHappyHourD && currentD < endHappyHourD ){
                return true;
            }else{
                return false;
            }
        },
        create(){
            if(!this.validateTime()){
                this.is_alert = true;
                this.message = 'Извините, в данное время не возможно подать заявку!';
                this.type = 'yellow'
                setTimeout(() => {this.closeAlert(); }, 3000);
            }else{
                this.isLoading = true;
                let organization_id = null;
                if(this.organization){
                    organization_id =  this.organization.id;
                }
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                axios.post('/api/admin/imprest/store', {
                    summ: this.imprest.summ,
                    type: this.imprest.type,
                    organization_id: organization_id,
                }, config)
                    .then(response => {
                    this.isLoading = false;
                        if (response.status == 200) {
                            this.is_alert = true;
                            this.message = 'Заяление успешно отправлено!';
                            setTimeout(() => {this.closeAlert(); }, 3000);
                            this.$router.push('/request/imprest/index')
                        }
                    })
                    .catch(errors => {
                    this.isLoading = false;
                        switch (errors.response.status) {
                            case 422:
                                this.$refs.imprest.setErrors(errors.response.data.errors);
                                this.errors.summ = errors.response.data.errors.summ ?? 0;
                                this.errors.type = errors.response.data.errors.type ?? 0;
                                this.errors.organization = errors.response.data.errors.organization_id ?? 0;
                                break;
                            case 404:
                                this.type = 'red';
                                this.message = errors.response.data.data.message;
                                this.is_alert = true;
                                setTimeout(() => {this.closeAlert(); }, 5000);
                        }
                    });
            }
        },
        async selectOrganization(event){
            this.isLoading = true;
            let result = await req.selectImprestOrganization(event.id);
            this.organization = result.organization;
            this.isLoading = false;
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <div>
        <BreadCrumbs
            :breadCrumbs="breadCrumbs"
            :title="title"
            :back_title="back_title"
            :link="link"
        />
        <div class="page" style="top: 30%;">
            <div class="table-responsive-md">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th class="text-muted">Вопрос</th>
                        <th class="text-muted">Дата штрафа</th>
                        <th class="text-muted">Сумма штрафа</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="penaltie in penalties"
                        :key="penaltie.id"
                    >
                        <td>
                            {{ penaltie.checks.name }}
                        </td>
                        <td>
                            {{ penaltie.date_fine }}
                        </td>
                        <td>
                            {{ penaltie.checks.penalty }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import spinner from "../../../components/Spinner";
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "Penalties",
    components: {
        spinner, VueMultiselect, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            breadCrumbs: true,
            title: 'Список штрафов',
            back_title: 'Несоответствия',
            link: 'select-defects',
            penalties: [],
        }
    },
    created() {
        this.getReports();
    },
    methods: {
        async getReports() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get('/api/admin/report/user/penalties', config);
                this.penalties = response.data.data.penalties;
                console.log(this.penalties);
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page">
                <h2 class="mb-4">Новый пользователь</h2>

                <Form ref="user" @submit="create">
                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Фамилия*</label>
                            <Field
                                v-model="user.surname"
                                type="text"
                                name="surname"
                                class="form-control"
                                :class="[errors.surname.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="surname"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Имя*</label>
                            <Field
                                v-model="user.name"
                                type="text"
                                name="name"
                                class="form-control"
                                :class="[errors.name.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="name" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Отчество</label>
                            <Field
                                v-model="user.patronymic"
                                type="text"
                                name="patronymic"
                                class="form-control"
                                :class="[errors.patronymic.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="patronymic"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Телефон*</label>
                            <Field
                                placeholder="+7 (___) ___-__-__"
                                v-model="user.phone"
                                type="text"
                                name="phone"
                                class="form-control"
                                :class="[errors.phone.length > 0 ? 'error' : '']"
                                v-telmask/>
                            <ErrorMessage name="phone" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">E-mail*</label>
                            <Field
                                v-model="user.email"
                                type="text"
                                name="email"
                                class="form-control"
                                :class="[errors.email.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="email"/>
                        </div>
                    </div>

<!--                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Телеграмм ID</label>
                            <Field
                                v-model="user.telegram_user_id"
                                type="number"
                                name="telegram_user_id"
                                class="form-control"
                                :class="[errors.telegram_user_id.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="telegram_user_id"/>
                        </div>
                    </div>-->

<!--                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">CRM ID</label>
                            <Field
                                v-model="user.crm_id"
                                type="number"
                                name="crm_id"
                                class="form-control"
                                :class="[errors.crm_id.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="crm_id"/>
                        </div>
                    </div>-->

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Организация*</label>
                            <VueMultiselect
                                v-model="user.organization"
                                placeholder="Выберите организацию"
                                label="name"
                                track-by="id"
                                :options="organizations"
                                :searchable="false"
                                :show-labels="false"
                                :multiple="true"
                                @select="selectOrganization($event)"
                                @remove="removeOrganization($event)"
                                ></VueMultiselect>
                            <ErrorMessage name="organization"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Отдел*</label>
                            <VueMultiselect
                                v-model="user.department"
                                placeholder="Выберите отдел"
                                label="name"
                                track-by="id"
                                :options="optionsDepartment"
                                :searchable="false"
                                :show-labels="false"
                                @tag="addDepartment"></VueMultiselect>
                            <ErrorMessage name="departments"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Роль*</label>
                            <VueMultiselect
                                v-model="user.role"
                                placeholder="Выберите роль"
                                label="title"
                                track-by="id"
                                :options="roles"
                                :multiple="true"
                                :searchable="false"
                                :show-labels="false"
                                @tag="addRole"></VueMultiselect>
                            <ErrorMessage name="roles"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 mt-4 text-end">
                            <button class="btn btn-green col-12 col-md-3 col-xl-2">
                                Сохранить
                            </button>
                        </div>
                    </div>

                </Form>
            </div>
        </div>
    </transition>

</template>

<script>
    import axios from "axios";
    import {Field, Form, ErrorMessage} from 'vee-validate';
    import spinner from '../../components/Spinner';
    import VueMultiselect from "vue-multiselect";
    import BreadCrumbs from "../../Page/BreadCrumbs";
    import {mask} from 'maska';
    import * as req from "../../services/users";

    const telmask = {
        mounted: (el, binding) => {
            el.onfocus = function (e) {
                this.value = '+7 ('
                this.setSelectionRange(4, 4)
            }
            el.oninput = function (e) {
                if (!e.isTrusted) {
                    return
                }
                const x = this
                    .value
                    .replace(/\D/g, '')
                    .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
                x[1] = '+7'
                this.value = !x[3]
                    ? x[1] + ' (' + x[2]
                    : x[1] + ' (' + x[2] + ') ' + x[3] + (
                    x[4]
                        ? '-' + x[4]
                        : ''
                ) + (
                    x[5]
                        ? '-' + x[5]
                        : ''
                )
            }
        }
    }

    export default {
        name: "CreateUser",
        components: {
            spinner,
            Field,
            Form,
            ErrorMessage,
            VueMultiselect,
            BreadCrumbs
        },
        directives: {
            telmask
        },
        data() {
            return {
                mask: '+#(###)###-##-##',
                user: {
                    surname: null,
                    name: null,
                    patronymic: null,
                    phone: null,
                    email: null,
                    telegram_user_id: null,
                    crm_id: null,
                    department: [],
                    role: [],
                    organization: null,
                },
                organizations: [],
                errors: {
                    surname: '',
                    name: '',
                    patronymic: '',
                    phone: '',
                    email: '',
                    telegram_user_id: '',
                    crm_id: '',
                    status: '',
                    department: '',
                    role: ''
                },
                roles: [],
                optionsDepartment: [],
                status: [
                    {
                        "id": 0,
                        "name": "Не активный"
                    }, {
                        "id": 1,
                        "name": "Активный"
                    }
                ],
                selectDepartments: [],
                isLoading: false,
                breadCrumbs: true,
                title: 'Добавить',
                back_title: 'Пользователи',
                link: 'admins/users'
            }
        },
        created() {
            this.getDepartmentsRoles();
        },
        methods: {
            async getDepartmentsRoles() {
                try {
                    this.isLoading = true;
                    const config = {
                        'headers': {
                            'Authorization': 'Bearer ' + this
                                .$cookie
                                .getCookie('token')
                        }
                    };
                    const response = await axios.get('/api/admin/users/get/data', config);
                    //this.roles = response.data.data.roles;
                    //this.optionsDepartment = response.data.data.departments;
                    this.organizations = response.data.data.organizations;
                } catch (e) {
                    console.log(e);
                } finally {
                    this.isLoading = false;
                }
            },
            create() {
                this.isLoading = true;
                const config = {
                    'headers': {
                        'Authorization': 'Bearer ' + this
                            .$cookie
                            .getCookie('token')
                    }
                };
                axios
                    .post('/api/admin/users', {
                        surname: this.user.surname,
                        name: this.user.name,
                        patronymic: this.user.patronymic,
                        phone: this.user.phone,
                        email: this.user.email,
                        telegram_user_id: this.user.telegram_user_id,
                        crm_id: this.user.crm_id,
                        //status: this.user.status,
                        roles: this.user.role,
                        departments: this.user.department,
                        organization: this.user.organization
                    }, config)
                    .then(response => {
                        if (response.status == 200) {
                            this
                                .$router
                                .push('/admins/users');
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        switch (errors.response.status) {
                            case 422:
                                this.$refs.user.setErrors(errors.response.data.errors);
                                this.errors.surname = errors.response.data.errors.surname ?? 0;
                                this.errors.name = errors.response.data.errors.name ?? 0;
                                this.errors.patronymic = errors.response.data.errors.patronymic ?? 0;
                                this.errors.phone = errors.response.data.errors.phone ?? 0;
                                this.errors.email = errors.response.data.errors.email ?? 0;
                                this.errors.telegram_user_id = errors.response.data.errors.telegram_user_id ?? 0;
                                this.errors.crm_id = errors.response.data.errors.crm_id ?? 0;
                                //this.errors.status = errors.response.data.errors.status ?? 0;
                                this.errors.roles = errors.response.data.errors.roles ?? 0;
                                this.errors.departments = errors.response.data.errors.departments ?? 0;
                                this.errors.organization = errors.response.data.errors.organization ?? 0;
                                break;
                        }
                    });
            },
            addRole(newRole) {
                const role = {
                    title: newRole
                }
                this.roles.push(role)
                this.user.role.push(role)
            },
            addStatus(newStatus) {
                const status = {
                    title: newStatus
                }
                this.status.push(status)
                this.user.status.push(status)
            },
            addDepartment(newDepartment) {
                const department = {
                    title: newDepartment
                }
                this.optionsDepartment.push(department)
                this.user.department.push(department)
            },
            selectOrganization(event){
                this.selectDepartments.push(event.id);
                this.getDepartments(this.selectDepartments);
            },
            async getDepartments(data) {
                this.isLoading = true;
                let result = await req.getDepartments(data);
                this.optionsDepartment = result.departments;
                this.roles = result.roles;
                this.isLoading = false;
            },
            removeOrganization(event) {
                const organizationIdToRemove = event.id;
                const indexToRemove = this.selectDepartments.indexOf(organizationIdToRemove);

                if (indexToRemove !== -1) {
                    this.selectDepartments.splice(indexToRemove, 1);
                    this.getDepartments(this.selectDepartments);
                }
            }
        }
    }
</script>

<style scoped="scoped"></style>

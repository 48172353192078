<template>
    <spinner v-if="isLoading"></spinner>
    <BreadCrumbs
        :breadCrumbs="breadCrumbs"
        :title="title"
        :back_title="back_title"
        :link="link"
    />
    <div class="page">
        <div class="row mb-5">
            <div class="col">
            </div>
            <div class="col-auto">
                <router-link to="/admins/permissions/create">
                    <button class="btn gray btn_add">
                        Добавить
                    </button>
                </router-link>
            </div>
        </div>
        <div class="table-responsive-md">
            <table class="table table-app table-bordered">
                <thead>
                <tr>
                    <th class="text-muted">Модуль</th>
                    <th class="text-muted">Заголовок</th>
                    <th class="text-muted">Путь</th>
                    <th class="text-muted">Иконка</th>
                    <th class="text-muted">Сортировка</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="permission in permissions"
                    :key="permission.id"
                >
                    <td>
                        {{ permission.modules.title }}
                    </td>
                    <td>
                        {{ permission.title }}
                    </td>
                    <td>
                        {{ permission.path }}
                    </td>
                    <td>
                        {{ permission.icon }}
                    </td>
                    <td>
                        {{ permission.sort }}
                    </td>
                    <td class="text-end" style="width: 5%;">
                        <div class="dropdown">
                            <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                </svg>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                <li><button type="button" @click="$router.push(`/admins/permissions/edit/${permission.id}`)" class="dropdown-item">Редактировать</button></li>
                                <li><hr class="dropdown-divider"></li>
                                <li><button type="button" @click="$router.push(`/admins/permissions/actions/${permission.id}`)" class="dropdown-item">Действия</button></li>
                                <li><hr class="dropdown-divider"></li>
                                <li>
                                    <button type="button" @click="deletePermission(permission.id)" class="dropdown-item">Удалить</button>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";
import {fetchPermisson} from "../../services/helpers";

export default {
    name: "Index",
    components: {Spinner, BreadCrumbs},
    data(){
        return {
            isLoading: false,
            permissions: {},
            breadCrumbs: true,
            title: '',
            back_title: '',
            link: '',
            modules: [],
        }
    },
    mounted() {
      this.getPermission();
      this.module();
    },
    methods: {
        async module(){
            this.isLoading = true;
            this.permission = await fetchPermisson(this.$route.path);
            this.title = this.permission.title;
            this.back_title = this.permission.modules.title;
            this.link = this.permission.modules.path;
            this.isLoading = false;
        },
        async getPermission(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/permissions', config);
                this.permissions = response.data.data.permissions;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        deletePermission(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.delete(`/api/admin/permissions/${id}`, config)
                .then(response =>{
                  if(response.status == 200){

                    this.getPermission();
                  }
                })
                .catch(error =>{
                  console.log(error);
                });
        }
    },
}
</script>

<style scoped>

</style>

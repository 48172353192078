import * as auth from '../services/auth';
import Journal from "../Modules/TradeIn/Journal";
import Buying from "../Modules/TradeIn/Buying";
import Step_1 from "../Modules/TradeIn/Step_1";
import Step_2 from "../Modules/TradeIn/Step_2";
import Step_3 from "../Modules/TradeIn/Step_3";
import Step_4 from "../Modules/TradeIn/Step_4";
import Step_5 from "../Modules/TradeIn/Step_5";
import Step_6 from "../Modules/TradeIn/Step_6";
import Step_7 from "../Modules/TradeIn/Step_7";
import Step_8 from "../Modules/TradeIn/Step_8";
import Step_9 from "../Modules/TradeIn/Step_9";
import Step_10 from "../Modules/TradeIn/Step_10";
import Step_11 from "../Modules/TradeIn/Step_11";
import Step_12 from "../Modules/TradeIn/Step_12";
import TradeinPage from "../Modules/TradeIn/TradeinPage";
import TradeInList from "../Modules/TradeIn/TradeInList.vue";
import TradeInSettings from "../Modules/TradeIn/TradeInSettings";

export default [
    {
        name: 'tradeinlist',
        path: '/tradeinlist/:id',
        component: TradeInList,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'tradein',
        path: '/tradein',
        component: TradeinPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'journal',
        path: '/journal',
        component: Journal,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'buying',
        path: '/buying',
        component: Buying,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'client',
        path: '/buying/client/:id',
        component: Step_1,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'car',
        path: '/buying/car/:id',
        component: Step_2,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'purity',
        path: '/buying/purity/:id',
        component: Step_3,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'service',
        path: '/buying/service/:id',
        component: Step_4,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'carphoto',
        path: '/buying/carphoto/:id',
        component: Step_5,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'outside',
        path: '/buying/outside/:id',
        component: Step_6,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'interior',
        path: '/buying/interior/:id',
        component: Step_7,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'undersoot',
        path: '/buying/undersoot/:id',
        component: Step_8,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'complete',
        path: '/buying/complete/:id',
        component: Step_9,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'completeness',
        path: '/buying/completeness/:id',
        component: Step_10,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'testdrive',
        path: '/buying/testdrive/:id',
        component: Step_11,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'estimation',
        path: '/buying/estimation/:id',
        component: Step_12,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TradeInSettings',
        path: '/tradein/settings',
        component: TradeInSettings,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]

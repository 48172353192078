<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page">
                <div class="d-flex justify-content-between">
                    <h1 class="text-center font-weight-bold" style="color: #b3b6ba">
                        Доски
                    </h1>
                    <div>
                        <Button
                            v-if="is_admin"
                            caption="Добавить"
                            color="gray"
                            add_class="mb-3"
                            @click="showForm"
                        />
                    </div>
                </div>
                <div class="col-12 d-flex flex-wrap modules mt-3" v-if="items.length > 0">
                    <div class="col-12 col-lg-4 col-sm-6 col-xl-3 module_box pb-3"
                         v-for="item in items"
                         :key="item.id">
                        <router-link :to="'boards/'+item.alias" class="d-flex h-100 justify-content-between module">
                            <div class="d-flex module_icon">
                                <span class="material-symbols-rounded">dashboard</span>
                            </div>
                            <div class="d-flex flex-column module_titles text-end">
                                <div class="module_title mb-1">{{ item.name }}
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
<!--                <div class="table-responsive-md" v-if="items.length > 0">
                    <table class="table table-row table-app">
                        <thead>
                        <tr>
                            <th class="text-muted">Название отдела</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td>
                                {{ item.name }}
                            </td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button
                                        class="btn btn_drop px-0"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        id="dropdownMenuButton">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#ccc"
                                            class="bi bi-three-dots-vertical"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul
                                        class="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton">
                                        <li>
                                            <button
                                                type="button"
                                                @click="edit(item)"
                                                class="dropdown-item">Редактировать</button>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider"></li>
                                        <li>
                                            <button
                                                type="button"
                                                @click="destroy(item.id)"
                                                class="dropdown-item">Удалить</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive-md" v-else>
                    <h3>Данных нет!</h3>
                </div>-->
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{name_form}}
            </template>
            <template v-slot:body>
                <Input
                    v-model:modelValue="name"
                    @clear="clearInput($event)"
                    :error="[errors.name.length ? 'error' : '']"
                    label="Название доски"
                    name="name"
                    type="text"
                    placeholder="Название доски"
                    id="name"
                />
                <Button
                    caption="Сохранить"
                    color="btn-green"
                    add_class=""
                    @click="save()"
                />
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import ToastAlert from "../../components/ToastAlert.vue";
import Modal from "../../components/Modal.vue";
import Spinner from "../../components/Spinner.vue";
import BreadCrumbs from "../../Page/BreadCrumbs.vue";
import Button from "../../components/Button.vue";
import {errorMessages, fetchPermisson} from "../../services/helpers";
import Input from "../../components/Input.vue";
import * as req from "../../services/boards";

export default {
    name: "IndexPage",
    components: {
        Input,
        Spinner, BreadCrumbs, Modal, Button, ToastAlert
    },
    data() {
        return {
            isModal: false,
            isLoading: false,
            is_alert: false,
            is_admin: false,
            name_form: '',
            message: '',
            type: '',
            name: '',
            id: null,
            errors: {
                name: ''
            },
            breadCrumbs: true,
            title: 'Доски',
            back_title: this.$t("module.main_menu"),
            link: 'main',
            items: [],
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(){
            this.isLoading = true;
            let result = await req.getBoards();
            this.items = result.items;
            this.is_admin = result.is_admin;
            this.isLoading = false;
        },
        closeAlert(){
            this.is_alert = false;
        },
        clearInput(e){
            this.errors[e] = '';
        },
        showForm(){
            this.name_form = 'Добавить';
            this.isModal = true;
        },
        edit(item){
            this.id = item.id;
            this.name = item.name;
            this.name_form = 'Редактировать';
            this.isModal = true;
        },
        async save(){
            this.isLoading = true;
            let items = {
                id: this.id,
                name: this.name,
            }
            let result = await req.saveBoard(items);
            if(result.status === 422){
                this.errors = result.data.errors;
                this.isLoading = false;
                this.type = 'red';
                this.message = errorMessages(this.errors)
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }else {
                this.type = 'green';
                this.isLoading = false;
                this.closeModal();
                this.is_alert = true;
                this.message = result.message;
                this.items = result.items;
                setTimeout(() => {this.closeAlert(); }, 3000);
            }
        },
        async destroy(id){
            this.isLoading = true;
            let result = await req.deleteBoard(id);
            this.items = result.items;
            this.isLoading = false;
        },
        closeModal() {
            this.id = null;
            this.name = '';
            this.isModal = false;
        },
    }
}
</script>
<style scoped>

</style>

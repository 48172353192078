import * as auth from '../../services/auth';
import SettingsPage from "../../Modules/Request/Settings/SettingsPage";
import Categories from "../../Modules/Request/Settings/Categories";
import Documents from "../../Modules/Request/Settings/Documents";
import DocumentCreate from "../../Modules/Request/Settings/DocumentCreate";
import DocumentEdit from "../../Modules/Request/Settings/DocumentEdit";
import SettingImprest from "../../Modules/Request/Settings/SettingImprest";
import SettingMemos from "../../Modules/Request/Settings/SettingMemos";


export default [
    {
        name: 'SettingsPage',
        path: '/request/settings',
        component: SettingsPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Categories',
        path: '/request/settings/categories',
        component: Categories,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Documents',
        path: '/request/settings/documents',
        component: Documents,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'DocumentCreate',
        path: '/request/settings/documents/create',
        component: DocumentCreate,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'DocumentEdit',
        path: '/request/settings/documents/edit/:id',
        component: DocumentEdit,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'SettingImprest',
        path: '/request/settings/imprest',
        component: SettingImprest,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'SettingMemos',
        path: '/request/settings/memos',
        component: SettingMemos,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
];

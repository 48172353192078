import axios from "axios";
import * as auth from '../services/auth';

export const getModel = async(items) => {
    const config = { 'headers': { 'Authorization': 'Bearer ' + auth.getCookie() } };
    try {
        const response = await axios.post(`/api/admin/cars/get/model`, {
            id: items.brand_id,
        }, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};


<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <div class="col-12 flex-wrap hstack">
                        <div class="col-6 col-lg-3 col-xl-2">
                            <input
                                v-model="searchInspection"
                                type="text"
                                class="form-control"
                                placeholder="VIN..."
                            />
                        </div>
                        <div class="col-6 col-lg-3 col-xl-2">
                            <input type="date" class="form-control" v-model="startDay" @change="handler()" >
                        </div>
<!--                        <div class="col-12 col-lg-5 pe-lg-2 ps-lg-2">
                            <VueMultiselect
                                v-model:modelValue="organization"
                                placeholder="Выберите организацию"
                                label="name"
                                track-by="id"
                                :options="organizations"
                                name="organization"
                                :class="errors.organization.length ? 'error' : ''"
                                @select="selectOrganization($event)"
                            />
                        </div>-->
                        <div class="col-12 col-lg-5 pe-lg-2 ps-lg-2">
                            <VueMultiselect
                                v-model="user"
                                :placeholder="$t('request.worker')"
                                label="fullname"
                                track-by="id"
                                :options="users"
                                @select="selectUser"
                                @remove="resmoveUser"
                                :show-labels="false"
                                class="mb-2"
                            >
                            </VueMultiselect>
                        </div>
                    </div>
                    <div class="d-flex justify-between flex-wrap my-4">
                        <div class="col-12 col-sm-4 col-lg-3 offset-lg-3">
                            <button class="btn btn-green btn_add w-100"
                                :disabled="!is_organ"
                                @click="showModal"
                            >Начать осмотр</button>
                        </div>

                    </div>
                </div>
                <div v-if="lists.length > 0">
                    <div
                        v-for="list in filteredCard"
                        :key="list.id"
                        @click="selectCard(list.id)"
                        class="item_title mb-2" :class="goStatus(list.status_inspection)">

                        <!-- номер, дата, статус -->
                        <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                            <div class="fs-6 fw-bold">VIN: {{ list.car.vin_code }}</div>
<!--                            <div class="fs-7">{{ list.created_at }}</div>-->
                            <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block in_work" v-if="list.status == 'in_work'">В работе</div>
                            <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block pre_sale" v-if="list.status == 'pre_sale'">Предпродажа</div>
                            <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block completed" v-if="list.status == 'completed'">Завершено</div>
                        </div>

                        <div class="col-12 border-bottom pb-3">
                            <div>
                                <fa class="icon opacity-50 me-2" icon="home"></fa>
                                <span class="fw-normal">{{ list.organization.name }}</span>
                            </div>
                        </div>

                        <div class="col-12 border-bottom pb-3">
                            <div>
                                <fa class="icon opacity-50 me-2" icon="car"></fa>
                                <span class="fw-normal">{{ list.car.brands.name_brand }} {{ list.car.models.name_model }}</span>
                            </div>
                        </div>

                        <div class="col-12 mt-2">
                            <div>
                                <fa class="icon opacity-50 me-2" icon="calendar-days"></fa>
                                <span class="fw-normal">{{ list.date_inspection }}</span>
                            </div>
                            <div>
                                <fa class="icon opacity-50 me-2" icon="user-tie"></fa>
                                <span class="fw-normal">{{ list.fullNameManager }}</span>
                            </div>
                        </div>

<!--                        <div class="col-sm position-relative border-top mt-3 pt-3" v-if="is_sb && contract.is_started">
                            <button class="btn btn_vw btn-red" @click="finishTestDrive(contract.id)">
                                <fa class="icon opacity-50 me-1" icon="flag-checkered"></fa> ЗАВЕРШИТЬ
                            </button>
                        </div>-->
                    </div>
                </div>
                <div class="table-responsive-md" v-else>
                    <h3>Данных нет!</h3>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{name_form}}
            </template>
            <template v-slot:body>
                <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                    <div class="fs-6 fw-bold">{{ manager }}</div>
                    <div class="fs-7">{{ current_date }}</div>
                </div>
<!--                <div class="mb-3">
                    &lt;!&ndash; Организация &ndash;&gt;
                    <label class="form-label">Организация</label>
                    <VueMultiselect
                        v-model:modelValue="organization"
                        placeholder="Выберите организацию"
                        label="name"
                        track-by="id"
                        :options="organizations"
                        name="organization"
                        :class="errors.organization.length ? 'error' : ''"

                    />
                    <span v-if="errors.organization.length > 0" role="alert" class="mt-3">{{ errors.brand }}</span>
                </div>-->
                <div class="mb-3">
                    <label class="form-label fw-normal">Марка автомобиля*</label>
                    <VueMultiselect
                        v-model="brand"
                        placeholder="Выберите марку автомобиля"
                        label="name_brand"
                        track-by="id"
                        :options="brands"
                        name="brand"
                        @select="selectBrand"
                        @remove="removeBrand"
                        :show-labels="false"
                    >
                    </VueMultiselect>
                    <span v-if="errors.brand.length > 0" role="alert" class="mt-3">{{ errors.brand }}</span>
                </div>
                <div class="mb-3">
                    <label class="form-label fw-normal">Модель автомобиля*</label>
                    <VueMultiselect
                        v-model="model"
                        placeholder="Выберите модель автомобиля"
                        label="name_model"
                        track-by="id"
                        :options="models"
                        name="model"
                        @select="selectModel"
                        :show-labels="false"
                    >
                    </VueMultiselect>
                    <span v-if="errors.model.length > 0" role="alert" class="mt-3">{{ errors.model }}</span>
                </div>
                <div class="row mb-3 mt-3">
                    <label class="form-label fw-normal">VIN код*</label>
                    <div class="col-sm position-relative">
                        <Field
                            v-model="vin_code"
                            maxlength="17"
                            type="text"
                            name="vin_code"
                            style="text-transform: uppercase;"
                            class="form-control"
                            @input="errorVinClear()"
                            :class="[errors.vin_code.length > 0 ? 'is_valid' : '']"
                        />
                        <span v-if="errors.vin_code.length > 0" role="alert" class="mt-3">{{ errors.vin_code }}</span>
                    </div>
                </div>
                <div class="row mb-3 mt-3">
                    <label class="form-label fw-normal">Комплектация*</label>
                    <div class="col-sm position-relative">
                        <Field
                            v-model="configuration"
                            type="text"
                            name="configuration"
                            style="text-transform: uppercase;"
                            class="form-control"
                            @input="errorConfigurationClear()"
                            :class="[errors.configuration.length > 0 ? 'is_valid' : '']"
                        />
                        <span v-if="errors.configuration.length > 0" role="alert" class="mt-3">{{ errors.configuration }}</span>
                    </div>
                </div>

                <div class="item_title load_photo_wrapper">
                    <div class="block_foto" v-if="!showPreview">
                        <div v-if="Object.keys(this.image).length !== 0">
                            <div v-for="(file, key) in this.image" class="image_block"
                                 v-bind:key="'file-'+key">
                                <img class="defect_image" data-fancybox="gallery" :src="`${ file.path }`"/>
                            </div>
                        </div>
                        <div v-else class="pb_image">
                            <svg width="95" height="85" viewBox="0 0 95 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M37.2736 9.54167L27.8569 18.9583H9.83342V75.4583H85.1667V18.9583H67.1433L57.7266 9.54167H37.2736ZM33.3751 0.125H61.6251L71.0417 9.54167H89.8751C91.1238 9.54167 92.3214 10.0377 93.2044 10.9207C94.0874 11.8037 94.5834 13.0013 94.5834 14.25V80.1667C94.5834 81.4154 94.0874 82.613 93.2044 83.496C92.3214 84.379 91.1238 84.875 89.8751 84.875H5.12508C3.87635 84.875 2.67877 84.379 1.79579 83.496C0.912803 82.613 0.416748 81.4154 0.416748 80.1667V14.25C0.416748 13.0013 0.912803 11.8037 1.79579 10.9207C2.67877 10.0377 3.87635 9.54167 5.12508 9.54167H23.9584L33.3751 0.125ZM47.5001 70.75C40.6321 70.75 34.0454 68.0217 29.189 63.1653C24.3326 58.3089 21.6042 51.7222 21.6042 44.8542C21.6042 37.9862 24.3326 31.3995 29.189 26.543C34.0454 21.6866 40.6321 18.9583 47.5001 18.9583C54.3681 18.9583 60.9548 21.6866 65.8112 26.543C70.6676 31.3995 73.3959 37.9862 73.3959 44.8542C73.3959 51.7222 70.6676 58.3089 65.8112 63.1653C60.9548 68.0217 54.3681 70.75 47.5001 70.75ZM47.5001 61.3333C51.8706 61.3333 56.0622 59.5971 59.1526 56.5067C62.2431 53.4163 63.9793 49.2247 63.9793 44.8542C63.9793 40.4836 62.2431 36.2921 59.1526 33.2016C56.0622 30.1112 51.8706 28.375 47.5001 28.375C43.1295 28.375 38.938 30.1112 35.8476 33.2016C32.7571 36.2921 31.0209 40.4836 31.0209 44.8542C31.0209 49.2247 32.7571 53.4163 35.8476 56.5067C38.938 59.5971 43.1295 61.3333 47.5001 61.3333Z"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="block_foto align-middle d-flex justify-content-center align-items-center" v-else>
                        <div style="padding:  unset;">
                            <div v-for="(file, key) in files" class="image_block"
                                 v-bind:key="'file-'+key">
                                <img style="width: 100%;" class="defect_image" data-fancybox="gallery" v-bind:id="'image-'+parseInt( key )"/>
                                <input type="hidden" name="photo" v-bind:id="'photo-'+parseInt( key )"/>
                            </div>
                        </div>
                    </div>
                </div>
                <form enctype="multipart/form-data">
                    <div class="">
                        <input type="file" id="files" class="inputfile" multiple
                               accept="image/*"
                               ref="files"
                               @change="onAttachmentChange"
                               style="display: none"
                        />
                        <label for="files" class="btn gray btn_download_image btn_vw">Прикрепить ТТН</label>
                    </div>
                </form>

                <div class="col-sm position-relative mb-2 mt-2">
                    <button class="btn btn_vw btn-green"
                            @click="save"
                    >
                        Сохранить
                    </button>
                </div>
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>

</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import {ErrorMessage, Field, Form} from "vee-validate";
import axios from "axios";
import VueMultiselect from "vue-multiselect";
import Modal from '../../components/Modal';

export default {
    name: "Inspections",
    components: {
        spinner, BreadCrumbs, ToastAlert, Field, Form, ErrorMessage, VueMultiselect, Modal
    },
    data(){
        return {
            isModal: false,
            message: '',
            name_form: '',
            searchInspection: '',
            lists: [],
            brands: [],
            models: [],
            users: [],
            //organizations: [],
            //organization: null,
            user: null,
            brand: null,
            model: null,
            searchModel: null,
            startDay: null,
            vin_code: '',
            configuration: '',
            current_date: '',
            manager: '',
            type: '',
            errors: {
                vin_code: '',
                brand: '',
                model: '',
                configuration: '',
                //organization: '',
            },
            isLoading: false,
            is_not_send: false,
            is_alert: false,
            file: '',
            files: [],
            image: {},
            photo: [],
            showPreview: false,
            imagePreview: '',
            breadCrumbs: true,
            is_organ: false,
            title: 'Список осмотров',
            back_title: 'Осмотр ТС',
            link: 'inspections',
        }
    },
    computed: {
        filteredCard(){
            return this.lists.filter(item => item.car.vin_code.toLowerCase().includes(this.searchInspection.toLowerCase()));
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        async filter(){
            this.isLoading = true;
            this.is_filter = false;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            let result = await axios.post('/api/admin/inspection/filter', {
                startDay: this.startDay,
                user: this.user,
                //organization: this.organization
            }, config);
            this.lists = result.data.data.lists;
            this.isLoading = false;
        },
        selectOrganization(value){
            this.organization = value;
            this.filter();
        },
        selectUser(value){
            this.user = value;
            this.filter();
        },
        resmoveUser(){
            this.user = null;
            this.filter();
        },
        handler(){
            this.filter();
        },
        selectBrand(value){
            this.models = this.searchModel;
            this.models = this.models.filter(model => model.brand_id == value.id);
            this.errors.brand = '';
        },
        removeBrand(){
            this.models = this.searchModel;
        },
        selectModel(){
            this.errors.model = '';
        },
        errorVinClear(){
            this.errors.vin_code = '';
        },
        errorConfigurationClear(){
            this.errors.configuration = '';
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/inspection', config);
                if( !response.data.status){
                    this.is_alert = true;
                    this.type = 'red';
                    this.message = response.data.data.message;
                    setTimeout(() => {this.closeAlert(); }, 3000);
                }
                this.lists = response.data.data.lists;
                this.users = response.data.data.users;
                this.brands = response.data.data.brands;
                this.models = response.data.data.models;
                this.searchModel = response.data.data.models;
                this.manager = response.data.data.manager;
                this.current_date = response.data.data.current_date;
                this.is_not_send = response.data.data.is_send_service;
                this.is_organ = response.data.data.is_organ;
                //this.organization = response.data.data.firstOrganization;
                //this.organizations = response.data.data.organizations;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        closeAlert(){
            this.is_alert = false;
        },
        showModal(){
            this.name_form = 'Добавить осмотр';
            this.isModal = true;
        },
        closeModal(){
            this.isModal = false;
            this.errors.name = '';
        },
        selectCard(id){
            this.$router.push(`/inspections/index/${id}`);
        },
        validate(){
            let valid = [];
            if(!this.vin_code){
                this.errors.vin_code = 'Поле VIN код обязательно!';
                valid.push('vin');
            }
            if(this.vin_code.length < 17 || this.vin_code.length > 17){
                this.errors.vin_code = 'Поле VIN должно быть 17 символов!';
                valid.push('vin');
            }
            if(!this.configuration){
                this.errors.configuration = 'Поле комплектация обязательно!';
                valid.push('conf');
            }
            if(this.brand === null){
                this.errors.brand = 'Поле марка автомобиля обязательно!';
                valid.push('brand');
            }
            if(this.model === null){
                this.errors.model = 'Поле модель автомобиля обязательно!';
                valid.push('model');
            }
           /* if(this.organization === null){
                this.errors.organization = 'Поле организация обязательно!';
                valid.push('organization');
            }*/
            if(!this.showPreview){
                this.is_alert = true;
                this.type = 'red';
                this.message = 'Необходимо прикрепить ТТН!';
                setTimeout(() => {this.closeAlert(); }, 3000);
                valid.push('ttn');
            }

            if(valid.length > 0){
                return false;
            }else {
                return true;
            }

        },
        sendService(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/inspection/send/request', {
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.getData();
                        this.is_alert = true;
                        this.type = 'green';
                        this.message = 'VIN коды отправлены сервис консультантам!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        save(){
            if(this.validate()){
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const formData = new FormData();
                let q = Array.prototype.slice.call(document.getElementsByName("photo"));
                for (let i = 0; i < q.length; i++) {
                    formData.append('photos[' + i + ']', q[i].defaultValue);
                }
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
                }
                let data = {
                    'vin_code': this.vin_code,
                    'configuration': this.configuration,
                    'brand': this.brand,
                    'model': this.model,
                    'organization': this.organization
                }
                formData.append('data', JSON.stringify(data));
                axios.post('/api/admin/inspection', formData, config)
                    .then(response => {
                        this.isLoading = false;
                        if(response.status == 200){
                            if(response.data.data.id === 0){
                                this.is_alert = true;
                                this.type = 'red';
                                this.message = response.data.data.message;
                                setTimeout(() => {this.closeAlert(); }, 3000);
                            } else {
                                this.$router.push(`/inspections/index/${response.data.data.id}`);
                            }
                            this.is_modal = false;
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        console.log(errors);
                    });
            }
        },
        goStatus(status){
            return `card-status-` + status;
        },
        fileInfo(file) {
            let array = [];
            array.push(file.name);
            array.push(file.type);
            return array;
        },
        onAttachmentChange(event) {
            let uploadedFiles = this.$refs.files.files;
            this.count = 0;
            for (var i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i]);
                this.count++;
            }
            this.showPreview = true;
            this.getImagePreviews();
        },
        getImagePreviews() {
            const MAX_WIDTH = 1024;
            const MAX_HEIGHT = 768;

            for (let i = 0; i < this.files.length; i++) {
                const file = this.files[i]; // get the file
                const blobURL = URL.createObjectURL(file);
                const img = new Image();
                img.src = blobURL;
                img.onerror = function () {
                    URL.revokeObjectURL(this.src);
                    // Handle the failure properly
                    console.log("Cannot load image");
                };
                img.onload = function () {
                    URL.revokeObjectURL(this.src);
                    const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                    const canvas = document.createElement("canvas");
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    let ndata = canvas.toDataURL('image/jpeg')
                    document.getElementById('image-' + parseInt(i)).src = ndata;
                    document.getElementById('photo-' + parseInt(i)).value = ndata;
                };
            }


            function calculateSize(img, maxWidth, maxHeight) {
                let width = img.width;
                let height = img.height;

                // calculate the width and height, constraining the proportions
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
                return [width, height];
            }
        },
    }
}
</script>

<style scoped>
    .card-status-new {
        height: 100%;
        border-radius: 5px;
        border-left: 6px solid #343a40 !important;
        box-shadow: 0 2px 4px -3px;
        padding: 10px 5px 10px 15px;
        border-right: unset;
        border-top: unset;
        border-bottom: unset;
    }
    .card-status-defect {
        height: 100%;
        border-radius: 5px;
        border-left: 6px solid #f30808 !important;
        box-shadow: 0 2px 4px -3px;
        padding: 10px 5px 10px 15px;
        border-right: unset;
        border-top: unset;
        border-bottom: unset;
    }

    .card-status-accept {
        height: 100%;
        border-radius: 5px;
        border-left: 6px solid #4caf50 !important;
        box-shadow: 0 2px 4px -3px;
        padding: 10px 5px 10px 15px;
        border-right: unset;
        border-top: unset;
        border-bottom: unset;
    }

    .card-status-pre_sale {
        height: 100%;
        border-radius: 5px;
        border-left: 6px solid #ffa500 !important;
        box-shadow: 0 2px 4px -3px;
        padding: 10px 5px 10px 15px;
        border-right: unset;
        border-top: unset;
        border-bottom: unset;
    }
</style>

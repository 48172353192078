<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h5 class="mb-2">Исправить дефект</h5>

                <!-- Время и Сроки -->
                <div class="tasks_time item_title fs-7">
                    <div class="block_term_defect">
                        <span class="fw-normal pe-2">Задача открыта:</span>
                        <span class="fw-bold">{{ date_start }}</span>
                    </div>
                    <div class="block_term_defect">
                        <span class="fw-normal pe-2">Срок исполнения:</span>
                        <span class="fw-bold">{{ this.date_expires }}</span>
                    </div>
                    <div class="block_term_defect" v-if="this.finish != 'Время исполнения истекло!'">
                        <span class="fw-normal pe-2">Осталось:</span>
                        <span class="fw-bold">{{ this.finish }}</span>
                    </div>
                    <div class="block_term_defect" v-else>
                        <span class="text-danger fw-bold">{{ this.finish }}</span>
                    </div>
                </div>

                <!-- Пункт чек-листа -->
                <div class="item_title">
                    <div class="cl-text fw-normal">{{ subItemName }}</div>
                    <div class="gn_row d-flex gn_inner bg-status-gray p-3 my-2 mt-4 rounded-3">
                        <div class="gn_label pe-2 text-black-50">
                            <fa icon="comment" class="icon"></fa>
                        </div>
                        <div class="gn_value" v-if="this.comment.length > 0">{{ this.comment }}</div>
                        <div class="gn_value" v-else>Нет комментария</div>
                    </div>
                </div>

                <!-- Фотография -->
                <div class="item_title mb-3">
                    <div class="block_foto" :class="{ image_foto: type }" v-if="!showPreview">
                        <div v-if="Object.keys(this.image).length !== 0">
                            <div
                                v-for="(file, key) in this.image"
                                class="image_block"
                                v-bind:key="'file-'+key">
                                <img class="defect_image" data-fancybox="gallery" :src="`${ file }`"/>
                            </div>
                        </div>
                        <div class="image_block" v-else>
                            <img src="/storage/default/no_image.jpg" alt="">
                        </div>
                        <div class="check_answer">Фотография несоответствия</div>
                    </div>
                    <div class="block_foto align-middle d-flex justify-content-center align-items-center" v-else>
                        <div>
                            <div v-for="(file, key) in files" class="image_block" v-bind:key="'file-'+key">
                                <img
                                    style="width: 100%;"
                                    class="defect_image mb-1"
                                    data-fancybox="gallery"
                                    v-bind:id="'image-'+parseInt( key )"/>
                                <input
                                    type="hidden"
                                    name="photo"
                                    v-model="photo"
                                    v-bind:id="'photo-'+parseInt( key )"/>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Конка загрузки оправдания -->
                <form enctype="multipart/form-data">
                    <div class="mb-3">
                        <input
                            type="file"
                            id="files"
                            class="inputfile"
                            multiple
                            accept="image/*"
                            ref="files"
                            @change="onAttachmentChange"
                            style="display: none"/>
                        <label for="files" class="btn gray btn_download_image btn_vw"><fa class="icon pe-2" icon="cloud-arrow-up"></fa> ФОТО С ИСПРАВЛЕНИЕМ</label>
                    </div>
                </form>

                <!-- Комементарий к исправлению -->
                <div class="comments">
                    <div class="fs-7 mb-2 ms-2">
                        Примечание к устранению.
                    </div>
                    <textarea name="comment" v-model="comment_correction" class="form-control"></textarea>
                </div>

                <div v-if="!isLoading" class="text-center mt-1 mb-2">
                    <button type="button" class="btn btn-green btn_vw"
                            @click="submitCorrect"
                    >УСТРАНЕНО</button>
                </div>
                <div v-if="isLoading" class="text-center mt-1 mb-2">
                    <button class="btn btn-green btn_vw" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Загрузка...
                    </button>
                </div>
            </div>
            </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "TSMCorrectionPage",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            attachment: [],
            file: '',
            files: [],
            subItemName: '',
            showPreview: false,
            imagePreview: '',
            image: [],
            date_start: null,
            date_expires: null,
            finish: null,
            comment: '',
            comment_correction: '',
            image_file: true,
            audio_file: false,
            count: 0,
            photo: [],
            breadCrumbs: true,
            title: 'Исправить дефект',
            back_title: 'Список несоответствий',
            link: 'tsm-defects/' + this.$route.params.item_id,
        }
    },
    mounted() {
        this.getCheckDefect();
        //this.checking();
    },
    methods: {
        /*checking() {
            if (this.$route.params.id == 9) {
                console.log(1);
                this.image_file = false;
                this.audio_file = true;
            }
        },*/
        submitCorrect(e) {
            e.preventDefault();
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }, 'content-type': 'multipart/form-data'};
            const formData = new FormData();
            let q = Array.prototype.slice.call(document.getElementsByName("photo"));
            for( let i = 0; i < q.length; i++){
                formData.append('photos[' + i + ']', q[i].defaultValue);
            }
            for (let i = 0; i < this.files.length; i++) {
                formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
            }
            formData.append('check_id', this.$route.params.check_id);
            formData.append('status', 'corrected');
            formData.append('slug', 'corrected');
            formData.append('comment', this.comment);
            formData.append('comment_correction', this.comment_correction);
            axios.post(`/api/admin/tsm/defect/corrected`, formData, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push(`/tsm-defects/${this.$route.params.item_id}`);
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        fileInfo(file) {
            let array = [];

            array.push(file.name);
            array.push(file.type);

            return array;
        },
        async getCheckDefect() {
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tsm/get/defects/${this.$route.params.item_id}/${this.$route.params.check_id}`, config);
                this.image = response.data.data.image;
                this.date_start = response.data.data.checkings.date_verification;
                this.date_expires = response.data.data.checkings.subitem.deadline.time_performance;
                this.finish = response.data.data.finish;
                this.subItemName = response.data.data.subItemName;
                this.comment = response.data.data.comment;
                this.count = response.data.count;
                if (response.data.type_file === 'audio/mpeg') {
                    this.image_file = false;
                    this.audio_file = true;
                }
                console.log(this.image);
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        onAttachmentChange(event) {
            let uploadedFiles = this.$refs.files.files;
            this.count = 0;
            for (var i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i]);
                this.count++;
            }
            this.showPreview = true;
            this.getImagePreviews();
        },
        getImagePreviews() {
            const MAX_WIDTH = 1024;
            const MAX_HEIGHT = 768;

            for (let i = 0; i < this.files.length; i++) {
                const file = this.files[i]; // get the file
                const blobURL = URL.createObjectURL(file);
                const img = new Image();
                img.src = blobURL;
                img.onerror = function () {
                    URL.revokeObjectURL(this.src);
                    // Handle the failure properly
                    console.log("Cannot load image");
                };
                img.onload = function () {
                    URL.revokeObjectURL(this.src);
                    const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                    const canvas = document.createElement("canvas");
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    let ndata = canvas.toDataURL('image/jpeg')
                    document.getElementById('image-' + parseInt(i)).src = ndata;
                    document.getElementById('photo-' + parseInt(i)).value = ndata;
                };
            }


            function calculateSize(img, maxWidth, maxHeight) {
                let width = img.width;
                let height = img.height;

                // calculate the width and height, constraining the proportions
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
                return [width, height];
            }
        },
    }
}
</script>

<style scoped>

</style>

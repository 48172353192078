import AdminPage from "../../Page/AdminPage";
import * as auth from "../../services/auth";
import users from "./users";
import module from "./module";
import permission from "./permission";
import role from "./role";
import department from "./department";
import bank from "./bank";
import organizations from "./organizations";

export default [
    {
        name: 'admins',
        path: '/admins',
        component: AdminPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/');
            } else {
                next();
            }
        }
    },
    ...users,
    ...module,
    ...permission,
    ...role,
    ...department,
    ...bank,
    ...organizations,
];

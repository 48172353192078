<template>
    <div>
        <div class="d-flex">
            <vue-record-audio :mode="recordMode.audio" @stream="onStream" @result="onResult" />
            <div>
                <div v-for="(record, index) in recordings" :key="index" class="d-flex mb-2">
                    <div class="audio-container me-1 ms-1">
                        <audio :src="record.path" controls />
                    </div>
                    <div>
                        <button class="record-delete gray" @click="removeRecord(record.id)"><span class="material-symbols-rounded">delete</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueRecordAudio from "./VueRecordAudio";
import axios from "axios";
export default {
    name: "Records",
    components: {
        VueRecordAudio
    },
    props: {
        recordings: {
            type: Array,
            default: [],
        },
        id: null,
        type: '',
        slug: '',
    },
    data () {
        return {
            recordMode: {
                audio: 'hold',
            },
            recordings: [],
        }
    },
    methods: {
        removeRecord (id) {
            //this.$emit('deleteRecord', id)
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/delete/audio/file',{
                id: id,
                filetable_id: this.id,
                type: this.type,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.recordings = response.data.data.result;
                        this.$emit('result', this.recordings)
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        },
        onStream (stream) {
            //console.log('Got a stream object:', stream);
        },
        onResult (data) {
            //this.$emit('result', data)

            const config = {'headers': {
                    'Authorization': 'Bearer ' + this.$cookie.getCookie('token'),
                    'X-Custom-Header': 'some data',
                }};
            const formData = new FormData();
            formData.append('files', data, 'record.mp3')
            formData.append('id', this.id);
            formData.append('type', this.type);
            formData.append('slug', this.slug);
            axios.post('/api/audio/file', formData, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.recordings = response.data.data.result;
                        this.$emit('result', this.recordings)
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>

.record-delete {
    position: relative;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    display: inline-block;
    cursor: pointer;
}

.audio-container {
    display: flex;
    height: 54px;
    width: 235px;
}

</style>

import * as auth from "./auth";
import axios from "axios";

export const filterOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/inspection/filter/organization/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const saveSettings = async (items, type, organization) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        let item = {
            data: items,
            type: type,
            organization: organization
        }
        const response = await axios.post('/api/admin/inspection/settings/save', item, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
<!--            <button class="btn btn-green mt-4 btn_vw mb-4"
                    @click="scrollToElement"
            >Скролл</button>-->
            <div class="page d-flex flex-column justify-content-center mt-0">
                <div class="item_title">
                    {{ name }}
                </div>
                <div class="block_item">
                    <div class="mb-2" v-for="checklist in checklists" :key="checklist.id">
                        <div class="cl_item little" id="myScroll"
                             @click="next(checklist.id)"
                             :class="goClass(checklist.status)"
                        >
                            {{ checklist.name }}
                        </div>
                    </div>
                    <button class="btn btn-green mt-4 btn_vw mb-4"
                        :disabled="!is_active || inspection.status === 'completed'"
                        @click="save"
                    >{{ caption }}</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import axios from "axios";

export default {
    name: "InspectionItemCheckList",
    components: {
        spinner, BreadCrumbs, ToastAlert
    },
    data(){
        return {
            caption: 'Отправить на предпродажу',
            is_active: false,
            name: '',
            inspection: null,
            checklists: [],
            isLoading: false,
            is_children: false,
            breadCrumbs: true,
            title: 'Пункты осмотра',
            back_title: 'Чек лист осмотра',
            link: 'inspections/index/' + this.$route.params.id,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /*scrollToElement(){
            console.log(3);
            //window.scrollBy(0,localStorage.getItem('scroll'));
            window.scrollBy(0,localStorage.getItem('scroll'));
            /!*let ancor = localStorage.getItem('ancor')
            let element = document.createElement("a");
            element.setAttribute("href", '#'+ancor);
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);*!/

        },*/
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/inspection/show/${this.$route.params.id}/${this.$route.params.item}`, config);
                this.inspection = response.data.data.inspection;
                this.name = this.inspection.data.name;
                if (this.name == 'Прием после мойки'){
                    this.caption = 'Завершить осмотр';
                }
                this.checklists = this.inspection.data.children;
                this.is_active = response.data.data.is_active;
                setTimeout(function() {
                    this.scrollToElement();
                }, 10)
                } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        next(id){
            //let scrollTop = this.$refs.scrollToMe.scrollTop;
          /*  let scrollTop = window.pageYOffset;
            localStorage.setItem('scroll', scrollTop)*/
            this.$router.push(`/inspections/index/${this.$route.params.id}/${this.$route.params.item}/${id}`);
        },
        goClass(status_check) {
            return `type_${status_check}`;
        },
        save(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/inspection/item/save', {
                id: this.$route.params.id,
                item: this.$route.params.item,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.$router.push(`/inspections/index/${this.$route.params.id}`)
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="sign d-flex flex-column">
        <svg @click="close" style="position: absolute; right: 2%; z-index: 1000;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 9.40764L21.075 0.332642L23.6673 2.92498L14.5923 12L23.6673 21.075L21.075 23.6673L12 14.5923L2.92498 23.6673L0.332642 21.075L9.40764 12L0.332642 2.92498L2.92498 0.332642L12 9.40764Z" fill="#AFBBCC"/>
        </svg>
        <h2 class="mt-3">ПОДПИСЬ</h2>
        <h3 class="rules_title mt-3">Подпись клиента</h3>
        <div class="block_sign">
            <vue-signature-pad
                id="signature"
                ref="signaturePad"
                :options="options"
                style="height: calc(100% - 0px)"
            />
        </div>
        <div class="block_btn mt-auto hstack m-0 mt-3 gap-3">
            <button class="btn btn_vw btn-red mt-2"
                    @click="undo"
            >
                <fa class="icon me-1 opacity-50" icon="ban"></fa>
                Очистить
            </button>
            <button class="btn w-100 btn-green mt-2"
                @click="saveSign(download = false)"
            >
                <fa class="icon me-1 opacity-50" icon="check"></fa>
                Сохранить
            </button>
            <!-- <button class="btn w-100 btn-green mt-2"
                v-if="app == 'td'"
                @click="saveSign(download = true)"
            >
                <fa class="icon me-1 opacity-50" icon="cloud-arrow-down"></fa>
                Сохранить и скачать (.docx)
            </button> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Sign",
    props: {
        app: {
            type: String,
            default: '',
        }
    },
    data: () => ({
        options: {
            backgroundColor: 'rgb(255,255,255)',
            penColor: 'rgb(21,47,128)',
            minWidth: 2,
            maxWidth: 5,
        },
    }),
    methods: {
        close() {
            this.$emit('close-form-sign')
        },
        undo() {
            this.$refs.signaturePad.clearSignature();
            this.$refs.signaturePad.undoSignature();
        },
        saveSign(download) {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if (isEmpty === false) {
                this.$emit('save-sign', {data: data, download: download});
                this.$refs.signaturePad.clearSignature();
            }
        },
    }
}
</script>

<style scoped>

</style>

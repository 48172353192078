<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="this.title"
                :back_title="this.back_title"
                :link="this.link" />
            <div class="page">
                <h5 class="mb-1 mt-3 hstack">
                    <Buying :count="10"/>
                    Комплектность
                </h5>
                <div class="fs-7 fw-normal my-3">По-умолчанию указана полная комплетность. Отметьте в списке <strong>отсутствующие</strong> комплетующие.</div>
                <div
                    class="btn cl_item cl_status pad mb-2 w-100 text-center hstack"
                    v-for="item in lists"
                    :key="item.id"
                    :class="goClass(item.select)"
                    @click="deleteComplete(item.id)">
                    <span class="btn_complete_title">{{ item.name }}</span>
                    <span v-if="goClass(item.select) == 'type_defect'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">cancel</span>
                    <span v-else class="material-symbols-rounded me-2 opacity-50 cl_status_icon">check_circle</span>
                </div>
                <hr>
                <button class="btn btn-green w-100 mt-3 mb-5" @click="saveStep" >Сохранить</button>
            </div>
        </div>
    </transition>

</template>

<script>
import Buying from "./Buying";
import axios from "axios";
import Spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "Step_10",
    components: {
        Buying, Spinner, BreadCrumbs
    },
    data: () => ({
        step: 'testdrive',
        back_step: 'complete',
        lists: [],
        car: [],
        isLoading: false,
        breadCrumbs: true,
        title: "Комплектность",
        back_title: "Этапы приёма",
        link: "",
    }),
    created(){
        this.getData();
        this.getBackLink();
    },
    methods: {
        getBackLink() {
            this.link = this.link = `tradeinlist/${this.$route.params.id}`
        },
        saveStep(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/guration/save/step`, {
                id: this.$route.params.id,
                step_number: 10,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.$router.push(`/buying/testdrive/${this.$route.params.id}`);
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        deleteComplete(id){
            //this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/guration/delete`, {
                id: id,
                tradein_id: this.$route.params.id,
                step_number: 10,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.getData();
                    }
                })
                .catch(error =>{
                    //this.isLoading = false;
                    console.log(error);
                });
        },
        async getData(){
            try {
                //this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/guration/${this.$route.params.id}`, config);
                this.lists = response.data.data.lists.data;
            } catch (e) {
                console.log(e);
            } finally {
                //this.isLoading = false;
            }
        },
        goClass(data){
            if(data === false){
                return 'type_defect';
            } else {
                return 'type_accept';
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page">
                <h2 class="mb-4">Новый контрагент</h2>

                <Form ref="agent" @submit="update">
                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Название*</label>
                            <Field
                                v-model="agent.name"
                                type="text"
                                name="name"
                                class="form-control"
                                :class="[errors.name.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="name"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">БИН*</label>
                            <Field
                                v-model="agent.bin"
                                type="number"
                                name="bin"
                                class="form-control"
                                :class="[errors.bin.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="bin" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 mt-4 text-end">
                            <button class="btn btn-green col-12 col-md-3 col-xl-2">
                                Сохранить
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import axios from "axios";

export default {
    name: "CounteragentEdit",
    components: {
        spinner,
        Field,
        Form,
        ErrorMessage,
        VueMultiselect,
        BreadCrumbs
    },
    data() {
        return {
            agent: {
                name: null,
                bin: null,
            },
            errors: {
                name: '',
                bin: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: 'Добавить',
            back_title: 'Список котрагентов',
            link: 'request/counteragents'
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/counteragents/edit/${this.$route.params.id}`, config);
                this.agent = response.data.data.agent;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        update() {
            this.isLoading = true;
            const config = {
                'headers': {
                    'Authorization': 'Bearer ' + this
                        .$cookie
                        .getCookie('token')
                }
            };
            axios
                .post('/api/admin/counteragents/update', {
                    id: this.$route.params.id,
                    name: this.agent.name,
                    bin: this.agent.bin,
                }, config)
                .then(response => {
                    if (response.status == 200) {
                        this
                            .$router
                            .push('/request/counteragents');
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.agent.setErrors(errors.response.data.errors);
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            this.errors.bin = errors.response.data.errors.bin ?? 0;
                            break;
                    }
                });
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <BreadCrumbs
        :breadCrumbs="breadCrumbs"
        :title="title"
        :back_title="back_title"
        :link="link"
    />
    <div class="page">
        <div class="row">
            <div class="col">
                <h2 class="fs-4">Роли пользователей</h2>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div>
                <VueMultiselect
                    v-model="organization"
                    placeholder="Фильтр по организации"
                    label="name"
                    track-by="id"
                    :options="organizations"
                    :show-labels="false"
                    :searchable="false"
                    @select="filterOrganization($event)"
                    @remove="reset($event)"
                ></VueMultiselect>
            </div>
            <div>
                <Button
                    caption="Добавить"
                    color="gray"
                    add_class="mb-3"
                    @click="showModal()"
                />
            </div>
        </div>
        <div v-if="Object.keys(roles).length">
            <role-tree
                v-for="node in roles"
                :key="node.id"
                :node="node"
                :title="title"
                :organization="organization"
                :organizations="organizations"
                :id="id"
                :roles="roles"
                @delete-role="deleteRole($event)"
                @create-role="createRole($event)"
                @edit-role="editRole($event)"
            />
        </div>
        <div class="table-responsive-md" v-else>
            <h3>Данных нет!</h3>
        </div>
    </div>

    <transition name="slide-fade">
        <Modal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{name_form}}
            </template>
            <template v-slot:body>
                <FormRole
                    :id="id"
                    :all_roles="all_roles"
                    :roles="roles"
                    :title="title"
                    :organizations="organizations"
                    :organization="organization"
                    :role="role"
                    :errors="errors"
                    @filter="filterOrganization($event)"
                    @reset="reset($event)"
                    @save="save($event)"
                />
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import axios from "axios";
import Spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";
import {errorMessages, fetchPermisson} from "../../services/helpers";
import RoleTree from "./RoleTree";
import ToastAlert from "../../components/ToastAlert";
import {ErrorMessage, Field, Form} from "vee-validate";
import { ref } from 'vue'
import Input from '../../components/Input';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import VueMultiselect from "vue-multiselect";
import * as req from "../../services/roles";
import FormRole from "./FormRole.vue";

export default {
    name: "Index",
    components: {
        Spinner, BreadCrumbs, RoleTree, ToastAlert, Field, Form, ErrorMessage, Button, VueMultiselect, Modal, Input, FormRole
    },
    data(){
        return {
            isModal: false,
            message: '',
            is_alert: false,
            isLoading: false,
            all_roles: [],
            roles: [],
            role: null,
            breadCrumbs: true,
            id: null,
            title: null,
            errors: {
                title: '',
            },
            name_form: '',
            back_title: '',
            type: 'green',
            link: '',
            modules: [],
            permission: [],
            organization: null,
            organizations: [],
        }
    },
    mounted() {
        this.getRoles();
        this.module();
    },
    methods: {
        showModal(){
            this.name_form = 'Добавить роль';
            this.id = null;
            this.title = '';
            this.role = null;
            this.isModal = true;
        },
        closeModal(){
            this.isModal = false;
        },
        editRole(event){
            this.name_form = 'Редактировать роль';
            this.id = event.id;
            this.title = event.title;
            this.role = event.parent;
            this.organization = event.organization;
            this.isModal = true;
        },
        async deleteRole(id){
            this.isLoading = true;
            let data = {
                id: id,
                organization: this.organization
            };
            let result = await req.deleteRole(data);
            this.type = 'green';
            this.is_alert = true;
            this.message = result.message;
            this.roles = result.roles;
            this.all_roles = result.all_roles;
            this.organization = result.organization;
            setTimeout(() => {this.closeAlert(); }, 3000);
            this.isLoading = false;
        },
        async module(){
            this.isLoading = true;
            this.permission = await fetchPermisson(this.$route.path);
            this.title = this.permission.title;
            this.back_title = this.permission.modules.title;
            this.link = this.permission.modules.path;
            this.isLoading = false;
        },
        async getRoles(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/roles', config);
                this.roles = response.data.data.roles;
                this.all_roles = response.data.data.all_roles;
                this.organizations = response.data.data.organizations;
                this.organization = response.data.data.firstOrganization
                this.isLoading = false;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        closeAlert(){
            this.is_alert = false;
        },
        createRole(event){
            this.title = event.title;
            this.id = event.id;
            this.organization = event.organization;

            this.save();
        },
        async save(event){
            this.isLoading = true;
            let item = {
                'role': event.role,
                'title': event.title,
                'id': event.id,
                'organization': event.organization,
            }
            let result = await req.saveRole(item);
            if(result.status === 422){
                this.errors = result.data.errors;
                this.isLoading = false;
                this.type = 'red';
                this.message = errorMessages(this.errors)
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }else {
                this.type = 'green';
                this.isLoading = false;
                this.closeModal();
                this.is_alert = true;
                this.message = result.message;
                this.roles = result.roles;
                this.all_roles = result.all_roles;
                this.organization = result.organization;
                setTimeout(() => {this.closeAlert(); }, 3000);
            }
        },
        async filterOrganization(event){
            this.isLoading = true;
            let result = await req.filterOrganization(event.id);
            this.roles = result.roles;
            this.all_roles = result.all_roles;
            this.organization = result.organization;
            this.isLoading = false;
        },
        reset(event){
            this.getRoles();
        },
        clearInput(e){
            this.errors[e] = '';
        },
    },
}
</script>

<style scoped>
.droppable {
    padding: 15px;
    border-radius: 5px;
    background: #2c3e50;
    margin-bottom: 10px;
}
.droppable h4 {
    color: white;
}
.draggable {
    background: white;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
}
.draggable h5 {
    margin: 0;
}
</style>

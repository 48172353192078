<template>
    <div class="popup">
        <div class="popup-content">
            <div class="close float-end" @click="close">
                <span class="material-symbols-rounded">close</span>
            </div>

            <h6 class="mt-2 mb-4 hstack">
                <span class="material-symbols-rounded me-2 opacity-50">rule</span>
                Неисправности
            </h6>

            <div class="vstack justify-content-center mt-0">
                <div
                    v-for="checking in checkings"
                    :key="checking.id">
                    <button
                        class="btn cl_item pad mb-2 btn_vw text-center"
                        :class="goClass(checking.status)"
                        @click="update(checking.id, item_id, checking.status)">
                        {{ checking.name }}
                    </button>
                </div>
            </div>

            <div class="mb-4">
                <label class="form-label fw-normal mb-1">Примечание</label>
                <textarea class="form-control" v-model="comment"></textarea>
            </div>
            
            <button class="btn btn-green w-100 mb-5 mt-3" @click="save(item_id)">Сохранить</button>

        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "typeTestDrive",
    props: {
        checkings: {
            type: Array,
            default: [],
        },
        item_id: null,
        comment: '',
    },
    data: () => ({
        car: [],
    }),
    methods: {
        close() {
            this.$emit('close-form-testdrive')
        },
        update(id, item_id, status){
            this.$emit('update_status', {id:id, item_id:item_id, status:status, comment: this.comment})
        },
        goClass(status){
            return 'type_'+status;
        },
        save(item_id){
            this.$emit('save-checking', {item_id: item_id, comment: this.comment})
        }
    }
}
</script>

<style scoped>

</style>

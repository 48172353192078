<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <Modules
                :modules="modules"
            />
        </div>
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import axios from "axios";
import Modules from "../../Page/Modules";

export default {
    name: "InspectionPage",
    components: {
        spinner, BreadCrumbs, ToastAlert, Modules
    },
    data(){
        return {
            isLoading: false,
            breadCrumbs: true,
            title: 'Осмотр ТС',
            back_title: 'Главное меню',
            link: 'main',
            modules: [],
        }
    },
    created() {
        this.getSubModules();
    },
    methods: {
        async getSubModules(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/users/get/modules/inspections', config);
                this.modules = response.data.data.modules;
            } catch (e) {
                //this.$cookie.removeCookie('token');
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>

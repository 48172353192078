<template>
    <div v-if="isLoggedIn && !is_email_page">
        <Header
            v-if="!hideHeaderAndFooter"
            :user="user"
        />
        <div class="container-fluid main_frame" :style="getWidth(width)">
            <div class="row">
                <router-view></router-view>
                <vue-progress-bar></vue-progress-bar>
            </div>
            <div class="row mt-4 mb-4 text-center" v-if="!hideHeaderAndFooter">
                <div class="version">
                    <span>v.2.0</span>
                </div>
            </div>
        </div>
        <BottomMenu
            v-if="!hideHeaderAndFooter"
            :user="user"
        />
    </div>
    <div v-if="!isLoggedIn && !is_email_page">
        <router-view></router-view>
    </div>

    <!-- <transition-group name="list">
        <div class="popup" v-if="is_modal">
            <div class="popup-content">
                <div class="close float-end" @click="show_versions">
                    <span class="material-symbols-rounded">close</span>
                </div>
                <versions/>
            </div>
        </div>
    </transition-group> -->

</template>

<script>
import axios from "axios";
import Login from './Auth/Login';
import MainPage from './Page/MainPage';
import Header from "./Page/Header";
import BottomMenu from "./Page/BottomMenu";
import * as auth from './services/auth';
import versions from './components/Versions.vue';

export default {
    name: "App",
    components: {
        Login, MainPage, Header, BottomMenu, versions
    },
    data(){
        return{
            hideHeaderAndFooter: false,
            isLoggedIn: false,
            is_email_page: false,
            user: [],
            menus:[],
            sub_menu: [],
            permissions:[],
            role: null,
            width: '1180px',
            is_modal: false,
        }
    },
    watch: {
        // Следим за изменением маршрута
        $route(to) {
            this.checkRoute(to);
        }
    },
    mounted() {
      this.$Progress.finish();
    },
    created() {
        this.checkRoute(this.$route);
        this.$Progress.start();
        this.$router.beforeEach((to, from, next) => {
            if (to.meta.progress !== undefined) {
                let meta = to.meta.progress;
                this.$Progress.parseMeta(meta);
            }
            this.$Progress.start();
            next();
        });
        this.$router.afterEach((to, from) => {
            this.$Progress.finish();
        });

        this.registerServiceWorker()
        this.isLoggedIn = auth.isLoggedIn();
        if(this.isLoggedIn) {
            this.getProfile();
        }
        this.eventBus.on('page-width', this.addWith);
    },
    methods: {
        checkRoute(route) {
            // Указываем имена маршрутов, на которых не нужно показывать Header и BottomMenu
            const routesWithoutHeaderAndFooter = ['PCDisplay'];

            this.hideHeaderAndFooter = routesWithoutHeaderAndFooter.includes(route.name);
        },
        emailPage(){
            this.is_email_page = !this.is_email_page;
        },
        show_versions() {
            this.is_modal = !this.is_modal;
        },
        addWith(width){
            this.width = width;
        },
        async getProfile(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/profile', config);
                this.user = response.data.data.user;
                this.eventBus.emit('auth-user', this.user);
            } catch (e) {
                //this.$cookie.removeCookie('token');
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        getWidth(width){
            return `max-width: ${width};`;
        },

        /**
         * Register the service worker.
         */
        registerServiceWorker () {
            if (!('serviceWorker' in navigator)) {
                console.log('Service workers aren\'t supported in this browser.')
            } else {
                navigator.serviceWorker.register('/service-worker.js')
                .then(() => this.initialiseServiceWorker())
            }
        },

        initialiseServiceWorker () {
            if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
                console.log('Notifications aren\'t supported.')
            }
            if (Notification.permission === 'denied') {
                console.log('The user has blocked notifications.')
                return
            }
            if (!('PushManager' in window)) {
                console.log('Push messaging isn\'t supported.')
                return
            }

            /*navigator.serviceWorker.ready.then(registration => {
                registration.pushManager.getSubscription()
                    .then(subscription => {

                        if (!subscription) {
                            return
                        }

                        this.updateSubscription(subscription)

                        this.isPushEnabled = true
                    })
                    .catch(e => {
                        console.log('Error during getSubscription()', e)
                    })
            })*/
        },
        /**
         * Send a request to the server to update user's subscription.
         *
         * @param {PushSubscription} subscription
         */
        updateSubscription (subscription) {
            const key = subscription.getKey('p256dh')
            const token = subscription.getKey('auth')
            const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

            const data = {
                endpoint: subscription.endpoint,
                publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
                authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
                contentEncoding
            }

            this.loading = true
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/users/push/subscriptions', data, config)
                .then(() => { this.loading = false })
        },

    }
}
</script>

<style scoped>

</style>

<template>
    <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Список прав</h5>
                    <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md">
                            <div class="btn gray mb-3" @click="showAddPermission">Добавить</div>
                            <table class="table table-hover">
                                <thead class="table-light">
                                    <tr>
                                        <th></th>
                                        <th>Право доступа</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in permissions" :key="item.id">
                                        <td
                                            v-if="item.actions.length"
                                        >
                                            <a class="btn gray perm" href="#" @click="showAction(item.id)">Действия</a>
                                        </td>
                                        <td v-else> </td>
                                        <td style="width: 90%;">{{ item.title }}</td>
                                        <td class="text-end">
                                            <div
                                                class="btn btn_drop btn-red"
                                                style="cursor: pointer;"
                                                @click="deletePermission(item.id)">
                                                <fa icon="trash-can" class="icon"/>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn gray" @click="closeModal">Закрыть</button>
                    <button type="button" class="btn btn-green" @click="save">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PermissionModal",
    props: {
        permissions: {
            type: Array,
            default: [],
        },
        moduleId: null,
    },
    methods: {
        closeModal(){
            this.$emit('close-perms-modal')
        },
        showAddPermission(){
            this.$emit('show-add-perm', {moduleId: this.moduleId})
        },
        save(){
            this.$emit('save-perms-modal', {moduleId: this.moduleId})
        },
        deletePermission(id){
            this.$emit('delete-permission', {moduleId: this.moduleId, id: id})
        },
        showAction(id){
            this.$emit('show-modal-action', {id: id})
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="d-flex flex-wrap" v-if="this.defects.length > 0">
                    <h5 class="mb-3"><fa icon="list-check" class="icon" style="padding-right: 10px; color: #c00;"></fa>{{ checkName }}</h5>
                    <div class="col-12 item_title"
                        v-for="defect in defects"
                        :key="defect.id"
                        @click="next(defect.id)"
                    >
                        <!-- Время от и до -->
                        <div class="gn_time_row d-flex justify-content-between">
                            <div class="gn_time_start text-black-50 fs-7">
                                <fa icon="clock" class="icon text-black-50"></fa>
                                {{ defect.date_defect }}
                            </div>
                            <div class="gn_time_arrow">
                                <fa icon="angles-right" class="icon"></fa>
                            </div>
                            <div class="gn_time_end text-black-50 fs-7">Исп. немедленно</div>
                        </div>

                        <!-- Категория -->
                        <div class="gn_item mt-2">
                            <div class="gn_row d-flex gn_inner">
                                <div class="gn_label pe-2 text-black-50"><fa icon="list" class="icon"></fa></div>
                                <div class="gn_value">{{ defect.subcheck.name }}</div>
                            </div>    
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import axios from "axios";
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "CarDefectsPage",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            defects: [],
            checkName: '',
            breadCrumbs: true,
            title: 'Список несоответствий',
            back_title: 'Группы несоответствий',
            link: 'car-defects',
        }
    },
    created() {
        this.getDefects();
    },
    methods:{
        next(id){
            this.$router.push(`/car-defects/${this.$route.params.car_id}/${id}`);
        },
        async getDefects(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/car/get/defects/${this.$route.params.car_id}`, config);
                this.defects = response.data.data.defects;
                this.checkName = response.data.data.checkName;
                if(this.defects.length === 0){
                    this.$router.push('/car-defects');
                }
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>

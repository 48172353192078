<template>
    <button
        :class="['btn', `${color}`, `${add_class}`, {'btn_icon': btn_icon}, `${active}`]"
        :disabled="disabled"
    >
        <slot v-if="btn_icon"><span class="material-symbols-outlined">{{btn_icon}}</span></slot>
        <slot v-else>
            <span class="material-symbols-outlined me-1 opacity-100" v-if="icon">{{icon}}</span>
            {{ caption }}
        </slot>
    </button>
</template>

<script>
export default {
    name: "Button",
    props: {
        caption: {
            type: String,
            default: 'Button',
        },
        color: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false
        },
        icon: {
            type: String,
            required: false,
        },
        btn_icon: {
            required: false,
            type: String,
        },
        add_class: {
            type: String,
            required: false,
        },
        active: {
            type: String,
            default: ''
        },
    }
}
</script>

<style scoped>

</style>

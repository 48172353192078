import * as auth from "./auth";
import axios from "axios";

export const getDepartments  = async (item) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.post(`/api/admin/users/get/departments`, item, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const filterOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/users/organizations/filter/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const destroyUser  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.delete(`/api/admin/users/${id}`, config);
        return response;
    } catch (exception) {
        return exception.response;
    }
};

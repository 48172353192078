import * as auth from '../services/auth';
import TDIndex from "../Modules/TestDrive/TD/TDIndex";
import TDPage from "../Modules/TestDrive/TDPage";
import TDCreate from "../Modules/TestDrive/TD/TDCreate";
import TDContract from "../Modules/TestDrive/TD/TDContract";
import TDChart from "../Modules/TestDrive/TD/TDChart";
import SpoofIndex from "../Modules/TestDrive/Spoof/SpoofIndex";
import SpoofCreate from "../Modules/TestDrive/Spoof/SpoofCreate";
import SpoofContract from "../Modules/TestDrive/Spoof/SpoofContract";
import TDSettings from "../Modules/TestDrive/Settings/TDSettings";
import TDSettingCreate from "../Modules/TestDrive/Settings/TDSettingCreate";
import TDSettingEdit from "../Modules/TestDrive/Settings/TDSettingEdit";
import TDSettingsPage from "../Modules/TestDrive/Settings/TDSettingsPage.vue";
import TDCar from "../Modules/TestDrive/Settings/TDCar.vue";
import TDContracts from "../Modules/TestDrive/Settings/TDContracts.vue";

export default [
    {
        name: 'TDPage',
        path: '/td',
        component: TDPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDIndex',
        path: '/test-drive',
        component: TDIndex,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDCreate',
        path: '/test-drive/create',
        component: TDCreate,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDContract',
        path: '/test-drive/contract/:id/:download',
        component: TDContract,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDChart',
        path: '/test-drive/chart',
        component: TDChart,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'SpoofIndex',
        path: '/spoof',
        component: SpoofIndex,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'SpoofCreate',
        path: '/spoof/create',
        component: SpoofCreate,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'SpoofContract',
        path: '/spoof/contract/:id',
        component: SpoofContract,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDSettingsPage',
        path: '/td/settings',
        component: TDSettingsPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDSettings',
        path: '/td/settings/cron',
        component: TDSettings,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDSettingCreate',
        path: '/td/settings/cron/create',
        component: TDSettingCreate,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDSettingEdit',
        path: '/td/settings/cron/edit/:id',
        component: TDSettingEdit,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDCar',
        path: '/td/settings/car',
        component: TDCar,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TDContracts',
        path: '/td/settings/contracts',
        component: TDContracts,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]

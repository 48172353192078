import * as auth from "./auth";
import axios from "axios";

export const getCars  = async () => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/testdrives/settings/get/cars`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const getOrganizationCars  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/testdrives/get/organization/cars/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const createCar = async(items) => {
    const config = { 'headers': { 'Authorization': 'Bearer ' + auth.getCookie() } };
    try {
        const response = await axios.post(`/api/admin/cars`, {
            id: items.id,
            organization: items.organization,
            brand: items.brand,
            model: items.model,
            year_release: items.year_release,
            configuration: items.configuration,
            price: items.price,
            vin_code: items.vin_code,
            passport: items.passport,
            passport_from: items.passport_from,
            car_number: items.car_number,
            mileage: items.mileage,
            color: items.color,
            motor: items.motor,
            bodywork: items.bodywork,
            kpp: items.kpp,
            steering_wheel: items.steering_wheel,
            actuator: items.actuator,
            engine_capacity: items.engine_capacity,
            testdrive: items.testdrive,
            spoof: items.spoof,
        }, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const keysIssued  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.post(`/api/admin/testdrives/keys/issued`, {id:id}, config);
        return response;
    } catch (exception) {
        return exception.response;
    }
};

export const saveTestDriveContract  = async (item) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {

        let formData = new FormData();
        let data = {
            id: item.id,
            item: item.item,
            organization: item.organization,
        }
        formData.append('file', item.file);
        formData.append('data', JSON.stringify(data));
        const response = await axios.post(`/api/admin/testdrives/settings/save/templates`, formData, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const getTestDriveContract  = async () => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/testdrives/settings/get/templates`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

<script setup>
const emits = defineEmits(['update:checked', 'updateCheckboxGroup'])
const props = defineProps({
    name: {
        type: String,
        default: ''
    },
    id: {
        type: String,
        default: ''
    },
    value: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: ''
    },
    checked: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    group: {
        type: Boolean,
        default: false
    },
    type: {
        type: String,
        default: 'checkbox'
    }
})

const handleClick = (event) => {
    if (props.group) {
        emits('updateCheckboxGroup', {optionId: props.id, checked: event.target.checked})
    } else {
        emits('update:checked', event.target.checked)
    }
}
</script>

<template>
    <div class="form-check form-check-inline ps-4" :class="[{'form-switch': type === 'switch'}]">
        <label :for="id" class="form-label" v-if="label">{{label}}</label>
        <input
            class="form-check-input"
            type="checkbox"
            :name="name"
            :id="id"
            :value="value"
            :checked="checked"
            :disabled="disabled"
            @input="handleClick($event)">
    </div>
</template>

<style lang="scss" scoped>


</style>

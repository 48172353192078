import * as auth from "../services/auth";
import Inspections from "../Modules/InspectionTS/Inspections";
import InspectionQuestions from "../Modules/InspectionTS/InspectionQuestions";
import InspectionPage from "../Modules/InspectionTS/InspectionPage";
import InspectionCheckList from "../Modules/InspectionTS/InspectionCheckList";
import InspectionCheckPage from "../Modules/InspectionTS/InspectionCheckPage";
import InspectionItemCheckList from "../Modules/InspectionTS/InspectionItemCheckList";
import InspectionSettings from "../Modules/InspectionTS/InspectionSettings";

export default [
    {
        name: 'InspectionPage',
        path: '/inspections',
        component: InspectionPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Inspections',
        path: '/inspections/index',
        component: Inspections,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'InspectionCheckList',
        path: '/inspections/index/:id',
        component: InspectionCheckList,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'InspectionItemCheckList',
        path: '/inspections/index/:id/:item',
        component: InspectionItemCheckList,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'InspectionCheckPage',
        path: '/inspections/index/:id/:item/:sub_item',
        component: InspectionCheckPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'InspectionQuestions',
        path: '/inspections/questions',
        component: InspectionQuestions,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'InspectionSettings',
        path: '/inspections/settings',
        component: InspectionSettings,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]

<template>
    <div class="col-12 d-flex flex-wrap modules mt-3">

            <div class="col-12 col-lg-4 col-sm-6 col-xl-3 module_box pb-3"
                v-for="module in modules"
                :key="module.id">
                <router-link :to="'/'+module.path" class="d-flex h-100 justify-content-between module">
                    <div class="d-flex module_icon">
                        <span class="material-symbols-rounded">{{ module.icon }}</span>
                    </div>
                    <div class="d-flex flex-column module_titles text-end">
                        <div class="module_title mb-1">{{ module.title }}
                            <span class="badge rounded-pill text-bg-white ms-2" v-if="module.requestCount > 0">{{ module.requestCount }}</span>
                        </div>
                        <div class="sub_module" v-if="module.sub_modules.length > 0">
                            <div
                                v-for="item in module.sub_modules"
                                :key="item.id"
                                class="d-none d-md-block"
                            >{{ item.title }}</div>
                        </div>
                    </div>
                </router-link>
            </div>

<!--        <template v-else>
            <div
                class="col-12 col-lg-4 col-sm-6 col-xl-3 module_box pb-3"
                v-for="module in modules"
                :key="module.id">
                <router-link :to="'/'+module.path" class="d-flex h-100 justify-content-between module"
                    v-if="module.title == 'Заявки' || module.title == 'Платежи' || module.title == 'Аванс' ||
                    module.title == 'Служебные записки' || module.title == 'Контрагенты'"
                >
                    <div class="d-flex module_icon">
                        <span class="material-symbols-rounded">{{ module.icon }}</span>
                    </div>
                    <div class="d-flex flex-column module_titles text-end">
                        <div class="module_title mb-1">{{ module.title }}
                            <span class="badge rounded-pill text-bg-white ms-2" v-if="module.count > 0">{{ module.count }}</span>
                        </div>
                        <div class="sub_module" v-if="module.sub_modules.length > 0">
                            <div
                                v-for="item in module.sub_modules"
                                :key="item.id"
                                class="d-none d-md-block"
                            >{{ item.title }}</div>
                        </div>
                    </div>
                </router-link>
                <div v-else class="d-flex h-100 justify-content-between module" @click="showModal(module.title)">
                    <div class="d-flex module_icon">
                        <span class="material-symbols-rounded">{{ module.icon }}</span>
                    </div>
                    <div class="d-flex flex-column module_titles text-end">
                        <div class="module_title mb-1">{{ module.title }}
                            <span class="badge rounded-pill text-bg-white ms-2" v-if="module.count > 0">{{ module.count }}</span>
                        </div>
                        <div class="sub_module" v-if="module.sub_modules.length > 0">
                            <div
                                v-for="item in module.sub_modules"
                                :key="item.id"
                                class="d-none d-md-block"
                            >{{ item.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>-->
    </div>

    <!-- >>>>>>>>>>>>>>>> Second Modules <<<<<<<<<<<<<<<<<  -->
    <!-- <div class="menu-links mt-4">
        <div v-for="module in modules" :key="module.id" class="col-4 col-md-3 col-xl-2 p-2 mb-4">
            <router-link :to="'/'+module.path" class="text-center vstack module-link">
                <span class="module-icon-box">
                    <span class="badge rounded-pill text-bg-white ms-2" v-if="module.count > 0">{{ module.count }}</span>
                    <span class="material-symbols-rounded module-icon">{{ module.icon }}</span>
                </span>
                <span class="module-link-title lh-1">
                    {{ module.title }}
                </span>
            </router-link>
        </div>
    </div> -->
    <!-- >>>>>>>>>>>>>>>> Second Modules <<<<<<<<<<<<<<<<<  -->

    <transition-group name="list">
        <div class="modal d-block" v-if="is_modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="link">
                            <h5>Модуль {{name_module}} не доступен! <br> Перейдите по ссылке на старую версию приложения.</h5>
                            <a v-if="link" :href="link" target="_blank">Перейти</a>
                        </div>
                        <h5 v-else>Модуль {{name_module}} не доступен!</h5>
                    </div>
                </div>
            </div>
        </div>
    </transition-group>


</template>

<script>
import axios from "axios";

export default {
    name: "Modules",
    props: {
        modules: {
            type: Array,
            default: [],
        },
    },
    data(){
        return {
            is_modal: false,
            name_module: '',
            link: '',
            user: [],
        }
    },
    mounted() {
        this.getProfile();
    },
    methods: {
        async getProfile(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/profile', config);
                this.user = response.data.data.user;
            } catch (e) {
                //this.$cookie.removeCookie('token');
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        showModal(title) {
            this.is_modal = true;
            if(title == 'Test Drive'){
                this.link = 'https://td.tkst.kz/';
                this.name_module = 'Test Drive';
            }else if(title == 'TSM'){
                this.link = 'https://tsm.tkst.kz/';
                this.name_module = 'Чек листы';
            }else{
                this.link = '';
                this.name_module = title;
            }
        },
        closeModal(){
            this.is_modal = false;
            this.name_module = '';
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <label class="form-label">Организация*</label>
    <VueMultiselect
        v-model="organization"
        placeholder="Выберите организацию"
        label="name"
        track-by="id"
        :options="organizations"
        :show-labels="false"
    ></VueMultiselect>
    <!-- Название отдела -->
    <Input v-model:modelValue="name" @clear="clearInput($event)" :error="[errors.name.length ? 'error' : '']" label="Название отдела" name="name" type="text" placeholder="Название отдела" id="name"/>
    <!-- Аббревиатура -->
    <Input v-model:modelValue="abbreviated" @clear="clearInput($event)" label="Аббревиатура отдела" name="abbreviated" type="text" placeholder="Аббревиатура отдела" id="abbreviated"/>

    <Button
        caption="Сохранить"
        color="btn-green"
        add_class=""
        @click="save()"
    />

</template>

<script>
import Input from "../../components/Input.vue";
import Button from "../../components/Button.vue";
import VueMultiselect from "vue-multiselect";

export default {
    name: "FormDepartment",
    components: {
        Input, Button, VueMultiselect
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        abbreviated: {
            type: String,
            default: ''
        },
        organization: null,
        id: 0,
        organizations: [],
        errors: {
            name: '',
            organization: '',
        }
    },
    methods: {
        save() {
            this.$emit('save',{
                id: this.id,
                name: this.name,
                abbreviated: this.abbreviated,
                organization: this.organization
            })
        },
        clearInput(e){
            this.errors[e] = '';
        },
    }
}
</script>

<style scoped>

</style>

import {VueCookieNext} from "vue-cookie-next";

window._ = require('lodash');
import Echo from 'laravel-echo';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.Pusher = require('pusher-js');

    window.axios = require('axios');

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.MIX_PUSHER_APP_KEY,
        cluster: process.env.MIX_PUSHER_APP_CLUSTER,
        forceTLS: true,
        authEndpoint: '/api/broadcasting/auth',
        auth: {
            headers: {
                Authorization: "Bearer " + VueCookieNext.getCookie('token')
            }
        }
    });
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    // require('bootstrap');
    //require('vuetify');
} catch (e) {
    console.log(e);
}



/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */







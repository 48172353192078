<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="d-flex flex-column justify-content-center mt-0">
                <div class="mt-2 mb-2 text-center">
                    <router-link class="btn btn-red btn_vw" to="/td/settings/car">
                        Автомобили
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center">
                    <router-link class="btn btn-red btn_vw" to="/td/settings/cron">
                        Общие
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center">
                    <router-link class="btn btn-red btn_vw" to="/td/settings/contracts">
                        Договора
                    </router-link>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "TDSettingsPage",
    components: {spinner, BreadCrumbs},
    data() {
        return {
            isLoading: false,
            breadCrumbs: true,
            title: 'Настройки',
            back_title: 'Меню',
            link: 'td',
        }
    },
}
</script>

<style scoped>

</style>

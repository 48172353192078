export default {
    request: {
        button: {
            save: 'Save',
            delete: 'Delete',
            history: 'History',
            processing: 'Processing',
            modified_amount: 'Change the amount',
            approve: 'Approve',
            deny: 'Deny',
            approve_with_change: 'Approve with change',
            cancel: 'Cancel',
            accept_to_work: 'Accept to work',
            download_document: 'Download the document',
            to_perform: 'To perform',
            revision: 'Revision',
            for_revision: 'For revision',
            reject: 'Reject',
            filter: 'Filter',
            reset: 'Reset',
            complete: 'Complete',
            approval: 'Approval',
            approval_in_order: 'Approval in the order',
            allow_purchase: 'Allow purchase',
            to_send: 'To send',
            executed: 'Executed',
            agreement: 'Agreement',
            edit: 'Edit',
            add: 'Add',
            return_to_work: 'Return to work',
            download: 'Download',
            create: 'Create',
            statistics: 'Statistics',
            issue_advance: 'Issue an advance'
        },
        advance: 'Аdvance',
        request: 'Request',
        request_menu: 'Request menu',
        advance_section: 'Advance section',
        get_advance: 'Get an advance',
        my_requests: 'My requests',
        awaiting_processing: 'Awaiting processing',
        awaiting_approval: 'Awaiting approval',
        awaiting_chief_accountant: 'Awaiting approval of the chief accountant',
        list_requests: 'List of requests',
        journal: 'Journal',
        time_reception: 'The time for accepting applications is MON-SAT from 8:00 to 17:00',
        advance_payment_amount: 'Advance payment amount',
        method_obtaining: 'Method of obtaining',
        specify_method_obtaining: 'Specify the method of issuing the advance',
        money: 'In cash',
        card: 'To the map',
        total_requests: 'Total requests',
        the_amount: 'For the amount of',
        created: 'Created',
        amount: 'The amount',
        debt: 'Arrears',
        earned: 'Earned',
        current_status: 'Current status',
        list_requests_is_empty: 'The list of applications is empty',
        worker: 'Worker',
        earned_today: 'Earned for today',
        arrears_today: 'Arrears for today',
        comment: 'Comment',
        modified_amount: 'Change the amount',
        new: 'New',
        in_work: 'In work',
        сompleted: 'Completed',
        no_new_requests: 'There are no new requests',
        in_work_requests: 'There are no requests in the work',
        completed_requests: 'There are no completed requests',
        status: 'Status',
        payments: 'Payments',
        to_create: 'To create',
        my_payments: 'My payments',
        waiting: 'Waiting',
        create_payment: 'Create a payment',
        edit_payment: 'Edit Payment',
        new_request: 'New request',
        urgent_payment: 'Urgent payment',
        request_payment_document: 'Request a payment document',
        request_power_attorney: 'Request a power of attorney',
        select_counterparty: 'Select a counterparty',
        payment_amount: 'Payment amount',
        select_currency: 'Select currency',
        payment_type: 'Payment type',
        select_category: 'Select a category',
        attach_files: 'Attach files',
        attach: 'Attach',
        counterparty: 'Counterparty',
        category: 'Category',
        categories: 'Categories',
        category_name: 'Category name',
        pay: 'pay',
        provide_payment_card: 'Provide a payment card',
        payment_document: 'Payment document',
        provide_power_attorney: 'Provide a power of attorney',
        attorney: 'Attorney',
        applicant: 'Applicant',
        attachments: 'Attachments',
        attachments_from: 'Attachments from',
        appointment: 'Appointment',
        edit: 'Edit',
        actions: 'Actions',
        payment_number: 'Payment number',
        memos: 'Memos',
        my_memos: 'My memos',
        new_memo: 'New memo',
        select_memos: 'Select a memos',
        content: 'Content',
        select_employee: 'Select an employee',
        routes: 'Routes',
        advance_imprest_settings: 'Advance Payment Settings',
        memo_settings: 'Memo Settings',
        settings: 'Settings',
        title: 'Title',
        document_name: 'Document name',
        list_responsible_persons: 'List of responsible persons',
        role_chief_accountant: 'The role of the Chief accountant',
        role_accountant: 'The role of an accountant',
        cashier_role: 'Cashier\'s role',
        hr_role: 'The role of the HR Department',
        supplier_role: 'The role of the supplier',
        add: 'Add',
        add_responsible: 'Add a responsible person',
        choose_responsible: 'Choose a responsible person',
        responsible: 'responsible',
        settings_saved: 'Settings saved',
        memo_approved: 'The memo is approved!',
        memo_return: 'The memo has been sent for revision!',
        memo_rejected: 'The memo was rejected!',
        memo_executed: 'The memo has been executed!',
        memo_accept: 'The memo has Been Approved!',
        memo_sent: 'The memo has been sent!',
        request_sent: 'The application has been sent!',
        memo_approval: 'The memo has been sent for approval!',
        memo_deleted: 'The memo has been deleted!',
        not_possible_apply_time: 'Sorry, it is not possible to apply at this time!',
        request_delete: 'The request has been successfully deleted!',
        request_added: 'Request added!',
        request_approved: 'Request approved!',
        request_revision: 'Request has been sent for revision!',
        request_rejected: 'Application rejected!',
        request_completed: 'Request completed!',
        request_work: 'Request has been accepted for work!',
        information_provided: 'Thank you for the information provided!',
        list_counterparties: 'List of counterparties',
        application_restored: 'The application has been restored to work',
    },
    organization: 'Организация'
}

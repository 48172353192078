import * as auth from "./auth";
import axios from "axios";

export const filterOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/roles/filter/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const saveRole = async (item) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {

        const response = await axios.post('/api/admin/roles', item, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const deleteRole  = async (data) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.post(`/api/admin/roles/delete`, data, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

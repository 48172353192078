<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page d-flex flex-column justify-content-center mt-0" v-if="inspection">
                <!-- номер, дата, статус -->
                <div class="item_title">
                    <!-- номер, дата, статус -->
                    <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-2">
                        <div class="fs-6 fw-bold">VIN: {{ inspection.car.vin_code }}</div>
                    </div>

                    <div class="col-12 border-bottom mb-2 pb-2">
                        <div class="d-flex">
                            <span class="material-symbols-rounded opacity-50 me-2">directions_car</span>
                            <span class="fw-normal">{{ inspection.car.brands.name_brand }} {{ inspection.car.models.name_model }}</span>
                        </div>
                    </div>

                    <div class="col-12 border-bottom mb-2 pb-2">
                        <div class="d-flex">
                            <span class="material-symbols-rounded opacity-50 me-2">file_present</span>
                            <a class="defect_image text-dark" data-fancybox="gallery" :href="`${ inspection.ttn.path }`">ТТН автомобиля</a>
                        </div>
                    </div>
                </div>
                <div class="block_item">
                    <div class="mb-2" v-for="(checklist, index) in inspection.data" :key="checklist.id">
                        <button
                            class="cl_item little w-100"
                            @click="next(checklist, index)"
                            :class="goClass(checklist.status)"
                        >
                            {{ checklist.name }}
                        </button>
                    </div>
                    <button class="btn btn-green mt-4 btn_vw mb-4"
                        :disabled="!is_active || inspection.status === 'completed'"
                        @click="save"
                    >СОХРАНИТЬ</button>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import axios from "axios";

export default {
    name: "InspectionCheckList",
    components: {
        spinner, BreadCrumbs, ToastAlert
    },
    data(){
        return {
            is_alert: false,
            message: '',
            type: 'green',
            inspection: null,
            is_active: false,
            isLoading: false,
            is_two_step: false,
            breadCrumbs: true,
            title: 'Чек лист осмотра',
            back_title: 'Список осмотров',
            link: 'inspections/index',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/inspection/show/${this.$route.params.id}`, config);
                this.inspection = response.data.data.inspection;
                this.is_active = response.data.data.is_active;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        next(checklist, index){
            if(this.inspection.data[index-1] !== undefined && this.inspection.data[index-1].status === 'new'){
                this.message = 'Предыдущий этап не пройден';
                this.type = 'yellow';
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 4000);
            }else {
                this.$router.push({ name: 'InspectionItemCheckList', params: { id: this.$route.params.id, item: checklist.id }});
            }
            /*if(checklist.children){
                this.$router.push({ name: 'InspectionItemCheckList', params: { id: this.$route.params.id, item: checklist.id }});
            }else{
                this.$router.push({ name: 'InspectionCheckPage', params: { id: this.$route.params.id, item: checklist.id, sub_item: 0 }});
            }*/
        },
        goClass(status_check) {
            return `type_${status_check}`;
        },
        save(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/inspection/complete', {
                id: this.$route.params.id,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.$router.push(`/inspections/index`)
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        closeAlert(){
            this.is_alert = false;
        },
    }
}
</script>

<style scoped>

</style>

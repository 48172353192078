import { createStore } from "vuex";
import * as auth from './services/auth';

export default createStore({
    state: {
        isLoggedIn: null,
        profile: {},
    },
    getters: {
        profile:(profile) => {
            return profile;
        },
    },
    mutations: {
        authenticate(state, payload) {
            state.isLoggedIn = auth.isLoggedIn();
            if (state.isLoggedIn) {
                state.profile = payload;
            } else {
                state.profile = {};
            }
        },
    },
    actions: {
        authenticate(context, payload) {
            context.commit('authenticate', payload);
        },
    }
});

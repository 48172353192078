<template>
    <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Список действий</h5>
                    <button type="button" class="btn-close close_modal" @click="close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md">
                            <table class="table table-hover">
                                <thead class="table-light">
                                <tr>
                                    <th>Название</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="action in all_actions" :key="action.id">
                                    <td style="width: 90%;">{{ action.name }}</td>
                                    <td class="text-center">
                                        <label class="cb-container">
                                            <input
                                                name="menu"
                                                type="checkbox"
                                                class="check-highload"
                                                v-model="items"
                                                :id="action.id"
                                                :value="action.id"/>
                                            <span class="highload2"></span>
                                        </label>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn gray" @click="close">Закрыть</button>
                    <button type="button" class="btn btn-green" @click="saveAction">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddAction",
    data(){
        return {
            items: [],
        }
    },
    props: {
        all_actions: {
            type: Array,
            default: [],
        },
        permissionId: null,
    },
    methods: {
        close(){
            this.$emit('close-add-action')
        },
        saveAction(){
            this.$emit('save-action', {items: this.items, permissionId: this.permissionId})
        }
    }
}
</script>

<style scoped>

</style>

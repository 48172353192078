import request from './request';
import modules from './modules';
import header from "./header";
import users from "./users";
import validate from "./validate";

export default {
    ...request,
    ...modules,
    ...header,
    ...users,
    ...validate
}

<template>
  <spinner v-if="isLoading"></spinner>
  <transition name="fade">
    <div>
      <BreadCrumbs
        :breadCrumbs="breadCrumbs"
        :title="title"
        :back_title="back_title"
        :link="link"
      />
      <div class="page">
        <h5 class="py-2">Список заявок на аванс</h5>

        <!-- Новые, В работе, Выполнено -->
        <ul class="nav nav-pills gap-1 mb-3">
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link fs-7"
                    id="first-tab"
                    data-bs-target="#home"
                    :class="goActive('first')"
                    @click="changeTab('first')">
                    {{ $t("request.new") }}
                    <span
                        class="badge rounded-pill text-bg-white"
                        v-if="new_lists_count > 0">
                        {{ new_lists_count }}
                    </span>
                </button>
            </li>

            <li class="nav-item" role="presentation">
                <button
                    class="nav-link fs-7"
                    id="completed-tab"
                    :class="goActive('completed')"
                    @click="changeTab('completed')">
                    {{ $t("request.сompleted") }}
                    <span
                        class="badge rounded-pill text-bg-white"
                        v-if="completed_count > 0">{{ completed_count }}</span >
                </button>
            </li>
        </ul>

        <!-- Вкладки -->
        <div class="tab-content">
          <!-- Новые -->
          <div class="tab-pane fade show" id="first" :class="goTabActive('first')">
              <template v-if="is_generalnyi">
                  <div v-if="Object.keys(new_lists).length">
                      <div class="accordion accordion-flush mb-2" :id="'accordionFlushExample-'+ items.id" v-for="(items) in new_lists" :key="items.id">
                          <div class="accordion-item p-3 rounded">
                              <h2 class="accordion-header" id="flush-headingOne">
                                  <button class="accordion-button collapsed p-0 fs-7" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne-'+ items.id" aria-expanded="false" aria-controls="flush-collapseOne">
                                      <h3>{{ items.name }}</h3>
                                  </button>
                              </h2>
                              <div :id="'flush-collapseOne-'+ items.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" :data-bs-parent="'#accordionFlushExample-'+ items.id">
                                  <div v-if="Object.keys(items.requests).length">
                                      <div class="item_title mb-2" v-for="list in items.requests" :key="list.id">
                                        <!-- Номер, дата -->
                                        <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                                          <div class="fs-6 fw-bold">№{{ list.id }}</div>
                                          <div>{{ moment(list.created_at).format("DD.MM.YYYY hh:mm") }}</div>
                                        </div>

                                        <!-- Сотрудник -->
                                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.worker") }}</span>
                                          <span class="text-end fw-semibold">{{ list.user.surname }} {{ list.user.name }}</span>
                                        </div>

                                        <!-- Сумма -->
                                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.amount") }}</span>
                                          <span class="text-end fw-semibold">{{ cur(list.payment_amount) }}</span>
                                        </div>

                                        <!-- Задолженность -->
                                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.debt") }}</span>
                                          <span class="text-end fw-semibold">{{ cur(list.debt) }}</span>
                                        </div>

                                        <!-- Заработано -->
                                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.earned") }}</span>
                                          <span class="text-end fw-semibold">{{ cur(list.earned) }}</span>
                                        </div>

                                        <!-- Способ получения -->
                                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
                                          <span class="text-end fw-semibold">{{ list.payment_type }}</span>
                                        </div>

                                        <!-- Кнопки -->
                                        <button
                                            class="btn btn_vw btn-green mt-2 mb-2"
                                            @click="save(list.id)"
                                        >
                                            <span class="material-symbols-rounded opacity-50 me-1">check</span>
                                            {{ $t("request.button.issue_advance") }}
                                        </button>
                                        <button
                                          class="btn btn_vw btn-yellow mt-2 mb-2"
                                          @click="downloadImprest(list.id)"
                                        >
                                          <span class="material-symbols-rounded opacity-50 me-1">download</span>
                                          {{ $t("request.button.download_document") }}
                                        </button>
                                        <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                                          <span class="material-symbols-rounded opacity-50 me-1">history</span>
                                          {{ $t("request.button.history") }}
                                        </button>
                                      </div>
                                    </div>
                                    <div v-else>
                                      <div class="item_title mb-3">
                                        {{ $t("request.no_new_requests") }}
                                      </div>
                                    </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </template>
              <template v-else>
                  <div v-if="Object.keys(new_lists).length">
                      <div class="item_title mb-2" v-for="list in new_lists" :key="list.id">
                          <!-- Номер, дата -->
                          <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                              <div class="fs-6 fw-bold">№{{ list.id }}</div>
                              <div>{{ moment(list.created_at).format("DD.MM.YYYY hh:mm") }}</div>
                          </div>

                          <!-- Сотрудник -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.worker") }}</span>
                              <span class="text-end fw-semibold">{{ list.user.surname }} {{ list.user.name }}</span>
                          </div>

                          <!-- Сумма -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.amount") }}</span>
                              <span class="text-end fw-semibold">{{ cur(list.payment_amount) }}</span>
                          </div>

                          <!-- Задолженность -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.debt") }}</span>
                              <span class="text-end fw-semibold">{{ cur(list.debt) }}</span>
                          </div>

                          <!-- Заработано -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.earned") }}</span>
                              <span class="text-end fw-semibold">{{ cur(list.earned) }}</span>
                          </div>

                          <!-- Способ получения -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
                              <span class="text-end fw-semibold">{{ list.payment_type }}</span>
                          </div>

                          <!-- Кнопки -->
                          <button
                              class="btn btn_vw btn-green mt-2 mb-2"
                              @click="save(list.id)"
                          >
                              <span class="material-symbols-rounded opacity-50 me-1">check</span>
                              {{ $t("request.button.issue_advance") }}
                          </button>
                          <button
                              class="btn btn_vw btn-yellow mt-2 mb-2"
                              @click="downloadImprest(list.id)"
                          >
                              <span class="material-symbols-rounded opacity-50 me-1">download</span>
                              {{ $t("request.button.download_document") }}
                          </button>
                          <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                              <span class="material-symbols-rounded opacity-50 me-1">history</span>
                              {{ $t("request.button.history") }}
                          </button>
                      </div>
                  </div>
                  <div v-else>
                      <div class="item_title mb-3">
                          {{ $t("request.no_new_requests") }}
                      </div>
                  </div>
              </template>
          </div>

          <!-- Выполнено -->
          <div class="tab-pane fade" id="completed" :class="goTabActive('completed')">
              <template v-if="is_generalnyi">
                  <div v-if="Object.keys(completed).length">
                      <div class="accordion accordion-flush mb-2" :id="'accordionFlushExample-'+ items.id" v-for="(items) in completed" :key="items.id">
                          <div class="accordion-item p-3 rounded">
                              <h2 class="accordion-header" id="flush-headingOne">
                                  <button class="accordion-button collapsed p-0 fs-7" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne-'+ items.id" aria-expanded="false" aria-controls="flush-collapseOne">
                                      <h3>{{ items.name }}</h3>
                                  </button>
                              </h2>
                              <div :id="'flush-collapseOne-'+ items.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" :data-bs-parent="'#accordionFlushExample-'+ items.id">
                                  <div v-if="Object.keys(items.requests).length">
                                      <div class="item_title mb-2" v-for="list in items.requests" :key="list.id">
                                        <!-- Номер, дата -->
                                        <div
                                          class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3"
                                        >
                                          <div class="fs-6 fw-bold">№{{ list.id }}</div>
                                          <div>{{ moment(list.created_at).format("DD.MM.YYYY hh:mm") }}</div>
                                        </div>

                                        <!-- Сотрудник -->
                                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.worker") }}</span>
                                          <span class="text-end fw-semibold"
                                            >{{ list.user.surname }} {{ list.user.name }}</span
                                          >
                                        </div>

                                        <!-- Сумма -->
                                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                            <span class="fw-normal">{{ $t("request.amount") }}</span>
                                            <span class="text-end fw-semibold">{{ cur(list.payment_amount) }}</span>
                                          </div>

                                        <!-- Задолженность -->
                                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.debt") }}</span>
                                          <span class="text-end fw-semibold">{{ cur(list.debt) }}</span>
                                        </div>

                                        <!-- Заработано -->
                                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                          <span class="fw-normal">{{ $t("request.earned") }}</span>
                                          <span class="text-end fw-semibold">{{ cur(list.earned) }}</span>
                                        </div>

                                          <!-- Способ получения -->
                                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                            <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
                                            <span class="text-end fw-semibold">{{ list.payment_type }}</span>
                                          </div>

                                          <!-- Кнопки -->
                                        <div class="col-sm position-relative mb-2 mt-4">
                                          <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                                            <span class="material-symbols-rounded opacity-50 me-1">history</span>
                                            {{ $t("request.button.history") }}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-else>
                                      <div class="item_title mb-3">
                                        {{ $t("request.no_new_requests") }}
                                      </div>
                                    </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </template>
              <template v-else>
                  <div v-if="Object.keys(completed).length">
                      <div class="item_title mb-2" v-for="list in completed" :key="list.id">
                          <!-- Номер, дата -->
                          <div
                              class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3"
                          >
                              <div class="fs-6 fw-bold">№{{ list.id }}</div>
                              <div>{{ moment(list.created_at).format("DD.MM.YYYY hh:mm") }}</div>
                          </div>

                          <!-- Сотрудник -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.worker") }}</span>
                              <span class="text-end fw-semibold"
                              >{{ list.user.surname }} {{ list.user.name }}</span
                              >
                          </div>

                          <!-- Сумма -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.amount") }}</span>
                              <span class="text-end fw-semibold">{{ cur(list.payment_amount) }}</span>
                          </div>

                          <!-- Задолженность -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.debt") }}</span>
                              <span class="text-end fw-semibold">{{ cur(list.debt) }}</span>
                          </div>

                          <!-- Заработано -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.earned") }}</span>
                              <span class="text-end fw-semibold">{{ cur(list.earned) }}</span>
                          </div>

                          <!-- Способ получения -->
                          <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                              <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
                              <span class="text-end fw-semibold">{{ list.payment_type }}</span>
                          </div>

                          <!-- Кнопки -->
                          <div class="col-sm position-relative mb-2 mt-4">
                              <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                                  <span class="material-symbols-rounded opacity-50 me-1">history</span>
                                  {{ $t("request.button.history") }}
                              </button>
                          </div>
                      </div>
                  </div>
                  <div v-else>
                      <div class="item_title mb-3">
                          {{ $t("request.no_new_requests") }}
                      </div>
                  </div>
              </template>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <transition-group name="list">
    <history v-if="is_history" :historis="historis" @close-form="close" />
  </transition-group>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import { ErrorMessage, Field, Form } from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import History from "./History";
import moment from "moment";

export default {
  name: "ImprestCashierList",
  components: {
    History,
    spinner,
    BreadCrumbs,
    Modules,
    Field,
    Form,
    ErrorMessage,
    VueMultiselect,
  },
  data() {
    return {
      completed_count: 0,
      new_lists_count: 0,
      comment: "",
      is_history: false,
      new_lists: [],
      completed: [],
      url: "",
      is_generalnyi: false,
      isLoading: false,
      breadCrumbs: true,
      title: this.$t("request.list_requests"),
      back_title: this.$t("request.advance_section"),
      link: "request/imprest",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    moment,
    changeTab(tab) {
      this.getData();
      this.$router.push({ params: { active: tab } });
    },
    goActive(tab) {
      if (this.$route.params.active == tab) {
        return "active";
      } else {
        return "";
      }
    },
    goTabActive(tab) {
      if (this.$route.params.active == tab) {
        return "show active";
      } else {
        return "";
      }
    },
    show(id) {
      this.in_work[id].is_show = true;
    },
    close() {
      this.is_history = false;
    },
    getHistory(data) {
      this.historis = data;
      this.is_history = true;
    },
    save(id){
        this.isLoading = true;
        const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};

        axios.post('/api/admin/imprest/cashier/work/save', {
            id: id,
            //comment: this.comment,
        }, config)
            .then(response => {
                this.isLoading = false;
                if (response.status == 200) {
                    this.getData();
                }
            })
            .catch(errors => {
                this.isLoading = false;
                console.log(errors);
            });
    },
    async getData() {
        this.isLoading = true;
        const config = {
          headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
        };
        const response = await axios.get("/api/admin/imprest/list/cashier", config);
        this.new_lists = response.data.data.lists;
        this.completed = response.data.data.completed;
        this.is_generalnyi = response.data.data.is_generalnyi;
        this.completed_count = response.data.data.completed_count;
        this.new_lists_count = response.data.data.new_lists_count;
        this.isLoading = false;
    },
    cur(val) {
      if (!val) {
        return "0 KZT";
      }
      const format = new Intl.NumberFormat("ru-Ru", {
        style: "currency",
        currency: "KZT",
        minimumFractionDigits: 0,
      });
      return format.format(parseInt(val));
    },
    downloadURL(url) {
      let count = 0;
      let hiddenIFrameID = "hiddenDownloader" + count++;
      var iframe = document.createElement("iframe");
      iframe.id = hiddenIFrameID;
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      iframe.src = url;
    },
    async downloadImprest(id) {
      try {
        this.isLoading = true;
        const config = {
          headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
        };
        const response = await axios.get(`/api/admin/imprest/download/${id}`, config);
        this.url = response.data.data.url;
        this.downloadURL(this.url);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.tab-content {
  padding: unset !important;
}
.nav-tabs {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
}
</style>

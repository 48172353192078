<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="item_title">
                    <h5 class="mb-3">Договор № {{ contract_number }}</h5>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Автомобиль</div>
                        <div class="fw-semibold text-end">{{ car }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Гос. номер</div>
                        <div class="fw-semibold text-end">{{ car_number }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Менеджер</div>
                        <div class="fw-semibold text-end">{{ manager }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Клиент</div>
                        <div class="fw-semibold text-end">{{ client }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Телефон</div>
                        <div class="fw-semibold text-end">{{ phone }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Уд.л.</div>
                        <div class="fw-semibold text-end">{{ id_number }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">ИИН</div>
                        <div class="fw-semibold text-end">{{ IIN }}</div>
                    </div>

                    <div class="hstack justify-content-between border-bottom mb-2 pb-2">
                        <div class="fs-7 fw-normal text-black-50">Вод.уд.</div>
                        <div class="fw-semibold text-end">{{ driver_id_number }}</div>
                    </div>

                    <div>
                        <div class="fs-7 fw-normal text-black-50 w-100">Примечание</div>
                        <div class="bg-opacity-25 fw-normal mt-1 p-2 pay_journal_comment w-100 mb-2 lh-base">{{ comment }}</div>
                    </div>

                    <!-- История -->
                    <h6 class="border-top mt-3 pt-3">{{ $t('request.button.history')}}</h6>
                    <div class="col-12">
                        <div v-for="item in history" :key="item" class="hstack justify-content-between fs-7 py-1 border-bottom">
                            <div class="col-5">{{ item.date }}</div>
                            <div class="col-7 fw-normal text-end">{{ item.text }}</div>
                        </div>
                    </div>

                    <!-- Фотогафии -->
                    <div class="col-12">
                        <div class="mb-3">
                            <h6 class="mt-3 pt-3">Фото при выдаче</h6>
                            <div class="load_photo_wrapper">
                                <div
                                    v-for="(file, key) in this.before_images"
                                    class="image_block"
                                    v-bind:key="'file-'+key">
                                    <img class="defect_image" data-fancybox="gallery-1" :src="`${ file }`"/>
                                </div>
                            </div>
                        </div>

                        <div class="mb-3" v-if="Object.keys(this.after_images).length !== 0">
                            <h6 class="mt-3 pt-3">Фото при возврате</h6>
                            <div class="load_photo_wrapper">
                                <div
                                    v-for="(file, key) in this.after_images"
                                    class="image_block"
                                    v-bind:key="'file-'+key">
                                    <img class="defect_image" data-fancybox="gallery-2" :src="`${ file }`"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="mb-3">

                    <div class="col-sm position-relative mb-2" v-if="!this.contract.is_started">
                        <input type="hidden" name="photo" id="photo-1"/>
                        <form  enctype="multipart/form-data">
                            <input type="file"
                                   multiple
                                   id="files-1"
                                   class="inputfile"
                                   accept="image/*"
                                   ref="files"
                                   @change="onAttachmentChange('files', 'before')"
                                   style="display: none"
                            />
                            <label for="files-1" class="btn w-100 btn-yellow">
                                <fa class="icon opacity-50 me-1" icon="car-on"></fa>
                                Фото до выдачи</label>
                        </form>
                    </div>

                    <div class="col-sm position-relative mb-2">
                        <button class="btn w-100 gray" @click="showModal">
                            <fa class="icon me-1 opacity-50" icon="cloud-arrow-down"></fa>
                            Скачать документы
                        </button>
                    </div>

                    <div class="col-sm position-relative mb-2" v-if="this.contract.is_started && !this.contract.is_finished">
                        <input type="hidden" name="photo" id="photo-2"/>
                        <form  enctype="multipart/form-data">
                            <input type="file"
                                   multiple
                                   id="files-2"
                                   class="inputfile"
                                   accept="image/*"
                                   ref="files"
                                   @change="onAttachmentChange('files', 'after')"
                                   style="display: none"
                            />
                            <label for="files-2" class="btn w-100 btn-yellow">
                                <fa class="icon opacity-50 me-1" icon="flag-checkered"></fa>
                                Фото после возврата</label>
                        </form>
                    </div>
                    <div class="col-sm position-relative mb-2" v-if="is_issue">
                        <button class="btn w-100 btn-green"
                                @click="startSpoof(contract_number)"
                        >
                            <fa class="icon opacity-50 me-1" icon="car-on"></fa>
                            Выдать автомобиль
                        </button>
                    </div>
                    <div class="col-sm position-relative mb-2" v-if="is_cancel">
                        <button class="btn w-100 btn-red"
                                @click="deleteContract(contract_number)"
                        >
                            <fa class="icon opacity-50 me-2" icon="ban"></fa>
                            Отменить
                        </button>
                    </div>
                    <div class="col-sm position-relative mb-2" v-if="is_refund">
                        <button class="btn btn_vw btn-green"
                                @click="finishSpoof(contract_number)"
                        >
                            <fa class="icon opacity-50 me-1" icon="flag-checkered"></fa>
                            Возврат автомобиля
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>

<div class="modal d-block" v-if="is_modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="closeModal"></button>
            </div>
            <div class="modal-body">
                <div class="mb-2">
                <a :href="url_contract"  class="btn btn_vw btn-red"
                >
                    Договор № {{ contract_number }}
                </a>
            </div>

            <div class="mb-2">
                <a :href="url_proxy" class="btn btn_vw btn-red"
                >
                    Доверенность № {{ contract_number }}
                </a>
            </div>

            <div class="mb-2">
                <a :href="url_act" class="btn btn_vw btn-red"
                >
                    Акт приёма передачи
                </a>
            </div>

            <div class="mb-2">
                <a :href="url_request" class="btn btn_vw btn-red"
                >
                    Заявка
                </a>
            </div>

            <div class="mb-2">
                <button class="btn btn_vw btn-red"
                        @click="downloadDocs"
                >
                    Скачать все документы
                </button>
            </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn w-100 gray" @click="closeModal">
                    <fa class="icon me-1 opacity-50" icon="xmark"></fa>
                    Закрыть
                </button>
            </div>
        </div>
    </div>
</div>


    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";
import ToastAlert from '../../../components/ToastAlert';

export default {
    name: "SpoofContract",
    components: {
        spinner, BreadCrumbs, ToastAlert
    },
    data() {
        return {
            is_refund: false,
            is_cancel: true,
            is_delete: false,
            is_before: false,
            is_after: false,
            is_issue: false,
            is_modal: false,
            type: '',
            message: '',
            is_alert: false,
            is_drive: false,
            phone: null,
            contract_number: null,
            car: null,
            car_number: null,
            client: null,
            manager: null,
            IIN: null,
            id_number: null,
            driver_id_number: null,
            comment: null,
            history: [],
            contract: [],
            url_contract: null,
            url_request: null,
            url_act: null,
            url_proxy: null,
            before_images: [],
            after_images: [],
            isLoading: false,
            breadCrumbs: true,
            title: 'Договор',
            back_title: 'Список договоров',
            link: 'spoof',
        }
    },
    created() {
        this.getContract();
    },
    methods: {
        finishSpoof(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/spoof/finish', {
                id: id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.is_alert = true;
                        this.message = 'Автомобиль принят';
                        this.history = response.data.data.history;
                        this.getContract();
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        startSpoof(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/spoof/start', {
                id: id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.is_alert = true;
                        this.type = 'green'
                        this.message = response.data.data.message;
                        this.history = response.data.data.history;
                        this.getContract();
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                    if(response.status == 201){
                        this.is_alert = true;
                        this.type = 'red'
                        this.message = response.data.data.message;
                        this.getContract();
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        async onAttachmentChange(ref, slug) {
            const MAX_WIDTH = 1024;
            const MAX_HEIGHT = 768;
            let files = this.$refs.files.files;
            let data = [];

            for (let i = 0; i < files.length; i++) {
                await new Promise((resolve, reject) => {
                    const blobURL = URL.createObjectURL(files[i]);
                    const img = new Image();
                    img.src = blobURL;
                    img.onerror = function () {
                        URL.revokeObjectURL(this.src);
                        // Handle the failure properly
                        console.log("Cannot load image");
                    };
                    img.onload = function () {
                        URL.revokeObjectURL(this.src);
                        const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                        const canvas = document.createElement("canvas");
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, newWidth, newHeight);
                        data[i] = canvas.toDataURL('image/jpeg')

                        resolve();
                    };
                });
            }

            this.addImage(data, files, slug)

            function calculateSize(img, maxWidth, maxHeight) {
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
                return [width, height];
            }
        },
        addImage(data, files, slug){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }, 'content-type': 'multipart/form-data'};
            let i = 0;
            const formData = new FormData();
            for( let i = 0; i < data.length; i++){
                formData.append('photos[' + i + ']', data[i]);
            }
            for (let i = 0; i < files.length; i++) {
                formData.append('files[' + i + ']', this.fileInfo(files[i]));
            }
            formData.append('slug', slug);
            formData.append('contract_number', this.contract_number);
            axios.post(`/api/admin/spoof/save/image`, formData, config)
                .then(response => {
                    if (response.status == 200) {
                        this.contract = response.data.data.contract;
                        this.before_images = response.data.data.beforeImages;
                        this.after_images = response.data.data.afterImages;
                        this.history = response.data.data.history;
                        if(Object.keys(this.before_images).length !== 0){
                            this.is_issue = true;
                            this.is_alert = true;
                            this.message = 'Фото До добавлено';
                        }
                        if(this.contract.is_started){
                            this.is_issue = !this.is_issue;
                            this.is_cancel = true;
                        }
                        if(Object.keys(this.after_images).length !== 0){
                            this.is_refund = true;
                            this.is_cancel = false;
                            this.is_alert = true;
                            this.message = 'Фото После добавлено';
                        }
                        this.isLoading = false;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }else{
                        this.isLoading = false;
                    }
                })
                .catch(function (error) {
                    this.isLoading = false;
                    console.error(error);
                });
        },
        fileInfo(file){
            let array = [];
            array.push(file.name);
            array.push(file.type);
            return array;
        },
        downloadDocs(){
            let files = [];
            files.push(this.url_contract);
            files.push(this.url_request);
            files.push(this.url_act);
            files.push(this.url_proxy);
            for(var ii=0; ii<files.length; ii++){
                this.downloadURL(files[ii]);
            }
        },
        downloadURL(url){
            let count=0;
            let hiddenIFrameID = 'hiddenDownloader' + count++;
            var iframe = document.createElement('iframe');
            iframe.id = hiddenIFrameID;
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            iframe.src = url;
        },
        closeModal(){
            this.is_modal = false;
        },
        closeModalDelete(){
            this.is_delete= false;
        },
        showModelDelete(){
            this.is_delete= true;
        },
        showModal(){
            this.is_modal = true;
        },
        closeAlert(){
            this.is_alert = false;
        },
        deleteContract(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/spoof/contract/delete', {
                id: id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.is_alert = true;
                        this.message = 'Договор отменен';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.$router.push('/spoof')
                    }
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        async getContract(){
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/spoof/contract/${this.$route.params.id}`, config);
                this.contract = response.data.data.contract;
                this.history = this.contract.history;
                this.phone = this.contract.phone;
                this.contract_number = this.contract.contract_number;
                this.car = this.contract.car;
                this.car_number = this.contract.car_number;
                this.client = this.contract.client;
                this.manager = this.contract.manager;
                this.IIN = this.contract.IIN;
                this.id_number = this.contract.id_number;
                this.driver_id_number = this.contract.driver_id_number;
                this.comment = this.contract.comment;
                this.url_contract = response.data.data.url_contract;
                this.url_request = response.data.data.url_request;
                this.url_act = response.data.data.url_act;
                this.url_proxy = response.data.data.url_proxy;
                this.before_images = response.data.data.beforeImages;
                if(Object.keys(this.before_images).length !== 0){
                    this.is_issue = true;
                }
                this.after_images = response.data.data.afterImages;
                if(Object.keys(this.after_images).length !== 0){
                    this.is_after = true;
                    this.is_refund = true;
                }
                if(this.contract.is_started){
                    this.is_issue = !this.is_issue;
                    this.is_cancel = false;
                }
                if(this.contract.is_finished){
                    this.is_refund = false;
                    this.is_cancel = false;
                }

            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
    <div>
        <BreadCrumbs
            :breadCrumbs="breadCrumbs"
            :title="title"
            :back_title="back_title"
            :link="link"/>
        <div class="page">
            <div class="row">
                <div class="col">
                    <h2>Отделы</h2>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div>
                    <VueMultiselect
                        v-model="organization"
                        placeholder="Фильтр по организации"
                        label="name"
                        track-by="id"
                        :options="organizations"
                        :show-labels="false"
                        @select="filterOrganization($event)"
                        @remove="reset($event)"
                    ></VueMultiselect>
                </div>
                <div>
                    <Button
                        caption="Добавить"
                        color="gray"
                        add_class="mb-3"
                        @click="showForm"
                    />
                </div>
            </div>
            <div class="table-responsive-md" v-if="departments.length > 0">
                <table class="table table-row table-app">
                    <thead>
                        <tr>
                            <th class="text-muted">Организация</th>
                            <th class="text-muted">Название отдела</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="department in departments" :key="department.id">
                            <td>
                                {{ department.organization.name }}
                            </td>
                            <td>
                                {{ department.name }}
                            </td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button
                                        class="btn btn_drop px-0"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        id="dropdownMenuButton">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#ccc"
                                            class="bi bi-three-dots-vertical"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul
                                        class="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton">
                                        <li>
                                            <button
                                                type="button"
                                                @click="editDepartment(department)"
                                                class="dropdown-item">Редактировать</button>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider"></li>
                                            <li>
                                                <button
                                                    type="button"
                                                    @click="deleteDepartment(department.id)"
                                                    class="dropdown-item">Удалить</button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive-md" v-else>
                    <h3>Данных нет!</h3>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{name_form}}
            </template>
            <template v-slot:body>
                <FormDepartment
                    :errors="errors"
                    :id="id"
                    :name="name"
                    :abbreviated="abbreviated"
                    :organization="organization"
                    :organizations="organizations"
                    @save="save($event)"
                />
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import axios from "axios";
import Spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";
import {errorMessages, fetchPermisson} from "../../services/helpers";
import Button from '../../components/Button.vue';
import Modal from '../../components/Modal';
import FormDepartment from "./FormDepartment.vue";
import * as req from "../../services/departments";
import ToastAlert from "../../components/ToastAlert.vue";
import VueMultiselect from "vue-multiselect";

export default {
    name: "Index",
    components: {
        Spinner, BreadCrumbs, Modal, FormDepartment, Button, ToastAlert, VueMultiselect
    },
    data(){
        return {
            isModal: false,
            isLoading: false,
            is_alert: false,
            name_form: '',
            message: '',
            type: '',
            name: '',
            abbreviated: '',
            organization: null,
            organizations: [],
            departments: [],
            id: 0,
            breadCrumbs: true,
            title: '',
            back_title: '',
            link: '',
            modules: [],
            errors: {
                name: '',
                organization: '',
            }
        }
    },
    mounted() {
        this.getDepartment();
        this.module();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        showForm(){
            this.name_form = 'Добавить отдел';
            this.isModal = true;
        },
        async module(){
            this.isLoading = true;
            this.permission = await fetchPermisson(this.$route.path);
            this.title = this.permission.title;
            this.back_title = this.permission.modules.title;
            this.link = this.permission.modules.path;
            this.isLoading = false;
        },
        async save(data){
            this.isLoading = true;
            let result = await req.saveDepartment(data);
            if(result.status === 422){
                this.errors = result.data.errors;
                this.isLoading = false;
                this.type = 'red';
                this.message = errorMessages(this.errors)
                this.is_alert = true;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }else {
                this.type = 'green';
                this.isLoading = false;
                this.closeModal();
                this.is_alert = true;
                this.message = result.message;
                this.departments = result.departments;
                setTimeout(() => {this.closeAlert(); }, 3000);
            }
        },
        async getDepartment(){
            this.isLoading = true;
            let result = await req.getDepartments();
            this.departments = result.departments;
            this.organizations = result.organizations;
            this.isLoading = false;
        },
        editDepartment(department){
            this.name_form = 'Редактировать отдел';
            this.isModal = true;
            this.id = department.id;
            this.name = department.name;
            this.abbreviated = department.abbreviated;
            this.organization = department.organization;
            this.isModal = true;
        },
        async deleteDepartment(id){
            this.isLoading = true;
            let result = await req.deleteDepartment(id);
            this.type = 'green';
            this.is_alert = true;
            this.message = result.message;
            this.departments = result.departments;
            setTimeout(() => {this.closeAlert(); }, 3000);
            this.isLoading = false;
        },
        closeModal() {
            this.id = 0;
            this.name = '';
            this.abbreviated = '';
            this.organization = null;
            this.isModal = false;
        },
        async filterOrganization(event){
            this.isLoading = true;
            let result = await req.filterOrganization(event.id);
            this.departments = result.departments;
            this.isLoading = false;
        },
        reset(event){
            this.getDepartment();
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div class="container-fluid login_top">
            <div class="row">
                <div class="login_page_wrap align-items-center d-flex h-100 justify-content-start position-absolute col-12 g-0" >
                    <div class="align-items-center col-12 col-lg-5 col-md-6 col-xl-4 col-xxl-3 d-flex h-100 justify-content-center login_box">
                        <div class="auth_box" v-if="is_token">
                            <Form ref="form" @submit="resetPassword">
                                <div class="logo">
                                    <img class="mb-4 logo-img" src="/image/logo.png" alt="">
                                </div>
                                <div class="fs-7 fw-normal text-center mb-3 opacity-75">
                                    Введите новый пароль и подтвердите его.
                                </div>
                                <div class="form-signin">
                                    <div class="login_input_box">

                                        <Field
                                            autocomplete="new-password"
                                            type="password"
                                            name="password"
                                            class="form-control"
                                            placeholder="Новый пароль"
                                            v-model="password"/>
                                        <ErrorMessage name="password"/>

                                        <Field
                                            autocomplete="new-password"
                                            type="password"
                                            name="password_confirmation"
                                            class="form-control"
                                            placeholder="Повторите пароль"
                                            v-model="password_confirmation"/>
                                    </div>
                                </div>

                                <button class="w-100 btn btn-red mt-3">
                                    <span class="material-symbols-rounded me-2 opacity-50">save</span>
                                    Сохранить
                                </button>
                            </Form>
                        </div>
                        <div class="auth_box" v-if="!is_token">
                            <Form ref="form">
                                <div class="logo">
                                    <img class="mb-4 logo-img" src="/image/logo.png" alt="">
                                </div>
                                <div class="fs-7 fw-normal text-center mb-3 opacity-75" style="max-width: 295px;">
                                    Ссылка на смену пароля устарела (прошло 60 минут), запросите повторно.
                                </div>

                                <button class="w-100 btn gray mt-4" @click="gotoBack()">
                                    <span class="material-symbols-rounded me-2 opacity-50">undo</span>
                                    Вернуться
                                </button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>



    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            type="type"/>
    </transition>
</template>

<script>
import {ErrorMessage, Field, Form} from "vee-validate";
import axios from "axios";
import spinner from "../components/Spinner";
import ToastAlert from "../components/ToastAlert";

export default {
    name: "Reset",
    components: {
        Field, Form, ErrorMessage, spinner, ToastAlert
    },
    data() {
        return {
            type: 'green',
            message: '',
            isLoading: false,
            is_alert: false,
            is_token: false,
            password: '',
            password_confirmation: '',
            errors: {
                password: '',
                password_confirmation: '',
            },
            email_error: false,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/get/reset/token/${this.$route.params.token}`, config);
                this.is_token = response.data.data.is_token;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        closeAlert(){
            this.is_alert = false;
        },
        gotoBack(){
            this.$router.push('/reset-password')
        },
        resetPassword(){
            this.isLoading = true;
            axios.post('/api/update-new-password', {
                token: this.$route.params.token,
                password: this.password,
                password_confirmation: this.password_confirmation
            })
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.$router.push('/');
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.form.setErrors(errors.response.data.errors);
                            this.errors.password = errors.response.data.errors.password ?? 0;
                            break;

                    }
                });
        }
    }
}
</script>

<style scoped>

</style>

import * as auth from "../../services/auth";
import IndexOrganizations from "../../Modules/Organizations/IndexOrganizations.vue";

export default [
    {
        name: 'organizations',
        path: '/admins/organizations',
        component: IndexOrganizations,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        },
    },
]

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page">
                <div class="row">
                    <h2 class="mb-4">Чек-листы ежедневные</h2>
                    <div class="control_row hstack gap-3 mb-4 flex-wrap">
                        <div class="control_row_cell col-12 col-md-8 col-lg-6 col-xl-5">
                            <VueMultiselect
                                v-model="department"
                                placeholder="Выберите отдел"
                                label="name"
                                track-by="id"
                                :options="optionsDepartment"
                                :show-labels="false"
                                @tag="addDepartment"></VueMultiselect>
                        </div>
                        <div class="control_row_cell ms-auto col-12 col-md-3 col-xl-2">
                            <router-link to="/quality-setting/create"
                                v-if="contains(actions, 'dobavit-vopros-v-cek-list-ezednevnyi')"
                            >
                                <button class="btn gray btn_add w-100">Добавить</button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="table-responsive-md">
                    <table class="table table-row table-app">
                        <thead>
                            <tr>
                                <th class="text-muted">Отдел</th>
                                <th class="text-muted">Название</th>
                                <th class="text-muted">Штраф</th>
                                <th class="text-muted">Срок</th>
                                <th style="width: 20%;" class="text-muted">Ответственные</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="checklist in filteredDepartment" :key="checklist.id">
                                <td>
                                    {{ checklist.department.name }}
                                </td>
                                <td>
                                    {{ checklist.name }}
                                </td>
                                <td>
                                    {{ checklist.penalty }}
                                </td>
                                <td>
                                    {{ checklist.deadline.time_performance }}
                                </td>
                                <td>
                                    <div v-for="item in checklist.user">
                                        <div>{{ item.surname }}
                                            {{ item.name }}</div>
                                    </div>
                                </td>
                                <td class="text-end" style="width: 5%;"
                                    v-if="contains(actions, 'redaktirovat-vopros-cek-lista-ezednevnyi') || contains(actions, 'udalit-vopros-cek-lista-ezednevnyi')"
                                >
                                    <div class="dropdown">
                                        <button
                                            class="btn btn_drop px-0"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            id="dropdownMenuButton">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="#ccc"
                                                class="bi bi-three-dots-vertical"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                            </svg>
                                        </button>
                                            <ul
                                                class="dropdown-menu dropdown-menu-end"
                                                aria-labelledby="dropdownMenuButton">
                                                <li
                                                    v-if="contains(actions, 'redaktirovat-vopros-cek-lista-ezednevnyi')"
                                                >
                                                    <button
                                                        type="button"
                                                        @click="$router.push(`/quality-setting/edit/${checklist.id}`)"
                                                        class="dropdown-item">Редактировать</button>
                                                </li>
                                                <li><hr class="dropdown-divider"></li>
                                                <li
                                                    v-if="contains(actions, 'udalit-vopros-cek-lista-ezednevnyi')"
                                                >
                                                    <button type="button" @click="deleteCheck(checklist.id)" class="dropdown-item">Удалить</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </transition>
    </template>

<script>
import axios from "axios";
import { Field, Form, ErrorMessage } from 'vee-validate';
import spinner from '../../../components/Spinner';
import VueMultiselect from 'vue-multiselect';
import BreadCrumbs from "../../../Page/BreadCrumbs";
import {getUserPermissionActions} from "../../../services/helpers";

export default {
    name: "IndexCheck",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs
    },
    data(){
        return {
            department: null,
            checklists: [],
            actions: [],
            isLoading: false,
            optionsDepartment: [],
            breadCrumbs: true,
            title: 'Ежедневные чек листы',
            back_title: 'Настройки чек листов',
            link: 'check-setting',
        }
    },
    computed: {
        filteredDepartment: function() {
            return this.checklists.filter(function(check) {
                if(this.department !== null){
                    return check.department_id == this.department.id;
                }else{
                    return this.checklists;
                }
            }.bind(this));
        },
    },
    created() {
        this.getCheckLists();
        this.getUserActions();
    },
    methods: {
        async getUserActions(){
            this.isLoading = true;
            this.actions = await getUserPermissionActions('/check-setting');
            this.isLoading = false;
        },
        contains(arr, elem){
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].alias === elem) {
                    return true;
                }
            }
            return false;
        },
        deleteCheck(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.delete(`/api/admin/quality/${id}`, config)
                .then(response =>{
                    if(response.status == 200){
                        /* this.$flashMessage.show({
                             type: 'success',
                             text: 'Пункт меню успешно удален!',
                         });*/
                        this.getCheckLists();
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        },
        async getCheckLists(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/checklist', config);
                this.checklists = response.data.data.checklists;
                this.optionsDepartment = response.data.data.departments;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        addDepartment(newDepartment){
            const department = {
                name: newDepartment,
            }
            this.optionsDepartment.push(department)
            this.department.push(department)
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
        <BreadCrumbs
            :breadCrumbs="breadCrumbs"
            :title="title"
            :back_title="back_title"
            :link="link"
        />
        <div class="page">
            <h2>Пользователи</h2>

            <div class="row">
                <div class="d-flex justify-between flex-wrap my-4">
                    <div class="col-12 col-sm-8 pe-sm-4 col-lg-5    ">
                        <input
                            v-model="searchUser"
                            type="text"
                            name="surname"
                            class="form-control"
                            placeholder="Поиск..."
                        />
                    </div>
                    <div class="col-12 col-sm-8 pe-sm-4 col-lg-5">
                        <!-- В работе-->
                        <VueMultiselect
                            v-model="organization"
                            placeholder="Фильтр по организации"
                            label="name"
                            track-by="id"
                            :options="organizations"
                            :show-labels="false"
                            :searchable="false"
                            @select="filterOrganization($event)"
                        ></VueMultiselect>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-2">
                        <router-link to="/admins/users/create">
                            <button class="btn gray btn_add w-100">Добавить</button>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="table_arrows d-md-none">
                <fa icon="fa-solid fa-hand-pointer" /> <fa icon="fa-solid fa-arrows-left-right" /><br>
                <span>Двигайте таблицу по горизонтали</span>
            </div>
            <div class="table-responsive-md">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th class="text-muted">ID</th>
                        <th class="text-muted">Организация</th>
                        <th class="text-muted">Сотрудник</th>
                        <th class="text-muted">Роль</th>
                        <th class="text-muted">Модули</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="user in filteredUser"
                        :key="user.id"
                    >
                        <td style="padding: 20px;">
                            {{ user.id }}
                        </td>
                        <td style="padding: 20px;">
                            <div v-for="item in user.organizations" :key="item.id" class="text-nowrap">{{item.name}}</div>
                        </td>

                        <td style="padding: 20px;">
                            <div class="d-flex flex-column fw-bold">
                                <span>{{ user.surname }}</span>
                                <span>{{ user.name }}</span>
                                <span>{{ user.patronymic }}</span>
                            </div>
                        </td>
                        <td style="padding: 20px;">
                            <div v-for="item in user.role" class="mb-3 d-flex flex-column">
                                <span>{{ item.title }}</span>
                                <span class="text-black-50 small">{{item.organizationName}}</span>
                            </div>
                        </td>
                        <td style="padding: 20px;">
                            <div v-for="module in user.modules" :key="module.id" class="mb-3">
                                <div class="fw-bold">{{ module.title }}</div>
                                <div class="small" v-for="permission in module.permissions" :key="permission.id">
                                    {{ permission.title }}
                                </div>
                            </div>
                        </td>
                        <td class="text-end" style="padding: 20px;">
                            <div class="dropdown">
                                <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                    <li><button type="button" @click="$router.push(`/admins/users/edit/${user.id}`)" class="dropdown-item">Редактировать</button></li>
                                    <li><hr class="dropdown-divider"></li>
                                    <li>
                                        <button type="button" @click="deleteUser(user.id)" class="dropdown-item">Удалить</button>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import axios from "axios";
import spinner from "../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import BreadCrumbs from "../../Page/BreadCrumbs";
import {fetchPermisson} from "../../services/helpers";
import VueMultiselect from "vue-multiselect";
import * as req from "../../services/users";
import ToastAlert from '../../components/ToastAlert';

export default {
    name: "Index",
    components: {
        spinner, Field, Form, ErrorMessage, BreadCrumbs, VueMultiselect, ToastAlert
    },
    data(){
        return {
            organizations: [],
            users: [],
            organization: null,
            isLoading: false,
            searchUser: '',
            breadCrumbs: true,
            title: 'Пользователи',
            back_title: 'Администрирование',
            link: 'admins',
            message: '',
            type: 'green',
            is_alert: false,
        }
    },
    computed: {
        filteredUser(){
            return this.users.filter(user => user.surname.toLowerCase().includes(this.searchUser.toLowerCase()));
        },
    },
    created() {
        this.getUsers();
        this.module();
    },
    methods: {
        async module(){
            this.isLoading = true;
            this.permission = await fetchPermisson(this.$route.path);
            this.title = this.permission.title;
            this.back_title = this.permission.modules.title;
            this.link = this.permission.modules.path;
            this.isLoading = false;
        },
        async getUsers(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            const response = await axios.get('/api/admin/users', config);

            const organizationId = localStorage.getItem('organizationId');

            // Проверяем, если значение не равно 0
            if (organizationId && organizationId !== "0") {
                // Вызываем функцию фильтрации
                this.filterOrganization({ id: organizationId });
            } else {
                this.users = response.data.data.users;
            }
            this.organizations = response.data.data.organizations;
            this.isLoading = false;
        },
        async deleteUser(id){
            this.isLoading = true;
            let result = await req.destroyUser(id);
            if(result.status == 409){
                this.isLoading = false;
                this.type = 'red';
                this.is_alert = true;
                this.message = result.data.data.message;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }
            if(result.status == 200){
                this.isLoading = false;
                this.getUsers();
                this.type = 'green';
                this.is_alert = true;
                this.message = result.data.data.message;
                setTimeout(() => {this.closeAlert(); }, 5000);
            }
        },
        closeAlert(){
            this.is_alert = false;
        },
        goStatus(status){
            if(status === 0){
                return 'Не активный';
            }else if(status === 1){
                return 'Активный';
            }
        },
        goClass(status){
            if(status === 0){
                return 'status_not_active';
            }else if(status === 1){
                return 'status_active';
            }
        },
        async filterOrganization(event){
            this.isLoading = true;
            localStorage.setItem('organizationId', event.id);
            let result = await req.filterOrganization(event.id);
            this.users = result.users;
            this.organization = result.organization;
            this.isLoading = false;
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h2>Спиоск контрагентов</h2>

                <div class="row">
                    <div class="d-flex justify-between flex-wrap my-4">
                        <div class="col-12 col-sm-8 pe-sm-4 col-lg-6    ">
                            <input
                                v-model="searchAgent"
                                type="text"
                                name="surname"
                                class="form-control"
                                placeholder="Поиск..."
                            />
                        </div>
                        <div class="col-12 col-sm-4 col-lg-3 offset-lg-3">
                            <router-link to="/request/counteragents/create"
                                v-if="contains(actions, 'create')"
                            >
                                <button class="btn gray btn_add w-100">Добавить</button>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="table_arrows d-md-none">
                    <fa icon="fa-solid fa-hand-pointer" /> <fa icon="fa-solid fa-arrows-left-right" /><br>
                    <span>Двигайте таблицу по горизонтали</span>
                </div>
                <div class="table-responsive-md">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-muted">ID</th>
                            <th class="text-muted">Название</th>
                            <th class="text-muted">BIN</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="list in filteredAgent"
                            :key="list.id"
                        >
                            <td>
                                {{ list.id }}
                            </td>
                            <td>
                                {{ list.name }}
                            </td>
                            <td>
                                {{ list.bin }}
                            </td>

                            <td class="text-end" style="width: 5%;"
                                v-if="contains(actions, 'edit') || contains(actions, 'delete')"
                            >
                                <div class="dropdown">
                                    <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                        <li
                                            v-if="contains(actions, 'edit')"
                                        ><button type="button" @click="$router.push(`/request/counteragents/edit/${list.id}`)" class="dropdown-item">Редактировать</button></li>
                                        <li  v-if="contains(actions, 'edit')"><hr class="dropdown-divider"></li>
                                        <li v-if="contains(actions, 'delete')">
                                            <button type="button" @click="deleteAgent(list.id)" class="dropdown-item">Удалить</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :bg="bg"
    />
</template>

<script>
import ToastAlert from "../../../components/ToastAlert";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import {getUserPermissionActions} from "../../../services/helpers";

export default {
    name: "CounteragentIndex",
    components: {
        ToastAlert,
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect
    },
    data(){
        return {
            lists: [],
            searchAgent: '',
            message: '',
            is_alert: false,
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.list_counterparties'),
            back_title: this.$t('request.request_menu'),
            link: 'request',
            actions: [],
        }
    },
    computed: {
        filteredAgent(){
            return this.lists.filter(list => list.name.toLowerCase().includes(this.searchAgent.toLowerCase()));
        },
    },
    created(){
        this.getData();
        this.getUserActions();
    },
    methods: {
        async getUserActions(){
            this.actions = await getUserPermissionActions(this.$route.path);
        },
        contains(arr, elem){
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].alias === elem) {
                    return true;
                }
            }
            return false;
        },
        closeAlert(){
            this.is_alert = false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/counteragents/index', config);
                this.lists = response.data.data.lists;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        deleteAgent(id){
            this.isLoading = true;
            const config = {
                'headers': {
                    'Authorization': 'Bearer ' + this
                        .$cookie
                        .getCookie('token')
                }
            };
            axios
                .post('/api/admin/counteragents/delete', {
                    id: id,
                }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = response.data.data.message;
                        this.bg = response.data.data.bg;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="popup">
        <div class="d-flex justify-content-between popup-header hstack">
            <div>
                <img class="logo-header-img" src="/image/logo_header.png" alt="">
            </div>
            <div class="popup-title">
                <slot name="title"></slot>
            </div>
            <div class="popup-close" @click="close">
                <span class="material-symbols-outlined">close</span>
            </div>
        </div>
        <div class="popup-content mb-2">
            <slot name="body">
            </slot>
        </div>

    </div>
</template>

<script>
export default {
    name: "Modal",
    methods: {
        close() {
            this.$emit('close');
        },
    },
}
</script>

<style lang="scss" scoped>
.popup {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    left: 0;
    top: 0;
    z-index: 1050;
    padding: unset;
    overflow-y: auto;
    .popup-header {
        height: 60px;
        background-color: #e9e5f1;
        padding: 10px;
        .popup-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
        // .popup-close {
        //     cursor: pointer;
        // }
    }
    .popup-content {
        padding: 10px;
        z-index: 10001;
        position: relative;
        top: 15px;
        max-width: 950px;
        margin: 0 auto;
        input.form-check-input {
            box-shadow: 0 1px 4px 0px rgb(0 0 0 / 30%) inset !important;
        }
    }
    .border-bottom {
        border-color: #c4c9d2 !important;
    }
}
</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <Form ref="memos" @submit="create">

                        <!-- Организация -->
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">Организация*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model:modelValue="organization"
                                    placeholder="Выберите организацию"
                                    label="name"
                                    track-by="id"
                                    :options="organizations"
                                    name="organization"
                                    :class="errors.organization.length ? 'error' : ''"
                                    @select="selectOrganization($event)"
                                    :show-labels="false"
                                />
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">{{ $t('request.hr_role') }}*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model="memos.hr"
                                    :placeholder="$t('request.hr_role')"
                                    label="fullname"
                                    track-by="id"
                                    :options="users"
                                    :taggable="true"
                                    :show-labels="false"
                                >
                                </VueMultiselect>
                                <ErrorMessage name="hr"/>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">{{ $t('request.supplier_role') }}*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model="memos.supply"
                                    :placeholder="$t('request.supplier_role')"
                                    label="fullname"
                                    track-by="id"
                                    :options="users"
                                    :taggable="true"
                                    :show-labels="false"
                                >
                                </VueMultiselect>
                                <ErrorMessage name="supply"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-lg-2">
                                <button class="btn btn-green">
                                    {{ $t('request.button.save') }}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :bg="bg"
    />
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../../components/ToastAlert";
import axios from "axios";
import * as req from "../../../services/request";

export default {
    name: "SettingMemos",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data() {
        return {
            bg: 'bg-primary',
            message: '',
            is_alert: false,
            users: [],
            organizations: [],
            organization: null,
            memos: {
                hr: null,
                supply: null,
            },
            errors: {
                hr: '',
                supply: '',
                organization: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.memos'),
            back_title: this.$t('request.settings'),
            link: 'request/settings',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        create(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/settings/payments', {
                hr: this.memos.hr,
                supply: this.memos.supply,
                organization: this.organization
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.settings_saved');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.memos.setErrors(errors.response.data.errors);
                            this.errors.hr = errors.response.data.errors.hr ?? 0;
                            this.errors.supply = errors.response.data.errors.supply ?? 0;
                            break;
                    }
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/settings/data', config);
                this.users = response.data.data.users;
                this.memos = response.data.data.memos;
                this.organizations = response.data.data.organizations;
                this.organization = response.data.data.firstOrganization;
                console.log(this.imprest);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        async selectOrganization(event){
            this.isLoading = true;
            let result = await req.selectOrganization(event.id);
            this.memos = result.memos;
            this.organization = result.organization;
            this.users = result.users;
            this.isLoading = false;
        }
    }
}
</script>

<style scoped>

</style>

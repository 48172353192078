<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="d-flex flex-column justify-content-center mt-0">
                <div class="mt-2 mb-2 text-center"
                     v-if="contains(actions, 'nastroiki-sotrudnikov')"
                >
                    <router-link class="btn btn-red btn_vw" to="/issuance/settings/workers">
                        Настройки сотрудников
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center"
                     v-if="contains(actions, 'punkty-proverok')"
                >
                    <router-link class="btn btn-red btn_vw" to="/issuance/settings/points">
                        Список пунктов проверки
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center"
                     v-if="contains(actions, 'avtorizovannye-polzovateli')"
                >
                    <router-link class="btn btn-red btn_vw" to="/issuance/settings/authorized">
                        Список авторизованных сотрудников
                    </router-link>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {getUserPermissionActions} from "../../../services/helpers";

export default {
    name: "SettingsIssuancePage",
    components: {
        spinner, BreadCrumbs, Modules
    },
    data() {
        return {
            actions: [],
            isLoading: false,
            breadCrumbs: true,
            title: 'Настройки',
            back_title: 'Категории',
            link: 'issuance',
        }
    },
    created() {
        this.getUserActions();
    },
    methods: {
        async getUserActions(){
            this.isLoading = true;
            this.actions = await getUserPermissionActions(this.$route.path);
            this.isLoading = false;
        },
        contains(arr, elem){
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].alias === elem) {
                    return true;
                }
            }
            return false;
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <div class="col-12 col-lg-2">
                        <VueMultiselect
                            v-model="organization"
                            :placeholder="$t('request.organization')"
                            label="name"
                            track-by="id"
                            :options="organizations"
                            :show-labels="false"
                            @select="selectOrganization"
                            @remove="resmoveOrganization"
                            class="mb-2"
                        >
                        </VueMultiselect>
                    </div>
                    <div class="col-12 col-lg-2">
                        <VueMultiselect
                            v-model="user"
                            :placeholder="$t('request.worker')"
                            label="name"
                            track-by="id"
                            :options="users"
                            :show-labels="false"
                            @select="selectUser"
                            @remove="resmoveUser"
                            class="mb-2"
                        >
                        </VueMultiselect>
                    </div>
                    <div class="col-12 col-lg-2">
                        <VueMultiselect
                            v-model="status"
                            :placeholder="$t('request.status')"
                            label="name"
                            track-by="id"
                            :options="statuses"
                            :searchable="false"
                            :show-labels="false"
                            @select="selectStatus"
                            @remove="resmoveStatus"
                            class="mb-2"
                        >
                        </VueMultiselect>
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2">
                        <input type="date" class="form-control mb-2" v-model="startDay"
                            @change="handler()"
                        >
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2">
                        <input type="date" class="form-control mb-2" v-model="endDay"
                            @change="handler()"
                        >
                    </div>
                    <div class="col-12 col-xl-2 mt-lg-2 mt-xl-0">
                        <button class="btn gray w-100" @click="exportInXls">
                            <fa class="icon me-2 opacity-50" icon="file-excel"></fa>Скачать
                        </button>
                    </div>

                    <!-- BLOCKS -->
                    <div class="mt-4 payments-boxes-wrap">

                        <template v-for="list in lists" :key="list.id">
                            <div class="d-flex flex-wrap item_title paymat-box">

                                <!-- Номер, дата, статус, срочность -->
                                <div class="border-bottom col-12 col-lg-2 d-flex d-lg-block fw-semibold justify-content-between mb-3 mb-lg-0 pay_number pb-3 pb-lg-0" style="/*width:11%*/">
                                    <div class="fs-6">
                                        <fa class="icon text-danger me-1" icon="triangle-exclamation" v-if="list.urgent"></fa>№{{ list.id }}
                                    </div>
                                    <div class="fs-7">
                                        {{ list.date_create }}
                                    </div>
                                    <div :class="list.status" class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block">
                                        {{ list.status_title }}
                                    </div>
                                    <div class="fs-7 fw-normal rounded-pill px-2 d-inline-block" v-if="list.category.category.organization">
                                        {{ list.category.category.organization.name }}
                                    </div>
                                </div>

                                <!-- Контрагент, сумма, валюта, тип -->
                                <div class="border-bottom col-12 col-lg-3 fs-7 m-lg-0 mb-3 pay_contragent pb-3 pb-lg-0 pe-lg-2 ps-lg-2" style="/*width:28%*/">
                                    <div>{{ list.counteragent.name }}</div>
                                    <div class="fs-6 fw-bold">{{ cur(list.payment_amount) }} {{ list.currency }}</div>
                                    <div>{{ list.payment_type }}</div>
                                </div>

                                <!-- Заявитель, категория, назначение -->
                                <div class="col-12 col-lg-3 fs-7 pay_worker px-lg-2" style="/*width:22%*/">
                                    <div class="fw-semibold mb-2 lh-1"><fa class="icon me-1 opacity-50" icon="list-ul"></fa>{{ list.category.name }}</div>
                                    <div class="fw-semibold mb-2"><fa class="icon me-1 opacity-50" icon="user-tie"></fa>{{ list.user.surname }} {{ list.user.name }}</div>
                                    <!-- Вложения -->
                                    <div v-for="file in list.files" :key="file.id">
                                        <a data-fancybox :href="file.link" class="attachment my-1 mt-lg-0 mb-lg-2" style="cursor: pointer;"
                                            v-if="file.type" :title="file.name"
                                        >
                                            <fa class="icon me-1" icon="paperclip"></fa>{{ file.name }}
                                        </a>
                                        <a @click="downloadURL(file.link, file.name)" class="attachment my-1 mt-lg-0 mb-lg-2" style="cursor: pointer;"
                                            v-else :title="file.name"
                                        >
                                            <fa class="icon me-1" icon="paperclip"></fa>{{ file.name }}
                                        </a>
                                    </div>
                                    <div class="bg-opacity-25 p-2 fw-normal pay_journal_comment"><fa class="icon me-1 opacity-25" icon="message"></fa>{{ list.comment }}</div>
                                </div>

                                <!-- Платёжка, Доверка -->
                                <div class="border-top col-12 col-lg-2 mt-3 mt-lg-0 pay_docs pt-3 pt-lg-0 px-lg-2"

                                >

                                    <!-- Платёжка -->
                                    <div v-if="Object.keys(list.payment_documents).length > 0">
                                        <div v-for="file in list.payment_documents" :key="file.id">
                                            <a data-fancybox :href="file.link" class="attachment my-1 mt-lg-0 mb-lg-2" style="cursor: pointer;" v-if="file.type">
                                                <fa class="icon me-1" icon="paperclip"></fa>
                                                П.п.№{{ list.payment_number }}
                                            </a>
                                            <a @click="downloadURL(file.link, 'П.п.№' + list.payment_number)" class="attachment my-1 mt-lg-0 mb-lg-2" style="cursor: pointer;" v-else>
                                                <fa class="icon me-1" icon="paperclip"></fa>
                                                П.п.№{{ list.payment_number }}
                                            </a>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <button class="btn btn-small btn-yellow btn_vw lh-1 my-1" v-if="list.payment_document" @click="showModal(list.id, 'add_payment')">
                                            Отправить Платёжку
                                        </button>
                                    </div>

                                    <!-- Доверка -->
                                    <div v-if="Object.keys(list.attorney_document).length > 0">
                                        <div v-for="file in list.attorney_document" :key="file.id">
                                            <a data-fancybox :href="file.link" class="attachment my-1 mt-lg-0 mb-lg-2" style="cursor: pointer;" v-if="file.type" >
                                                <fa class="icon me-1" icon="paperclip"></fa>Доверенность
                                            </a>
                                            <a @click="downloadURL(file.link, 'Доверенность')" class="attachment my-1 mt-lg-0 mb-lg-2" style="cursor: pointer;" v-else>
                                                <fa class="icon me-1" icon="paperclip"></fa>Доверенность
                                            </a>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <button class="btn btn-small btn-yellow btn_vw lh-1 my-1" v-if="list.letter_attorney" @click="showModal(list.id, 'add_attorney')">
                                            Отправить Доверенность
                                        </button>
                                    </div>

                                </div>
<!--                                <div v-else class="d-none d-lg-flex col-lg-2 pay_docs px-lg-2">

                                </div>-->

                                <!-- Действия  -->
                                <div class="border-top col-12 col-lg-2 mt-3 mt-lg-0 pay_actions ps-lg-2 pt-3 pt-lg-0" style="/*width:16%*/">
                                    <div v-if="list.executor_type == 'bh'">
                                        <div v-if="list.status != 'completed' && list.status != 'revision'">
                                            <button class="btn btn-yellow mb-1 w-100 btn-small"
                                                v-if="!list.in_bh_work"
                                                @click="showModal(list.id, 'in_work')"
                                            >
                                                <fa class="icon opacity-50 me-2" icon="play"></fa>
                                                {{ $t("request.button.accept_to_work") }}
                                            </button>
                                            <button class="btn btn-red mb-1 w-100 btn-small"
                                                v-if="!list.in_bh_work"
                                                @click="showModal(list.id, 'return')"
                                            >
                                                <fa class="icon opacity-50 me-2" icon="reply"></fa>
                                                {{ $t("request.button.for_revision") }}
                                            </button>
                                            <button class="btn btn-green w-100 mb-1 btn-small"
                                                v-if="list.in_bh_work"
                                                @click="showModal(list.id, 'complete')"
                                            >
                                                <fa class="icon opacity-50 me-2" icon="flag-checkered"></fa>
                                                {{ $t("request.button.complete") }}
                                            </button>
                                        </div>
                                    </div>
                                    <button class="btn gray w-100 btn-small" @click="showHistory(list.id)">
                                       <fa class="icon me-2 opacity-50" icon="file-lines"></fa>История
                                    </button>
                                </div>

                            </div>

                            <div class="pay_history">
                                <div v-if="is_history[list.id]">

                                    <div class="history-cards-list mb-4">
                                        <div class="history-card-wrap" v-for="history in list.historys" :key="history">
                                            <div class="history-card" :class="goStatus(history.status)">
                                                <div class="fs-7 fw-semibold">
                                                    <fa class="icon me-2 opacity-25" icon="calendar"></fa>{{ history.date }}
                                                </div>
                                                <div class="fs-7 fw-semibold">
                                                    <fa class="icon me-2 opacity-25" icon="circle-chevron-right"></fa>{{ history.message }}
                                                </div>
                                                <div class="fs-7 fw-semibold">
                                                    <fa class="icon me-2 opacity-25" icon="user-tie"></fa>{{ history.fullname }}
                                                </div>
                                                <div class="fs-7 fw-normal mt-3 mb-2" v-if="history.comment">
                                                    <fa class="icon me-2 opacity-25" icon="message"></fa>{{ history.comment }}
                                                </div>
                                                <div v-if="history.records">
                                                    <div class="fs-7 fw-normal mt-3 mb-2">
                                                        <fa class="icon me-2 opacity-25" icon="volume-up"></fa>
                                                    </div>
                                                    <div v-for="(record, index) in history.records" :key="index">
                                                        <audio :src="record" controls />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex history-next-card justify-content-center my-1">
                                                <fa class="icon fa-2xs" icon="angle-down"></fa>
                                            </div>
                                        </div>
                                        <button class="btn btn-green w-100" @click="showHistory(list.id)">
                                            <fa class="icon me-2 opacity-50" icon="file-lines"></fa>Закрыть историю
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </template>
                    </div>


                </div>

                <transition-group name="list">
                    <div class="modal d-block" v-if="is_modal">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title"></h5>
                                    <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row mb-3" v-if="type_method == 'add_payment'">
                                        <label class="col-sm-3 col-form-label required text-end">{{ $t("request.payment_number") }}*</label>
                                        <div class="col-sm-8 position-relative">
                                            <Field
                                                v-model="payment_number"
                                                type="text"
                                                name="payment_number"
                                                class="form-control"
                                            />
                                            <ErrorMessage name="payment_number" style="color: red;"/>
                                        </div>
                                    </div>
                                    <div class="mb-3" v-if="type_method == 'add_payment' || type_method == 'add_attorney'">
                                        <label for="formFileMultiple" class="form-label">{{ $t("request.attach_files") }}</label>
                                        <input ref="files" class="form-control" type="file" id="formFileMultiple" multiple
                                               v-on:change="handleFileUpload()"
                                        >
                                    </div>
                                    <div class="row mb-3" v-if="type_method == 'return'">
                                        <label class="col-sm-3 col-form-label required text-end">{{ $t('request.select_employee') }}*</label>
                                        <div class="col-sm-8 position-relative">
                                            <VueMultiselect
                                                v-model="user"
                                                :placeholder="$t('request.select_employee')"
                                                label="name"
                                                track-by="id"
                                                :options="users"
                                                name="user"
                                                :taggable="true"
                                                @select="selectReturnUser"
                                                :show-labels="false"
                                            >
                                            </VueMultiselect>
                                            <div class="invalid-feedback" :class="errors.user.length > 0 ? 'visible' : ''">{{ errors.user }}</div>
                                        </div>
                                    </div>
                                    <div class="row mb-3 mt-3" v-if="type_method == 'in_work' || type_method == 'return' || type_method == 'complete'">
                                        <label class="col-sm-12 col-form-label required text-start">{{ $t("request.comment") }}</label>
                                        <div class="col-sm position-relative">
                                            <textarea class="form-control" rows="3" v-model="comment"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-sm position-relative mb-2 mt-2">
                                        <button class="btn btn_vw btn-green"
                                                @click="save"
                                        >
                                            {{ $t("request.button.save") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :bg="bg"
    />
</template>

<script>
import History from "../Imprest/History";
import ToastAlert from "../../../components/ToastAlert";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";

export default {
    name: "PaymentsJournal",
    components: {
        History, ToastAlert,
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect
    },
    data(){
        return {
            message: '',
            is_alert: false,
            startDay: null,
            endDay: null,
            user: null,
            users: [],
            lists: [],
            statuses: [],
            organizations: [],
            organization: null,
            is_filter: true,
            comment: '',
            files: '',
            id: null,
            errors: {
                user: '',
            },
            status: null,
            payment_number: null,
            type_method: '',
            is_modal: false,
            is_history: [],
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.journal'),
            back_title: this.$t('request.payments'),
            link: 'request/payments',
            url: '',
        }
    },
    created(){
        this.getData();
        this.eventBus.emit('page-width', '100%');
    },
    methods: {
        downloadURL(url, name){
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.onload = function(e) {
                if (this.status == 200) {
                    var myBlob = this.response;
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(myBlob);
                    link.download = name;
                    link.click();
                }
            };
            xhr.send();
        },
        selectReturnUser(value){
            this.errors.user = '';
        },
        selectStatus(value){
            this.status = value;
            this.filter();
        },
        resmoveStatus(){
            this.status = null;
            this.filter();
        },
        selectUser(value){
            this.user = value;
            this.filter();
        },
        selectOrganization(value){
            this.organization = value;
            this.filter();
        },
        resmoveOrganization(){
            this.organization = null;
            this.filter();
        },
        resmoveUser(){
            this.user = null;
            this.filter();
        },
        handler(){
            this.filter();
        },
        handleFileUpload(){
            this.files = this.$refs.files.files;
        },
        exportInXls(){
            let user_id = null;
            let status = null;
            if(this.user != null){
                user_id = this.user.id;
            }
            if(this.status != null){
                status = this.status.id
            }
            let element = document.createElement("a");
            let url = `/api/request/payments/export/${this.startDay}/${this.endDay}/${user_id}/${status}`;
            element.setAttribute("href", url);
            element.setAttribute("download", "export.xlsx");
            element.setAttribute("target", "_blank");
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        filter(){
            this.isLoading = true;
            this.is_filter = false;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/payments/filter', {
                startDay: this.startDay,
                endDay: this.endDay,
                user: this.user,
                status: this.status,
                organization: this.organization,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.lists = response.data.data.lists;
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        goStatus(status){
            return `history_status_` + status;
        },
        showHistory(id) {
            this.is_history[id] = !this.is_history[id];
        },
        cur(val) {
            if (!val) {
                return "0";
            }

            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        closeModal(){
            this.type_method = '';
            this.comment = '';
            this.is_modal = false;
        },
        showModal(id, type){
            this.id = id;
            this.type_method = type;
            this.is_modal = true;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/payments/journal', config);
                this.lists = response.data.data.lists;
                this.users = response.data.data.users;
                this.statuses = response.data.data.statuses;
                this.startDay = response.data.data.startDay;
                this.endDay = response.data.data.endDay;
                this.organizations = response.data.data.organizations;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        closeAlert(){
            this.is_alert = false;
        },
        save(){
            if(this.type_method == 'in_work'){
                this.takeToWork();
            }else if (this.type_method == 'complete'){
                this.complete();
            }else if(this.type_method == 'return'){
                this.return();
            }else if(this.type_method == 'add_payment'){
                this.add_payment();
            }else if(this.type_method == 'add_attorney'){
                this.add_payment();
            }
        },
        add_payment(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            const formData = new FormData();
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }
            formData.append('payment_number', this.payment_number);
            formData.append('type_method', this.type_method);
            formData.append('id', this.id);
            axios.post('/api/admin/payments/add/docs', formData, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.request_added');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.type_method = '';
                        this.comment = '';
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        return(){
            if(this.user == null) {
                console.log(this.user);
                this.errors.user = this.$t('request.select_employee');
            }else{
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                axios.post('/api/admin/payments/return', {
                    comment: this.comment,
                    user: this.user,
                    id: this.id,
                }, config)
                    .then(response => {
                        if (response.status == 200) {
                            this.is_alert = true;
                            this.message = this.$t('request.request_revision');
                            setTimeout(() => {this.closeAlert(); }, 3000);
                            this.is_modal = false;
                            this.type_method = '';
                            this.comment = '';
                            this.id = null;
                            this.getData();
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        console.log(errors);
                    });
            }
        },
        complete(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/payments/complete', {
                comment: this.comment,
                id: this.id,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.request_completed');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.type_method = '';
                        this.comment = '';
                        this.id = null;
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        takeToWork(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/payments/inwork', {
                comment: this.comment,
                id: this.id,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.request_work');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.type_method = '';
                        this.comment = '';
                        this.id = null;
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>
    .btn_journal {
        line-height: unset!important;
        padding: 3px;
        font-size: 11px;
    }
    .visible {
        display: unset!important;
    }
</style>

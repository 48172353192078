<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />

            <div class="page">

                <h5 class="my-3 hstack">
                    <Buying :count="12"/>
                    Предварительная оценка
                </h5>


                <!-- Сделка -->
                <div class="item_title">
                    <h6 class="hstack">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">handshake</span>
                        Сделка
                    </h6>
                    <div>
                        <div class="border-top mt-2 pt-2 hstack justify-content-between">
                            <span class="fs-7 fw-normal">Тип сделки</span>
                            <span>Обмен на новый</span>
                        </div>
                    </div>
                </div>

                <!-- Цена на рынке -->
                <div class="item_title">
                    <h6 class="hstack">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">monitoring</span>
                        Цены
                    </h6>
                    <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                        <label class="fw-normal fs-7 hstack">
                            <span class="material-symbols-rounded me-2 text-danger">expand_less</span>
                            Максимум
                        </label>
                      <span>{{ parseInt(car_price_max).toLocaleString() }} ₸</span>
                    </div>
                    <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                        <label class="fw-normal fs-7 hstack">
                            <span class="material-symbols-rounded me-2 text-warning">navigate_next</span>
                            Средняя
                        </label>
                      <span class="text-bg-warning px-2 rounded lh-1 py-1">{{ parseInt(car_price_avg).toLocaleString() }} ₸</span>
                    </div>
                    <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                        <label class="fw-normal fs-7 hstack">
                            <span class="material-symbols-rounded me-2 text-success">expand_more</span>
                            Минимум
                        </label>
                      <span>{{ parseInt(car_price_min).toLocaleString() }} ₸</span>
                    </div>

                    <div class="hstack gap-2 border-bottom mb-2 pb-2">
                        <span class="col-6 fs-7 fw-normal">Цена клиента</span>
                        <input type="tel" class="form-control mb-0 text-end" v-model="price_client"/>
                    </div>
                    <div class="hstack gap-2">
                        <span class="col-6 fs-7 fw-normal">Цена менеджера</span>
                        <input type="tel" class="form-control mb-0 text-end" v-model="price_manager"/>
                    </div>

                </div>

                <!-- Автомобиль -->
                <div class="item_title">
                    <h6 class="hstack">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">directions_car</span>
                        Автомобиль
                    </h6>
                    <template v-if="Object.keys(car).length > 0">
                        <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                            <label class="fw-normal fs-7">Марка</label>
                            <span>{{ car.brand }}</span>
                        </div>
                        <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                            <label class="fw-normal fs-7">Модель</label>
                            <span>{{ car.model }}</span>
                        </div>
                        <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                            <label class="fw-normal fs-7">Год выпуска</label>
                            <span>{{ car.year }}</span>
                        </div>
                        <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                            <label class="fw-normal fs-7">Двигатель</label>
                            <span>{{ car.motor }}</span>
                        </div>
                        <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                            <label class="fw-normal fs-7">Привод</label>
                            <span>{{ car.actuator }}</span>
                        </div>
                        <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                            <label class="fw-normal fs-7">Пробег</label>
                            <span>{{ car_mileage.toLocaleString() }} км</span>
                        </div>
                        <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                            <label class="fw-normal fs-7">VIN</label>
                            <span>{{ car.vin_code }}</span>
                        </div>
                        <div class="border-bottom mb-2 pb-2 hstack justify-content-between">
                            <label class="fw-normal fs-7">Г/Н</label>
                            <span>{{ car.car_number }}</span>
                        </div>
                    </template>
                    <div v-else class="fs-7 fw-normal text-opacity-50">Данные об автомобиле не заполнены</div>
                </div>

                <!-- Фотографии -->
                <div class="item_title">
                    <h6 class="hstack">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">photo_camera</span>
                        Фотографии
                    </h6>
                    <template v-if="Object.keys(photos).length > 0">
                        <div class="block_foto">
                            <div
                                class="image_block hide_next"
                                v-for="(item, index) in photos "
                                :key="item.id">
                                <img
                                    class="image_car"
                                    :src="`${ item.path }`"
                                    :id="'image-'+item.id"
                                    data-fancybox="gallery"/>
                            </div>
                        </div>
                    </template>
                    <div v-else class="fs-7 fw-normal text-opacity-50">Фотографии не загружены</div>
                </div>

                <!-- Комплектация -->
                <div class="item_title">
                    <h6 class="hstack mb-3">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">rule</span>
                        Комплектация
                    </h6>
                    <div v-if="Object.keys(completes).length > 0">
                        <template v-for="(complet, index) in completes" :key="index">
                                <h6 class="mb-1">{{index}}</h6>
                                <div class="lh-base fw-normal fs-7 border-bottom mb-3 pb-3">
                                    <span v-for="item in complet">
                                        {{ item }},
                                    </span>
                                </div>
                        </template>
                    </div>
                    <div v-else class="fs-7 fw-normal text-opacity-50">Комплеткация не отмечена</div>
                </div>

                <!-- Юридическая чистота ????? -->
                <div class="item_title">
                    <h6 class="hstack">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">policy</span>
                        Юридическая чистота
                    </h6>
                    <template v-if="Object.keys(puritys).length > 0">
                        <div class="mt-3">
                            <div class="hstack border-bottom mb-2 pb-2"
                                v-for="item in puritys"
                                :key="item.id">
                                <span v-if="item.status === 'accept'" class="material-symbols-rounded me-2 text-success">check_circle</span>
                                <span v-if="item.status === 'warning'" class="material-symbols-rounded me-2 text-warning">error</span>
                                <span v-if="item.status === 'defect'" class="material-symbols-rounded me-2 text-danger">close</span>
                                <span class="fw-normal fs-7">{{ item.title }}</span>
                            </div>
                        </div>
                    </template>
                    <div v-else class="fw-normal fs-7">Юридическая чистота не проверена</div>
                </div>

                <!-- Внешний осмотр -->
                <div class="item_title">
                    <h6 class="hstack mb-3">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">directions_car</span>
                        Внешний осмотр
                    </h6>
                    <template v-if="Object.keys(exterior_array).length > 0">
                        <template v-if="exterior_array.status && exterior_array.status == 'accept'">
                            <div class="fw-normal fs-7">{{ exterior_array.message }}</div>
                        </template>
                        <template v-else>
                            <div v-for="(item, index) in exterior_array" :key="index" class="border-bottom mb-3 pb-3 hstack">
                                <template v-if="item.length > 0">
                                    <div class="vstack">
                                        <span class="hstack">
                                            <span class="material-symbols-rounded me-2 text-danger">car_crash</span>
                                            <span class="fw-semibold">{{ index }}</span>
                                        </span>
                                        <span class="fs-7 fw-normal">{{ item }}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="vstack">
                                        <span class="hstack">
                                            <span class="material-symbols-rounded me-2 text-danger">car_crash</span>
                                            <span class="fw-semibold">{{ index }}</span>
                                        </span>
                                        <template v-for="(line, idx) in item" :key="line">
                                            <template v-if="line.length > 0">
                                                <span class="fw-semibold mt-3">{{ idx }}</span>
                                                <span class="fs-7 fw-normal">{{ line }}</span>
                                            </template>
                                            <template v-else>
                                                <template v-for="(text, ind_text) in line" :key="text">
                                                    <template v-if="text !== ''">
                                                        <span class="fw-semibold mt-3">{{ ind_text }}</span>
                                                        <span class="fs-7 fw-normal">{{ text }}</span>
                                                    </template>
                                                </template>
                                            </template>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>

                    <div v-else class="fw-normal fs-7">Внешний осмотр не проведен</div>
                </div>

                <!-- Осмотр салона -->
                <div class="item_title pb-0">
                    <h6 class="hstack">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">directions_car</span>
                        Осмотр салона
                    </h6>

                    <template v-if="Object.keys(interiors_array).length > 0">
                        <template v-if="interiors_array.status && interiors_array.status == 'accept'">
                            <div class="fw-normal fs-7">{{ interiors_array.message }}</div>
                        </template>
                        <template v-else>
                            <template v-for="(inter_damages, inter_index) in interiors_array" :key="inter_damages">
                            <div>
                                <div class="hstack mb-1">
                                    <span class="material-symbols-rounded me-2 text-danger">error</span>
                                    {{ inter_index }}
                                </div>
                                <div class="fs-7 fw-normal">
                                    Износ в баллах:
                                    <div class="wear_detail_wrap mt-1">
                                        <div class="wear_detail_row hstack gap-1">
                                            <span class="w1 vstack text-center lh-1 p-2 rounded"
                                                :class="[(parseInt(inter_damages.iznos)) == 1 ? 'active' : '']">
                                                <span class="wN_num">1</span>
                                                <span class="wN_label">ужасно</span>
                                            </span>
                                            <span class="w2 vstack text-center lh-1 p-2 rounded"
                                                :class="[(parseInt(inter_damages.iznos)) == 2 ? 'active' : '']">
                                                <span class="wN_num">2</span>
                                                <span class="wN_label">плохо</span>
                                            </span>
                                            <span class="w3 vstack text-center lh-1 p-2 rounded"
                                                :class="[(parseInt(inter_damages.iznos)) == 3 ? 'active' : '']">
                                                <span class="wN_num">3</span>
                                                <span class="wN_label">нормально</span>
                                            </span>
                                            <span class="w4 vstack text-center lh-1 p-2 rounded"
                                                :class="[(parseInt(inter_damages.iznos)) == 4 ? 'active' : '']">
                                                <span class="wN_num">4</span>
                                                <span class="wN_label">хорошо</span>
                                            </span>
                                            <span class="w5 vstack text-center lh-1 p-2 rounded"
                                                :class="[(parseInt(inter_damages.iznos)) == 5 ? 'active' : '']">
                                                <span class="wN_num">5</span>
                                                <span class="wN_label">отлично</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="inter_damages.damage.length > 0" class="border-bottom mb-3 pb-3 mt-2">
                                    <div class="">Повреждения</div>
                                    <div class="fs-7 fw-normal">{{ inter_damages.damage }}</div>
                                </div>
                                <div v-else class="border-bottom mb-3 pb-3"></div>
                            </div>
                        </template>
                        </template>
                    </template>
                    <div v-else class="fw-normal fs-7">Осмотр салона не проведен</div>
                </div>

                <!-- Подкапотное пространство -->
                <div class="item_title pb-0">
                    <h6 class="hstack mb-3">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">directions_car</span>
                        Подкапотное пространство
                    </h6>
                    <template v-if="hood_array.status == 'defect'">
                        <div v-for="(item, index) in hood_array.defects" :key="index" class="border-bottom mb-3 pb-3">
                            <div v-if="!item.smudges" class="hstack">
                                <span class="material-symbols-rounded me-2 text-danger">car_crash</span>
                                {{ index }}
                            </div>
                            <div v-else class="hstack">
                                <span class="material-symbols-rounded me-2 text-danger">water_drop</span>
                                Имеются масляные подтёки.
                            </div>
                            <div class="fs-7 fw-normal" v-if="item.damage">
                                {{ item.damage }}
                            </div>
                        </div>
                    </template>
                    <template v-else-if="hood_array.status == 'accept'">
                        <div class="fw-normal fs-7">Дефектов не обнаружено</div>
                    </template>
                    <template v-else>
                        <div class="fw-normal fs-7">Подкапотное пространство не осмотрено</div>
                    </template>
                </div>

                <!-- Комплектность -->
                <div class="item_title">
                    <h6 class="hstack">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">directions_car</span>
                        Комплектность
                    </h6>
                    <template v-if="qurations.length > 0">
                        <div v-for="(item, index) in qurations" :key="index" class="hstack">
                            <span class="material-symbols-rounded me-2 text-danger">close</span>
                            <span class="fs-7 fw-normal">{{ item }}</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="fw-normal fs-7">Без дефектов</div>
                    </template>
                </div>

                <!-- Тест-драйв -->
                <div class="item_title">
                    <h6 class="hstack mb-3">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">directions_car</span>
                        Тест-драйв
                    </h6>

                    <template v-if="Object.keys(test_drive).length > 0">
                        <template v-if="test_drive.status && test_drive.status == 'accept'">
                            <div class="fw-normal fs-7">{{ test_drive.message }}</div>
                        </template>
                        <template v-else>
                            <div v-for="(item, index) in test_drive" :key="index">
                                <div class="hstack">
                                    <span class="material-symbols-rounded me-2 text-danger">car_crash</span>
                                    <span>{{ index }}</span>
                                </div>
                                <div class="fs-7 fw-normal border-bottom pb-3 mb-3">
                                    <span>{{ item }}</span>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="fw-normal fs-7">Тест-драйв не проведён</div>
                    </template>
                </div>

                <!-- Комментарий -->
                <div v-if="tradein.status_price == 'new'">
                    <label class="form-label">Комментарий</label>
                    <textarea class="form-control" v-model="comment"></textarea>
                </div>

                <div class="item_title" v-if="comment">
                    <h6 class="hstack mb-3">
                        <span class="material-symbols-rounded me-2 mb-1 opacity-50">comment</span>
                        Комментарий
                    </h6>
                    <div class="fw-normal fs-7">{{ comment }}</div>
                </div>

            </div>


            <div class="estimation">

                <div class="my-4">
                    <button class="btn btn-green w-100"
                            v-if="tradein.status_price == 'new'"
                        @click="save"
                    >Сохранить</button>

                    <button class="btn btn-yellow w-100"
                            v-if="tradein.status == 'remaking' && !is_chief"
                            @click="showModal('remaking_manager')"
                    >
                        <span class="material-symbols-rounded me-2 opacity-50">check</span>
                        Доработано
                    </button>

                    <button class="btn btn-green w-100"
                            v-if="tradein.status == 'adjustment' && !is_chief"
                            @click="showModal('adjustment_manager')"
                    >
                        <span class="material-symbols-rounded me-2 opacity-50">check</span>
                        Откорректировано
                    </button>
                </div>
                <div v-if="is_chief &&
                    tradein.status_price != 'new' &&
                    tradein.status != 'adjustment' &&
                    tradein.status != 'remaking' &&
                    tradein.status != 'rejection' &&
                    tradein.status_price != 'approved'"
                >
                   <button class="btn btn-primary w-100 mb-2"
                           @click="approved"
                   >
                       <span class="material-symbols-rounded me-2 opacity-50">check</span>
                       Утвердить
                   </button>

                   <button class="btn btn-green w-100 mb-2"
                           @click="showModal('adjustment')"
                   >
                       <span class="material-symbols-rounded me-2 opacity-50">autorenew</span>
                       Корректировка
                   </button>

                   <button class="btn btn-yellow w-100 mb-2"
                           @click="showModal('remaking')"
                   >
                       <span class="material-symbols-rounded me-2 opacity-50">autorenew</span>
                       Доработка
                   </button>

                   <button class="btn btn-red w-100"
                           @click="showModal('rejection')"
                   >
                       <span class="material-symbols-rounded me-2 opacity-50">block</span>
                       Отказ
                   </button>
                </div>

                <transition name="slide-fade">
                    <div class="popup" v-if="is_modal">
                        <div class="popup-content">
                            <div class="close float-end" @click="closeModal">
                                <span class="material-symbols-rounded">close</span>
                            </div>
                            <div class="mb-3" v-if="is_adjustment">
                                <label class="form-label">Цена*</label>
                                <div class="fs-4 ps-2 fw-bold"></div>
                                <Field
                                    v-model="adjusted_price"
                                    type="number"
                                    class="form-control"
                                />
                                <ErrorMessage name="summ" style="color: red;"/>
                            </div>

                            <div class="mb-3 mt-3">
                                <label class="form-label">Комментарий</label>
                                <textarea class="form-control" v-model="comment_history"></textarea>
                            </div>

                            <div class="col-sm position-relative mb-2 mt-2">
                                <button class="btn btn_vw btn-green"
                                        @click="saveRequest"
                                >
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>
<!--                <transition>
                    <div class="popup-fade" v-if="is_modal">
                        <div class="td-modal">
                            <div class="row" style="height: 20px;">
                                <div class="col-12">
                                    <svg @click="closeModal" style="position: absolute; right: 2%; z-index: 1000;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 9.40764L21.075 0.332642L23.6673 2.92498L14.5923 12L23.6673 21.075L21.075 23.6673L12 14.5923L2.92498 23.6673L0.332642 21.075L9.40764 12L0.332642 2.92498L2.92498 0.332642L12 9.40764Z" fill="#AFBBCC"/>
                                    </svg>
                                </div>
                            </div>
                            <hr/>
                            <div class="row mb-3" v-if="is_adjustment">
                                <label class="col-sm-12 col-form-label required text-start">Цена*</label>
                                <div class="col-sm position-relative">
                                    <Field
                                        v-model="adjusted_price"
                                        type="number"
                                        class="form-control"
                                    />
                                    <ErrorMessage name="summ" style="color: red;"/>
                                </div>
                            </div>
                            <div class="row mb-3 mt-3">
                                <label class="col-sm-12 col-form-label required text-start">Комментарий</label>
                                <div class="col-sm position-relative">
                                    <textarea class="form-control" rows="3" v-model="comment_history"></textarea>
                                </div>
                            </div>
                            <div class="col-sm position-relative mb-2 mt-2">
                                <button class="btn btn_vw btn-green"
                                        @click="saveRequest"
                                >
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>-->
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import Buying from "./Buying";
import axios from "axios";
import Spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import Modules from "../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import { template } from "lodash";

export default {
    name: "Step_12",
    components: {
    Buying,
    Spinner,
    BreadCrumbs,
    ToastAlert,
    Field,
    Form,
    ErrorMessage,
    template
},
    data: () => ({
        type: 'green',
        message: '',
        is_alert: false,
        isLoading: false,
        step: 'disabled',
        back_step: 'testdrive',
        visible: false,
        is_chief: false,
        is_modal: false,
        is_adjustment: false,
        type_method: null,
        adjusted_price: null,
        is_new: true,
        car: [],
        files: [],
        photos: [],
        puritys: [],
        interiors_array: [],
        hood_array: [],
        test_drive: [],
        completes: [],
        price: [],
        qurations: [],
        exterior_array: [],
        tradein: [],
        price_client: null,
        price_manager: null,
        comment: null,
        comment_history: null,
        breadCrumbs: true,
        title: 'Лист согласования',
        back_title: 'Этапы приёма',
        link: '',
        car_mileage: 0,
        car_price_max: '',
        car_price_avg: 0,
        car_price_min: 0,
    }),
    created() {
        this.getData();
        this.getBackLink();
    },
    methods: {
        getBackLink() {
            this.link = this.link = `tradeinlist/${this.$route.params.id}`
        },
        closeModal(){
            this.is_modal = false;
            this.is_adjustment = false;
            this.type_method = null;
            this.comment_history = null;
        },
        showModal(type){
            if(type == 'adjustment'){
                this.is_adjustment = true;
            }
            this.type_method = type;
            this.is_modal = true;
        },
        closeAlert(){
            this.is_alert = false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/estimation/${this.$route.params.id}`, config);
                this.car = response.data.data.car;
                this.photos = response.data.data.photos;
                this.puritys = response.data.data.puritys;
                this.interiors_array = response.data.data.interiors_array;
                this.exterior_array = response.data.data.exterior_array;
                this.hood_array = response.data.data.hood_array;
                this.test_drive = response.data.data.test_drive;
                this.completes = response.data.data.completes;
                this.price = response.data.data.price;
                this.comment = response.data.data.comment;
                this.qurations = response.data.data.qurations;
                this.price_client = this.price.price_client;
                this.price_manager = this.price.price_manager;
                this.is_chief = response.data.data.is_chief;
                //this.is_new = response.data.data.is_new;
                this.tradein = response.data.data.tradein;
                this.car_mileage = response.data.data.car.mileage;
                this.car_price_max = response.data.data.tradein.price_max;
                this.car_price_avg = response.data.data.tradein.price_average;
                this.car_price_min = response.data.data.tradein.price_min;
            } catch (e) {
                this.isLoading = false;
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        saveRequest(){
            if(this.type_method == 'rejection'){
                this.rejection();
            }else if(this.type_method == 'remaking'){
                this.remaking();
            }else if(this.type_method == 'adjustment'){
                this.adjustment();
            }else if(this.type_method == 'remaking_manager'){
                this.saveRemaking();
            }else if(this.type_method == 'adjustment_manager'){
                this.saveAdjustment();
            }
        },
        adjustment(){
            this.closeModal();
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/adjustment`, {
                id: this.$route.params.id,
                comment_history: this.comment_history,
                adjusted_price: this.adjusted_price,
            }, config)
                .then(response =>{
                    this.isLoading = false;
                    if(response.status == 200){
                        this.type_method = '';
                        this.is_adjustment = false;
                        if(response.data.data.is_chief){
                            this.getData();
                        }else{
                            this.$router.push('/journal');
                        }

                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        remaking(){
            this.closeModal();
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/remaking`, {
                id: this.$route.params.id,
                comment_history: this.comment_history,
            }, config)
                .then(response =>{
                    this.isLoading = false;
                    if(response.status == 200){
                        this.type_method = '';
                        if(response.data.data.is_chief){
                            this.getData();
                        }else{
                            this.$router.push('/journal');
                        }
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        rejection(){
            this.closeModal();
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/rejection`, {
                id: this.$route.params.id,
                comment_history: this.comment_history,
            }, config)

                .then(response =>{
                    this.isLoading = false;
                    if(response.status == 200){
                        this.type_method = '';
                        if(response.data.data.is_chief){
                            this.getData();
                        }else{
                            this.$router.push('/journal');
                        }
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        approved(){
            this.closeModal();
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/approved`, {
                id: this.$route.params.id,
            }, config)

                .then(response =>{
                    if(response.status == 200){
                        this.type_method = '';
                        if(response.data.data.is_chief){
                            this.getData();
                        }else{
                            this.$router.push('/journal');
                        }
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        save(){
            if(this.price_client == null && this.price_manager == null){
                this.is_alert = true;
                this.type = 'red';
                this.message = 'Введите цену клиента и менеджера!';
                setTimeout(() => {this.closeAlert(); }, 3000);
            }else{
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                axios.post(`/api/admin/tradein/estimation`, {
                    id: this.$route.params.id,
                    price_client: this.price_client,
                    price_manager: this.price_manager,
                    comment:  this.comment,
                    step_number: 12,
                }, config)

                    .then(response =>{
                        if(response.status == 200){
                            if(response.data.data.is_chief){
                                this.getData();
                            }else{
                                this.$router.push('/journal');
                            }
                        }
                    })
                    .catch(error =>{
                        this.isLoading = false;
                        console.log(error);
                    });
            }
        },
        saveAdjustment(){
            this.closeModal();
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/save/adjustment`, {
                id: this.$route.params.id,
                price_client: this.price_client,
                price_manager: this.price_manager,
                comment:  this.comment,
                step_number: 12,
                comment_history: this.comment_history,
            }, config)

                .then(response =>{
                    if(response.status == 200){
                        if(response.data.data.is_chief){
                            this.getData();
                        }else{
                            this.$router.push('/journal');
                        }
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        saveRemaking(){
            this.closeModal();
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/save/remaking`, {
                id: this.$route.params.id,
                price_client: this.price_client,
                price_manager: this.price_manager,
                comment:  this.comment,
                step_number: 12,
                comment_history: this.comment_history,
            }, config)

                .then(response =>{
                    if(response.status == 200){
                        if(response.data.data.is_chief){
                            this.getData();
                        }else{
                            this.$router.push('/journal');
                        }
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        cur(str) {
            let parts = (str + '').split('.'),
                main = parts[0],
                len = main.length,
                output = '',
                i = len - 1;

            while(i >= 0) {
                output = main.charAt(i) + output;
                if ((len - i) % 3 === 0 && i > 0) {
                    output = ' ' + output;
                }
                --i;
            }

            if (parts.length > 1) {
                output += '.' + parts[1];
            }
            return output;
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="top-line hstack justify-content-between">
        <div class="logo-box hstack justify-content-between">
            <div class="back-arrow">
                <a :href="'/boards/doska-to'">
                    <span class="material-symbols-outlined">arrow_back_ios</span>
                </a>
            </div>
        </div>
    </div>
    <div class="page">
        <div class="table-responsive-md">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>Название автомобиля</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.car_name }}</td>
                    <td v-for="(prices, capacity) in item.price" :key="capacity">
                        {{ capacity }}
                        <ul>
                            <li v-for="(priceDetail, priceIndex) in prices" :key="priceIndex">
                                {{ priceDetail.title }}: {{ priceDetail.price }}₸
                            </li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import * as req from "../../../services/boards";

export default {
    name: "Display",
    data() {
        return {
            items: [],
            cars: [],
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(){
            let result = await req.getTOBoards();
            this.items = result.items;
            this.cars = result.cars;
        },
    }
}
</script>

<style scoped>

</style>

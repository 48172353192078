<template xmlns:fa="http://www.w3.org/1999/html">
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="col-12 d-flex flex-wrap modules">

                <div class="col-12 col-lg-4 col-sm-6 col-xl-3 module_box pb-3"
                    v-if="contains(actions, 'prosmotr-cek-list-avtomoika')"
                >
                    <router-link class="d-flex h-100 justify-content-between module" to="/car">
                        <div class="d-flex module_icon">
                            <fa icon="check"></fa>
                        </div>
                        <div class="d-flex flex-column module_titles text-end">
                            <div class="module_title mb-1">
                                чек-лист автомойка
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-12 col-lg-4 col-sm-6 col-xl-3 module_box pb-3"
                    v-if="contains(actions, 'prosmotr-cek-list-tsm')"
                >
                    <router-link class="d-flex h-100 justify-content-between module" to="/tsm/item">
                        <div class="d-flex module_icon">
                            <fa icon="check"></fa>
                        </div>
                        <div class="d-flex flex-column module_titles text-end">
                            <div class="module_title mb-1">
                                чек-лист tsm
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-12 col-lg-4 col-sm-6 col-xl-3 module_box pb-3"
                    v-if="contains(actions, 'prosmotr-cek-list-ezednevnye')"
                >
                    <router-link class="d-flex h-100 justify-content-between module" to="/quality">
                        <div class="d-flex module_icon">
                            <fa icon="check"></fa>
                        </div>
                        <div class="d-flex flex-column module_titles text-end">
                            <div class="module_title mb-1">
                                чек-лист ежедневные
                            </div>
                        </div>
                    </router-link>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import {getUserPermissionActions} from "../../services/helpers";

export default {
    name: "SelectCheck",
    components: {
        spinner, BreadCrumbs
    },
    data() {
        return {
            actions: [],
            isLoading: true,
            breadCrumbs: true,
            title: 'Список чек листов',
            back_title: 'Чек листы TSM',
            link: 'tsm',
        }
    },
    created() {
        this.getUserActions();
    },
    methods: {
        async getUserActions(){
            this.isLoading = true;
            this.actions = await getUserPermissionActions(this.$route.path);
            this.isLoading = false;
        },
        contains(arr, elem){
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].alias === elem) {
                    return true;
                }
            }
            return false;
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page" v-if="is_form">

                <h5 class="mt-3">Новый договор</h5>

                <Form ref="contract">

<!--                    <div class="mb-3">
                        <label class="form-label">Организация</label>
                        <VueMultiselect
                            v-model:modelValue="organization"
                            placeholder="Выберите организацию"
                            label="name"
                            track-by="id"
                            :options="organizations"
                            name="organization"
                            :class="errors.organization.length ? 'error' : ''"
                            @select="selectOrganization($event)"
                            @clear="clearInput($event)"
                        />
                    </div>-->

                    <div class="mb-3">
                        <label class="form-label">Автомобиль</label>
                        <VueMultiselect
                            v-model="contract.car"
                            placeholder="Выберите автомобиль"
                            label="name"
                            track-by="id"
                            :options="cars"
                            :searchable="false"
                            :show-labels="false"
                            @select="selectCar($event)"
                            :class="[errors.car.length > 0 ? 'error is-invalid' : '']"></VueMultiselect>
                        <ErrorMessage name="car"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Менеджер</label>
                        <Field
                            v-model="contract.manager"
                            type="text"
                            readonly="true"
                            name="manager"
                            class="form-control"
                            :class="[errors.manager.length > 0 ? 'error' : '']"/>
                        <ErrorMessage name="manager"/>
                    </div>

                    <div class="mb-3 position-relative">
                        <label class="form-label">Телефон</label>
                            <Field
                                placeholder="+7 (___) ___-__-__"
                                v-model="phone"
                                type="tel"
                                name="phone"
                                class="form-control"
                                :class="[errors.phone.length > 0 ? 'error' : '']"
                                v-telmask
                                />
                            <span class="input_search_preloader" v-if="isSearch">
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </span>
                            <ErrorMessage name="phone" style="color: red;"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Фамилия</label>
                        <Field
                            placeholder="Фамилия"
                            v-model="contract.surname"
                            type="text"
                            name="surname"
                            class="form-control"
                            :class="[errors.surname.length > 0 ? 'error is-invalid' : '']"/>
                        <ErrorMessage name="surname" style="color: red;"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Имя</label>
                        <Field
                            placeholder="Имя"
                            v-model="contract.name"
                            type="text"
                            name="name"
                            class="form-control"
                            :class="[errors.name.length > 0 ? 'error is-invalid' : '']"/>
                        <ErrorMessage name="name" style="color: red;"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Отчество</label>
                        <Field
                            placeholder="Отчество"
                            v-model="contract.patronymic"
                            type="text"
                            name="patronymic"
                            class="form-control"
                            :class="[errors.patronymic.length > 0 ? 'error' : '']"/>
                        <ErrorMessage name="patronymic"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Уд.л.</label>
                        <Field
                            placeholder="№ ..."
                            v-model="contract.id_number"
                            type="number"
                            name="id_number"
                            class="form-control"
                            :class="[errors.id_number.length > 0 ? 'error' : '']"/>
                        <ErrorMessage name="id_number"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Кем выдано Уд.л.</label>
                        <Field
                            placeholder="Кем выдано..."
                            v-model="contract.id_issued"
                            type="text"
                            name="id_issued"
                            class="form-control"
                            :class="[errors.id_issued.length > 0 ? 'error' : '']"/>
                        <ErrorMessage name="id_issued"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Дата выдачи Уд.л.</label>
                        <Field
                            placeholder="__.__.____"
                            v-maska="date_mask"
                            v-model="contract.id_date"
                            type="text"
                            name="id_date"
                            class="form-control"
                            :class="[errors.id_date.length > 0 ? 'error' : '']"/>
                        <ErrorMessage name="id_date" style="color: red;"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Вод.уд.</label>
                        <Field
                            placeholder="№ ..."
                            v-model="contract.driver_id_number"
                            type="text"
                            name="driver_id_number"
                            class="form-control"
                            :class="[errors.driver_id_number.length > 0 ? 'error' : '']"/>
                        <ErrorMessage name="driver_id_number"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Дата выдачи Вод.уд.</label>
                        <Field
                            placeholder="__.__.____"
                            v-maska="date_mask"
                            v-model="contract.driver_id_date"
                            type="text"
                            name="driver_id_date"
                            class="form-control"
                            :class="[errors.driver_id_date.length > 0 ? 'error' : '']"/>
                        <ErrorMessage name="driver_id_date" style="color:red;"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">ИИН</label>
                        <Field
                            placeholder="____________"
                            v-maska="iin_mask"
                            v-model="contract.IIN"
                            type="text"
                            name="IIN"
                            class="form-control"
                            :class="[errors.IIN.length > 0 ? 'error is-invalid' : '']"/>
                        <ErrorMessage name="IIN" style="color: red;"/>
                    </div>

                    <div class="mt-4">
                        <div class="form-check">
                            <label class="form-check-label fw-semibold fs-7">
                                <input
                                    type="checkbox"
                                    @click="toggleClientBtw"
                                    class="form-check-input border_ckeckbox shadow-sm me-2 opacity-75"
                                    :checked="client_btw"/>
                                Клиент за рулем
                            </label>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="form-check">
                            <label class="form-check-label fw-semibold fs-7">
                                <input
                                    type="checkbox"
                                    @click="toggleClientFromService"
                                    class="form-check-input border_ckeckbox shadow-sm me-2 opacity-75"
                                    :checked="client_from_service"/>
                                Клиент с сервиса
                            </label>
                        </div>
                    </div>

                    <div class="mb-4">
                        <label class="form-label">Примечание</label>
                        <textarea
                            class="form-control comment_step_third"
                            v-model="contract.comment"></textarea>
                    </div>

                </Form>

                <div class="mb-3">
                    <button class="btn w-100 btn-red" @click="showRules">
                        Обязанности клиента
                    </button>
                </div>

            </div>
        </div>
    </transition>

    <rules v-if="is_rules" @close-form="close" @show-sign="showSign"/>

    <sign
        v-if="is_sign"
        @close-form-sign="closeSign"
        @save-sign="createContract($event)"
        :app="app"/>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            type="type"/>
    </transition>
</template>

<script>

const telmask = {
    mounted: (el, binding) => {
        el.onfocus = function (e) {
            this.value = '+7 ('
            this.setSelectionRange(4, 4)
        }
        el.oninput = function (e) {
            if (!e.isTrusted) {
                return
            }
            const x = this
                .value
                .replace(/\D/g, '')
                .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
            x[1] = '+7'
            this.value = !x[3]
                ? x[1] + ' (' + x[2]
                : x[1] + ' (' + x[2] + ') ' + x[3] + (
                    x[4]
                        ? '-' + x[4]
                        : ''
                ) + (
                    x[5]
                        ? '-' + x[5]
                        : ''
                )
        }
    }
}



import axios from "axios";
import { Field, Form, ErrorMessage } from 'vee-validate';
import spinner from '../../../components/Spinner';
import ToastAlert from '../../../components/ToastAlert';
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Rules from "../Rules";
import Sign from "../Sign";
import { mask } from 'maska';
import * as req from "../../../services/td";

export default {
    name: "TDCreate",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs, Rules, Sign, ToastAlert, mask
    },
    directives: {
        telmask
    },
    data() {
        return {
            type: 'green',
            message: '',
            app: 'td',
            is_alert: false,
            isSearch: false,
            is_rules: false,
            is_form: true,
            is_sign: false,
            //mask: '+#(###)###-##-##',
            date_mask: '##.##.####',
            iin_mask: '############',
            cars: [],
            //organizations: [],
            //organization: null,
            phone: '',
            client_btw: false,
            client_from_service	: false,
            contract: {
                id: null,
                car: null,
                manager: null,
                surname: null,
                name: null,
                patronymic: null,
                id_number: null,
                id_issued: null,
                id_date: null,
                driver_id_number: null,
                driver_id_date: null,
                IIN: null,
                comment: null,
            },
            errors: {
                car: '',
                manager: '',
                surname: '',
                name: '',
                patronymic: '',
                phone: '',
                id_number: '',
                id_issued: '',
                id_date: '',
                driver_id_number: '',
                driver_id_date: '',
                IIN: '',
                comment: '',
                //organization: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: 'Добавить',
            back_title: 'Список договоров',
            link: 'test-drive',
        }
    },
    watch: {
        phone(after, before) {
            this.search_client();
        }
    },
    created() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        toggleClientBtw() {
            this.client_btw = !this.client_btw
        },
        toggleClientFromService() {
            this.client_from_service = !this.client_from_service
        },
        createContract(event){
            this.isLoading = true;
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.post('/api/admin/testdrives/save/contract', {
                data: JSON.stringify(event),
                id: this.id,
                //organization: this.organization,
                car: this.contract.car,
                surname: this.contract.surname,
                name: this.contract.name,
                patronymic: this.contract.patronymic,
                id_number: this.contract.id_number,
                id_issued: this.contract.id_issued,
                id_date: this.contract.id_date,
                driver_id_number: this.contract.driver_id_number,
                driver_id_date: this.contract.driver_id_date,
                IIN: this.contract.IIN,
                comment: this.contract.comment,
                client_btw: this.client_btw,
                client_from_service	: this.client_from_service,
                phone: this.phone,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.$router.push(`/test-drive/contract/${response.data.data.contract_id}/${event.download}`)
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        validate(){
            let error = [];
            if(this.contract.surname == null){
                this.errors.surname = 'Поле фамилия обязательно';
                error.push('Поле фамилия обязательно');
            }
            if(this.contract.name == null){
                this.errors.name = 'Поле имя обязательно';
                error.push('Поле имя обязательно');
            }
            if(this.contract.IIN == null){
                this.errors.IIN = 'Поле ИИН обязательно';
                error.push('Поле ИИН обязательно');
            }
            if(this.contract.car == null){
                this.errors.car = 'Поле Автомобиль обязательно';
                error.push('Поле Автомобиль обязательно');
            }
            /*if(this.organization == null){
                this.errors.organization = 'Поле Организация обязательно';
                error.push('Поле Организация обязательно');
            }*/

            if(error.length > 0){
                return false;
            }else{
                return true;
            }
        },
        showSign(){
            this.is_rules = false;
            this.is_sign = true;
        },
        showRules(){
            this.validate();
            if(this.validate()){
                this.is_rules = true;
                this.is_form = false;
            }
        },
        close(){
            this.is_form = true;
            this.is_rules = false;
        },
        closeSign(){
            this.is_rules = true;
            this.is_sign = false;
        },
        async getData() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get('/api/admin/testdrives/get/data', config);
                //this.organizations = response.data.data.organizations;
                this.contract.manager = response.data.data.manager;
                this.cars = response.data.data.cars;
                console.log(this.cars);
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        async search_client(){
            try {
                this.isSearch = true;
                if(this.phone.length == 18){
                    const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                    const response = await axios.get(`/api/admin/testdrives/search/${this.phone}`, config);
                    if(Object.keys(response.data.data.clients).length > 0){
                        this.is_alert = true;
                        this.message = 'Клиент найден';
                        this.errors.surname = '';
                        this.errors.name = '';
                        this.errors.IIN = '';
                        this.id = response.data.data.clients.id;
                        this.contract.surname = response.data.data.clients.surname;
                        this.contract.name = response.data.data.clients.name;
                        this.contract.patronymic = response.data.data.clients.patronymic;
                        this.contract.id_number = response.data.data.clients.id_number;
                        this.contract.id_issued = response.data.data.clients.id_issued;
                        this.contract.id_date = response.data.data.clients.id_date;
                        this.contract.driver_id_number = response.data.data.clients.driver_id_number;
                        this.contract.driver_id_date = response.data.data.clients.driver_id_date;
                        this.contract.IIN = response.data.data.clients.IIN;
                    }else {
                        this.is_alert = true;
                        this.message = 'Клиент не найден';
                        this.type = 'yellow';
                    }
                    setTimeout(() => {this.closeAlert(); }, 3000);
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.isSearch = false;
            }
        },
       /* async selectOrganization(item){
            this.isLoading = true;
            this.errors.organization = '';
            let result = await req.getOrganizationCars(item.id);
            this.cars = result.cars;
            this.isLoading = false;
        },*/
        clearInput(e){
            this.errors[e] = '';
        },
        selectCar(item){
            this.errors.car = '';
        }
    }
}
</script>

<style scoped>

</style>

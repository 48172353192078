import Index from '../../Modules/Permission/Index';
import * as auth from '../../services/auth';
import CreatePermission from "../../Modules/Permission/CreatePermission";
import EditPermission from "../../Modules/Permission/EditPermission";
import Actions from "../../Modules/Permission/Actions";



export default [
    {
        name: 'permissions',
        path: '/admins/permissions',
        component: Index,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CreatePermissions',
        path: '/admins/permissions/create',
        component: CreatePermission,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'EditPermissions',
        path: '/admins/permissions/edit/:id',
        component: EditPermission,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Actions',
        path: '/admins/permissions/actions/:id',
        component: Actions,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="d-flex flex-column justify-content-center mt-0">
                <div class="mt-2 mb-2 text-center">
                    <router-link class="btn btn-red btn_vw" to="/request/settings/categories">
                        {{ $t('request.categories') }}
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center">
                    <router-link class="btn btn-red btn_vw" to="/request/settings/documents">
                        {{ $t('request.routes') }}
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center">
                    <router-link class="btn btn-red btn_vw" to="/request/settings/imprest">
                        {{ $t('request.advance_imprest_settings') }}
                    </router-link>
                </div>
                <div class="mt-2 mb-2 text-center">
                    <router-link class="btn btn-red btn_vw" to="/request/settings/memos">
                        {{ $t('request.memo_settings') }}
                    </router-link>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";

export default {
    name: "SettingsPage",
    components: {
        spinner, BreadCrumbs, Modules
    },
    data() {
        return {
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.request_menu'),
            back_title: this.$t('request.request_menu'),
            link: 'request',
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <h6 class="pb-3 pt-1">Версии системы</h6>
    <div v-for="version in versions_list">
        <h6 class="fs-7">
            <span>{{ version.ver }}</span>
            <span class="fw-normal"> &ndash; {{ version.date }}</span>
        </h6>
        <div class="border-bottom mb-3 pb-3" style="border-color: #cfc9da !important;">
            <div v-for="text in version.text" class="fs-7">
                {{text}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "versions",
    data() {
        return {
            versions_list: [
                {
                    ver: 'v.1.47',
                    date: '14.12.2022',
                    text: {
                        0: '- Подключен модуль Прием автомобилей с автовоза.',
                    }
                },
                {
                    ver: 'v.1.46',
                    date: '12.12.2022',
                    text: {
                        0: '- Обновление модуль TradeIn.',
                    }
                },
                {
                    ver: 'v.1.45',
                    date: '05.12.2022',
                    text: {
                        0: '- Подключен модуль TradeIn для поэтапного приёма автомобилей с пробегом.',
                    }
                },
                {
                    ver: 'v.1.44',
                    date: '14.11.2022',
                    text: {
                        0: '- Реализовано восстановление пароля по электронной почте.',
                    }
                },
            ]
        }
    }
}

</script>

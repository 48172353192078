<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="d-flex justify-content-around gap-3 flex-wrap" v-if="this.lists.length > 0">
                    <h5 class="ps-3 mb-3"><fa icon="list-check" class="icon" style="padding-right: 10px; color: #c00;"></fa>Список выдач</h5>

                    <div  class="col-12 item_title mb-3"
                          v-for="list in lists"
                          :key="list.id"
                    >
                        <h5 class="text-center mb-3">Договор № {{ list.info.contract_num }}</h5>
                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="car"></fa>
                                    {{ list.info.car }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="user"></fa>
                                    {{ list.info.client }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="user-tie"></fa>
                                    {{ list.user.surname }} {{ list.user.name }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="clock"></fa>
                                    {{ list.time_period[0] }} -
                                    {{ list.time_period[1] }}
                                </div>
                            </div>
                        </div>
                        <hr>
                        <!--Ресепшн-->
                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock" v-if="!list.ch_reception"></fa>
                                    <fa class="icon pe-1 pt-1 text-success" icon="user-check" v-if="list.ch_reception"></fa>
                                    Ресепшн
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-black-50" icon="check" v-if="!list.ch_reception" @click="issuanceMark(list.id, 'ch_reception', 1)"></fa></span>
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-success" icon="check" v-if="list.ch_reception"></fa></span>
                                </div>
                            </div>
                        </div>

                        <!--СБ доки-->
                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock" v-if="list.ch_sb == 'in_work'"></fa>
                                    <fa class="icon pe-1 pt-1 text-success" icon="user-check" v-if="list.ch_sb == 'agreed'"></fa>
                                    СБ - сверка доукомплектации
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-black-50" icon="check" v-if="list.ch_sb == 'in_work'" @click="issuanceMark(list.id, 'ch_sb', 'agreed')"></fa></span>
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-success" icon="check" v-if="list.ch_sb == 'agreed'"></fa></span>
                                </div>
                            </div>
                        </div>

                        <!--ФИС-->
                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock" v-if="!list.ch_credit"></fa>
                                    <fa class="icon pe-1 pt-1 text-success" icon="user-check" v-if="list.ch_credit"></fa>
                                    Отдел кредитования
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-black-50" icon="check" v-if="!list.ch_credit" @click="issuanceMark(list.id, 'ch_credit', 1)"></fa></span>
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-success" icon="check" v-if="list.ch_credit"></fa></span>
                                </div>
                            </div>
                        </div>

                        <!--Касса-->
                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock" v-if="list.ch_cash == 'in_work'"></fa>
                                    <fa class="icon pe-1 pt-1 text-success" icon="user-check" v-if="list.ch_cash == 'agreed'"></fa>
                                    Касса
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-black-50" icon="check" v-if="list.ch_cash == 'in_work'" @click="issuanceMark(list.id, 'ch_cash', 'agreed')"></fa></span>
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-success" icon="check" v-if="list.ch_cash == 'agreed'"></fa></span>
                                </div>
                            </div>
                        </div>

                        <!--РОП стандарты-->
                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock" v-if="list.ch_chief == 'in_work'"></fa>
                                    <fa class="icon pe-1 pt-1 text-success" icon="user-check" v-if="list.ch_chief == 'agreed'"></fa>
                                    {{ list.chief_name_short }} - стандарты выдачи
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-black-50" icon="check" v-if="list.ch_chief == 'in_work'" @click="issuanceMark(list.id, 'ch_chief', 'agreed')"></fa></span>
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-success" icon="check" v-if="list.ch_chief == 'agreed'"></fa></span>
                                </div>
                            </div>
                        </div>

                        <!--РОП выезд-->
                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock" v-if="list.access_chief == 'in_work'"></fa>
                                    <fa class="icon pe-1 pt-1 text-success" icon="user-check" v-if="list.access_chief == 'agreed'"></fa>
                                    {{ list.chief_name_short }} - разрешение на выдачу
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-black-50" icon="check" v-if="list.access_chief == 'in_work'" @click="issuanceMark(list.id, 'access_chief', 'agreed')"></fa></span>
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-success" icon="check" v-if="list.access_chief == 'agreed'"></fa></span>
                                </div>
                            </div>
                        </div>

                        <!--СБ выезд-->
                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock" v-if="list.access_sb == 'in_work'"></fa>
                                    <fa class="icon pe-1 pt-1 text-success" icon="user-check" v-if="list.access_sb == 'agreed'"></fa>
                                    СБ - разрешение на выезд
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-black-50" icon="check" v-if="list.access_sb == 'in_work'" @click="issuanceMark(list.id, 'access_sb', 'agreed')"></fa></span>
                                    <span class="p-2"><fa class="icon ms-2 pe-1 pt-1 text-success" icon="check" v-if="list.access_sb == 'agreed'"></fa></span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <button class="btn btn-green btn_vw mt-2 mb-5"
                                :disabled="list.is_complete"
                                @click="complete(list.id)"
                            >Завершить выдачу
                            </button>
                        </div>
                    </div>
                </div>
                <div class="text_end" v-else>
                    <div class="item_title text-center border-green mb-4">
                        Все выдачи оформлены
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import CreateModal from "./CreateModal";
import axios from "axios";

export default {
    name: "CheckListAdmin",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs, ToastAlert, CreateModal
    },
    data() {
        return {
            lists: [],
            message: '',
            bg: 'bg-primary',
            is_alert: false,
            isLoading: false,
            comment: null,
            breadCrumbs: true,
            title: 'Список выдач',
            back_title: 'Меню выдачи',
            link: 'issuance',
        }
    },
    created() {
        this.getDate();
    },
    methods: {
        async getDate() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/issuances/admin/checklist`, config);
                this.lists = response.data.data.lists;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        complete(id){
            this.isLoading = true;
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.post('/api/admin/issuances/admin/checklist/complete', {
                id: id,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.getDate();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        issuanceMark(id, work, status) {
            this.isLoading = true;
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.post('/api/admin/issuances/admin/checklist', {
                id: id,
                work: work,
                status: status,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.getDate();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h2 class="mb-5">Новая настройка</h2>
                <Form ref="setting" @submit="createSetting">
                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Название*</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="setting.name"
                                type="text"
                                name="name"
                                class="form-control"
                                :class="[errors.name.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="name" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Слаг*</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="setting.slug"
                                type="text"
                                name="slug"
                                class="form-control"
                                :class="[errors.slug.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="slug" />
                        </div>
                    </div>


                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Статус*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="setting.status"
                                placeholder="Выберите статус"
                                label="name"
                                track-by="id"
                                :options="items"
                                :taggable="true"
                                :class="[errors.status.length > 0 ? 'error is-invalid' : '']"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="status" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Данные*</label>
                        <div class="col-sm-8 position-relative">
                            <div class="input-group">
                                <Field
                                    v-model="setting.data"
                                    type="text"
                                    name="data"
                                    class="form-control"
                                    :class="[errors.data.length > 0 ? 'error' : '']"
                                />
                                <ErrorMessage name="data"/>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end"></label>
                        <div class="col-sm-8 position-relative">
                            <button class="btn btn-green">
                                Сохранить
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import ToastAlert from '../../../components/ToastAlert';
import VueMultiselect from "vue-multiselect";
import { Field, Form, ErrorMessage } from 'vee-validate';

export default {
    name: "TDSettingCreate",
    components: {
        spinner, BreadCrumbs, ToastAlert, VueMultiselect, Field, Form, ErrorMessage
    },
    data(){
        return {
            setting: {
                'name': null,
                'status': null,
                'slug': null,
                'data': null,
            },
            errors: {
                'name': '',
                'status': '',
                'slug': '',
                'data': '',
            },
            items: [],
            isLoading: false,
            breadCrumbs: true,
            title: 'Добавить',
            back_title: 'Список настроек',
            link: 'td/settings/cron',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get('/api/admin/testdrives/settings/create', config);
                this.items = response.data.data.items;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        createSetting(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/testdrives/settings/store', {
                name: this.setting.name,
                status: this.setting.status,
                slug: this.setting.slug,
                data: this.setting.data,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.$router.push('/td/settings')
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.setting.setErrors(errors.response.data.errors);
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            this.errors.status = errors.response.data.errors.status ?? 0;
                            this.errors.slug = errors.response.data.errors.slug ?? 0;
                            this.errors.data = errors.response.data.errors.data ?? 0;
                            break;
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <template v-if="type === 'tel'">
        <label class="form-label">{{ label }}</label>
        <input
            :name="name"
            :type="type"
            class="form-control"
            :placeholder="placeholder"
            :value="modelValue"
            :readonly="readonly"
            :maxlength="maxlength"
            @input="emitValue($event)"
            :class="[`${error}`, `${add_class}`]"
            v-telmask
        />
    </template>
    <template v-else>
        <label class="form-label">{{ label }}</label>
        <input
            :name="name"
            :type="type"
            class="form-control"
            :placeholder="placeholder"
            :readonly="readonly"
            :value="modelValue"
            :maxlength="maxlength"
            @input="emitValue($event)"
            :class="[`${error}`, `${add_class}`]"
        />
    </template>
</template>

<script>
const telmask = {
    mounted: (el, binding) => {
        el.onfocus = function (e) {
            this.value = '+7 ('
            this.setSelectionRange(4, 4)
        }
        el.oninput = function (e) {
            if (!e.isTrusted) {
                return
            }
            const x = this
                .value
                .replace(/\D/g, '')
                .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
            x[1] = '+7'
            this.value = !x[3]
                ? x[1] + ' (' + x[2]
                : x[1] + ' (' + x[2] + ') ' + x[3] + (
                x[4]
                    ? '-' + x[4]
                    : ''
            ) + (
                x[5]
                    ? '-' + x[5]
                    : ''
            )
        }
    }
}

export default {
    name: "Input",
    components: {

    },
    directives: {
        telmask
    },
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            required: true
        },
        maxlength: {
            type: Number,
        },
        error: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: false
        },
        add_class: {
            type: String,
            required: false,
        }
    },
    methods: {
        emitValue(e){
            this.$emit('clear', e.target.name)
            this.$emit('update:modelValue', e.target.value)
        },
    }
}
</script>

<style scoped>

</style>

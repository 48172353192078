import * as auth from "./auth";
import axios from "axios";

export const saveOrganization = async (item) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {

        const response = await axios.post('/api/admin/organizations', item, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const getOrganizations  = async () => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/organizations`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const destroyOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.delete(`/api/admin/organizations/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

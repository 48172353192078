<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="item_title">
                    {{ checkName }}
                </div>

                <div v-if="!isDefect" style="padding:  unset;">
                    <div class="check_btns col-12 hstack justify-content-between my-3">
                        <div class="btn btn-red col-5 col-lg-3 col-md-4" @click="showDefectPage">НЕТ</div>
                        <div class="btn btn-green col-5 col-lg-3 col-md-4" @click="submitAccept">ДА</div>
                    </div>
                </div>

                <transition-group name="list">
                    <div v-if="isDefect" style="padding:  unset;">
                        <div class="item_title">

                            <div class="mb-2 ps-2 fs-7 fw-normal">
                                Выберите ответственных сотрудников.
                            </div>

                            <div class="pt-3">
                                <VueMultiselect
                                    v-model="user"
                                    placeholder="Выбрать ответственного..."
                                    label="name"
                                    track-by="id"
                                    :options="users"
                                    :multiple="true"
                                    :show-labels="false"
                                    @tag="addUser"
                                >
                                </VueMultiselect>
                            </div>

                            <div class="check_answer mb-3">
                                Загрузите одну или несколько фотографий.
                            </div>

                            <div class="load_photo_wrapper mt-2">
                                <div class="block_foto" :class="{ image_foto: type }" v-if="!showPreview">
                                    <div v-if="Object.keys(this.image).length !== 0">
                                        <div v-for="(file, key) in this.image" class="image_block"
                                             v-bind:key="'file-'+key">
                                            <img class="defect_image" data-fancybox="gallery" :src="`${ file }`"/>
                                        </div>
                                    </div>
                                    <div v-else class="pb_image">
                                        <svg width="95" height="85" viewBox="0 0 95 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M37.2736 9.54167L27.8569 18.9583H9.83342V75.4583H85.1667V18.9583H67.1433L57.7266 9.54167H37.2736ZM33.3751 0.125H61.6251L71.0417 9.54167H89.8751C91.1238 9.54167 92.3214 10.0377 93.2044 10.9207C94.0874 11.8037 94.5834 13.0013 94.5834 14.25V80.1667C94.5834 81.4154 94.0874 82.613 93.2044 83.496C92.3214 84.379 91.1238 84.875 89.8751 84.875H5.12508C3.87635 84.875 2.67877 84.379 1.79579 83.496C0.912803 82.613 0.416748 81.4154 0.416748 80.1667V14.25C0.416748 13.0013 0.912803 11.8037 1.79579 10.9207C2.67877 10.0377 3.87635 9.54167 5.12508 9.54167H23.9584L33.3751 0.125ZM47.5001 70.75C40.6321 70.75 34.0454 68.0217 29.189 63.1653C24.3326 58.3089 21.6042 51.7222 21.6042 44.8542C21.6042 37.9862 24.3326 31.3995 29.189 26.543C34.0454 21.6866 40.6321 18.9583 47.5001 18.9583C54.3681 18.9583 60.9548 21.6866 65.8112 26.543C70.6676 31.3995 73.3959 37.9862 73.3959 44.8542C73.3959 51.7222 70.6676 58.3089 65.8112 63.1653C60.9548 68.0217 54.3681 70.75 47.5001 70.75ZM47.5001 61.3333C51.8706 61.3333 56.0622 59.5971 59.1526 56.5067C62.2431 53.4163 63.9793 49.2247 63.9793 44.8542C63.9793 40.4836 62.2431 36.2921 59.1526 33.2016C56.0622 30.1112 51.8706 28.375 47.5001 28.375C43.1295 28.375 38.938 30.1112 35.8476 33.2016C32.7571 36.2921 31.0209 40.4836 31.0209 44.8542C31.0209 49.2247 32.7571 53.4163 35.8476 56.5067C38.938 59.5971 43.1295 61.3333 47.5001 61.3333Z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div class="block_foto align-middle d-flex justify-content-center align-items-center" v-else>
                                    <div>
                                        <div v-for="(file, key) in files" class="image_block"
                                             v-bind:key="'file-'+key">
                                            <img style="width: 100%;" class="defect_image" data-fancybox="gallery" v-bind:id="'image-'+parseInt( key )"/>
                                            <input type="hidden" name="photo" v-bind:id="'photo-'+parseInt( key )"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form enctype="multipart/form-data">
                                <div class="mb-3">
                                    <input type="file" id="files" class="inputfile" multiple
                                           accept="image/*"
                                           ref="files"
                                           @change="onAttachmentChange"
                                           style="display: none"
                                    />
                                    <label for="files" class="btn gray btn_download_image btn_vw">ЗАГРУЗИТЬ ФОТО</label>
                                </div>
                            </form>
                            <div class="comments">
                                <div class="mb-2 ps-2 fs-7 fw-normal">
                                    Напишите примечание.
                                </div>
                                <textarea name="comment" v-model="comment" class="form-control">
                            </textarea>
                            </div>


                        </div>

                        <div v-if="!isLoading">
                            <button class="btn btn-green btn_vw mt-2 mb-3"
                                    @click="submitDefect"
                            >СОХРАНИТЬ
                            </button>
                        </div>
                        <div v-if="isLoading">
                            <button class="btn btn-green btn_vw mb-5" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Загрузка...
                            </button>
                        </div>
                    </div>
                </transition-group>

            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import {Fancybox, Carousel, Panzoom} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import VueMultiselect from 'vue-multiselect';

export default {
    name: "CheckPage",
    components: {
        spinner, BreadCrumbs, VueMultiselect
    },
    data() {
        return {
            isLoading: false,
            comment: null,
            checkName: '',
            isDefect: false,
            attachment: [],
            file: '',
            files: [],
            showPreview: false,
            imagePreview: '',
            image: {},
            photo: [],
            breadCrumbs: true,
            title: 'Проверка несоответствия',
            back_title: 'Список вопросов',
            link: 'quality/' + this.$route.params.dep_id,
            users: [],
            user: [],
        }
    },
    created() {
        this.getCheckName();
    },
    methods: {
        submitAccept() {
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.post('/api/admin/quality/accept', {
                dep_id: this.$route.params.dep_id,
                check_id: this.$route.params.check_id,
                status: 'accept',
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push(`/quality/${this.$route.params.dep_id}`)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        submitDefect(e) {
            e.preventDefault();
            this.isLoading = true;
            console.log(this.user);
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}, 'content-type': 'multipart/form-data'};
            const formData = new FormData();
            let q = Array.prototype.slice.call(document.getElementsByName("photo"));
            for (let i = 0; i < q.length; i++) {
                formData.append('photos[' + i + ']', q[i].defaultValue);
            }
            for (let i = 0; i < this.files.length; i++) {
                formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
            }

            formData.append('dep_id', this.$route.params.dep_id);
            formData.append('check_id', this.$route.params.check_id);
            formData.append('status', 'defect');
            formData.append('slug', 'defect');
            formData.append('comment', this.comment);
            if (this.user != null) {
                for (let i = 0; i < this.user.length; i++) {
                    formData.append('user[' + i + ']', this.user[i].id);
                }
            } else {
                formData.append('user', null);
            }
            axios.post(`/api/admin/quality/defect`, formData, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push(`/quality/${this.$route.params.dep_id}`);
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        async getCheckName() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/checklist/check/name/${this.$route.params.dep_id}/${this.$route.params.check_id}`, config);
                this.checkName = response.data.data.checkName;
                this.image = response.data.data.image;
                this.users = response.data.data.users;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        showDefectPage() {
            this.isDefect = true;
        },
        fileInfo(file) {
            let array = [];

            array.push(file.name);
            array.push(file.type);

            return array;
        },
        onAttachmentChange(event) {
            let uploadedFiles = this.$refs.files.files;
            this.count = 0;
            for (var i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i]);
                this.count++;
            }
            this.showPreview = true;
            this.getImagePreviews();
        },
        getImagePreviews() {
            const MAX_WIDTH = 1024;
            const MAX_HEIGHT = 768;

            for (let i = 0; i < this.files.length; i++) {
                const file = this.files[i]; // get the file
                const blobURL = URL.createObjectURL(file);
                const img = new Image();
                img.src = blobURL;
                img.onerror = function () {
                    URL.revokeObjectURL(this.src);
                    // Handle the failure properly
                    console.log("Cannot load image");
                };
                img.onload = function () {
                    URL.revokeObjectURL(this.src);
                    const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                    const canvas = document.createElement("canvas");
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    let ndata = canvas.toDataURL('image/jpeg')
                    document.getElementById('image-' + parseInt(i)).src = ndata;
                    document.getElementById('photo-' + parseInt(i)).value = ndata;
                };
            }


            function calculateSize(img, maxWidth, maxHeight) {
                let width = img.width;
                let height = img.height;

                // calculate the width and height, constraining the proportions
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
                return [width, height];
            }
        },
        addUser(item) {
            const tag = {
                name: item,
            }
            this.users.push(tag)
            this.user.push(tag)
        },
    }
}
</script>

<style scoped>

</style>

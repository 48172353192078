import * as auth from '../../services/auth';
import RequestPage from "../../Modules/Request/RequestPage";
import SettingsPage from "../../Modules/Request/Settings/SettingsPage";
import Categories from "../../Modules/Request/Settings/Categories";
import Documents from "../../Modules/Request/Settings/Documents";
import DocumentCreate from "../../Modules/Request/Settings/DocumentCreate";
import DocumentEdit from "../../Modules/Request/Settings/DocumentEdit";
import ImprestPage from "../../Modules/Request/Imprest/ImprestPage";
import ImprestCreate from "../../Modules/Request/Imprest/ImprestCreate";
import SettingImprest from "../../Modules/Request/Settings/SettingImprest";
import ImprestIndex from "../../Modules/Request/Imprest/ImprestIndex";
import ImprestAccountantList from "../../Modules/Request/Imprest/ImprestAccountantList";
import ImprestAccauntantPage from "../../Modules/Request/Imprest/ImprestAccauntantPage";
import ImprestChiefList from "../../Modules/Request/Imprest/ImprestChiefList";
import ImprestChiefPage from "../../Modules/Request/Imprest/ImprestChiefPage";
import ImprestChiefAccountantPage from "../../Modules/Request/Imprest/ImprestChiefAccountantPage";
import ImprestCheifAccountantList from "../../Modules/Request/Imprest/ImprestCheifAccountantList";
import ImprestCashierList from "../../Modules/Request/Imprest/ImprestCashierList";
import ImprestCashierPage from "../../Modules/Request/Imprest/ImprestCashierPage";
import ImprestCashierPageCompleted from "../../Modules/Request/Imprest/ImprestCashierPageCompleted";
import ImprestJournal from "../../Modules/Request/Imprest/ImprestJournal";

export default [
    {
        name: 'ImprestPage',
        path: '/request/imprest',
        component: ImprestPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestIndex',
        path: '/request/imprest/index',
        component: ImprestIndex,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestCreate',
        path: '/request/imprest/create',
        component: ImprestCreate,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestAccountantList',
        path: '/request/imprest/waiting',
        component: ImprestAccountantList,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestAccauntantPage',
        path: '/request/imprest/waiting/:id',
        component: ImprestAccauntantPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestChiefList',
        path: '/request/imprest/chief/list',
        component: ImprestChiefList,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestChiefPage',
        path: '/request/imprest/chief/list/:id',
        component: ImprestChiefPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestCheifAccountantList',
        path: '/request/imprest/chief/chief-accountant/list',
        component: ImprestCheifAccountantList,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestChiefAccountantPage',
        path: '/request/imprest/chief/chief-accountant/:id',
        component: ImprestChiefAccountantPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestCashierList',
        path: '/request/imprest/cashier/list/:active',
        component: ImprestCashierList,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestCashierPage',
        path: '/request/imprest/cashier/page/:id',
        component: ImprestCashierPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestCashierPageCompleted',
        path: '/request/imprest/cashier/page/completed/:id',
        component: ImprestCashierPageCompleted,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ImprestJournal',
        path: '/request/imprest/journal',
        component: ImprestJournal,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>

            <div class="page">
                <div class="col-12 d-flex flex-wrap modules mt-3">
                    <div class="col-12 col-lg-4 col-sm-6 col-xl-3 module_box pb-3">
                        <router-link to="/boards/doska-to/price" class="d-flex h-100 justify-content-between module">
                            <div class="d-flex module_icon">
                                <span class="material-symbols-rounded">drag_indicator</span>
                            </div>
                            <div class="d-flex flex-column module_titles text-end">
                                <div class="module_title mb-1">Прайс
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="col-12 col-lg-4 col-sm-6 col-xl-3 module_box pb-3">
                        <router-link to="/boards/doska-to/display" class="d-flex h-100 justify-content-between module">
                            <div class="d-flex module_icon">
                                <span class="material-symbols-rounded">dashboard</span>
                            </div>
                            <div class="d-flex flex-column module_titles text-end">
                                <div class="module_title mb-1">Экран
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import BreadCrumbs from "../../../Page/BreadCrumbs.vue";
import Spinner from "../../../components/Spinner.vue";

export default {
    name: "TOIndex",
    components: {
        Spinner, BreadCrumbs
    },
    data() {
        return {
            isLoading: false,
            breadCrumbs: true,
            title: 'Доска ТО',
            back_title: 'Доски',
            link: 'boards',
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <div class="col-auto mb-2">
                        <Button
                            caption="Добавить"
                            color="gray"
                            add_class="mb-3"
                            @click="showModal()"
                        />
                    </div>
                </div>

                <div class="table-responsive-md">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-muted">Организация</th>
                            <th class="text-muted">Название</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="item in templates"
                            :key="item.id"
                        >
                            <td>
                                {{ item.organization.name }}
                            </td>

                            <td>
                                {{ item.typeName }}
                            </td>

                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                        <li><button type="button" @click="downloadTemplate(item)" class="dropdown-item">Скачать шаблон</button></li>
                                        <li><hr class="dropdown-divider"></li>
                                        <li><button type="button" @click="editTemplate(item)" class="dropdown-item">Заменить</button></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{ name_form }}
            </template>
            <template v-slot:body>
                <TDFormContract
                    :organizations="organizations"
                    :errors="errors"
                    :organization="organization"
                    :item="item"
                    :items="items"
                    :id="id"
                    @save="save($event)"
                />
            </template>
        </Modal>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import ToastAlert from '../../../components/ToastAlert';
import * as req from "../../../services/td";
import * as help from "../../../services/helpers";
import TDFormCar from "./TDFormCar.vue";
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import * as carHelp from "../../../services/cars";
import TDFormContract from "./TDFormContract.vue";
import axios from "axios";
import {errorMessages} from "../../../services/helpers";

export default {
    name: "TDContracts",
    components: {
        spinner, BreadCrumbs, ToastAlert, TDFormCar, Modal, Button, TDFormContract
    },
    data(){
        return {
            type: '',
            message: '',
            is_alert: false,
            isModal: false,
            isLoading: false,
            breadCrumbs: true,
            title: 'Категории',
            back_title: 'Настройки',
            link: 'td/settings',
            name_form: 'Добавить контракт',
            organization: null,
            id: null,
            template: '',
            item: '',
            organizations: [],
            templates: [],
            items: [],
            errors: {
                organization: '',
                file: '',
                item: ''
            },
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        async getData(){
            this.isLoading = true;
            let result = await req.getTestDriveContract();
            this.organizations = result.organizations;
            this.templates = result.templates;
            this.items = result.types;
            this.isLoading = false;
        },
        showModal(){
            this.name_form = 'Добавить новый шаблон';
            this.isModal = true;
        },
        closeModal(){
            this.errors = {
                organization: '',
                file: '',
                item: ''
            }
            this.organization = null;
            this.template = '';
            this.id = null;
            this.item = '';
            this.isModal = false;
        },
        editTemplate(item){
            this.organization = item.organization;
            this.id = item.id;
            this.item = item.type;
            this.name_form = 'Заменить шаблон';
            this.showModal();
        },
        validateForm(event) {
            const originalErrors = {
                organization: '',
                file: '',
                item: ''
            };

            this.errors = { ...originalErrors };

            for (const key in event) {
                if (event.hasOwnProperty(key) && originalErrors.hasOwnProperty(key)) {
                    if (!event[key]) {
                        this.errors[key] = `Поле ${key} обязательно`;
                    }
                }
            }

            if (Object.values(this.errors).some(error => error !== '')) {
                this.message = 'Не все поля заполнены';
                this.type = 'red';
                this.is_alert = true;
                setTimeout(() => { this.closeAlert(); }, 3000);
            }

            return Object.values(this.errors).every(error => error === '');
        },

        async save(event){
            if (this.validateForm(event)) {
                this.isLoading = true;
                try {
                    let result = await req.saveTestDriveContract(event);
                    this.templates = result.templates;
                    this.type = 'green';
                    this.is_alert = true;
                    this.message = result.message;
                    setTimeout(() => { this.closeAlert(); }, 3000);
                    this.closeModal();
                } catch (error) {
                    console.error(error);
                    // Обработка ошибок при сохранении
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async downloadTemplate(item){
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/testdrives/settings/download/templates/${item.id}`, config);
                let element = document.createElement("a");
                element.setAttribute("href", response.data.data.url);
                element.setAttribute("download", response.data.data.name);
                element.setAttribute("target", "_blank");
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>

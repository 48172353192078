<template>
    <spinner v-if="isLoading"></spinner>
    <div class="container-fluid">
        <div class="row">
            <div class="1st-page" style="opacity: 1">
                <div class="align-items-stretch col-12 d-flex dashboard-wrap flex-wrap p-0">

                    <!--заголовки и общие показатели-->
                    <div class="align-items-stretch col-12 d-flex flex-wrap p-0 top-rows">
                        <!--заголовок-->
                        <div class="align-items-center col-12 d-flex db-row justify-content-between no-gutters p-0">
                            <div class="col db-col">
                                <!--дата время-->
                                <div class="align-items-center d-flex data-time p-2">
                                    <div class="back-arrow col-2 pt-2 ps-2">
                                        <a :href="'/boards/personalnyi-konsultant'">
                                            <span class="material-symbols-outlined">arrow_back_ios</span>
                                        </a>
                                    </div>
                                    <div class="logo col-5 text-left">
                                        <img src="/image/logo_board.png" alt="">
                                    </div>
                                    <div class="dt col-5 text-right">
                                        <div class="clock pe-3">
                                            <div class="time d-flex justify-content-end">
                                                <span id="hours" v-text="formattedDate.time"></span>
                                            </div>
                                            <div class="data fs-5 text-end" id="Date" v-text="formattedDate.date"></div>
                                        </div>
                                    </div>

<!--                                    <div class="col-12 d-flex justify-content-center"
                                         style="color:#fff; font-size:35px; cursor:pointer;" v-if="showButtons">
                                        <span class="material-symbols-rounded text-white" @click="prev">navigate_before</span>
                                        <span class="material-symbols-rounded text-white" v-if="pause" @click="playPause">play_circle</span>
                                        <span class="material-symbols-rounded text-white" v-if="!pause" @click="playPause">pause_circle</span>
                                        <span class="material-symbols-rounded text-white" @click="next">navigate_next</span>
                                    </div>-->
                                </div>
                            </div>

                            <div class="col db-col title" v-for="(header, index) in tableHeaders" :key="index">{{ header }}</div>
                        </div>

                        <!--годовой план-->
<!--                        <div class="align-items-center col-12 d-flex db-row header justify-content-between no-gutters p-0" style="height: 37.5%">

                            <div class="col d-flex db-col justify-content-between p-3">
                                <div class="current-year d-inline-block pl-4 w-auto">{{currentYear}}
                                    <small>год</small>
                                </div>

                            </div>

                            <div class="col db-col" v-for="(field, index) in plan.y" :key="index">
                                <div class="worker_digits">
                                    <div
                                        class="fact"
                                        :class="[planCls(field.plan, fact.y[field.indicator_id].fact)]"
                                        v-text="fact.y[field.indicator_id].fact"></div>
                                    <div class="plan" v-text="field.plan.toLocaleString()"></div>
                                </div>
                            </div>

                            <div class="progress-line">
                                <div class="top" :style="{width:checkOverplanP(yPercent)?'100%':yPercent+'%', backgroundColor:getColor(yPercent)}"></div>
                                <div class="bottom-wrap">
                                    <div class="bottom" :style="{width:checkOverplanP(yPercent)?'100%':yPercent+'%', backgroundColor:getColor(yPercent)}"></div>
                                </div>
                            </div>
                        </div>-->

                        <!--месячный план-->
                        <div class="align-items-center col-12 d-flex db-row justify-content-between no-gutters p-0">
                            <div class="col d-flex db-col justify-content-between p-3">
                                <div class="current-year d-inline-block pl-4 w-auto ps-3" v-text="plan.month"></div>
                                <div class="d-inline-block percent pr-4 text-right w-auto">
                                    <span
                                        class="record"
                                        style="font-size: 33px;color: rgb(48, 215, 18);line-height: 33px;text-shadow: 0 0 14px;">
                                        <em
                                            style="display: block;font-size: 20px;font-style: normal;color: rgb(48, 215, 18);line-height: 20px;margin: 0 0 -3px 0;">Рекорд</em>
                                        <span v-text="recordPercent+'%'"></span>
                                    </span>
                                </div>
                            </div>

                            <div class="col db-col" v-for="(field, index) in plan.m" :key="index">
                                <div class="worker_digits">
                                    <div
                                        class="fact"
                                        :class="[planCls(plan.m.trafic_plan, fact.m[field.indicator_id].fact)]"
                                        v-text="fact.m[field.indicator_id].fact.toLocaleString()"></div>
                                    <div v-if="field.alias !== 'kolicestvo-klientov' && field.alias !== 'valovaia-pribyl'"
                                         class="plan"
                                         v-text="field.plan.toLocaleString()"></div>
                                </div>
                            </div>

                            <div class="progress-line">
                                <div class="top"
                                     :style="{width:checkOverplanP(monthPercent)?'100%':monthPercent+'%', backgroundColor:getColor(monthPercent)}"></div>
                                <div class="bottom-wrap">
                                    <div class="bottom"
                                         :style="{width:checkOverplanP(monthPercent)?'100%':monthPercent+'%', backgroundColor:getColor(monthPercent)}"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--сотрудники-->
                    <transition-group
                        name="flip-list"
                        tag="div"
                        class="align-items-stretch col-12 d-flex flex-wrap p-0 top-rows"
                        v-if="orderedWorkers">
                        <div :key="worker.id" v-for="(worker, idx) in orderedWorkers"
                             class="align-items-center col-12 d-flex db-row justify-content-between no-gutters p-0">
                            <div class="col d-flex db-col justify-content-between worker"
                                 :class="{'best':idx===0, 'bad':idx===workers.length-1}">
                                <div class="worker-photo d-inline-block w-auto ps-4">
                                    <img :src="worker.image" alt="">
                                </div>
                                <div class="align-items-end d-flex flex-column h-75 justify-content-end pe-4 rating w-auto mt-2">
                                    <div class="rating-arrow float-right green" :class="[worker.arrow]" :data-id="worker.id"></div>
                                    <div class="percent" v-text="worker.efficiency+'%'"></div>
                                    <div class="name text-right" v-html="(worker.user_name).split(' ').join('<br>')"></div>

                                    <!--Stars-->
<!--                                    <div class="starsWrap">
                                        <span class="material-symbols-rounded star" :class="{'on':worker.rate>=70}">star</span>
                                        <span class="material-symbols-rounded star" :class="{'on':worker.rate>=75}">star</span>
                                        <span class="material-symbols-rounded star" :class="{'on':worker.rate>=80}">star</span>
                                        <span class="material-symbols-rounded star" :class="{'on':worker.rate>=90}">star</span>
                                        <span class="material-symbols-rounded star" :class="{'on':worker.rate>=95}">star</span>
                                    </div>-->

                                </div>
                            </div>

                            <!-- Показатели -->
                            <div class="col db-col" v-for="(indicator, index) in worker.indicators" :key="index">
                                <div class="worker_digits">
                                    <div
                                        v-if="indicator.has_fact"
                                        class="fact"
                                        :class="{
                                                'planRed':indicator.fact*1 == 0,
                                                'planGreen':indicator.fact*1 >= indicator.plan*1,
                                                }"
                                        v-text="indicator.fact.toLocaleString()"></div>
                                    <div v-if="indicator.has_plan" class="plan" v-text="indicator.plan.toLocaleString()"></div>
                                </div>
                            </div>

                            <!--crm-->
<!--                            <div class="col db-col">
                                <div class="worker_digits">
                                    <div class="fact" v-text="worker.total_percent+'%'"></div>
                                    <div class="plan"></div>
                                </div>
                            </div>-->


                            <div class="progress-line">
                                <div class="top" :class="{'overplan':checkOverplan(worker)}"
                                     :style="{width:checkOverplan(worker)?'100%':worker.total_percent+'%', backgroundColor:getColor(worker.total_percent)}"></div>
                                <div class="bottom-wrap">
                                    <div class="bottom" :class="{'overplan':checkOverplan(worker)}"
                                         :style="{width:checkOverplan(worker)?'100%':worker.total_percent+'%', backgroundColor:getColor(worker.total_percent)}"></div>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as req from "../../../services/boards";
import Spinner from "../../../components/Spinner.vue";

export default {
    name: "PCDisplay",
    components: {
        Spinner
    },
    data() {
        return {
            isLoading: false,
            tableHeaders: [],
            plan: [],
            fact: [],
            formattedDate: [],
            workers: [],
            monthPercent: 0,
            yPercent: 0,
            recordPercent: 0,
            currentYear: new Date().getFullYear(),
        }
    },
    computed: {
/*        orderedWorkers: function () {
            return _.orderBy(this.workers, 'efficiency', 'desc')
        },*/
        orderedWorkers() {
            // Отсортировать сотрудников по эффективности и взять только 5
            return this.workers
                .sort((a, b) => b.efficiency - a.efficiency) // Сортировка по эффективности
                .slice(0, 5); // Взять первых 5 сотрудников
        },
    },
    mounted() {
        window.Echo.channel('board-updates')
            .listen('BoardUpdated', (event) => {
                this.getData();
            });
        this.getData();
    },
    methods: {
        async getData(){
            //this.isLoading = true;
            let result = await req.fetchBoardDisplay('personalnyi-konsultant');
            this.tableHeaders = result.tableHeaders;
            this.plan = result.plan;
            this.fact = result.fact;
            this.workers = result.workers;
            this.formattedDate = result.formattedDate;
            this.recordPercent = result.recordPercent;
            //this.isLoading = false;
        },
        getColor(percent) {
            let r = percent < 50 ? 255 : Math.floor(255 - (percent * 2 - 100) * 255 / 100);
            let g = percent > 50 ? 255 : Math.floor((percent * 2) * 255 / 100);
            return 'rgb(' + r + ',' + g + ',0)';
        },
        planCls(plan, fact) {
            if (fact === 0) {
                return "planRed"
            }
            if(fact === plan){
                return "planGreen"
            }
            return "";
        },
        checkOverplan(worker) {
            if (worker.total_percent > 100) {
                return true
            }
            return false
        },
        checkOverplanP(p) {
            if (p > 100) {
                return true
            }
            return false
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="d-flex justify-content-around gap-3 flex-wrap" v-if="this.lists.length > 0">
                    <h5 class="ps-3 mb-3"><fa icon="list-check" class="icon" style="padding-right: 10px; color: #c00;"></fa>Список выдач</h5>

                    <div  class="col-12 item_title mb-3"
                          v-for="list in lists"
                          :key="list.id"
                    >
                        <h5 class="text-center mb-3">Договор № {{ list.info.contract_num }}</h5>
                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="car"></fa>
                                    {{ list.info.car }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <span>VIN:</span>
                                    {{ list.info.vin }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="user"></fa>
                                    {{ list.info.client }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="user-tie"></fa>
                                    {{ list.user.surname }} {{ list.user.name }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="calendar"></fa>
                                    {{ list.formatDate }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="clock"></fa>
                                    {{ list.time_period[0] }} -
                                    {{ list.time_period[1] }}
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-green btn_vw mt-2 mb-2"
                                @click="showModal(list.id)"
                        >Сумма кредита
                        </button>

                        <button class="btn btn-red btn_vw mt-2 mb-2"
                                @click="creditNotIssued(list)"
                        >Кредит не оформлялся
                        </button>

                    </div>
                </div>
                <div class="text_end" v-else>
                    <div class="item_title text-center border-green mb-4">
                        Все выдачи оформлены
                    </div>
                </div>

                <transition-group name="list">
                    <div class="modal d-block" v-if="is_modal">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title"></h5>
                                    <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row mb-3">
                                        <label class="form-label">Сумма кредита*</label>
                                        <Field
                                            v-model="summ"
                                            type="tel"
                                            name="summ"
                                            class="form-control"
                                            placeholder='Сумма кредита'
                                        />
                                    </div>
                                    <div class="row mb-3">
                                        <label class="form-label">Банк*</label>
                                        <div class="col-sm-8 position-relative">
                                            <VueMultiselect
                                                v-model="bank"
                                                placeholder="Выберите банк"
                                                label="name"
                                                track-by="id"
                                                :options="banks"
                                                name="user"
                                                :taggable="true"
                                                :show-labels="false"
                                            >
                                            </VueMultiselect>
                                        </div>
                                    </div>
                                    <div class="col-sm position-relative mb-2 mt-2">
                                        <button class="btn btn_vw btn-green"
                                                @click="save"
                                        >
                                            Сохранить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import spinner from "../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import CreateModal from "./CreateModal";
import axios from "axios";

export default {
    name: "CheckListCredit",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs, ToastAlert, CreateModal
    },
    data(){
        return {
            lists: [],
            banks: [],
            bank: null,
            summ: null,
            id: null,
            message: '',
            type: '',
            is_alert: false,
            is_modal: false,
            isLoading: false,
            breadCrumbs: true,
            title: 'Список выдач',
            back_title: 'Меню выдачи',
            link: 'issuance',
        }
    },
    created() {
        this.getDate();
    },
    methods: {
        closeModal(){
            this.is_modal = false;
        },
        showModal(id){
            this.id = id;
            this.is_modal = true;
        },
        closeAlert(){
            this.is_alert = false;
        },
        save(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/credit/checklist/issued', {
                issue_id: this.id,
                bank: this.bank,
                summ: this.summ,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.type = 'green';
                        this.message = 'Успешно сохранено!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.closeModal();
                        this.getDate();
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        async getDate() {
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/issuances/credit/checklist`, config);
                this.lists = response.data.data.lists;
                this.banks = response.data.data.banks;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        creditNotIssued(issue){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/credit/checklist/not/issued', {
                issue_id: issue.id,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.type = 'green';
                        this.message = 'Успешно сохранено!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.getDate();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
    }
}
</script>

<style scoped>

</style>

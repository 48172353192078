<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div v-if="Object.keys(this.defects).length > 0" class="mt-3">
                    <h5 class="my-3">Неисправленные дефекты</h5>
                    <div class="d-flex justify-content-around gap-2 flex-wrap">
                        <div class="item_title col-12 col-md-5 mb-0"
                            v-for="defect in this.defects"
                            :key="defect.id"
                            @click="next(defect.id)"
                        >
                            <!-- Карточка с нарушениями -->
                            <div class="gn_row d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column">
                                    <div class="gn_worker_name text-start mb-2">
                                        <fa class="icon pe-1 text-black-50" icon="list-check"></fa>
                                        {{ defect.check }}
                                    </div>
                                    <div class="gn_worker_name text-start fw-normal">
                                        <fa class="icon pe-1 text-black-50" icon="car"></fa>
                                        {{ defect.car_number }}
                                    </div>
                                </div>
                                <h6 class="gn_defects_count">
                                    <span class="badge rounded-pill text-bg-danger">{{ defect.count }}</span>
                                </h6>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="text_end" v-else>
                    <div class="item_title text-center border-green mb-4">
                        Все несоответствия устранены
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import axios from "axios";
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "CarDefectsLists",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            defects: [],
            breadCrumbs: true,
            title: 'Группы несоответствий',
            back_title: 'Несоответствия',
            link: 'select-defects',
        }
    },
    created() {
        this.getDefects();
    },
    methods:{
        next(id){
            this.$router.push(`/car-defects/${id}`);
        },
        async getDefects(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/car/get/defects', config);
                this.defects = response.data.data.defects;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
    <div>
        <BreadCrumbs
            :breadCrumbs="breadCrumbs"
            :title="title"
            :back_title="back_title"
            :link="link"
        />
            <div class="page">
                <Form ref="fine" @submit="update">

                    <div class="mb-3">
                        <label class="form-label">Название вопроса</label>
                        
                            <Field
                                readonly="true"
                                v-model="fine.name"
                                type="text"
                                name="name"
                                class="form-control"
                                :class="[errors.name.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="name" style="color: red;"/>
                        
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Сумма штрафа</label>
                            <Field
                                readonly="true"
                                v-model="fine.penalty"
                                type="text"
                                name="penalty"
                                class="form-control"
                                :class="[errors.penalty.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="penalty" style="color: red;"/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Ответственные</label>
                        
                            <VueMultiselect
                                v-model="fine.users"
                                placeholder="Выберите ответственных"
                                label="name"
                                track-by="id"
                                :options="options"
                                :multiple="true"
                                :show-labels="false"
                                @tag="addTag"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="users" style="color: red;"/>
                        
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Коментарий сотрудника</label>
                        
                            <div class="comments">
                                <textarea name="comment" v-model="fine.comment" class="form-control">
                                        {{ comment }}
                                </textarea>
                            </div>
                            <ErrorMessage name="comment" style="color: red;"/>
                        
                    </div>

                    
                        <div class="col col-lg-2">
                            <button class="btn btn-green w-100"
                            >
                                Сохранить
                            </button>
                        </div>
                    
                </Form>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from "../../../components/Spinner";
import VueMultiselect from "vue-multiselect";
import {ErrorMessage, Field, Form} from "vee-validate";
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "ReportEditPage",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            options: [],
            fine: {
                name: null,
                penalty: null,
                users: null,
                comment: null,
            },
            errors: {
                name: '',
                penalty: '',
                users: '',
                comment: '',
            },
            breadCrumbs: true,
            title: 'Корректировка штрафа',
            back_title: 'Журнал штрафов',
            link: 'report',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/report/${this.$route.params.id}`, config);
                this.fine = response.data.data.report;
                this.options = response.data.data.users;
                console.log(this.options);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        addTag(newTag){
            const tag = {
                name: newTag,
            }
            this.options.push(tag)
            this.fine.users.push(tag)
        },
        update() {
            //this.isLoading = true;
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.post('/api/admin/report/update', {
                id: this.$route.params.id,
                users: this.fine.users,
                comment: this.fine.comment,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push('/report');
                    }
                })
                .catch(errors => {
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.check.setErrors(errors.response.data.errors);
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            this.errors.penalty = errors.response.data.errors.penalty ?? 0;
                            this.errors.users = errors.response.data.errors.users ?? 0;
                            this.errors.comment = errors.response.data.errors.comment ?? 0;
                            break;
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>

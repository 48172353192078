<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="this.title"
                :back_title="this.back_title"
                :link="this.link" />

            <div class="page">
                <h5 class="my-3">
                    <Buying :count="11"/>
                    Тест-драйв
                </h5>
                <hr>
                <div class="legend">
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #00c43c;">check_circle</span>
                        &ndash; нет дефектов (по умолчанию)
                    </div>
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #cc0000;">error</span>
                        &ndash; обнаружены дефекты
                    </div>
                </div>
                <hr>
                <!-- Check List -->
                <div class="vstack justify-content-center mt-0">
                    <div v-for="item in lists" :key="item.id">
                        <button class="btn cl_item cl_status pad mb-2 w-100 text-center hstack"
                            @click="click(item.id)"
                            :class="goClass(item.status)">
                            <span
                                v-if="item.status == 'accept'"
                                class="material-symbols-rounded me-2 opacity-50 cl_status_icon">check_circle</span>
                            <span
                                v-else-if="item.status == 'defect'"
                                class="material-symbols-rounded me-2 opacity-50 cl_status_icon">error</span>
                                {{ item.name }}
                        </button>
                    </div>
                </div>

                <button class="btn btn-green w-100 mt-3 mb-5" @click="saveStep">Сохранить</button>

            </div>
        </div>
    </transition>

    <transition-group name="list">
        <type-test-drive
            :checkings="checkings"
            :item_id="item_id"
            :comment="comment"
            v-if="visible"
            @close-form-testdrive="hideFormTestdrive"
            @update_status="updateStatus($event)"
            @save-checking="saveChecking($event)"
        />
    </transition-group>
</template>

<script>
import Buying from "./Buying";
import TypeTestDrive from "./typeTestDrive";
import axios from "axios";
import Spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "Step_11",
    components: {
        TypeTestDrive,
        Buying,
        Spinner,
        BreadCrumbs,
    },
    data: () => ({
        car: [],
        step: 'estimation',
        back_step: 'completeness',
        visible: false,
        lists: [],
        checkings: [],
        item_id: null,
        comment: '',
        breadCrumbs: true,
        title: "Тест-драйв",
        back_title: "Этапы приёма",
        link: "",
        isLoading: false,
    }),
    created(){
        this.getData();
        this.getBackLink();
    },
    methods: {
        getBackLink() {
            this.link = this.link = `tradeinlist/${this.$route.params.id}`
        },
        saveStep(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/testdrive/save/step`, {
                id: this.$route.params.id,
                step_number: 11,
            }, config)

                .then(response =>{
                    if(response.status == 200){
                        this.$router.push(`/buying/estimation/${this.$route.params.id}`);
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        click(id){
            this.getChecking(id);
            this.item_id = id;
        },
        hideFormTestdrive(){
            this.visible = false;
        },
        saveChecking(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/testdrive/save/checking`, {
                id: this.$route.params.id,
                item_id: event.item_id,
                comment: event.comment,
                slug: 'defect',
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.getData();
                        this.hideFormTestdrive();
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        updateStatus(event){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/testdrive/update/status`, {
                id: event.id,
                tradein_id: this.$route.params.id,
                item_id: event.item_id,
                status: event.status,
                comment: event.comment,
                slug: 'defect'
            }, config)

                .then(response =>{
                    if(response.status == 200){
                        this.getChecking(response.data.data.tradein_id);
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/testdrive/${this.$route.params.id}`, config);
                this.lists = response.data.data.lists.data;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        async getChecking(id){
            try {
                console.log(id);
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/testdrive/checkings/${this.$route.params.id}/${id}`, config);
                this.checkings = response.data.data.lists;
                this.comment = response.data.data.comment;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
                this.visible = true;
            }
        },
        goClass(status){
            return 'type_' + status;
        }
    }
}
</script>

<style scoped>

</style>

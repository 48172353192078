<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="col-12 flex-wrap hstack">
                    <div class="col-12 col-lg-2">
                        <input type="text" name="id" class="form-control mb-2" v-model="number" :placeholder="$t('request.search_by_number')">
                    </div>
                    <div class="col-12 col-lg-2">
                        <VueMultiselect
                            v-model="organization"
                            :placeholder="$t('request.organization')"
                            label="name"
                            track-by="id"
                            :options="organizations"
                            :show-labels="false"
                            @select="selectOrganization"
                            @remove="resmoveOrganization"
                            class="mb-2"
                        >
                        </VueMultiselect>
                    </div>
                    <div class="col-12 col-lg-2 pe-lg-2 ps-lg-2">
                        <VueMultiselect
                            v-model="user"
                            :placeholder="$t('request.worker')"
                            label="name"
                            track-by="id"
                            :options="users"
                            @select="selectUser"
                            @remove="resmoveUser"
                            :show-labels="false"
                            class="mb-2"
                        >
                        </VueMultiselect>
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2">
                        <input type="date" class="form-control mb-2" v-model="startDay" @change="handler()" >
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2">
                        <input type="date" class="form-control mb-2" v-model="endDay" @change="handler()" >
                    </div>
                </div>

                <div class="mt-4 payments-boxes-wrap">
                    <template v-for="list in filteredLists" :key="list.id">
                        <div class="d-flex flex-wrap item_title paymat-box">

                            <div class="border-bottom col-12 col-lg-2 d-flex d-lg-block fw-semibold justify-content-between mb-3 mb-lg-0 pay_number pb-3 pb-lg-0">
                                <div class="fs-6">№{{ list.id }}</div>
                                <div class="fs-7">{{ list.date_create }}</div>
                                <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block" :class="list.status">{{ list.status_title }}</div>
                                <div class="fs-7 fw-normal rounded-pill px-2 d-inline-block" v-if="list.category.category.organization">
                                    {{ list.category.category.organization.name }}
                                </div>
                            </div>

                            <div class="col-12 col-lg-3 fs-7 pay_worker px-lg-2">
                                <!-- Категория -->
                                <div class="fw-semibold mb-2 lh-1">
                                    <fa class="icon me-1 opacity-50" icon="list-ul"></fa>
                                    {{ list.category.name }}
                                </div>
                                <!-- Заявитель -->
                                <div class="fw-semibold mb-2">
                                    <fa class="icon me-1 opacity-50" icon="user-tie"></fa>
                                    {{ list.user.surname }} {{ list.user.name }}
                                </div>
                                <!-- Вложения -->
                                <div v-if="list.files.length > 0" class="mb-3">
                                    <div v-for="file in list.files" :key="file.id">
                                        <a
                                            data-fancybox="data-fancybox"
                                            :href="file.link"
                                            class="attachment my-1 mt-lg-0 mb-lg-2"
                                            style="cursor: pointer;"
                                            v-if="file.type"
                                            :title="file.name">
                                            <fa class="icon me-1" icon="paperclip"></fa>{{ file.name }}
                                        </a>
                                        <a
                                            @click="downloadURL(file.link, file.name)"
                                            class="attachment my-1 mt-lg-0 mb-lg-2"
                                            style="cursor: pointer;"
                                            v-else
                                            :title="file.name">
                                            <fa class="icon me-1" icon="paperclip"></fa>{{ file.name }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-lg-5 fs-7 pay_worker px-lg-2" v-if="list.contents !== null">
                               <div class="bg-opacity-25 fw-normal lh-base mt-0 p-3 pay_journal_comment"
                                    :class="is_hidden ? 'min-block' : 'max-block'"
                                    v-html="list.contents"
                               >
                               </div>
                                <div class="btn btn-small gray w-100 mt-1" @click="showHideContent" v-if="list.contents.length > 500">
                                    <span v-if="is_hidden">Читать полностью</span>
                                    <span v-if="!is_hidden">Свернуть</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-5 fs-7 pay_worker px-lg-2" v-else>
                                <div class="bg-opacity-25 fw-normal lh-base mt-0 p-3 pay_journal_comment">
                                </div>
                            </div>

                            <div class="border-top col-12 col-lg-2 mt-3 mt-lg-0 pay_actions ps-lg-2 pt-3 pt-lg-0">

                                <button class="btn w-100 btn-green btn-small mb-2" @click="downloadMemos(list.id)">
                                    <fa class="icon me-2 opacity-50" icon="cloud-arrow-down"></fa>
                                    {{ $t('request.button.download') }}
                                </button>
                                <button class="btn gray w-100 btn-small" @click="showHistory(list.id)" >
                                    <fa class="icon me-2 opacity-50" icon="file-lines"></fa>
                                    {{ $t('request.button.history') }}
                                </button>
                            </div>

                        </div>
                        <div class="pay_history">
                                <div v-if="is_history[list.id]">

                                    <div class="history-cards-list mb-4">
                                        <div class="history-card-wrap" v-for="history in list.historys" :key="history">
                                            <div class="history-card" :class="goStatus(history.status)">
                                                <div class="fs-7 fw-semibold">
                                                    <fa class="icon me-2 opacity-25" icon="calendar"></fa>{{ history.date }}
                                                </div>
                                                <div class="fs-7 fw-semibold">
                                                    <fa class="icon me-2 opacity-25" icon="circle-chevron-right"></fa>{{ history.message }}
                                                </div>
                                                <div class="fs-7 fw-semibold">
                                                    <fa class="icon me-2 opacity-25" icon="user-tie"></fa>{{ history.fullname }}
                                                </div>
                                                <div class="fs-7 fw-normal mt-3 mb-2" v-if="history.comment">
                                                    <fa class="icon me-2 opacity-25" icon="message"></fa>{{ history.comment }}
                                                </div>
                                            </div>
                                            <div class="d-flex history-next-card justify-content-center my-1">
                                                <fa class="icon fa-2xs" icon="angle-down"></fa>
                                            </div>
                                        </div>
                                        <button class="btn btn-green w-100" @click="showHistory(list.id)">
                                            <fa class="icon me-2 opacity-50" icon="file-lines"></fa>Закрыть историю
                                        </button>
                                    </div>

                                </div>
                            </div>
                    </template>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
import History from "../Imprest/History";
import ToastAlert from "../../../components/ToastAlert";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";

export default {
    name: "MemosJournal",
    components: {
        History, ToastAlert,
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect
    },
    data(){
        return {
            is_hidden: true,
            scroll: 0,
            startDay: null,
            endDay: null,
            user: null,
            number: '',
            nameDoc: '',
            users: [],
            lists: [],
            organizations: [],
            organization: null,
            is_filter: true,
            is_history: [],
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.journal'),
            back_title: this.$t('request.memos'),
            link: 'request/memos',
        }
    },
    computed: {
        filteredLists(){
            if(this.number){
                return this.lists.filter(item => item.id == this.number);
            }else{
                return this.lists;
            }

        },
    },
    created(){
        this.getData();
        this.eventBus.emit('page-width', '100%');
    },
    methods: {
        selectUser(value){
            this.user = value;
            this.filter();
        },
        resmoveUser(){
            this.user = null;
            this.filter();
        },
        selectOrganization(value){
            this.organization = value;
            this.filter();
        },
        resmoveOrganization(){
            this.organization = null;
            this.filter();
        },
        handler(){
            this.filter();
        },
        goStatus(status){
            return `history_status_` + status;
        },
        close() {
            this.is_history = false;
        },
        showHistory(id) {
            this.is_history[id] = !this.is_history[id];
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/memos/journal', config);
                this.lists = response.data.data.lists;
                this.users = response.data.data.users;
                this.startDay = response.data.data.startDay;
                this.endDay = response.data.data.endDay;
                this.organizations = response.data.data.organizations;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        downloadURL(url, name){
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.onload = function(e) {
                if (this.status == 200) {
                    var myBlob = this.response;
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(myBlob);
                    link.download = name;
                    link.click();
                }
            };
            xhr.send();
        },
        async downloadMemos(id){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/memos/download/${id}`, config);
                this.url = response.data.data.url;
                this.nameDoc = response.data.data.nameDoc;
                this.downloadURL(this.url, this.nameDoc);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        showHideContent(){
            this.is_hidden = !this.is_hidden;
            if(!this.is_hidden){
                this.scroll = window.scrollY;
            }else{
                window.scrollTo(0, this.scroll);
            }
        },
        filter(){
            this.isLoading = true;
            this.is_filter = false;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/memos/filter', {
                startDay: this.startDay,
                endDay: this.endDay,
                user: this.user,
                organization: this.organization,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.lists = response.data.data.lists;
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>
    .download {
        line-height: unset!important;
        padding: unset!important;
        font-size: 12px;
    }

    .min-block {
        max-height: 300px;
        overflow: hidden;
        transition: max-height 0.8s ease-out;
    }

    .max-block {
        transition: max-height 0.3s ease-in;
        max-height: 100%;
    }
</style>

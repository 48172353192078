import * as auth from "./auth";
import axios from "axios";

export const getDepartments  = async () => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/departments`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const filterOrganization  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/departments/filter/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const saveDepartment = async (item) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {

        const response = await axios.post('/api/admin/departments', item, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const deleteDepartment  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.delete(`/api/admin/departments/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

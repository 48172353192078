<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <Form ref="setting" @submit="create">
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">Организация*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model="organization"
                                    placeholder="Выберите организацию"
                                    label="name"
                                    track-by="id"
                                    :options="organizations"
                                    :show-labels="false"
                                    @select="filterOrganization($event)"
                                    @remove="reset($event)"
                                ></VueMultiselect>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label required text-end">Роль начальника отдела*</label>
                            <div class="col-sm-8 position-relative">
                                <VueMultiselect
                                    v-model="settings.chief"
                                    placeholder="Роль начальника отдела"
                                    label="fullname"
                                    track-by="id"
                                    :options="users"
                                    :taggable="true"
                                    :multiple="true"
                                    :show-labels="false"
                                >
                                </VueMultiselect>
                                <ErrorMessage name="hr"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-lg-2">
                                <button class="btn btn-green">
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import Modules from "../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../components/ToastAlert";
import axios from "axios";
import * as req from "../../services/tradein";

export default {
    name: "TradeInSettings",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data() {
        return {
            type: 'green',
            message: '',
            is_alert: false,
            users: [],
            organizations: [],
            organization: null,
            settings: {
                chief: null,
            },
            errors: {
                chief: '',
                organization: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: "Настройки",
            back_title: "Меню Trade IN",
            link: 'tradein',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        create(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/tradein/settings/update', {
                data: this.settings.chief,
                type: 'chief',
                organization: this.organization
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = 'Успешно обновлено!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.settings = response.data.data.settings;
                        this.organization = response.data.data.organization;
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.memos.setErrors(errors.response.data.errors);
                            this.errors.chief = errors.response.data.errors.chief ?? 0;
                            break;
                    }
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/tradein/settings', config);
                this.users = response.data.data.users;
                this.settings = response.data.data.settings;
                this.organizations = response.data.data.organizations;
                this.organization = response.data.data.firstOrganization;
                console.log(this.imprest);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        async filterOrganization(event){
            this.isLoading = true;
            let result = await req.filterOrganization(event.id);
            this.settings = result.settings;
            this.organization = result.organization;
            this.isLoading = false;
        },
        reset(event){
            this.getData();
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page d-flex flex-column justify-content-center mt-0">
                <div class="block_select">
                    <div class="mb-2"
                         v-for="checklist in checklists"
                         :key="checklist.id"
                         @click="next(checklist.id)"
                    >
                        <div class="btn cl_item pad btn_vw"
                            :class="goClass(checklist.quality.status)"
                        >
                           {{ checklist.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "CheckList",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            checklists: [],
            breadCrumbs: true,
            title: 'Список вопросов',
            back_title: 'Список отделов',
            link: 'quality',
        }
    },
    created() {
        this.getChecklists();
    },
    methods: {
        next(id){
            this.$router.push(`/quality/${this.$route.params.dep_id}/${id}`);
        },
        async getChecklists(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/checklist/department/${this.$route.params.dep_id}`, config);
                this.checklists = response.data.data.checklists;
                console.log(this.checklists);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        goClass(status){
            if(status === 'not_verified'){
                return '';
            }else if(status === 'accept'){
                return 'type_accept';
            }else if(status === 'defect'){
                return 'type_defect';
            }else if(status === 'penalty'){
                return 'type_defect';
            }else if(status === 'corrected'){
                return 'type_corrected';
            }else if(status === 'resolved'){
                return 'type_resolved';
            }
        }
    }
}
</script>

<style scoped>

</style>

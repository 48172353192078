<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"/>
            <div class="page">

                <h5 class="mt-3">Новый договор</h5>

                <Form ref="contract">

<!--                    <div class="mb-3">
                        <label class="form-label">Организация</label>
                        <VueMultiselect
                            v-model:modelValue="organization"
                            placeholder="Выберите организацию"
                            label="name"
                            track-by="id"
                            :options="organizations"
                            name="organization"
                            :class="errors.organization.length ? 'error' : ''"
                            @select="selectOrganization($event)"
                            @clear="clearInput($event)"
                        />
                    </div>-->

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Автомобиль</label>
                        <VueMultiselect
                            v-model="contract.car"
                            placeholder="Выберите автомобиль"
                            label="name"
                            track-by="id"
                            :options="cars"
                            :show-labels="false"
                            :searchable="false"
                            @tag="addCar"
                            @select="selectCar($event)"
                            :class="[errors.surname.length > 0 ? 'is-invalid' : '']"
                        >
                        </VueMultiselect>
                        <ErrorMessage name="car" />
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Менеджер</label>
                        <Field
                            v-model="contract.manager"
                            type="text"
                            readonly="true"
                            name="manager"
                            class="form-control"
                            :class="[errors.manager.length > 0 ? 'error' : '']"
                        />
                        <ErrorMessage name="manager" />
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Телефон</label>
                        <Field
                            placeholder="+7 (___) ___-__-__"
                            v-model="phone"
                            type="tel"
                            name="phone"
                            class="form-control"
                            :class="[errors.phone.length > 0 ? 'error' : '']"
                            v-telmask
                        />
                        <span class="input_search_preloader" v-if="isSearch">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </span>
                        <ErrorMessage name="phone" style="color: red;"/>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Фамилия</label>
                        <Field
                            placeholder="Фамилия"
                            v-model="contract.surname"
                            type="text"
                            name="surname"
                            class="form-control"
                            :class="[errors.surname.length > 0 ? 'error is-invalid' : '']"
                        />
                        <ErrorMessage name="surname" style="color: red;"/>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Имя</label>
                        <Field
                            placeholder="Имя"
                            v-model="contract.name"
                            type="text"
                            name="name"
                            class="form-control"
                            :class="[errors.name.length > 0 ? 'error is-invalid' : '']"
                        />
                        <ErrorMessage name="name" style="color: red;"/>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Отчество</label>
                        <Field
                            placeholder="Отчество"
                            v-model="contract.patronymic"
                            type="text"
                            name="patronymic"
                            class="form-control"
                            :class="[errors.patronymic.length > 0 ? 'error' : '']"
                        />
                        <ErrorMessage name="patronymic" />
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">ИИН</label>
                        <Field
                            placeholder="____________"
                            v-maska="iin_mask"
                            v-model="contract.IIN"
                            type="text"
                            name="IIN"
                            class="form-control"
                            inputmode="numeric"
                            :class="[errors.IIN.length > 0 ? 'error is-invalid' : '']"
                        />
                        <ErrorMessage name="IIN" style="color: red;"/>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Уд.л.</label>
                        <Field
                            placeholder="№"
                            v-model="contract.id_number"
                            type="number"
                            name="id_number"
                            class="form-control"
                            :class="[errors.id_number.length > 0 ? 'error' : '']"
                        />
                        <ErrorMessage name="id_number" />
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Кем выдано уд.л.</label>
                        <Field
                            placeholder=""
                            v-model="contract.id_issued"
                            type="text"
                            name="id_issued"
                            class="form-control"
                            :class="[errors.id_issued.length > 0 ? 'error' : '']"
                        />
                        <ErrorMessage name="id_issued" />
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Дата выдачи уд.л.</label>
                        <Field
                            placeholder="__.__.____"
                            v-maska="date_mask"
                            v-model="contract.id_date"
                            type="text"
                            name="id_date"
                            class="form-control"
                            inputmode="numeric"
                            :class="[errors.id_date.length > 0 ? 'error' : '']"
                        />
                        <ErrorMessage name="id_date" style="color: red;"/>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <!-- <label class="form-label">Лицевая сторона уд.л.</label> -->
                        <img style="width: 100%;" data-fancybox="gallery" id="image-1" v-if="!is_front"/>
                        <img style="width: 100%;" data-fancybox="gallery" :src="front" v-if="is_front"/>
                        <form  enctype="multipart/form-data">
                            <div class="mb-3">
                                <input type="file"
                                       id="files-1"
                                       class="inputfile"
                                       accept="image/*"
                                       ref="files-1"
                                       @change="onAttachmentChange('files-1', 1, 'id-card-0')"
                                       style="display: none"
                                />
                                <label for="files-1" class="btn w-100 gray mt-1">
                                    <fa icon="camera" class="icon me-1 opacity-50"/>
                                    Фото лицевой стороны уд.л.</label>
                            </div>
                        </form>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <!-- <label class="form-label">Обратная сторона уд.л.</label> -->
                        <img style="width: 100%;" data-fancybox="gallery" id="image-2" v-if="!is_back"/>
                        <img style="width: 100%;" data-fancybox="gallery" :src="back" v-if="is_back"/>
                        <form  enctype="multipart/form-data">
                            <div class="mb-3">
                                <input type="file"
                                       id="files-2"
                                       class="inputfile"
                                       accept="image/*"
                                       ref="files-2"
                                       @change="onAttachmentChange('files-2', 2, 'id-card-1')"
                                       style="display: none"
                                />
                                <label for="files-2" class="btn w-100 gray mt-1">
                                    <fa icon="camera" class="icon me-1 opacity-50"/>
                                    Фото обратной стороны уд.л.</label>
                            </div>
                        </form>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Водительское уд.</label>
                        <Field
                            placeholder="№"
                            v-model="contract.driver_id_number"
                            type="text"
                            name="driver_id_number"
                            class="form-control"
                            :class="[errors.driver_id_number.length > 0 ? 'error' : '']"
                        />
                        <ErrorMessage name="driver_id_number" />
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Дата выдачи вод.уд.</label>
                        <Field
                            placeholder="__.__.____"
                            v-maska="date_mask"
                            v-model="contract.driver_id_date"
                            type="text"
                            name="driver_id_date"
                            class="form-control"
                            inputmode="numeric"
                            :class="[errors.driver_id_date.length > 0 ? 'error' : '']"
                        />
                        <ErrorMessage name="driver_id_date" style="color: red;"/>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <!-- <label class="form-label">Лицевая сторона</label> -->
                        <img style="width: 100%;" data-fancybox="gallery" id="image-3" v-if="!is_driver"/>
                        <img style="width: 100%;" data-fancybox="gallery" :src="driver" v-if="is_driver"/>
                        <form  enctype="multipart/form-data">
                            <div class="mb-3">
                                <input type="file"
                                       id="files-3"
                                       class="inputfile"
                                       accept="image/*"
                                       ref="files-3"
                                       @change="onAttachmentChange('files-3', 3, 'drive-card-0')"
                                       style="display: none"
                                />
                                <label for="files-3" class="btn w-100 gray mt-1">
                                    <fa icon="camera" class="icon me-1 opacity-50"/>
                                    Фото лицевой стороны вод.уд.</label>
                            </div>
                        </form>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Количество дней</label>
                        <Field
                            v-model="contract.days_count"
                            type="text"
                            name="days_count"
                            class="form-control"
                            inputmode="numeric"
                            :class="[errors.days_count.length > 0 ? 'error' : '']"
                        />
                        <ErrorMessage name="days_count" style="color: red;"/>
                    </div>

                    <div class="mb-3" v-if="is_form">
                        <label class="form-label">Примечание</label>
                        <div class="col-sm-8 position-relative">
                            <textarea class="form-control comment_step_third"
                                      v-model="contract.comment"
                            ></textarea>
                        </div>
                    </div>

                    <input type="hidden" name="photo" id="photo-1" v-if="!is_front"/>
                    <input type="hidden" name="photo" id="photo-2" v-if="!is_back"/>
                    <input type="hidden" name="photo" id="photo-3" v-if="!is_driver"/>
                </Form>
                <div class="mb-3" v-if="is_form">
                    <button class="btn w-100 btn-red"
                            :disabled="!is_docs"
                            @click="showRules"
                    >
                        Обязанности клиента
                    </button>
                </div>
            </div>
        </div>
    </transition>

    <rules
        v-if="is_rules"
        @close-form="close"
        @show-sign="showSign"
    />

    <sign
        v-if="is_sign"
        @close-form-sign="closeSign"
        @save-sign="createContract($event)"
        :app="app"
    />

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>

import {errorMessages} from "../../../services/helpers";

const telmask = {
    mounted: (el, binding) => {
        el.onfocus = function (e) {
            this.value = '+7 ('
            this.setSelectionRange(4, 4)
        }
        el.oninput = function (e) {
            if (!e.isTrusted) {
                return
            }
            const x = this
                .value
                .replace(/\D/g, '')
                .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
            x[1] = '+7'
            this.value = !x[3]
                ? x[1] + ' (' + x[2]
                : x[1] + ' (' + x[2] + ') ' + x[3] + (
                    x[4]
                        ? '-' + x[4]
                        : ''
                ) + (
                    x[5]
                        ? '-' + x[5]
                        : ''
                )
        }
    }
}

import axios from "axios";
import { Field, Form, ErrorMessage } from 'vee-validate';
import spinner from '../../../components/Spinner';
import ToastAlert from '../../../components/ToastAlert';
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import { mask } from 'maska';
import Rules from "../Rules";
import Sign from "../Sign";
import * as req from "../../../services/td";

export default {
    name: "SpoofCreate",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs, Rules, Sign, ToastAlert
    },
    directives: {
        telmask
    },
    data() {
        return {
            type: '',
            message: '',
            is_alert: false,
            is_front: false,
            is_back: false,
            is_driver: false,
            isSearch: false,
            is_rules: false,
            is_form: true,
            is_sign: false,
            is_docs: false,
            app: 'spoof',
            mask: '+#(###)###-##-##',
            date_mask: '##.##.####',
            iin_mask: '############',
            cars: [],
            //organizations: [],
            //organization: null,
            phone: '',
            client_btw: false,
            client_from_service	: false,
            contract: {
                id: null,
                car: null,
                manager: null,
                surname: null,
                name: null,
                patronymic: null,
                id_number: null,
                id_issued: null,
                id_date: null,
                driver_id_number: null,
                driver_id_date: null,
                IIN: null,
                comment: null,
                days_count: 1,
            },
            errors: {
                car: '',
                manager: '',
                surname: '',
                name: '',
                patronymic: '',
                phone: '',
                id_number: '',
                id_issued: '',
                id_date: '',
                driver_id_number: '',
                driver_id_date: '',
                IIN: '',
                comment: '',
                days_count: '',
                //organization: '',
            },
            id: null,
            front: null,
            back: null,
            driver: null,
            file: null,
            files: [],
            isLoading: false,
            breadCrumbs: true,
            title: 'Добавить',
            back_title: 'Список договоров',
            link: 'spoof',
        }
    },
    watch: {
        phone(after, before) {
            this.search_client();
        }
    },
    created() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        toggleClientBtw() {
            this.client_btw = !this.client_btw
        },
        toggleClientFromService() {
            this.client_from_service = !this.client_from_service
        },
        createContract(event){
            this.isLoading = true;
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            const formData = new FormData();
            if(!this.driver){
                let q = Array.prototype.slice.call(document.getElementsByName("photo"));
                for( let i = 0; i < q.length; i++){
                    formData.append('photos[' + i + ']', q[i].defaultValue);
                }
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
                }
            }
            if(!this.front && !this.back){
                let q = Array.prototype.slice.call(document.getElementsByName("photo"));
                for( let i = 0; i < q.length; i++){
                    formData.append('photos[' + i + ']', q[i].defaultValue);
                }
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
                }
            }
            if(!this.front){
                let q = Array.prototype.slice.call(document.getElementsByName("photo"));
                for( let i = 0; i < q.length; i++){
                    formData.append('photos[' + i + ']', q[i].defaultValue);
                }
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
                }
            }
            if(!this.back){
                let q = Array.prototype.slice.call(document.getElementsByName("photo"));
                for( let i = 0; i < q.length; i++){
                    formData.append('photos[' + i + ']', q[i].defaultValue);
                }
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
                }
            }
            if(!this.front && !this.back && !this.driver){
                let q = Array.prototype.slice.call(document.getElementsByName("photo"));
                console.log(q);
                for( let i = 0; i < q.length; i++){
                    formData.append('photos[' + i + ']', q[i].defaultValue);
                }
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
                }
            }
            formData.append('id', this.id);
            formData.append('car', this.contract.car.id);
            //formData.append('organization_id', this.organization.id);
            formData.append('surname', this.contract.surname);
            formData.append('name', this.contract.name);
            formData.append('patronymic', this.contract.patronymic);
            formData.append('id_number', this.contract.id_number);
            formData.append('id_issued', this.contract.id_issued);
            formData.append('id_date', this.contract.id_date);
            formData.append('driver_id_number', this.contract.driver_id_number);
            formData.append('driver_id_date', this.contract.driver_id_date);
            formData.append('IIN', this.contract.IIN);
            formData.append('comment', this.contract.comment);
            formData.append('phone', this.phone);
            formData.append('days_count', this.contract.days_count);
            formData.append('status', 'none');
            formData.append('slug', 'docs');
            formData.append('data', JSON.stringify(event));

            axios.post('/api/admin/spoof/save/contract', formData, config)
                .then(response => {
                    console.log(response);
                    if(response.status == 200){
                        this.$router.push(`/spoof/contract/${response.data.data.contract_id}`)
                    }

                })
                .catch(errors => {
                    this.isLoading = false;
                    if(errors.response.status == 400){
                        this.type = 'red';
                        this.message = errorMessages(errors.response.data.data.errors)
                        this.is_alert = true;
                        setTimeout(() => {this.closeAlert(); }, 5000);
                    }
                });
        },
        fileInfo(file){
            let array = [];
            array.push(file.docs_name);
            array.push(file.type);
            array.push(file.size);
            return array;
        },
        validate(){
            let error = [];
            if(this.contract.surname == null){
                this.errors.surname = 'Поле фамилия обязательно';
                error.push('Поле фамилия обязательно');
            }
            if(this.contract.name == null){
                this.errors.name = 'Поле имя обязательно';
                error.push('Поле имя обязательно');
            }
            if(this.contract.IIN == null){
                this.errors.IIN = 'Поле ИИН обязательно';
                error.push('Поле ИИН обязательно');
            }
            if(this.contract.car == null){
                this.errors.car = 'Поле Автомобиль обязательно';
                error.push('Поле Автомобиль обязательно');
                this.is_alert = true;
                this.message = this.errors.car;
                setTimeout(() => {this.closeAlert(); }, 3000);
            }
            /*if(this.organization == null){
                this.errors.organization = 'Поле Организация обязательно';
                error.push('Поле Организация обязательно');
            }*/

            if(error.length > 0){
                return false;
            }else{
                return true;
            }
        },
        showSign(){
            window.scrollTo(0, 0);
            this.is_rules = false;
            this.is_sign = true;
        },
        showRules(){
            this.validate();
            if(this.validate()){
                window.scrollTo(0, 0);
                this.is_rules = true;
                this.is_form = false;
            }
        },
        close(){
            this.is_form = true;
            this.is_rules = false;
        },
        closeSign(){
            this.is_rules = true;
            this.is_sign = false;
        },
        async getData() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get('/api/admin/spoof/get/data', config);
                //this.organizations = response.data.data.organizations;
                this.contract.manager = response.data.data.manager;
                this.cars = response.data.data.cars;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        async search_client(){
            try {
                this.isSearch = true;
                if(this.phone.length == 18){
                    const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                    const response = await axios.get(`/api/admin/spoof/search/${this.phone}`, config);
                    if(Object.keys(response.data.data.clients).length > 0){
                        this.id = response.data.data.clients.id;
                        this.contract.surname = response.data.data.clients.surname;
                        this.contract.name = response.data.data.clients.name;
                        this.contract.patronymic = response.data.data.clients.patronymic;
                        this.contract.id_number = response.data.data.clients.id_number;
                        this.contract.id_issued = response.data.data.clients.id_issued;
                        this.contract.id_date = response.data.data.clients.id_date;
                        this.contract.driver_id_number = response.data.data.clients.driver_id_number;
                        this.contract.driver_id_date = response.data.data.clients.driver_id_date;
                        this.contract.IIN = response.data.data.clients.IIN;
                        this.is_alert = true;
                        this.message = 'Клиент найден';
                        this.front = response.data.data.front;
                        this.back = response.data.data.back;
                        this.driver = response.data.data.driver;
                        if(this.front && this.back && this.driver){
                            this.is_docs = true;
                        }
                        this.is_front = true;
                        this.is_back = true;
                        this.is_driver = true;
                    }else {
                        this.is_alert = true;
                        this.message = 'Клиент не найден';
                    }

                    setTimeout(() => {this.closeAlert(); }, 3000);
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.isSearch = false;
            }
        },
        addCar(newItem){
            const item = {
                title: newItem,
            }
            this.cars.push(item)
            this.car.push(item)
        },
        onAttachmentChange(ref, number, docs) {
            if(number === 1){
                this.is_front = false;
                this.front = null;
            }
            if(number === 2){
                this.is_back = false;
                this.back = null;
            }
            if(number === 3){
                this.is_driver = false;
                this.driver = null;
            }
            const MAX_WIDTH = 1024;
            const MAX_HEIGHT = 768;
            this.file = this.$refs[ref].files[0];
            this.file.docs_name = docs;
            this.files.push(this.file);
            if(this.files.length >= 3){
                this.is_docs = true;
            }
            const file = this.file; // get the file
            const blobURL = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onerror = function () {
                URL.revokeObjectURL(this.src);
                // Handle the failure properly
                console.log("Cannot load image");
            };
            img.onload = function () {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                let ndata = canvas.toDataURL('image/jpeg')
                document.getElementById('image-'+number).src = ndata;
                document.getElementById('photo-'+number).value = ndata;
            };

            function calculateSize(img, maxWidth, maxHeight) {
                let width = img.width;
                let height = img.height;

                // calculate the width and height, constraining the proportions
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
                return [width, height];
            }
        },
        /*async selectOrganization(item){
            this.isLoading = true;
            this.errors.organization = '';
            let result = await req.getOrganizationCars(item.id);
            this.cars = result.cars;
            this.isLoading = false;
        },*/
        clearInput(e){
            this.errors[e] = '';
        },
        selectCar(item){
            this.errors.car = '';
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/spoof/car/available', {
                car_id: item.id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.is_alert = true;
                        this.type = 'green'
                        this.message = response.data.data.message;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                    if(response.status == 201){
                        this.is_alert = true;
                        this.type = 'red'
                        this.message = response.data.data.message;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>

import * as auth from "./auth";
import axios from "axios";

export const getBoards  = async () => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/boards`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const saveBoard = async (item) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {

        const response = await axios.post('/api/admin/boards', item, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const deleteBoard  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.delete(`/api/admin/boards/${id}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const fetchBoardData  = async (alias) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/boards/${alias}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const saveIndicator = async (item) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {

        const response = await axios.post('/api/admin/boards/indicator', item, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const closeIndicatorMonth = async (item) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {

        const response = await axios.post('/api/admin/boards/close', item, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const saveValueIndicator = async (data) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {

        const response = await axios.post('/api/admin/boards/value', {data: data}, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const fetchBoardDisplay  = async (alias) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/boards/display/${alias}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const getIndicators  = async (alias) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/boards/indicators/${alias}`, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const deleteIndicator = async (data) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {

        const response = await axios.post('/api/admin/boards/delete', data, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};


//Доска ТО
export const getTOBoards = async() => {
    const config = { 'headers': { 'Authorization': 'Bearer ' + auth.getCookie() } };
    try {
        const response = await axios.get(`/api/admin/to-boards/get/data`, config);
        return response.data.data;
    } catch (exception) {
        console.log(exception);
    }
};

export const getData = async() => {
    const config = { 'headers': { 'Authorization': 'Bearer ' + auth.getCookie() } };
    try {
        const response = await axios.get(`/api/admin/to-boards`, config);
        return response.data.data;
    } catch (exception) {
        console.log(exception);
    }
};

export const createPrice = async (items) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.post(`/api/admin/to-boards`, {
            brand: items.brand,
            model: items.model,
            items: items.items,
            id: items.id,
        }, config);
        return response.data.data;
    } catch (exception) {
        return exception.response;
    }
};

export const deletePrice  = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.delete(`/api/admin/to-boards/${id}`, config);
        return response.data.data;
    } catch (exception) {
        console.log(exception);
    }
};

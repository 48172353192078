<template>
    <div class="row">
        <div class="col">
            <h1>{{ title }}</h1>
        </div>
    </div>
    <!-- brand_id -->
    <label class="form-label">Марка автомобиля</label>
    <VueMultiselect
        v-model:modelValue="brand"
        placeholder="Марка автомобиля"
        label="name_brand"
        track-by="id"
        :options="brands"
        :taggable="true"
        name="brand"
        :class="errors.brand.length ? 'error' : ''"
        @select="selectBrand($event, 'brand')"
    />
    <!-- model_id -->
    <label class="form-label">Модель автомобиля</label>
    <VueMultiselect
        v-model:modelValue="model"
        placeholder="Модель автомобиля"
        label="name_model"
        track-by="id"
        :options="models"
        name="model"
        :taggable="true"
        :class="errors.model.length ? 'error' : ''"
    />
    <Button
        :caption="$t('buttons.add_volume')"
        color="gray"
        add_class="w-100 mb-2"
        btn_icon="add"
        @click="addRoute"
    />
    <div class="av_card mb-2"
         v-if="is_route"
         v-for="(capacity, idx) in newPrice"
         :key="idx"
    >
        <div class="d-flex justify-content-end">
            <Button
                :caption="$t('buttons.add_volume')"
                color="gray"
                add_class="w-100 mb-2"
                btn_icon="remove"
                @click="removePrice(idx)"
            />
        </div>
        <div class="mt-3 fw-normal">
            <div class="hstack justify-content-between border-bottom pb-2 mb-2" v-for="(item, index) in capacity" :key="idx">

                <div class="fw-bold fs-7">{{ item.title}}</div>
                <div class="fw-bold">
                    <Input v-if="index == 0" add_class="input_class" name="capacity" type="number" v-model:modelValue="newPrice[idx][index].capacity" @clear="clearInput($event)"/>
                    <Input v-else add_class="input_class" name="price" type="number" v-model:modelValue="newPrice[idx][index].price" @clear="clearInput($event)"/>
                </div>
            </div>
        </div>
    </div>
    <Button
        caption="Сохранить"
        color="btn-green"
        add_class="w-100"
        icon="check"
        @click="save"
    />

</template>

<script>
import VueMultiselect from "vue-multiselect";
import Input from "../../../components/Input.vue";
import Button from "../../../components/Button.vue";

export default {
    name: "FormPrice",
    components: {
        VueMultiselect, Input, Button
    },
    props: {
        is_route: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        id: {
            type: Number,
            default: 0
        },
        brand:{
            default: null,
        },
        brands:{
            type: Array,
            default: [],
        },
        model:{
            default: null,
        },
        models:{
            type: Array,
            default: [],
        },
        newPrice:{
            type: Array,
            default: [],
        },
        errors: {
            brand: '',
            model: '',
            capacity: '',
        },
    },
    methods: {
        save(){
            this.$emit('create-to', {
                brand: this.brand,
                model: this.model,
                items: this.newPrice,
                id: this.id,
            });
        },
        selectBrand(event, value){
            this.errors[value] = '';
            this.$emit('select-brand', {
                brand: event,
                brand_id: event.id,
            });
        },
        clearInput(e){
            this.errors[e] = '';
        },
        addRoute() {
            this.$emit('is_route', {is_route: true, model: this.model});
        },
        removePrice(index){
            this.$emit('remove_price', {index: index});
        }
    }
}
</script>

<style scoped>

</style>

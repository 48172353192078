<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <template v-if="is_generalnyi">
                    <div v-if="Object.keys(lists).length">
                        <div class="accordion accordion-flush mb-2" :id="'accordionFlushExample-'+ items.id" v-for="(items) in lists" :key="items.id">
                            <div class="accordion-item p-3 rounded">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed p-0 fs-7" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne-'+ items.id" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <h3>{{ items.name }}</h3>
                                    </button>
                                </h2>
                                <div :id="'flush-collapseOne-'+ items.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" :data-bs-parent="'#accordionFlushExample-'+ items.id">
                                    <div v-if="items.requests.length">
                                        <div class="item_title mb-2" v-for="list in items.requests" :key="list.id" >
                                            <!-- Номер и статус -->
                                            <div class="d-flex justify-content-between fs-7 mb-2">
                                                <div class="fs-5 fw-bold lh-base">№{{ list.id }}</div>
                                                <div
                                                    :class="list.status"
                                                    class="badge fs-7 fw-normal lh-base px-3 rounded-pill"
                                                >{{ list.status_title }}</div>
                                            </div>

                                            <div class="mt-3 fw-normal">
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.created') }}:</div>
                                                    <div class="fw-bold text-end">{{ list.date_create }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.counterparty') }}:</div>
                                                    <div class="fw-bold text-end ps-4  text-end">{{ list.counteragent.name }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.amount') }}:</div>
                                                    <div class="fw-bold text-end">{{ cur(list.payment_amount) }} {{ list.currency }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.category') }}:</div>
                                                    <div class="fw-bold text-end ps-4 text-end">{{ list.category.name }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.payment_type') }}:</div>
                                                    <div class="fw-bold text-end">{{ list.payment_type }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.provide_payment_card') }}:</div>
                                                    <div class="fw-bold text-end">{{ list.payment_document }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.provide_power_attorney') }}:</div>
                                                    <div class="fw-bold text-end">{{ list.letter_attorney }}</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.applicant') }}:</div>
                                                    <div class="fw-bold text-end" v-if="list.user != null">{{ list.user.surname }} {{ list.user.name }}</div>
                                                    <div class="fw-bold text-end text-danger" v-else>Автор заявки удален!</div>
                                                </div>
                                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                                    <div class="fs-7">{{ $t('request.status') }}:</div>
                                                    <div class="fw-bold text-end ps-4 text-end">{{ list.status_text }}</div>
                                                </div>
                                                <div class="vstack justify-content-between pb-3 mb-4">
                                                    <div class="pt-2 pb-1 fs-7">{{ $t('request.appointment') }}:</div>
                                                    <div class="alert-attachments fw-semibold lh-base rounded-3 w-100">{{ list.comment }}</div>
                                                </div>

                                                <!-- Вложения -->
                                                <div class="attchmts mb-4" v-if="(list.files.length > 0) || (Object.keys(list.user_files).length > 0)">
                                                    <h6>Прикрепленные файлы</h6>
                                                    <div v-if="list.files.length > 0">
                                                        <!-- От заявителя -->
                                                        <div class="alert alert-attachments mb-2 rounded-3" role="alert" v-if="list.user != null">
                                                            <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ list.user.surname }} {{ list.user.name }}</div>
                                                            <div v-for="file in list.files" :key="file.id">
                                                                <a data-fancybox :href="file.link" class="attachment mb-1" v-if="file.type">
                                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                                </a>
                                                                <a @click="downloadURL(file.link, file.name)" class="attachment mb-1" v-else>
                                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- От сотрудников -->
                                                    <div v-if="Object.keys(list.user_files).length > 0">
                                                        <div v-for="(items, name) in list.user_files" :key="name">
                                                            <div class="alert alert-attachments mb-2 rounded-3" role="alert">
                                                                <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ name }}</div>
                                                                <div v-for="item in items" :key="item">
                                                                    <a data-fancybox :href="item.link" class="attachment mb-1" style="cursor: pointer;" v-if="item.type">
                                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                                    </a>
                                                                    <a @click="downloadURL(item.link, item.name)" class="attachment mb-1" style="cursor: pointer;" v-else>
                                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Кнопки -->
                                            <div v-if="list.return_to">
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn-green w-100"
                                                            :disabled="list.is_under_review"
                                                            @click="showModal(list.id, 'send_corrected')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                                        {{ $t('request.button.to_send') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn-yellow w-100"
                                                            :disabled="list.is_under_review"
                                                            @click="showModal(list.id, 'return')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                                        {{ $t('request.button.agreement') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-if="!list.return_to && !list.in_final_step && !list.in_hr_work">
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn-green w-100"
                                                            @click="showModal(list.id, 'approve')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                                        {{ $t('request.button.approve') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn-yellow w-100"
                                                            @click="showModal(list.id, 'return')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                                        {{ $t('request.button.for_revision') }}
                                                    </button>
                                                </div>
                                                <div class="col-sm position-relative mb-2 mt-2">
                                                    <button class="btn btn-red w-100"
                                                            @click="showModal(list.id, 'denied')"
                                                    >
                                                        <fa class="icon me-2 opacity-50" icon="ban"></fa>
                                                        {{ $t('request.button.reject') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="col-sm position-relative mb-2 mt-2">
                                                <button class="btn btn_vw gray"
                                                        @click="getHistory(list.historys, list.id, list.is_under_review)"
                                                >   <fa class="icon me-2 opacity-50" icon="file-lines"></fa>
                                                    {{ $t('request.button.history') }}
                                                    <span class="badge rounded-pill text-bg-white ms-2" v-if="list.is_under_review">
                                                        <span class="material-symbols-rounded icon-custom">question_mark</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="mb-3">
                                            <div class="item_title fw-semibold">
                                                {{ $t('request.list_requests_is_empty') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="mb-3">
                            <div class="item_title fw-semibold">
                                {{ $t('request.list_requests_is_empty') }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="lists.length">
                        <div class="item_title mb-2" v-for="list in lists" :key="list.id" >
                            <!-- Номер и статус -->
                            <div class="d-flex justify-content-between fs-7 mb-2">
                                <div class="fs-5 fw-bold lh-base">№{{ list.id }}</div>
                                <div
                                    :class="list.status"
                                    class="badge fs-7 fw-normal lh-base px-3 rounded-pill"
                                >{{ list.status_title }}</div>
                            </div>

                            <div class="mt-3 fw-normal">
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.created') }}:</div>
                                    <div class="fw-bold text-end">{{ list.date_create }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.counterparty') }}:</div>
                                    <div class="fw-bold text-end ps-4  text-end">{{ list.counteragent.name }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.amount') }}:</div>
                                    <div class="fw-bold text-end">{{ cur(list.payment_amount) }} {{ list.currency }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.category') }}:</div>
                                    <div class="fw-bold text-end ps-4 text-end">{{ list.category.name }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.payment_type') }}:</div>
                                    <div class="fw-bold text-end">{{ list.payment_type }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.provide_payment_card') }}:</div>
                                    <div class="fw-bold text-end">{{ list.payment_document }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.provide_power_attorney') }}:</div>
                                    <div class="fw-bold text-end">{{ list.letter_attorney }}</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.applicant') }}:</div>
                                    <div class="fw-bold text-end" v-if="list.user !== null">{{ list.user.surname }} {{ list.user.name }}</div>
                                    <div class="fw-bold text-end text-danger" v-else>Автор заявки удален!</div>
                                </div>
                                <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                                    <div class="fs-7">{{ $t('request.status') }}:</div>
                                    <div class="fw-bold text-end ps-4 text-end">{{ list.status_text }}</div>
                                </div>
                                <div class="vstack justify-content-between pb-3 mb-4">
                                    <div class="pt-2 pb-1 fs-7">{{ $t('request.appointment') }}:</div>
                                    <div class="alert-attachments fw-semibold lh-base rounded-3 w-100">{{ list.comment }}</div>
                                </div>

                                <!-- Вложения -->
                                <div class="attchmts mb-4" v-if="(list.files.length > 0) || (Object.keys(list.user_files).length > 0)">
                                    <h6>Прикрепленные файлы</h6>
                                    <div v-if="list.files.length > 0">
                                        <!-- От заявителя -->
                                        <div class="alert alert-attachments mb-2 rounded-3" role="alert" v-if="list.user !== null">
                                            <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ list.user.surname }} {{ list.user.name }}</div>
                                            <div v-for="file in list.files" :key="file.id">
                                                <a data-fancybox :href="file.link" class="attachment mb-1" v-if="file.type">
                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                </a>
                                                <a @click="downloadURL(file.link, file.name)" class="attachment mb-1" v-else>
                                                    <fa class="icon me-2" icon="paperclip"></fa> {{ file.name }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- От сотрудников -->
                                    <div v-if="Object.keys(list.user_files).length > 0">
                                        <div v-for="(items, name) in list.user_files" :key="name">
                                            <div class="alert alert-attachments mb-2 rounded-3" role="alert">
                                                <div class="fw-semibold ps-1 mb-2 fs-7"><fa class="icon me-2 opacity-50" icon="user-tie"></fa>{{ name }}</div>
                                                <div v-for="item in items" :key="item">
                                                    <a data-fancybox :href="item.link" class="attachment mb-1" style="cursor: pointer;" v-if="item.type">
                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                    </a>
                                                    <a @click="downloadURL(item.link, item.name)" class="attachment mb-1" style="cursor: pointer;" v-else>
                                                        <fa class="icon me-2" icon="paperclip"></fa> {{ item.name }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!-- Кнопки -->
                            <div v-if="list.return_to">
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn-green w-100"
                                            :disabled="list.is_under_review"
                                            @click="showModal(list.id, 'send_corrected')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                        {{ $t('request.button.to_send') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn-yellow w-100"
                                            :disabled="list.is_under_review"
                                            @click="showModal(list.id, 'return')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                        {{ $t('request.button.agreement') }}
                                    </button>
                                </div>
                            </div>
                            <div v-if="!list.return_to && !list.in_final_step && !list.in_hr_work">
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn-green w-100"
                                            @click="showModal(list.id, 'approve')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="check"></fa>
                                        {{ $t('request.button.approve') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn-yellow w-100"
                                            @click="showModal(list.id, 'return')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="arrows-rotate"></fa>
                                        {{ $t('request.button.for_revision') }}
                                    </button>
                                </div>
                                <div class="col-sm position-relative mb-2 mt-2">
                                    <button class="btn btn-red w-100"
                                            @click="showModal(list.id, 'denied')"
                                    >
                                        <fa class="icon me-2 opacity-50" icon="ban"></fa>
                                        {{ $t('request.button.reject') }}
                                    </button>
                                </div>
                            </div>
                            <hr/>
                            <div class="col-sm position-relative mb-2 mt-2">
                                <button class="btn btn_vw gray"
                                        @click="getHistory(list.historys, list.id, list.is_under_review)"
                                >   <fa class="icon me-2 opacity-50" icon="file-lines"></fa>
                                    {{ $t('request.button.history') }}
                                    <span class="badge rounded-pill text-bg-white ms-2" v-if="list.is_under_review">
                                                        <span class="material-symbols-rounded icon-custom">question_mark</span>
                                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div v-else>
                        <div class="mb-3">
                            <div class="item_title fw-semibold">
                                {{ $t('request.list_requests_is_empty') }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </transition>

    <!-- Модальное окно -->
    <transition-group name="list">
        <div class="modal d-block" v-if="is_modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3" v-if="is_return">
                            <label class="form-label">{{ $t('request.select_employee') }}*</label>
                            <VueMultiselect
                                v-model="user"
                                :placeholder="$t('request.select_employee')"
                                label="name"
                                track-by="id"
                                :options="users"
                                name="user"
                                :show-labels="false"
                                @select="selectUser"></VueMultiselect>
                            <div class="invalid-feedback" :class="errors.user.length > 0 ? 'visible' : ''">{{ errors.user }}</div>
                        </div>
                        <div class="mb-3" v-if="send_corrected">
                            <label for="formFileMultiple" class="form-label">{{ $t('request.attach_files') }}</label>
                            <input
                                ref="files"
                                class="form-control"
                                type="file"
                                id="formFileMultiple"
                                multiple="multiple"
                                v-on:change="handleFileUpload()">
                        </div>
                        <div class="mb-2">
                            <label class="form-label">{{ $t('request.comment') }}</label>
                            <textarea class="form-control" rows="1" v-model="comment"></textarea>
                        </div>

                        <div class="col-sm position-relative mb-2 mt-2">
                            <button class="btn btn-green w-100" @click="save">
                                <fa class="icon me-2 opacity-50" icon="check"></fa>
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition-group>

    <transition-group name="list">
        <div class="popup-fade" v-if="is_image_modal">
            <div class="td-modal">
                <div class="row" style="height: 20px;">
                    <div class="col-12">
                        <svg @click="closeImageModal" style="position: absolute; right: 2%; z-index: 1000;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9.40764L21.075 0.332642L23.6673 2.92498L14.5923 12L23.6673 21.075L21.075 23.6673L12 14.5923L2.92498 23.6673L0.332642 21.075L9.40764 12L0.332642 2.92498L2.92498 0.332642L12 9.40764Z" fill="#AFBBCC"/>
                        </svg>
                    </div>
                </div>
                <hr/>
                <img style="width: 100%;" data-fancybox="gallery" :src="link_image" />
            </div>
        </div>
    </transition-group>

    <transition-group name="list">
        <history
            v-if="is_history"
            :historis="historis"
            @close-form="close"
            :is_print="false"
            :id="id"
        />
    </transition-group>
    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import History from "../Imprest/History";
import ToastAlert from "../../../components/ToastAlert";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import Records from "../../../components/VueAudioRecorderPlagin/Records"
import * as req from "../../../services/request.js";

export default {
    name: "PaymentChiefList",
    components: {
        History, ToastAlert,
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, Records
    },
    data(){
        return {
            headers: {
                'X-Custom-Header': 'some data'
            },
            message: '',
            type: 'green',
            is_generalnyi: false,
            is_alert: false,
            is_modal: false,
            is_return: false,
            send_corrected: false,
            id: null,
            is_history: false,
            is_image_modal: false,
            historis: [],
            lists: [],
            users: [],
            recordings: [],
            user:null,
            errors: {
                user: '',
            },
            files: '',
            type_method: '',
            comment: '',
            link_image: '',
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.list_requests'),
            back_title: this.$t('request.payments'),
            link: 'request/payments',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getRecords(data){
            this.recordings = data;
        },
        downloadURL(url, name){
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.onload = function(e) {
                if (this.status == 200) {
                    var myBlob = this.response;
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(myBlob);
                    link.download = name;
                    link.click();
                }
            };
            xhr.send();
        },
        handleFileUpload(){
            this.files = this.$refs.files[0].files;
        },
        selectUser(value){
            this.errors.user = '';
        },
        closeModal(){
            this.type_method = '';
            this.comment = '';
            this.is_modal = false;
            this.is_return = false;
        },
        showModal(id, type){
            if(type == 'return'){
                this.is_return = true;
            }
            if(type == 'send_corrected'){
                this.send_corrected = true;
            }
            this.id = id;
            this.type_method = type;
            this.is_modal = true;
        },
        save(){
            if(this.type_method == 'denied'){
                this.denied();
            }else if(this.type_method == 'return'){
                this.return();
            }else if(this.type_method == 'approve'){
                this.approve();
            }else if(this.type_method == 'send_corrected'){
                this.sendCorrected();
            }
        },
        sendCorrected(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            const formData = new FormData();
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }
            formData.append('recordings', JSON.stringify(this.recordings));
            formData.append('comment', this.comment);
            formData.append('id', this.id);
            axios.post('/api/admin/payments/send', formData, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.request_sent');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.type_method = '';
                        this.comment = '';
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        return(){
            if(this.user == null) {
                this.errors.user = this.$t('request.select_employee');
            }else {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                axios.post('/api/admin/payments/return', {
                    comment: this.comment,
                    user: this.user,
                    id: this.id,
                    recordings: this.recordings,
                }, config)
                    .then(response => {
                        if (response.status == 200) {
                            this.is_alert = true;
                            this.type = 'yellow'
                            this.message = this.$t('request.request_revision');
                            setTimeout(() => {
                                this.closeAlert();
                            }, 3000);
                            this.is_modal = false;
                            this.type_method = '';
                            this.comment = '';
                            this.id = null;
                            this.getData();
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        console.log(errors);
                    });
            }
        },
        approve(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/payments/approve', {
                comment: this.comment,
                id: this.id,
                recordings: this.recordings,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.request_approved');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.type_method = '';
                        this.comment = '';
                        this.id = null;
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        denied(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/payments/denied', {
                comment: this.comment,
                id: this.id,
                recordings: this.recordings,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.type = 'red'
                        this.message = this.$t('request.request_rejected');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.is_modal = false;
                        this.type_method = '';
                        this.comment = '';
                        this.id = null;
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        closeImageModal(){
            this.is_image_modal = false;
            this.link_image = '';
        },
        showImageModal(link){
            this.link_image = link;
            this.is_image_modal = true;
        },
        closeAlert(){
            this.is_alert = false;
        },
        close(){
            this.is_history = false;
        },
        async getHistory(data, id, is_under_review){
            if(is_under_review){
                let result = await req.viewingPaymentsHistory(id);
                if(result.message === 'success'){
                    this.getData();
                }
            }
            this.historis = data;
            this.id = id;
            this.is_history = true;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/payments/waiting', config);
                this.lists = response.data.data.lists;
                this.users = response.data.data.users;
                this.is_generalnyi = response.data.data.is_generalnyi;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        cur(val) {
            if (!val) {
                return "0";
            }
            return val.toLocaleString('ru-RU');
        },
    }
}
</script>

<style scoped>
.visible {
    display: unset!important;
}
</style>

import * as auth from "../../services/auth";
import CounteragentIndex from "../../Modules/Request/Counteragents/CounteragentIndex";
import CounteragentCreate from "../../Modules/Request/Counteragents/CounteragentCreate";
import CounteragentEdit from "../../Modules/Request/Counteragents/CounteragentEdit";

export default [
    {
        name: 'CounteragentIndex',
        path: '/request/counteragents',
        component: CounteragentIndex,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CounteragentCreate',
        path: '/request/counteragents/create',
        component: CounteragentCreate,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CounteragentEdit',
        path: '/request/counteragents/edit/:id',
        component: CounteragentEdit,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]

<template>
    <div class="type_damage">
        <div class="row">
            <div class="col-4">
                <div class="type_title">{{ name }}</div>
                <div class="block_defects mt-3">
                    <div class="d-flex mb-2"
                        v-for="part in parts.parts"
                        :key="part.id"
                    >
                        <div class="btn_damage"
                            @click="addDefect(parts.item.id, part.id, parts.item.item_id)"
                            :class="part.check === true ? 'active_defect' : ''"
                        >
                            <span class="btn_damage_character">{{ part.character }}</span>
                        </div>
                        <div class="defect_title">{{ part.parameter }}</div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="type_title">Фото</div>
                <div class="image_block">
                    <div class="photo_defect">
                        <div class="block_foto" v-if="!showPreview">
                            <div v-if="Object.keys(image).length !== 0">
                                <div v-for="(file, key) in image"  class="block_image"
                                     v-bind:key="'file-'+key">
                                    <img class="defect_image" data-fancybox="gallery" :src="`${ file }`"/>
                                </div>
                            </div>
                            <div v-else class="pb_image">
                                <svg width="95" height="85" viewBox="0 0 95 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.2736 9.54167L27.8569 18.9583H9.83342V75.4583H85.1667V18.9583H67.1433L57.7266 9.54167H37.2736ZM33.3751 0.125H61.6251L71.0417 9.54167H89.8751C91.1238 9.54167 92.3214 10.0377 93.2044 10.9207C94.0874 11.8037 94.5834 13.0013 94.5834 14.25V80.1667C94.5834 81.4154 94.0874 82.613 93.2044 83.496C92.3214 84.379 91.1238 84.875 89.8751 84.875H5.12508C3.87635 84.875 2.67877 84.379 1.79579 83.496C0.912803 82.613 0.416748 81.4154 0.416748 80.1667V14.25C0.416748 13.0013 0.912803 11.8037 1.79579 10.9207C2.67877 10.0377 3.87635 9.54167 5.12508 9.54167H23.9584L33.3751 0.125ZM47.5001 70.75C40.6321 70.75 34.0454 68.0217 29.189 63.1653C24.3326 58.3089 21.6042 51.7222 21.6042 44.8542C21.6042 37.9862 24.3326 31.3995 29.189 26.543C34.0454 21.6866 40.6321 18.9583 47.5001 18.9583C54.3681 18.9583 60.9548 21.6866 65.8112 26.543C70.6676 31.3995 73.3959 37.9862 73.3959 44.8542C73.3959 51.7222 70.6676 58.3089 65.8112 63.1653C60.9548 68.0217 54.3681 70.75 47.5001 70.75ZM47.5001 61.3333C51.8706 61.3333 56.0622 59.5971 59.1526 56.5067C62.2431 53.4163 63.9793 49.2247 63.9793 44.8542C63.9793 40.4836 62.2431 36.2921 59.1526 33.2016C56.0622 30.1112 51.8706 28.375 47.5001 28.375C43.1295 28.375 38.938 30.1112 35.8476 33.2016C32.7571 36.2921 31.0209 40.4836 31.0209 44.8542C31.0209 49.2247 32.7571 53.4163 35.8476 56.5067C38.938 59.5971 43.1295 61.3333 47.5001 61.3333Z" fill="#BABFC7"/>
                                </svg>
                            </div>
                        </div>
                        <div class="block_foto align-middle d-flex justify-content-center align-items-center" v-else>
                            <div style="padding:  unset;">
                                <div v-for="(file, key) in files" class="block_image"
                                     v-bind:key="'file-'+key">
                                    <img style="width: 100%;" class="defect_image" data-fancybox="gallery" v-bind:id="'image-'+parseInt( key )"/>
                                    <input type="hidden" name="photo" v-bind:id="'photo-'+parseInt( key )"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isDelete">
                        <form  enctype="multipart/form-data">
                            <div class="mb-3">
                                <input type="file" id="files" class="inputfile" multiple
                                       accept="image/*"
                                       ref="files"
                                       @change="onAttachmentChange"
                                       style="display: none"
                                />
                                <label for="files"  class="delete_image is_delete">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 2V0H15V2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5ZM4 4V18H16V4H4ZM7 7H9V15H7V7ZM11 7H13V15H11V7Z" fill="white"/>
                                    </svg>
                                    Удалить фото
                                </label>
                            </div>
                        </form>
                    </div>
                    <div v-if="!isDelete">
                        <form  enctype="multipart/form-data">
                            <div class="mb-3">
                                <input type="file" id="add" class="inputfile" multiple
                                       accept="image/*"
                                       ref="files"
                                       @change="onAttachmentChange"
                                       style="display: none"
                                />
                                <label for="add" class="delete_image is_add">
                                    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.1817 3.63009L8.52274 6.28905H3.4335V22.2428H24.7052V6.28905H19.6159L16.957 3.63009H11.1817ZM10.0809 0.97113H18.0578L20.7167 3.63009H26.0347C26.3873 3.63009 26.7254 3.77016 26.9747 4.01949C27.2241 4.26881 27.3641 4.60697 27.3641 4.95957V23.5723C27.3641 23.9249 27.2241 24.263 26.9747 24.5124C26.7254 24.7617 26.3873 24.9018 26.0347 24.9018H2.10402C1.75142 24.9018 1.41326 24.7617 1.16393 24.5124C0.914606 24.263 0.774536 23.9249 0.774536 23.5723V4.95957C0.774536 4.60697 0.914606 4.26881 1.16393 4.01949C1.41326 3.77016 1.75142 3.63009 2.10402 3.63009H7.42193L10.0809 0.97113ZM14.0693 20.9133C12.13 20.9133 10.2702 20.1429 8.89887 18.7717C7.52758 17.4004 6.75719 15.5405 6.75719 13.6012C6.75719 11.6619 7.52758 9.80202 8.89887 8.43072C10.2702 7.05943 12.13 6.28905 14.0693 6.28905C16.0086 6.28905 17.8685 7.05943 19.2398 8.43072C20.6111 9.80202 21.3815 11.6619 21.3815 13.6012C21.3815 15.5405 20.6111 17.4004 19.2398 18.7717C17.8685 20.1429 16.0086 20.9133 14.0693 20.9133ZM14.0693 18.2544C15.3034 18.2544 16.487 17.7641 17.3596 16.8915C18.2323 16.0188 18.7225 14.8353 18.7225 13.6012C18.7225 12.3671 18.2323 11.1835 17.3596 10.3109C16.487 9.43825 15.3034 8.94801 14.0693 8.94801C12.8352 8.94801 11.6517 9.43825 10.779 10.3109C9.9064 11.1835 9.41615 12.3671 9.41615 13.6012C9.41615 14.8353 9.9064 16.0188 10.779 16.8915C11.6517 17.7641 12.8352 18.2544 14.0693 18.2544Z" fill="#EDF0F4"/>
                                    </svg>
                                    Добавить фото
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <svg @click="close" style="position: absolute; right: 2%; z-index: 1000;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 9.40764L21.075 0.332642L23.6673 2.92498L14.5923 12L23.6673 21.075L21.075 23.6673L12 14.5923L2.92498 23.6673L0.332642 21.075L9.40764 12L0.332642 2.92498L2.92498 0.332642L12 9.40764Z" fill="#AFBBCC"/>
                </svg>
                <div class="type_title">Примечание</div>
                <div class="mt-3">
                    <textarea class="comment_defect" v-model="comment"></textarea>
                </div>
            </div>
            <div class="mt-2">
                <button class="btn btn-green"
                    @click="save"
                >Сохранить</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "HoodSpace",
    data: () => ({
        isDelete: false,
        attachment: [],
        file: '',
        files: [],
        showPreview: false,
        imagePreview: '',
        photo: [],
    }),
    props: {
        parts: {
            type: Array,
            default: [],
        },
        image: {
            type: Array,
            default: [],
        },
        comment: '',
        name: '',
    },
    methods: {
        addDefect(inspection_id, part_id, item_id){
            this.$emit('add-defect', {inspection_id:inspection_id, part_id:part_id, item_id:item_id});
        },
        close() {
            this.$emit('close-form-body')
        },
        fileInfo(file){
            let array = [];
            array.push(file.name);
            array.push(file.type);
            return array;
        },
        onAttachmentChange(event) {
            let uploadedFiles = this.$refs.files.files;
            this.count = 0;
            for (var i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i]);
                this.count++;
            }
            this.showPreview = true;
            this.getImagePreviews();
        },
        getImagePreviews() {
            for (let i = 0; i < this.files.length; i++) {
                if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
                    let reader = new FileReader();
                    reader.readAsDataURL(this.files[i]);

                    reader.onloadend = function() {
                        let result = this.result
                        let img = new Image()
                        img.src = result
                        img.onload = function() {
                            document.getElementById('image-' + parseInt(i)).src = compressImage(img, 0.3);
                            document.getElementById('photo-' + parseInt(i)).value = compressImage(img, 0.3);
                        }
                    }

                }
            }

            function compressImage(img, size) {

                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                let initSize = img.src.length
                let width = img.width
                let height = img.height
                canvas.width = width
                canvas.height = height
                ctx.fillStyle = '#fff'
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(img, 0, 0, width, height)
                let ndata = canvas.toDataURL('image/jpeg', size)
                return ndata
            }
        },
        save(e) {
            e.preventDefault();
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }, 'content-type': 'multipart/form-data'};
            const formData = new FormData();
            let q = Array.prototype.slice.call(document.getElementsByName("photo"));
            for( let i = 0; i < q.length; i++){
                formData.append('photos[' + i + ']', q[i].defaultValue);
            }
            for (let i = 0; i < this.files.length; i++) {
                formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
            }
            formData.append('id', this.parts.item.id);
            formData.append('comment', this.comment);
            formData.append('status', 'defect');
            formData.append('slug', 'defect');
            axios.post(`/api/admin/tradein/hood-space/save`, formData, config)
                .then(response => {
                    if (response.status == 200) {
                        this.close();
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
    }
}
</script>

<style scoped>

</style>

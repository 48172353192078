import axios from "axios";
import * as auth from '../services/auth';

export const getTradeInStatusStep = async (id) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.get(`/api/admin/step/lists/${id}`, config);
        return response.data.data.lists;
    } catch (exception) {
        return exception.response;
    }
};

export const fetchPermisson = async (path) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.post('/api/admin/permissions/get/data', {path: path}, config);
        return response.data.data.permission;
    } catch (exception) {
        return exception.response;
    }
};

export const getUserPermissionActions = async (path) => {
    const config = {'headers': { 'Authorization': 'Bearer ' + auth.getCookie() }};
    try {
        const response = await axios.post('/api/admin/users/get/user/permission/actions', {path: path}, config);
        return response.data.data.actions;
    } catch (exception) {
        return exception.response;
    }
};

export function errorMessages(items) {
    let message = '';
    Object.entries(items).forEach((entry) => {
        const [key, value] = entry;
        message += value + '\n';
    });

    return message
}

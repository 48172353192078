<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">


                <h5 class="py-2">Заявка на аванс</h5>
                    <div class="item_title mb-2">

                        <!-- Номер, Дата -->
                        <div
                            class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                            <div class="fs-6 fw-bold">№{{ id }}</div>
                            <div>{{ moment(created_at).format("DD.MM.YYYY hh:mm") }}</div>
                        </div>

                        <!-- Сотрудник -->
                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                            <span class="fw-normal">{{ $t("request.worker") }}</span>
                            <span class="text-end fw-semibold">{{ fullname }}</span>
                        </div>

                        <!-- Сумма -->
                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                            <span class="fw-normal">{{ $t("request.amount") }}</span>
                            <span class="text-end fw-semibold">{{ cur(payment_amount) }}</span>
                        </div>

                        <!-- Долг -->
                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                            <span class="fw-normal">{{ $t("request.debt") }}</span>
                            <span class="text-end fw-semibold">{{ cur(debt) }}</span>
                        </div>

                        <!-- Заработано -->
                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                            <span class="fw-normal">{{ $t("request.earned") }}</span>
                            <span class="text-end fw-semibold">{{ cur(earned) }}</span>
                        </div>

                        <!-- Способ  -->
                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                            <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
                            <span class="text-end fw-semibold">{{ payment_type }}</span>
                        </div>

                        <!-- Комментарий -->
                        <div class="hstack justify-content-between border-bottom pb-2 mb-2" v-if="comment">
                            <span class="fw-normal">{{ $t("request.comment") }}</span>
                            <span class="text-end fw-semibold">{{ cur(comment) }}</span>
                        </div>

                    </div>

                    <div class="buttons_row">
                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.specify_method_obtaining') }}*</label>
                            <VueMultiselect
                                v-model="type"
                                :placeholder="$t('request.method_obtaining')"
                                label="name"
                                track-by="id"
                                :options="types"
                                :searchable="false"
                                :show-labels="false"
                                @select="select"
                                @remove="remove"
                                :class="[errors.type.length > 0 ? 'error is-invalid' : '']"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="type" style="color: red;"/>
                        </div>

                        <div class="mb-2 mt-2" v-if="is_save">
                            <button class="btn btn_vw btn-green" @click="save">
                                <span class="material-symbols-rounded opacity-50 me-1">check</span>
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                        <div class="mb-2 mt-2">
                            <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                                <span class="material-symbols-rounded opacity-50 me-1">history</span>
                                {{ $t('request.button.history') }}
                            </button>
                        </div>

                    </div>


            </div>
        </div>
    </transition>

    <transition-group name="list">
        <history
            v-if="is_history"
            :historis="historis"
            @close-form="close"
        />
    </transition-group>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type_allert"
    />

</template>

<script>
import History from "./History";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import moment from "moment";
import ToastAlert from "../../../components/ToastAlert.vue";

export default {
    name: "ImprestChiefAccountantPage",
    components: {
        History,
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data(){
        return {
            is_alert: false,
            message: '',
            type_allert: '',
            is_history: false,
            is_save: false,
            types: [
                { name: this.$t('request.money'), id: "money" },
                { name: this.$t('request.card'), id: "card" },
            ],
            errors: {
                type: '',
            },
            id: null,
            type: null,
            payment_type: null,
            fullname: null,
            payment_amount: null,
            earned: null,
            debt: null,
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.request')+ ' №' + this.$route.params.id,
            back_title: this.$t('request.list_requests'),
            link: 'request/imprest/chief/chief-accountant/list',
        }
    },
    created(){
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        moment,
        close(){
            this.is_history = false;
        },
        getHistory(data){
            this.historis = data;
            this.is_history = true;
        },
        select(){
            this.is_save = true;
        },
        remove(){
            this.is_save = false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/imprest/one/${this.$route.params.id}`, config);
                this.list = response.data.data.list;
                this.id = this.list.id;
                this.debt = this.list.debt;
                this.earned = this.list.earned;
                this.payment_type = this.list.payment_type;
                this.fullname = this.list.user.surname +' '+ this.list.user.name;
                this.payment_amount = this.list.payment_amount;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        cur(val) {
            if (!val) {
                return "0 KZT";
            }
            const format = new Intl.NumberFormat("ru-Ru", {
                style: "currency",
                currency: "KZT",
                minimumFractionDigits: 0,
            });
            return format.format(parseInt(val));
        },
        save(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};

            axios.post('/api/admin/imprest/chief/accountant/save', {
                id: this.id,
                type: this.type,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.$router.push('/request/imprest/chief/chief-accountant/list')
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 503:
                            this.is_alert = true;
                            this.type_allert = 'red';
                            this.message = errors.response.data.data.message;
                            setTimeout(() => {this.closeAlert(); }, 3000);
                            break;
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>

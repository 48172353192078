<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <div class="col-auto">
                        <router-link to="/td/settings/cron/create">
                            <button class="btn gray btn_add">
                                Добавить
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="table-responsive-md">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-muted">Название</th>
                            <th class="text-muted" style="width: 15%;">Статус</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="setting in settings"
                            :key="setting.id"
                        >
                            <td>
                                {{ setting.name }}
                            </td>
                            <td>
                                {{ setting.status }}
                            </td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                        <li><button type="button" @click="$router.push(`/td/settings/cron/edit/${setting.id}`)" class="dropdown-item">Редактировать</button></li>
                                        <li><hr class="dropdown-divider"></li>
                                        <li>
                                            <button type="button" @click="deleteSetting(setting.id)" class="dropdown-item">Удалить</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import ToastAlert from '../../../components/ToastAlert';

export default {
    name: "TDSettings",
    components: {
        spinner, BreadCrumbs, ToastAlert
    },
    data(){
        return {
            settings: [],
            isLoading: false,
            breadCrumbs: true,
            title: 'Категории',
            back_title: 'Настройки',
            link: 'td/settings',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        deleteSetting(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/testdrives/settings/delete', {
                id: id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.getData();
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get('/api/admin/testdrives/settings/index', config);
                this.settings = response.data.data.settings;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <VueMultiselect
                        v-model="organization"
                        placeholder="Фильтр по организации"
                        label="name"
                        track-by="id"
                        :options="organizations"
                        :show-labels="false"
                        @select="filterOrganization($event)"
                        @remove="reset($event)"
                    ></VueMultiselect>

                    <div class="row mb-2">
                        <label class="col-sm-3 col-form-label required text-end">Сервис консультанты*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="settings.service_consultant"
                                placeholder="Выбрать..."
                                label="fullname"
                                track-by="id"
                                :options="users"
                                :taggable="true"
                                :multiple="true"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="director"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-lg-2">
                            <button class="btn btn-green"
                                    @click="saveSettings(settings.service_consultant, 'service_consultant')"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>

                    <hr/>

                    <div class="row mb-2">
                        <label class="col-sm-3 col-form-label required text-end">Страховой комиссар*</label>
                        <div class="col-sm-8 position-relative">
                            <VueMultiselect
                                v-model="settings.commissar"
                                placeholder="Выбрать..."
                                label="fullname"
                                track-by="id"
                                :options="users"
                                :taggable="true"
                                :multiple="true"
                                :show-labels="false"
                            >
                            </VueMultiselect>
                            <ErrorMessage name="chief"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-lg-2">
                            <button class="btn btn-green"
                                    @click="saveSettings(settings.commissar, 'commissar')"
                            >
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import Modules from "../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import ToastAlert from "../../components/ToastAlert";
import axios from "axios";
import * as req from "../../services/inspection";

export default {
    name: "InspectionSettings",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data() {
        return {
            type: 'green',
            message: '',
            is_alert: false,
            users: [],
            organizations: [],
            organization: null,
            settings: {
                service_consultant:null,
                commissar:null,
            },
            isLoading: false,
            breadCrumbs: true,
            title: 'Настройки',
            back_title: 'Осмотр ТС',
            link: 'inspections',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/inspection/settings/data', config);
                this.users = response.data.data.users;
                this.settings = response.data.data.settings;
                this.organizations = response.data.data.organizations;
                this.organization = response.data.data.firstOrganization;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        async saveSettings(items, type){
            this.isLoading = true;
            let result = await req.saveSettings(items, type, this.organization);
            this.message = result.message;
            this.type = 'green';
            this.settings = result.settings;
            this.organization = result.organization;
            this.is_alert = true;
            setTimeout(() => {this.closeAlert(); }, 3000);
            this.isLoading = false;
        },
        async filterOrganization(event){
            this.isLoading = true;
            let result = await req.filterOrganization(event.id);
            this.settings = result.settings;
            this.organization = result.organization;
            this.isLoading = false;
        },
        reset(event){
            this.getData();
        },
    }
}
</script>

<style scoped>

</style>

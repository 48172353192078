<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                
                <!-- Список дефектов TSM -->
                <div v-if="is_tsm">
                    <div class="d-flex justify-content-around gap-3 flex-wrap" v-if="this.defects.length > 0">
                        <div class="col-12 mb-0"
                             v-for="(item, index) in defects"
                             :key="index"
                        >
                            <h5 class="ps-3 mb-3"><fa icon="list-check" class="icon" style="padding-right: 10px; color: #c00;"></fa>{{ item.name }}</h5>

                            <div  class="col-12 item_title mb-3"
                                v-for="defect in item.defects"
                                :key="defect.id"
                            >
                                <!-- Время от и до -->
                                <div class="gn_time_row d-flex justify-content-between">
                                    <div class="gn_time_start text-black-50">
                                        <fa icon="clock" class="icon text-black-50"></fa>
                                        {{ defect.date_verification }}
                                    </div>
                                    <div class="gn_time_arrow"><fa icon="angles-right" class="icon"></fa></div>
                                    <div class="gn_time_end text-black-50">{{ defect.term_execution }}</div>
                                </div>

                                <!-- Категория -->
                                <div class="gn_item my-3">
                                    <div class="gn_row d-flex gn_inner">
                                        <div class="gn_label pe-2 text-black-50"><fa icon="list" class="icon"></fa></div>
                                        <div class="gn_value fw-normal">{{ defect.subitem.name }}</div>
                                    </div>    
                                </div>

                                <!-- Комментарий -->
                                <div class="gn_row d-flex gn_inner bg-status-gray p-3 my-2 rounded-3">
                                    <div class="gn_label pe-2 text-black-50"><fa icon="comment" class="icon"></fa></div>
                                    <div class="gn_value">{{ defect.comment }}</div>
                                </div>

                                <!-- Фото -->
                                <div v-if="Object.keys(defect.files_defect).length > 0">
                                    <div v-for="(item, index) in defect.files_defect" :key="index" class="gn_row d-flex gn_inner">
                                        <a :href="item" data-fancybox class="btn btn-red w-100 mb-2">Фото {{ index + 1 }}</a>
                                    </div>
                                </div>
                                <div class="gn_row d-flex gn_inner mt-3" v-else >
                                    <div class="gn_label text-black-50 pe-2"><fa icon="image" class="icon"></fa></div>
                                    <div class="gn_value text-black-50 mb-2">
                                        Фото отсутствует
                                    </div>
                                </div>

                                <!-- Ответственный -->
                                <div class="gn_row d-flex gn_inner mt-2">
                                    <div class="gn_label pe-2 text-black-50"><fa icon="user" class="icon"></fa></div>
                                    <div class="gn_value fw-normal fs-7">{{ defect.fullname }}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- Список дефектов Автомойка -->
                <div v-if="is_car">
                    <div class="d-flex justify-content-around gap-3 flex-wrap" v-if="this.defects.length > 0">
                        <div class="col-12 mb-0"
                            v-for="(item, index) in defects"
                            :key="index"
                        >

                            <h5 class="ps-3 mb-3"><fa icon="car" class="icon" style="padding-right: 10px; color: #c00;"></fa>{{ item.name }}</h5>

                            <div  class="col-12 item_title mb-3"
                                 v-for="defect in item.defects"
                                 :key="defect.id"
                            >

                                <!-- Время от и до -->
                                <div class="gn_time_row d-flex justify-content-between">
                                    <div class="gn_time_start text-black-50">
                                        <fa icon="clock" class="icon text-black-50"></fa>
                                        {{ defect.date_defect }}
                                    </div>
                                    <div class="gn_time_arrow"><fa icon="angles-right" class="icon"></fa></div>
                                    <div class="gn_time_end text-black-50">{{ defect.term_execution }}</div>
                                </div>

                                <!-- Категория -->
                                <div class="gn_item my-3">
                                    <div class="gn_row d-flex gn_inner">
                                        <div class="gn_label pe-2 text-black-50"><fa icon="list" class="icon"></fa></div>
                                        <div class="gn_value fw-normal">{{ defect.subcheck.name }}</div>
                                    </div>    
                                </div>

                                <!-- Комментарий -->
                                <div class="gn_row d-flex gn_inner bg-status-gray p-3 my-2 rounded-3">
                                    <div class="gn_label pe-2 text-black-50"><fa icon="comment" class="icon"></fa></div>
                                    <div class="gn_value">{{ defect.comment }}</div>
                                </div>

                                <!-- Фото -->
                                <div v-if="Object.keys(defect.files_defect).length > 0">
                                    <div v-for="(item, index) in defect.files_defect" :key="index" class="gn_row d-flex gn_inner">
                                        <a :href="item" data-fancybox class="btn btn-red w-100 mb-2">Фото {{ index + 1 }}</a>
                                    </div>
                                </div>
                                <div class="gn_row d-flex gn_inner mt-3" v-else >
                                    <div class="gn_label text-black-50 pe-2"><fa icon="image" class="icon"></fa></div>
                                    <div class="gn_value text-black-50 mb-2">
                                        Фото отсутствует
                                    </div>
                                </div>

                                <!-- Ответственный -->
                                <div class="gn_row d-flex gn_inner mt-2">
                                    <div class="gn_label pe-2 text-black-50"><fa icon="user" class="icon"></fa></div>
                                    <div class="gn_value fw-normal fs-7">{{ defect.fullname }}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Список дефектов Ежедневные -->
                <div v-if="is_quality">
                    <div class="d-flex justify-content-around gap-3 flex-wrap" v-if="this.defects.length > 0">
                        <div class="col-12 mb-0"
                             v-for="(item, index) in defects"
                             :key="index"
                        >
                            <h5 class="ps-3 mb-3"><fa icon="list-check" class="icon" style="padding-right: 10px; color: #c00;"></fa>{{ item.name }}</h5>

                            <div  class="col-12 item_title mb-3"
                                  v-for="defect in item.defects"
                                  :key="defect.id"
                            >

                                <!-- {{ defect }} -->
                                <!-- Время от и до -->
                                <div class="gn_time_row d-flex justify-content-between">
                                    <div class="gn_time_start text-black-50">
                                        <fa icon="clock" class="icon text-black-50"></fa>
                                        {{ defect.start_date }}
                                    </div>
                                    <div class="gn_time_arrow"><fa icon="angles-right" class="icon"></fa></div>
                                    <div class="gn_time_end text-black-50">{{ defect.term_execution }}</div>
                                </div>

                                <!-- Категория -->
                                <div class="gn_item my-3">
                                    <div class="gn_row d-flex gn_inner">
                                        <div class="gn_label pe-2 text-black-50"><fa icon="list" class="icon"></fa></div>
                                        <div class="gn_value fw-normal">{{ defect.checks.name }}</div>
                                    </div>    
                                </div>

                                <!-- Комментарий -->
                                <div class="gn_row d-flex gn_inner bg-status-gray p-3 my-2 rounded-3">
                                    <div class="gn_label pe-2 text-black-50"><fa icon="comment" class="icon"></fa></div>
                                    <div class="gn_value">{{ defect.comment }}</div>
                                </div>

                                <!-- Фото -->
                                <div v-if="Object.keys(defect.files_defect).length > 0">
                                    <div v-for="(item, index) in defect.files_defect" :key="index" class="gn_row d-flex gn_inner">
                                        <a :href="item" data-fancybox class="btn btn-red w-100 mb-2">Фото {{ index + 1 }}</a>
                                    </div>
                                </div>
                                <div class="gn_row d-flex gn_inner mt-3" v-else >
                                    <div class="gn_label text-black-50 pe-2"><fa icon="image" class="icon"></fa></div>
                                    <div class="gn_value text-black-50 mb-2">
                                        Фото отсутствует
                                    </div>
                                </div>

                                <!-- Ответственный -->
                                <div class="gn_row d-flex gn_inner mt-2">
                                    <div class="gn_label pe-2 text-black-50"><fa icon="user" class="icon"></fa></div>
                                    <div class="gn_value fw-normal fs-7">{{ defect.fullname }}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import axios from "axios";
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "DefectsUserLists",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            defects: [],
            user: [],
            itemName: '',
            breadCrumbs: true,
            title: 'Список дефектов сотрудника',
            back_title: 'Весь список дефектов',
            link: 'defects/' + this.$route.params.name,
            is_car: false,
            is_tsm: false,
            is_quality: false,
        }
    },
    created() {
        this.getUserDefects();
        this.blocks();
    },
    methods: {
        blocks(){
            if(this.$route.params.name === 'cars'){
                this.is_car = true;
            }else if(this.$route.params.name === 'tsm'){
                this.is_tsm = true;
            }else if(this.$route.params.name === 'quality'){
                this.is_quality = true;
            }
        },
        next(id){
            this.$router.push(`/defects/${this.$route.params.name}/${id}`)
        },
        async getUserDefects(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tsm/user/defects/${this.$route.params.name}/${this.$route.params.user_id}`, config);
                this.defects = response.data.data.defects;
                this.user = response.data.data.user;
                this.itemName = response.data.data.itemName;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>

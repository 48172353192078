<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h5 class="py-2">Журнал заявок на аванс</h5>
                <div class="item_title mb-2" v-for="list in lists" :key="list.id">

                    <!-- Номер, дата -->
                    <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                        <div class="fs-6 fw-bold">№{{ list.id }}</div>
                        <div>{{ moment(list.created_at).format("DD.MM.YYYY hh:mm") }}</div>
                        <div
                            class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block denied"
                            v-if="list.status == 'denied'">
                            Отказано
                        </div>
                        <div
                            class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block in_work"
                            v-if="list.status == 'in_work'">
                            В работе
                        </div>
                        <div
                            class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block completed"
                            v-if="list.status == 'completed'">
                            Завершено
                        </div>
                    </div>

                    <!-- Сотрудник -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.worker") }}</span>
                        <span class="text-end fw-semibold" v-if="list.user != null">{{ list.user.surname }} {{ list.user.name }}</span>
                        <span class="text-end fw-semibold text-danger" v-else>Автор заявки удален!</span>
                    </div>

                    <!-- Сумма -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.amount") }}</span>
                        <span class="text-end fw-semibold">{{ cur(list.payment_amount) }}</span>
                    </div>

                    <!-- Задолженность -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.debt") }}</span>
                        <span class="text-end fw-semibold">{{ cur(list.debt) }}</span>
                    </div>

                    <!-- Заработано -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.earned") }}</span>
                        <span class="text-end fw-semibold">{{ cur(list.earned) }}</span>
                    </div>

                    <!-- Способ получения -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
                        <span class="text-end fw-semibold" v-if="list.payment_type == 'money'">Наличные</span>
                        <span class="text-end fw-semibold" v-else>На карту</span>
                    </div>

                    <!-- Статус -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal pe-4">{{ $t('request.status') }}</span>
                        <span class="text-end fw-semibold">{{ list.status_text }}</span>
                    </div>

                    <div class="col-sm position-relative mb-2 mt-2">
                        <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                            <span class="material-symbols-rounded pe-1 opacity-75">history</span>
                            {{ $t("request.button.history") }}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </transition>

    <transition-group name="list">
        <history v-if="is_history" :historis="historis" @close-form="close" />
    </transition-group>
</template>



<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import axios from "axios";
import History from "./History";
import moment from "moment";

export default {
    name: "ImprestJournal",
    components: {
        spinner, BreadCrumbs, History
    },
    data() {
        return {
            is_modal: false,
            is_history: false,
            historis: [],
            lists: [],
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.journal'),
            back_title: this.$t('request.advance_section'),
            link: 'request/imprest',
        }
    },
    created(){
        this.getData();
        this.eventBus.emit('page-width', '100%');
    },
    methods: {
        moment,
        getHistory(data) {
            this.historis = data;
            this.is_history = true;
        },
        showModal(id) {
            this.id = id;
            this.is_modal = true;
        },
        closeModal() {
            this.is_modal = false;
            this.comment = "";
        },
        goStatus(status){
            return `history_status_` + status;
        },
        close() {
            this.is_history = false;
        },
        showHistory(id) {
            this.is_history[id] = !this.is_history[id];
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/imprest/journal', config);
                this.lists = response.data.data.lists;
                console.log(this.lists);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        cur(val) {
            if (!val) {
                return "0 KZT";
            }
            const format = new Intl.NumberFormat("ru-Ru", {
                style: "currency",
                currency: "KZT",
                minimumFractionDigits: 0,
            });
            return format.format(parseInt(val));
        },
    }
}
</script>

<style scoped>

</style>

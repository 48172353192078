<template>
    <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Список действий</h5>
                    <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md">
                            <div class="btn gray mb-3" @click="showAddAction">Добавить</div>
                            <table class="table table-hover">
                                <thead class="table-light">
                                <tr>
                                    <th>Список действий</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in actions" :key="item.id">
                                    <td style="width: 90%;">{{ item.name }}</td>
                                    <td class="text-end">
                                        <div
                                            class="btn btn_drop btn-red"
                                            style="cursor: pointer;"
                                            @click="deleteAction(item.id)">
                                            <fa icon="trash-can" class="icon"/>
                                        </div>

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn gray" @click="closeModal">Закрыть</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActionModal",
    props: {
        actions: {
            type: Array,
            default: [],
        },
        permissionId: null,
    },
    methods: {
        showAddAction(){
            this.$emit('show-add-action', {id: this.permissionId})
        },
        deleteAction(id){
            this.$emit('delete-action', {permissionId: this.permissionId, id: id});
        },
        closeModal(){
            this.$emit('close-action-modal');
        },
    }
}
</script>

<style scoped>

</style>

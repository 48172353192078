<template>
    <spinner v-if="isLoading"></spinner>
    <BreadCrumbs
        :breadCrumbs="breadCrumbs"
        :title="title"
        :back_title="back_title"
        :link="link"
    />
    <div class="page">
        <div class="row mb-5">
            <div class="col">
            </div>
            <div class="col-auto">
                <div class="btn btn_add gray w-100" @click="showModal('add')">
                    Добавить
                </div>
            </div>
        </div>
        <div class="table-responsive-md">
            <table class="table table-app table-bordered">
                <thead>
                <tr>
                    <th class="text-muted">Название</th>
                    <th class="text-muted">Alias</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="action in actions"
                    :key="action.id"
                >
                    <td>
                        {{ action.name }}
                    </td>
                    <td>
                        {{ action.alias }}
                    </td>
                    <td class="text-end" style="width: 5%;">
                        <div class="dropdown">
                            <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                </svg>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                <li><button type="button" @click="editAction(action.id)" class="dropdown-item">Редактировать</button></li>
                                <li><hr class="dropdown-divider"></li>
                            </ul>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <transition-group name="list">
        <actions-modal
            v-if="is_modal"
            :title="title"
            :name="name"
            :permissions="permissions"
            @add-action="addAction($event)"
            @close-modal="closeModal"
        />
    </transition-group>
</template>

<script>
import Spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ActionsModal from "./ActionsModal";
import axios from "axios";

export default {
    name: "Actions",
    components: {
        Spinner, BreadCrumbs, ActionsModal
    },
    data(){
        return {
            isLoading: false,
            is_modal: false,
            name: null,
            actionId: null,
            actions: [],
            permissions: [],
            breadCrumbs: true,
            title: 'Список действий',
            back_title: 'Права',
            link: 'admins/permissions',
        }
    },
    mounted() {
        this.getActions();
    },
    methods: {
        showModal(type) {
            if (type == 'add') {
                this.title = 'Добавить действие';
            } else if (type == 'add') {
                this.title = 'Редактировать действие';
            }
            this.is_modal = true
        },
        closeModal() {
            this.is_modal = false;
        },
        async editAction(id){
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/actions/edit/${id}`, config);
                this.name = response.data.data.action.name;
                this.actionId = response.data.data.action.id;
                this.is_modal = true;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        addAction(event) {
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.post(`/api/admin/actions`, {
                event,
                id: this.$route.params.id,
                actionId: this.actionId,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.is_modal = false;
                        this.actionId = null;
                        this.name = null;
                        this.getActions();
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        async getActions() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/actions/${this.$route.params.id}`, config);
                this.actions = response.data.data.actions;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        deletePermission(id) {
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.delete(`/api/admin/permissions/${id}`, config)
                .then(response => {
                    if (response.status == 200) {

                        this.getPermission();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
</script>

<style scoped>

</style>

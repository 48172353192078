<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <Button
                    caption="Добавить"
                    color="gray"
                    add_class="mb-3"
                    @click="showModal()"
                />

                <div class="table-responsive-md">
                    <table class="table table-row table-app">
                        <thead>
                        <tr>
                            <th class="text-muted">Организация</th>
                            <th class="text-muted">Категория</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="category in categories"
                            :key="category.id"
                        >
                            <td>
                                {{ category.organization.name }}
                            </td>
                            <td>
                                {{ category.name }}
                            </td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                        <li><button type="button" @click="edit(category)" class="dropdown-item">{{ $t('request.button.edit') }}</button></li>
                                        <li><hr class="dropdown-divider"></li>
                                        <li>
                                            <button type="button" @click="deleteItem(category.id)" class="dropdown-item">{{ $t('request.button.delete') }}</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{name_form}}
            </template>
            <template v-slot:body>
                <!-- Организация -->
                <label class="form-label">Организация</label>
                <VueMultiselect
                    v-model:modelValue="organization"
                    placeholder="Выберите организацию"
                    label="name"
                    track-by="id"
                    :options="organizations"
                    name="organization"
                    :class="errors.organization.length ? 'error' : ''"
                    @select="clearInput('organization')"
                    :show-labels="false"
                />

                <Input
                    label="Название"
                    placeholder="Название"
                    v-model:modelValue="name"
                    type="text"
                    name="name"
                    id="name"
                    :class="errors.name.length ? 'error' : ''"
                    @clear="clearInput($event)"
                />

                <Button
                    caption="Сохранить"
                    color="btn-green"
                    add_class="mb-3"
                    @click="create()"
                />
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from "axios";
import ToastAlert from '../../../components/ToastAlert';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import VueMultiselect from "vue-multiselect";

export default {
    name: "Categories",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, Modal, Button, ToastAlert, Input, VueMultiselect
    },
    data() {
        return {
            name_form: '',
            message: '',
            type: 'green',
            is_alert: false,
            isModal: false,
            organizations: [],
            organization: null,
            categories: [],
            id: null,
            name: '',
            errors: {
                organization: '',
                name: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.categories'),
            back_title: this.$t('request.settings'),
            link: 'request/settings',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        clearInput(e){
            this.errors[e] = '';
        },
        closeModal() {
            this.name = '';
            this.id = null;
            this.organization = null;
            this.isModal = false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/settings/categories', config);
                this.categories = response.data.data.categories;
                this.organizations = response.data.data.organizations;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        create(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/settings/categories/store', {
                    id: this.id,
                    name: this.name,
                    organization: this.organization,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.type = 'green';
                        this.closeModal();
                        this.is_alert = true;
                        this.message = response.data.data.message;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.name = '';
                        this.id = null;
                        this.organization = null;
                        this.categories = response.data.data.categories;
                        this.isLoading = false;
                    }
                })
                .catch(errors => {
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.check.setErrors(errors.response.data.errors);
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            break;
                    }
                });
        },
        deleteItem(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/settings/categories/delete', {
                id: id,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.type = 'green';
                        this.closeModal();
                        this.is_alert = true;
                        this.message = response.data.data.message;
                        this.categories = response.data.data.categories;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                })
                .catch(errors => {
                    if (errors.response.status == 422) {
                        this.type = 'red';
                        this.closeModal();
                        this.is_alert = true;
                        this.message = errors.response.data.data.message;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    } else if(errors.response.status == 404){
                        this.type = 'red';
                        this.closeModal();
                        this.is_alert = true;
                        this.message = errors.response.data.data.message;
                        setTimeout(() => {this.closeAlert(); }, 3000);
                    }
                });
        },
        edit(item){
            this.isModal = true;
            this.name_form = 'Редактирование категории';
            this.id = item.id;
            this.name = item.name;
            this.organization = item.organization;

        },
        closeAlert(){
            this.is_alert = false;
        },
        showModal(){
            this.isModal = true;
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h2 class="mb-3">Редактировать вопрос</h2>
                <Form ref="check">
                    
                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Выберите пункт проверки*</label>
                            <VueMultiselect
                                v-model="check.item"
                                placeholder="Выберите пункт проверки"
                                label="name"
                                track-by="id"
                                :options="optionsItems"
                                :show-labels="false"
                                @tag="addItems"></VueMultiselect>
                            <ErrorMessage name="item" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Название вопроса*</label>
                            <Field
                                v-model="check.name"
                                type="text"
                                name="name"
                                class="form-control"
                                :class="[errors.name.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="name" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Выберите срок*</label>
                            <VueMultiselect
                                v-model="check.deadline"
                                placeholder="Выберите срок"
                                label="time_performance"
                                track-by="id"
                                :options="deadlines"
                                :show-labels="false"
                                @tag="addDeadline"></VueMultiselect>
                            <ErrorMessage name="deadline" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Выберите период*</label>
                            <VueMultiselect
                                v-model="check.period"
                                placeholder="Выберите период"
                                label="name"
                                track-by="id"
                                :options="periods"
                                :show-labels="false"
                                @tag="addPeriod"></VueMultiselect>
                            <ErrorMessage name="period" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Выберите ответственных*</label>
                            <VueMultiselect
                                v-model="check.users"
                                placeholder="Выберите ответственных"
                                label="name"
                                track-by="id"
                                :options="options"
                                :multiple="true"
                                :show-labels="false"
                                @tag="addTag"></VueMultiselect>
                            <ErrorMessage name="users" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col position-relative">
                            <div class="item_title my-3">
                                <div class="block_foto" :class="{ image_foto: type }" v-if="!showPreview">
                                    <div v-if="Object.keys(this.image).length !== 0">
                                        <div
                                            v-for="(file, key) in this.image"
                                            class="image_block"
                                            v-bind:key="'file-'+key">
                                            <img class="defect_image" data-fancybox="gallery" :src="`${ file }`"/>
                                        </div>
                                    </div>
                                    <div class="image_block" v-else>
                                        <img src="/storage/default/no_image.jpg" alt="">
                                    </div>
                                </div>
                                <div class="block_foto align-middle d-flex justify-content-center align-items-center" v-else>
                                    <div>
                                        <div v-for="(file, key) in files" class="image_block" v-bind:key="'file-'+key">
                                            <img
                                                style="width: 100%;"
                                                class="defect_image"
                                                data-fancybox="gallery"
                                                v-bind:id="'image-'+parseInt( key )"/>
                                            <input
                                                type="hidden"
                                                name="photo"
                                                v-model="photo"
                                                v-bind:id="'photo-'+parseInt( key )"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="check_answer">Фотография эталона для сравнения.</div>
                            </div>
                            <form enctype="multipart/form-data">
                                <div class="mb-3">
                                    <input
                                        type="file"
                                        id="files"
                                        class="inputfile"
                                        accept="image/*"
                                        ref="files"
                                        @change="onAttachmentChange"
                                        style="display: none"/>
                                    <label for="files" class="btn gray btn_download_image btn_vw">ЗАГРУЗИТЬ ФОТО</label>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="row justify-content-end">
                        <div class="col col-lg-2">
                            <button class="btn btn-green w-100" @click="update">
                                Сохранить
                            </button>
                        </div>
                    </div>
                    
                </Form>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import { Field, Form, ErrorMessage } from 'vee-validate';
import spinner from '../../../components/Spinner';
import VueMultiselect from 'vue-multiselect';
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "TSMEditCheck",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            deadlines: [],
            options: [],
            optionsItems: [],
            periods: [],
            check: {
                name: null,
                deadline: null,
                users: null,
                item: null,
                period: null,
            },
            errors: {
                name: '',
                deadline: '',
                users: '',
                item: '',
                period: '',
            },
            attachment: [],
            file: '',
            files: [],
            showPreview: false,
            imagePreview: '',
            image: {},
            photo: [],
            breadCrumbs: true,
            title: 'Редактировать вопрос',
            back_title: 'Чек листы TSM',
            link: 'tsm-setting',
        }
    },
    created(){
        this.getData();
    },
    methods: {
        update(e){
            e.preventDefault();
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }, 'content-type': 'multipart/form-data'};
            const formData = new FormData();
            let q = Array.prototype.slice.call(document.getElementsByName("photo"));
            for( let i = 0; i < q.length; i++){
                formData.append('photos[' + i + ']', q[i].defaultValue);
            }
            for (let i = 0; i < this.files.length; i++) {
                formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
            }
            if(this.check.name !== null){
                formData.append('name', this.check.name);
            }
            if(this.check.item !== null){
                formData.append('item', JSON.stringify(this.check.item));
            }
            if(this.check.deadline !== null){
                formData.append('deadline', JSON.stringify(this.check.deadline));
            }
            if(this.check.users !== null){
                formData.append('users', JSON.stringify(this.check.users));
            }
            if(this.check.period !== null){
                formData.append('period', JSON.stringify(this.check.period));
            }
            axios.post(`/api/admin/tsm/update/${this.$route.params.id}`, formData, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push('/tsm-setting');
                    }
                })
                .catch(errors => {
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.check.setErrors(errors.response.data.errors);
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            this.errors.deadline = errors.response.data.errors.deadline_id ?? 0;
                            this.errors.users = errors.response.data.errors.users ?? 0;
                            this.errors.item = errors.response.data.errors.item_id ?? 0;
                            this.errors.period = errors.response.data.errors.period_id ?? 0;
                            break;
                    }
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tsm/${this.$route.params.id}`, config);
                this.deadlines = response.data.data.deadlines;
                this.options = response.data.data.users;
                this.optionsItems = response.data.data.items;
                this.periods = response.data.data.periods;
                this.check = response.data.data.check;
                this.check.users = response.data.data.selectUser;
                this.image = response.data.data.image;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        addTag(newTag){
            const tag = {
                name: newTag,
            }
            this.options.push(tag)
            this.check.users.push(tag)
        },
        addItems(newItems){
            const item = {
                name: newItems,
            }
            this.optionsItems.push(item)
            this.check.item.push(item)
        },
        addDeadline(newDeadline){
            const deadline = {
                name: newDeadline,
            }
            this.deadlines.push(deadline)
            this.check.deadline.push(deadline)
        },
        addPeriod(newPeriod){
            const period = {
                name: newPeriod,
            }
            this.periods.push(period)
            this.check.period.push(period)
        },
        fileInfo(file){
            let array = [];

            array.push(file.name);
            array.push(file.type);

            return array;
        },
        onAttachmentChange(event) {
            let uploadedFiles = this.$refs.files.files;
            this.count = 0;
            for (var i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i]);
                this.count++;
            }
            this.showPreview = true;
            this.getImagePreviews();
        },
        getImagePreviews() {
            for (let i = 0; i < this.files.length; i++) {
                if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
                    let reader = new FileReader();

                    reader.readAsDataURL(this.files[i]);

                    reader.onloadend = function() {
                        //console.log(this.result);
                        let result = this.result
                        let img = new Image()
                        img.src = result
                        img.onload = function() {
                            document.getElementById('image-' + parseInt(i)).src = compressImage(img, 0.3);
                            document.getElementById('photo-' + parseInt(i)).value = compressImage(img, 0.3);
                        }
                    }

                }
            }

            function compressImage(img, size) {

                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                let initSize = img.src.length
                let width = img.width
                let height = img.height
                canvas.width = width
                canvas.height = height
                // Прокладываем цвет фона
                ctx.fillStyle = '#fff'
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(img, 0, 0, width, height)
                // Выполняем минимальное сжатие
                let ndata = canvas.toDataURL('image/jpeg', size)
                //console.log(ndata.length)
                return ndata
            }
        },
    }
}
</script>

<style scoped>

</style>

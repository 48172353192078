<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="this.title"
                :back_title="this.back_title"
                :link="this.link" />

            <div class="page">

                <h5 class="my-3 hstack">
                    <Buying :count="5"/>
                    Фотографии авто
                </h5>

                <div class="">
                    <div class="car_photo_btns d-flex flex-wrap gap-1 justify-content-around">

                        <!-- Передняя часть -->
                        <label :for="'file'+ image_1.number">
                            <div class="car_photo_btn">
                                <div v-if="!is_loader[image_1.number]" class="car_photo_btn_icon" style="width: 100px; height: 100px;" :style="image_1.status ? addStyle(image_1.path) : ''">
                                    <svg viewBox="0 0 300 300" v-if="!image_1.status">
                                        <g transform="matrix(0.03370797,0,0,-0.03370797,7.8223105,259.25118)">
                                            <path d="m 4875.0501,4282.0967 c -11,-7 -13,-24 -8,-75 l 6,-66 h -442 c -1051,-1 -1668,-30 -1909,-90 -96,-24 -190,-71 -215,-107 -77,-112 -111,-327 -111,-703 0,-377 34,-592 112,-704 24,-35 134,-88 229,-110 218,-51 502,-66 1432,-76 440,-5 823,-9 852,-9 l 52,-1 -6,-68 c -5,-59 -3,-70 11,-75 20,-8 58,28 91,84 29,48 71,56 368,69 495,21 617,45 723,142 77,71 115,169 155,394 33,187 33,521 0,708 -40,229 -79,325 -160,398 -92,83 -209,111 -531,128 -552,29 -529,25 -572,101 -27,49 -57,72 -77,60 z m -931,-262 c 17,-30 8,-62 -20,-76 -31,-16 -217,-17 -415,-3 -150,11 -196,19 -203,38 -8,24 58,34 298,46 352,19 328,19 340,-5 z m 940,11 c 0,-5 -4,-10 -9,-10 -5,0 -76,-11 -157,-25 -345,-57 -611,-78 -621,-49 -6,18 41,78 67,85 41,11 720,10 720,-1 z m 1034,-61 c 51,-14 86,-53 131,-141 40,-81 44,-108 16,-108 -20,0 -201,85 -240,112 -28,20 -131,139 -131,151 0,12 168,2 224,-14 z m -2769,-5 c 38,-25 21,-34 -66,-34 -85,0 -101,11 -46,35 43,19 82,19 112,-1 z m 2191,-27 c 141,-218 192,-705 114,-1082 -34,-161 -85,-310 -115,-335 -24,-20 -576,56 -621,85 -12,7 -24,28 -28,45 -11,58 -18,772 -9,989 11,253 3,240 148,266 185,33 391,63 440,64 46,1 52,-2 71,-32 z m -2487,-203 61,-7 v -488 -489 h -210 c -209,0 -210,0 -215,23 -3,12 -10,42 -15,67 -27,117 -19,778 10,855 5,14 20,30 32,35 27,10 248,13 337,4 z m 3227,-968 c 7,-19 -32,-130 -61,-174 -35,-53 -80,-79 -155,-91 -82,-14 -170,-14 -170,-1 0,5 32,44 72,86 62,65 86,82 174,124 56,27 105,54 109,59 9,15 25,14 31,-3 z m -2282,-216 c 59,-6 123,-17 142,-25 29,-12 34,-19 34,-46 v -31 l -227,6 c -221,7 -385,20 -417,32 -25,10 -19,33 11,45 68,26 287,35 457,19 z m -631,-7 c 12,-12 -28,-32 -64,-33 -42,0 -99,18 -99,31 0,10 153,13 163,2 z m 1307,-22 c 218,-29 393,-60 405,-72 7,-7 -107,-9 -356,-7 -299,2 -369,6 -383,17 -24,20 -48,70 -40,83 9,14 179,5 374,-21 z">
                                            </path>
                                            <g transform="translate(195.77805,-1.1219882e-5)" style="fill: rgb(255 80 80)">
                                                <rect width="305.63309" height="862.96399" x="7832.5972" y="-3672.5901" transform="scale(1,-1)" rx="80" ry="80" />
                                                <rect width="197.76259" height="479.42444" x="7598.9136" y="-3480.8203" transform="scale(1,-1)" rx="60" ry="60" />
                                                <path d="M 7431.0432,3420.053 V 3060.4846 L 6499.765,2522.8109 v 1436.5946 l 929.4102,-530.6023 z"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div class="car_photo_btn_icon" v-if="is_loader[image_1.number]">
                                    <div class="spinner-border text-primary image_load" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <div class="car_photo_btn_text">
                                    <span class="cpb_title">Передняя часть</span>
                                </div>
                            </div>
                        </label>
                        <input type="hidden" :name="'photo-'+ image_1.number" v-model="files" :id="'photo-'+ image_1.number"/>
                        <input type="file" class="inputfile"
                               accept="image/*"
                               :id="'file'+ image_1.number"
                               style="display: none"
                               :ref="'file'+ image_1.number"
                               @change="handleFileUpload('file'+ image_1.number, image_1.number)"
                        />

                        <!-- Передний правый угол -->
                        <label :for="'file'+ image_2.number">
                            <div class="car_photo_btn">
                            <div v-if="!is_loader[image_2.number]" class="car_photo_btn_icon" style="width: 100px; height: 100px;" :style="image_2.status ? addStyle(image_2.path) : ''">

                                <svg viewBox="0 0 300 300" v-if="!image_2.status">
                                    <g transform="matrix(.033708 0 0 -.033708 7.8223 259.25)">
                                        <path d="m4875.1 4282.1c-11-7-13-24-8-75l6-66h-442c-1051-1-1668-30-1909-90-96-24-190-71-215-107-77-112-111-327-111-703 0-377 34-592 112-704 24-35 134-88 229-110 218-51 502-66 1432-76 440-5 823-9 852-9l52-1-6-68c-5-59-3-70 11-75 20-8 58 28 91 84 29 48 71 56 368 69 495 21 617 45 723 142 77 71 115 169 155 394 33 187 33 521 0 708-40 229-79 325-160 398-92 83-209 111-531 128-552 29-529 25-572 101-27 49-57 72-77 60zm-931-262c17-30 8-62-20-76-31-16-217-17-415-3-150 11-196 19-203 38-8 24 58 34 298 46 352 19 328 19 340-5zm940 11c0-5-4-10-9-10s-76-11-157-25c-345-57-611-78-621-49-6 18 41 78 67 85 41 11 720 10 720-1zm1034-61c51-14 86-53 131-141 40-81 44-108 16-108-20 0-201 85-240 112-28 20-131 139-131 151s168 2 224-14zm-2769-5c38-25 21-34-66-34-85 0-101 11-46 35 43 19 82 19 112-1zm2191-27c141-218 192-705 114-1082-34-161-85-310-115-335-24-20-576 56-621 85-12 7-24 28-28 45-11 58-18 772-9 989 11 253 3 240 148 266 185 33 391 63 440 64 46 1 52-2 71-32zm-2487-203 61-7v-977h-210c-209 0-210 0-215 23-3 12-10 42-15 67-27 117-19 778 10 855 5 14 20 30 32 35 27 10 248 13 337 4zm3227-968c7-19-32-130-61-174-35-53-80-79-155-91-82-14-170-14-170-1 0 5 32 44 72 86 62 65 86 82 174 124 56 27 105 54 109 59 9 15 25 14 31-3zm-2282-216c59-6 123-17 142-25 29-12 34-19 34-46v-31l-227 6c-221 7-385 20-417 32-25 10-19 33 11 45 68 26 287 35 457 19zm-631-7c12-12-28-32-64-33-42 0-99 18-99 31 0 10 153 13 163 2zm1307-22c218-29 393-60 405-72 7-7-107-9-356-7-299 2-369 6-383 17-24 20-48 70-40 83 9 14 179 5 374-21z"/>
                                        <g style="fill: rgb(255 80 80)">
                                            <rect transform="rotate(-58.546 10801 -291)" x="7832.6" y="-3672.6" width="305.63" height="862.96" rx="80" ry="80"/>
                                            <rect transform="rotate(-58.546 10801 -291)" x="7598.9" y="-3480.8" width="197.76" height="479.42" rx="60" ry="60"/>
                                            <path transform="matrix(.52181 -.85306 -.85306 -.52181 5413.2 9074.8)" d="m7431 3420.1v-359.57l-931.28-537.67v1436.6l929.41-530.6z"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>

                            <div class="car_photo_btn_icon" v-if="is_loader[image_2.number]">
                                <div class="spinner-border text-primary image_load" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            <div class="car_photo_btn_text">
                                <span class="cpb_title">Передний правый угол</span>
                            </div>
                        </div>
                            <input type="hidden" :name="'photo-'+ image_2.number" v-model="files" :id="'photo-'+ image_2.number"/>
                            <input type="file" class="inputfile"
                                   accept="image/*"
                                   :id="'file'+ image_2.number"
                                   style="display: none"
                                   :ref="'file'+ image_2.number"
                                   @change="handleFileUpload('file'+ image_2.number, image_2.number)"
                            />
                        </label>

                        <!-- Правая сторона -->
                        <label :for="'file'+ image_3.number">
                            <div class="car_photo_btn">
                                <div v-if="!is_loader[image_3.number]" class="car_photo_btn_icon" style="width: 100px; height: 100px;" :style="image_3.status ? addStyle(image_3.path) : ''">
                                    <svg viewBox="0 0 300 300" v-if="!image_3.status">
                                        <g transform="matrix(.033708 0 0 -.033708 7.8223 259.25)">
                                        <path d="m4875.1 4282.1c-11-7-13-24-8-75l6-66h-442c-1051-1-1668-30-1909-90-96-24-190-71-215-107-77-112-111-327-111-703 0-377 34-592 112-704 24-35 134-88 229-110 218-51 502-66 1432-76 440-5 823-9 852-9l52-1-6-68c-5-59-3-70 11-75 20-8 58 28 91 84 29 48 71 56 368 69 495 21 617 45 723 142 77 71 115 169 155 394 33 187 33 521 0 708-40 229-79 325-160 398-92 83-209 111-531 128-552 29-529 25-572 101-27 49-57 72-77 60zm-931-262c17-30 8-62-20-76-31-16-217-17-415-3-150 11-196 19-203 38-8 24 58 34 298 46 352 19 328 19 340-5zm940 11c0-5-4-10-9-10s-76-11-157-25c-345-57-611-78-621-49-6 18 41 78 67 85 41 11 720 10 720-1zm1034-61c51-14 86-53 131-141 40-81 44-108 16-108-20 0-201 85-240 112-28 20-131 139-131 151s168 2 224-14zm-2769-5c38-25 21-34-66-34-85 0-101 11-46 35 43 19 82 19 112-1zm2191-27c141-218 192-705 114-1082-34-161-85-310-115-335-24-20-576 56-621 85-12 7-24 28-28 45-11 58-18 772-9 989 11 253 3 240 148 266 185 33 391 63 440 64 46 1 52-2 71-32zm-2487-203 61-7v-977h-210c-209 0-210 0-215 23-3 12-10 42-15 67-27 117-19 778 10 855 5 14 20 30 32 35 27 10 248 13 337 4zm3227-968c7-19-32-130-61-174-35-53-80-79-155-91-82-14-170-14-170-1 0 5 32 44 72 86 62 65 86 82 174 124 56 27 105 54 109 59 9 15 25 14 31-3zm-2282-216c59-6 123-17 142-25 29-12 34-19 34-46v-31l-227 6c-221 7-385 20-417 32-25 10-19 33 11 45 68 26 287 35 457 19zm-631-7c12-12-28-32-64-33-42 0-99 18-99 31 0 10 153 13 163 2zm1307-22c218-29 393-60 405-72 7-7-107-9-356-7-299 2-369 6-383 17-24 20-48 70-40 83 9 14 179 5 374-21z"/>
                                        <g style="fill: rgb(255 80 80)">
                                            <rect transform="matrix(0 -1 -1 0 970.07 7956.1)" x="7832.6" y="-3672.6" width="305.63" height="862.96" rx="80" ry="80"/>
                                            <rect transform="matrix(0 -1 -1 0 970.07 7956.1)" x="7598.9" y="-3480.8" width="197.76" height="479.42" rx="60" ry="60"/>
                                            <path transform="rotate(-90 4463.1 3493)" d="m7431 3420.1v-359.57l-931.28-537.67v1436.6l929.41-530.6z"/>
                                        </g>
                                        </g>
                                    </svg>
                                </div>

                                <div class="car_photo_btn_icon" v-if="is_loader[image_3.number]">
                                    <div class="spinner-border text-primary image_load" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <div class="car_photo_btn_text">
                                    <span class="cpb_title">Правая сторона</span>
                                </div>
                            </div>
                            <input type="hidden" :name="'photo-'+ image_3.number" v-model="files" :id="'photo-'+ image_3.number"/>
                            <input type="file" class="inputfile"
                                   accept="image/*"
                                   :id="'file'+ image_3.number"
                                   style="display: none"
                                   :ref="'file'+ image_3.number"
                                   @change="handleFileUpload('file'+ image_3.number, image_3.number)"
                            />
                        </label>

                        <!-- Задний правый угол -->
                        <label :for="'file'+ image_4.number">
                            <div class="car_photo_btn">
                                <div v-if="!is_loader[image_4.number]" class="car_photo_btn_icon" style="width: 100px; height: 100px;" :style="image_4.status ? addStyle(image_4.path) : ''">
                                    <svg viewBox="0 0 300 300" v-if="!image_4.status">
                                        <g transform="matrix(.033708 0 0 -.033708 7.8223 259.25)">
                                            <path d="m4875.1 4282.1c-11-7-13-24-8-75l6-66h-442c-1051-1-1668-30-1909-90-96-24-190-71-215-107-77-112-111-327-111-703 0-377 34-592 112-704 24-35 134-88 229-110 218-51 502-66 1432-76 440-5 823-9 852-9l52-1-6-68c-5-59-3-70 11-75 20-8 58 28 91 84 29 48 71 56 368 69 495 21 617 45 723 142 77 71 115 169 155 394 33 187 33 521 0 708-40 229-79 325-160 398-92 83-209 111-531 128-552 29-529 25-572 101-27 49-57 72-77 60zm-931-262c17-30 8-62-20-76-31-16-217-17-415-3-150 11-196 19-203 38-8 24 58 34 298 46 352 19 328 19 340-5zm940 11c0-5-4-10-9-10s-76-11-157-25c-345-57-611-78-621-49-6 18 41 78 67 85 41 11 720 10 720-1zm1034-61c51-14 86-53 131-141 40-81 44-108 16-108-20 0-201 85-240 112-28 20-131 139-131 151s168 2 224-14zm-2769-5c38-25 21-34-66-34-85 0-101 11-46 35 43 19 82 19 112-1zm2191-27c141-218 192-705 114-1082-34-161-85-310-115-335-24-20-576 56-621 85-12 7-24 28-28 45-11 58-18 772-9 989 11 253 3 240 148 266 185 33 391 63 440 64 46 1 52-2 71-32zm-2487-203 61-7v-977h-210c-209 0-210 0-215 23-3 12-10 42-15 67-27 117-19 778 10 855 5 14 20 30 32 35 27 10 248 13 337 4zm3227-968c7-19-32-130-61-174-35-53-80-79-155-91-82-14-170-14-170-1 0 5 32 44 72 86 62 65 86 82 174 124 56 27 105 54 109 59 9 15 25 14 31-3zm-2282-216c59-6 123-17 142-25 29-12 34-19 34-46v-31l-227 6c-221 7-385 20-417 32-25 10-19 33 11 45 68 26 287 35 457 19zm-631-7c12-12-28-32-64-33-42 0-99 18-99 31 0 10 153 13 163 2zm1307-22c218-29 393-60 405-72 7-7-107-9-356-7-299 2-369 6-383 17-24 20-48 70-40 83 9 14 179 5 374-21z"/>
                                            <g style="fill: rgb(255 80 80)">
                                                <rect transform="matrix(-.52181 -.85306 -.85306 .52181 3047.8 9074.9)" x="7832.6" y="-3672.6" width="305.63" height="862.96" rx="78.921" ry="79.181"/>
                                                <rect transform="matrix(-.52181 -.85306 -.85306 .52181 3047.8 9074.9)" x="7598.9" y="-3480.8" width="197.76" height="479.42" rx="78.921" ry="79.181"/>
                                                <path transform="rotate(238.55 4067.4 3683.2)" d="m7431 3420.1v-359.57l-931.28-537.67v1436.6l929.41-530.6z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div class="car_photo_btn_icon" v-if="is_loader[image_4.number]">
                                    <div class="spinner-border text-primary image_load" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <div class="car_photo_btn_text">
                                    <span class="cpb_title">Задний правый угол</span>
                                </div>
                            </div>
                            <input type="hidden" :name="'photo-'+ image_4.number" v-model="files" :id="'photo-'+ image_4.number"/>
                            <input type="file" class="inputfile"
                                   accept="image/*"
                                   :id="'file'+ image_4.number"
                                   style="display: none"
                                   :ref="'file'+ image_4.number"
                                   @change="handleFileUpload('file'+ image_4.number, image_4.number)"
                            />
                        </label>

                        <!-- Задняя часть -->
                        <label :for="'file'+ image_5.number">
                            <div class="car_photo_btn">
                                <div v-if="!is_loader[image_5.number]" class="car_photo_btn_icon" style="width: 100px; height: 100px;" :style="image_5.status ? addStyle(image_5.path) : ''">
                                    <svg viewBox="0 0 300 300" v-if="!image_5.status">
                                        <g transform="matrix(.033708 0 0 -.033708 7.8223 259.25)">
                                            <path d="m4875.1 4282.1c-11-7-13-24-8-75l6-66h-442c-1051-1-1668-30-1909-90-96-24-190-71-215-107-77-112-111-327-111-703 0-377 34-592 112-704 24-35 134-88 229-110 218-51 502-66 1432-76 440-5 823-9 852-9l52-1-6-68c-5-59-3-70 11-75 20-8 58 28 91 84 29 48 71 56 368 69 495 21 617 45 723 142 77 71 115 169 155 394 33 187 33 521 0 708-40 229-79 325-160 398-92 83-209 111-531 128-552 29-529 25-572 101-27 49-57 72-77 60zm-931-262c17-30 8-62-20-76-31-16-217-17-415-3-150 11-196 19-203 38-8 24 58 34 298 46 352 19 328 19 340-5zm940 11c0-5-4-10-9-10s-76-11-157-25c-345-57-611-78-621-49-6 18 41 78 67 85 41 11 720 10 720-1zm1034-61c51-14 86-53 131-141 40-81 44-108 16-108-20 0-201 85-240 112-28 20-131 139-131 151s168 2 224-14zm-2769-5c38-25 21-34-66-34-85 0-101 11-46 35 43 19 82 19 112-1zm2191-27c141-218 192-705 114-1082-34-161-85-310-115-335-24-20-576 56-621 85-12 7-24 28-28 45-11 58-18 772-9 989 11 253 3 240 148 266 185 33 391 63 440 64 46 1 52-2 71-32zm-2487-203 61-7v-977h-210c-209 0-210 0-215 23-3 12-10 42-15 67-27 117-19 778 10 855 5 14 20 30 32 35 27 10 248 13 337 4zm3227-968c7-19-32-130-61-174-35-53-80-79-155-91-82-14-170-14-170-1 0 5 32 44 72 86 62 65 86 82 174 124 56 27 105 54 109 59 9 15 25 14 31-3zm-2282-216c59-6 123-17 142-25 29-12 34-19 34-46v-31l-227 6c-221 7-385 20-417 32-25 10-19 33 11 45 68 26 287 35 457 19zm-631-7c12-12-28-32-64-33-42 0-99 18-99 31 0 10 153 13 163 2zm1307-22c218-29 393-60 405-72 7-7-107-9-356-7-299 2-369 6-383 17-24 20-48 70-40 83 9 14 179 5 374-21z"/>
                                            <g style="fill: rgb(255 80 80)">
                                                <rect transform="rotate(180 4121.2 0)" x="7832.6" y="-3672.6" width="305.63" height="862.96" rx="78.921" ry="79.181"/>
                                                <rect transform="rotate(180 4121.2 0)" x="7598.9" y="-3480.8" width="197.76" height="479.42" rx="78.921" ry="79.181"/>
                                                <path transform="matrix(-1 0 0 1 8242.4 0)" d="m7431 3420.1v-359.57l-931.28-537.67v1436.6l929.41-530.6z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div class="car_photo_btn_icon" v-if="is_loader[image_5.number]">
                                    <div class="spinner-border text-primary image_load" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <div class="car_photo_btn_text">
                                    <span class="cpb_title">Задняя часть</span>
                                </div>
                            </div>
                            <input type="hidden" :name="'photo-'+ image_5.number" v-model="files" :id="'photo-'+ image_5.number"/>
                            <input type="file" class="inputfile"
                                   accept="image/*"
                                   :id="'file'+ image_5.number"
                                   style="display: none"
                                   :ref="'file'+ image_5.number"
                                   @change="handleFileUpload('file'+ image_5.number, image_5.number)"
                            />
                        </label>

                        <!-- Задний левый угол -->
                        <label :for="'file'+ image_6.number">
                            <div class="car_photo_btn">
                                <div v-if="!is_loader[image_6.number]" class="car_photo_btn_icon" style="width: 100px; height: 100px;" :style="image_6.status ? addStyle(image_6.path) : ''">
                                    <svg viewBox="0 0 300 300" v-if="!image_6.status">
                                        <g transform="matrix(.033708 0 0 -.033708 7.8223 259.25)">
                                            <path d="m4875.1 4282.1c-11-7-13-24-8-75l6-66h-442c-1051-1-1668-30-1909-90-96-24-190-71-215-107-77-112-111-327-111-703 0-377 34-592 112-704 24-35 134-88 229-110 218-51 502-66 1432-76 440-5 823-9 852-9l52-1-6-68c-5-59-3-70 11-75 20-8 58 28 91 84 29 48 71 56 368 69 495 21 617 45 723 142 77 71 115 169 155 394 33 187 33 521 0 708-40 229-79 325-160 398-92 83-209 111-531 128-552 29-529 25-572 101-27 49-57 72-77 60zm-931-262c17-30 8-62-20-76-31-16-217-17-415-3-150 11-196 19-203 38-8 24 58 34 298 46 352 19 328 19 340-5zm940 11c0-5-4-10-9-10s-76-11-157-25c-345-57-611-78-621-49-6 18 41 78 67 85 41 11 720 10 720-1zm1034-61c51-14 86-53 131-141 40-81 44-108 16-108-20 0-201 85-240 112-28 20-131 139-131 151s168 2 224-14zm-2769-5c38-25 21-34-66-34-85 0-101 11-46 35 43 19 82 19 112-1zm2191-27c141-218 192-705 114-1082-34-161-85-310-115-335-24-20-576 56-621 85-12 7-24 28-28 45-11 58-18 772-9 989 11 253 3 240 148 266 185 33 391 63 440 64 46 1 52-2 71-32zm-2487-203 61-7v-977h-210c-209 0-210 0-215 23-3 12-10 42-15 67-27 117-19 778 10 855 5 14 20 30 32 35 27 10 248 13 337 4zm3227-968c7-19-32-130-61-174-35-53-80-79-155-91-82-14-170-14-170-1 0 5 32 44 72 86 62 65 86 82 174 124 56 27 105 54 109 59 9 15 25 14 31-3zm-2282-216c59-6 123-17 142-25 29-12 34-19 34-46v-31l-227 6c-221 7-385 20-417 32-25 10-19 33 11 45 68 26 287 35 457 19zm-631-7c12-12-28-32-64-33-42 0-99 18-99 31 0 10 153 13 163 2zm1307-22c218-29 393-60 405-72 7-7-107-9-356-7-299 2-369 6-383 17-24 20-48 70-40 83 9 14 179 5 374-21z"/>
                                            <g style="fill: rgb(255 80 80)">
                                                <rect transform="rotate(121.45 2245.7 -425.44)" x="7832.6" y="-3672.6" width="305.63" height="862.96" rx="78.921" ry="79.181"/>
                                                <rect transform="rotate(121.45 2245.7 -425.44)" x="7598.9" y="-3480.8" width="197.76" height="479.42" rx="78.921" ry="79.181"/>
                                                <path transform="matrix(-.52181 .85306 .85306 .52181 3054.5 -2563.1)" d="m7431 3420.1v-359.57l-931.28-537.67v1436.6l929.41-530.6z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div class="car_photo_btn_icon" v-if="is_loader[image_6.number]">
                                    <div class="spinner-border text-primary image_load" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <div class="car_photo_btn_text">
                                    <span class="cpb_title">Задний левый угол</span>
                                </div>
                            </div>
                            <input type="hidden" :name="'photo-'+ image_6.number" v-model="files" :id="'photo-'+ image_6.number"/>
                            <input type="file" class="inputfile"
                                   accept="image/*"
                                   :id="'file'+ image_6.number"
                                   style="display: none"
                                   :ref="'file'+ image_6.number"
                                   @change="handleFileUpload('file'+ image_6.number, image_6.number)"
                            />
                        </label>

                        <!-- Левая сторона -->
                        <label :for="'file'+ image_7.number">
                            <div class="car_photo_btn">
                                <div v-if="!is_loader[image_7.number]" class="car_photo_btn_icon" style="width: 100px; height: 100px;" :style="image_7.status ? addStyle(image_7.path) : ''">
                                    <svg viewBox="0 0 300 300" v-if="!image_7.status">
                                        <g transform="matrix(.033708 0 0 -.033708 7.8223 259.25)">
                                            <path d="m4875.1 4282.1c-11-7-13-24-8-75l6-66h-442c-1051-1-1668-30-1909-90-96-24-190-71-215-107-77-112-111-327-111-703 0-377 34-592 112-704 24-35 134-88 229-110 218-51 502-66 1432-76 440-5 823-9 852-9l52-1-6-68c-5-59-3-70 11-75 20-8 58 28 91 84 29 48 71 56 368 69 495 21 617 45 723 142 77 71 115 169 155 394 33 187 33 521 0 708-40 229-79 325-160 398-92 83-209 111-531 128-552 29-529 25-572 101-27 49-57 72-77 60zm-931-262c17-30 8-62-20-76-31-16-217-17-415-3-150 11-196 19-203 38-8 24 58 34 298 46 352 19 328 19 340-5zm940 11c0-5-4-10-9-10s-76-11-157-25c-345-57-611-78-621-49-6 18 41 78 67 85 41 11 720 10 720-1zm1034-61c51-14 86-53 131-141 40-81 44-108 16-108-20 0-201 85-240 112-28 20-131 139-131 151s168 2 224-14zm-2769-5c38-25 21-34-66-34-85 0-101 11-46 35 43 19 82 19 112-1zm2191-27c141-218 192-705 114-1082-34-161-85-310-115-335-24-20-576 56-621 85-12 7-24 28-28 45-11 58-18 772-9 989 11 253 3 240 148 266 185 33 391 63 440 64 46 1 52-2 71-32zm-2487-203 61-7v-977h-210c-209 0-210 0-215 23-3 12-10 42-15 67-27 117-19 778 10 855 5 14 20 30 32 35 27 10 248 13 337 4zm3227-968c7-19-32-130-61-174-35-53-80-79-155-91-82-14-170-14-170-1 0 5 32 44 72 86 62 65 86 82 174 124 56 27 105 54 109 59 9 15 25 14 31-3zm-2282-216c59-6 123-17 142-25 29-12 34-19 34-46v-31l-227 6c-221 7-385 20-417 32-25 10-19 33 11 45 68 26 287 35 457 19zm-631-7c12-12-28-32-64-33-42 0-99 18-99 31 0 10 153 13 163 2zm1307-22c218-29 393-60 405-72 7-7-107-9-356-7-299 2-369 6-383 17-24 20-48 70-40 83 9 14 179 5 374-21z"/>
                                            <g style="fill: rgb(255 80 80)">
                                                <rect transform="rotate(90 1210.6 -233.78)" x="7832.6" y="-3672.6" width="305.63" height="862.96" rx="78.921" ry="79.181"/>
                                                <rect transform="rotate(90 1210.6 -233.78)" x="7598.9" y="-3480.8" width="197.76" height="479.42" rx="78.921" ry="79.181"/>
                                                <path transform="matrix(0 1 1 0 976.82 -1444.4)" d="m7431 3420.1v-359.57l-931.28-537.67v1436.6l929.41-530.6z"/>
                                            </g>
                                         </g>
                                    </svg>
                                </div>

                                <div class="car_photo_btn_icon" v-if="is_loader[image_7.number]">
                                    <div class="spinner-border text-primary image_load" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <div class="car_photo_btn_text">
                                    <span class="cpb_title">Левая сторона</span>
                                </div>
                            </div>
                            <input type="hidden" :name="'photo-'+ image_7.number" v-model="files" :id="'photo-'+ image_7.number"/>
                            <input type="file" class="inputfile"
                                   accept="image/*"
                                   :id="'file'+ image_7.number"
                                   style="display: none"
                                   :ref="'file'+ image_7.number"
                                   @change="handleFileUpload('file'+ image_7.number, image_7.number)"
                            />
                        </label>

                        <!-- Передний левый угол -->
                        <label :for="'file'+ image_8.number">
                            <div class="car_photo_btn">
                                <div v-if="!is_loader[image_8.number]" class="car_photo_btn_icon" style="width: 100px; height: 100px;" :style="image_8.status ? addStyle(image_8.path) : ''">
                                    <svg viewBox="0 0 300 300" v-if="!image_8.status">
                                        <g transform="matrix(.033708 0 0 -.033708 7.8223 259.25)">
                                            <path d="m4875.1 4282.1c-11-7-13-24-8-75l6-66h-442c-1051-1-1668-30-1909-90-96-24-190-71-215-107-77-112-111-327-111-703 0-377 34-592 112-704 24-35 134-88 229-110 218-51 502-66 1432-76 440-5 823-9 852-9l52-1-6-68c-5-59-3-70 11-75 20-8 58 28 91 84 29 48 71 56 368 69 495 21 617 45 723 142 77 71 115 169 155 394 33 187 33 521 0 708-40 229-79 325-160 398-92 83-209 111-531 128-552 29-529 25-572 101-27 49-57 72-77 60zm-931-262c17-30 8-62-20-76-31-16-217-17-415-3-150 11-196 19-203 38-8 24 58 34 298 46 352 19 328 19 340-5zm940 11c0-5-4-10-9-10s-76-11-157-25c-345-57-611-78-621-49-6 18 41 78 67 85 41 11 720 10 720-1zm1034-61c51-14 86-53 131-141 40-81 44-108 16-108-20 0-201 85-240 112-28 20-131 139-131 151s168 2 224-14zm-2769-5c38-25 21-34-66-34-85 0-101 11-46 35 43 19 82 19 112-1zm2191-27c141-218 192-705 114-1082-34-161-85-310-115-335-24-20-576 56-621 85-12 7-24 28-28 45-11 58-18 772-9 989 11 253 3 240 148 266 185 33 391 63 440 64 46 1 52-2 71-32zm-2487-203 61-7v-977h-210c-209 0-210 0-215 23-3 12-10 42-15 67-27 117-19 778 10 855 5 14 20 30 32 35 27 10 248 13 337 4zm3227-968c7-19-32-130-61-174-35-53-80-79-155-91-82-14-170-14-170-1 0 5 32 44 72 86 62 65 86 82 174 124 56 27 105 54 109 59 9 15 25 14 31-3zm-2282-216c59-6 123-17 142-25 29-12 34-19 34-46v-31l-227 6c-221 7-385 20-417 32-25 10-19 33 11 45 68 26 287 35 457 19zm-631-7c12-12-28-32-64-33-42 0-99 18-99 31 0 10 153 13 163 2zm1307-22c218-29 393-60 405-72 7-7-107-9-356-7-299 2-369 6-383 17-24 20-48 70-40 83 9 14 179 5 374-21z"/>
                                            <g style="fill: rgb(255 80 80)">
                                                <rect transform="matrix(.52181 .85306 .85306 -.52181 5419.9 -2563)" x="7832.6" y="-3672.6" width="305.63" height="862.96" rx="78.921" ry="79.181"/>
                                                <rect transform="matrix(.52181 .85306 .85306 -.52181 5419.9 -2563)" x="7598.9" y="-3480.8" width="197.76" height="479.42" rx="78.921" ry="79.181"/>
                                                <path transform="rotate(58.546 4996.1 3552.9)" d="m7431 3420.1v-359.57l-931.28-537.67v1436.6l929.41-530.6z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div class="car_photo_btn_icon" v-if="is_loader[image_8.number]">
                                    <div class="spinner-border text-primary image_load" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <div class="car_photo_btn_text">
                                    <span class="cpb_title">Передний левый угол</span>
                                </div>
                            </div>
                            <input type="hidden" :name="'photo-'+ image_8.number" v-model="files" :id="'photo-'+ image_8.number"/>
                            <input type="file" class="inputfile"
                                   accept="image/*"
                                   :id="'file'+ image_8.number"
                                   style="display: none"
                                   :ref="'file'+ image_8.number"
                                   @change="handleFileUpload('file'+ image_8.number, image_8.number)"
                            />
                        </label>

                        <!-- Крыша -->
                        <label :for="'file'+ image_9.number">
                            <div class="car_photo_btn">
                                <div v-if="!is_loader[image_9.number]" class="car_photo_btn_icon" style="width: 100px; height: 100px;" :style="image_9.status ? addStyle(image_9.path) : ''">
                                    <svg viewBox="0 0 300 300" v-if="!image_9.status">
                                        <g transform="matrix(.033708 0 0 -.033708 7.8223 259.25)">
                                            <path d="m4875.1 4282.1c-11-7-13-24-8-75l6-66h-442c-1051-1-1668-30-1909-90-96-24-190-71-215-107-77-112-111-327-111-703 0-377 34-592 112-704 24-35 134-88 229-110 218-51 502-66 1432-76 440-5 823-9 852-9l52-1-6-68c-5-59-3-70 11-75 20-8 58 28 91 84 29 48 71 56 368 69 495 21 617 45 723 142 77 71 115 169 155 394 33 187 33 521 0 708-40 229-79 325-160 398-92 83-209 111-531 128-552 29-529 25-572 101-27 49-57 72-77 60zm-931-262c17-30 8-62-20-76-31-16-217-17-415-3-150 11-196 19-203 38-8 24 58 34 298 46 352 19 328 19 340-5zm940 11c0-5-4-10-9-10s-76-11-157-25c-345-57-611-78-621-49-6 18 41 78 67 85 41 11 720 10 720-1zm1034-61c51-14 86-53 131-141 40-81 44-108 16-108-20 0-201 85-240 112-28 20-131 139-131 151s168 2 224-14zm-2769-5c38-25 21-34-66-34-85 0-101 11-46 35 43 19 82 19 112-1zm2191-27c141-218 192-705 114-1082-34-161-85-310-115-335-24-20-576 56-621 85-12 7-24 28-28 45-11 58-18 772-9 989 11 253 3 240 148 266 185 33 391 63 440 64 46 1 52-2 71-32zm-2487-203 61-7v-977h-210c-209 0-210 0-215 23-3 12-10 42-15 67-27 117-19 778 10 855 5 14 20 30 32 35 27 10 248 13 337 4zm3227-968c7-19-32-130-61-174-35-53-80-79-155-91-82-14-170-14-170-1 0 5 32 44 72 86 62 65 86 82 174 124 56 27 105 54 109 59 9 15 25 14 31-3zm-2282-216c59-6 123-17 142-25 29-12 34-19 34-46v-31l-227 6c-221 7-385 20-417 32-25 10-19 33 11 45 68 26 287 35 457 19zm-631-7c12-12-28-32-64-33-42 0-99 18-99 31 0 10 153 13 163 2zm1307-22c218-29 393-60 405-72 7-7-107-9-356-7-299 2-369 6-383 17-24 20-48 70-40 83 9 14 179 5 374-21z"/>
                                            <g style="fill: rgb(255 80 80)">
                                                <circle transform="scale(1,-1)" cx="3839.2" cy="-3264.2" r="568.92" stroke-width="22.25"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div class="car_photo_btn_icon" v-if="is_loader[image_9.number]">
                                    <div class="spinner-border text-primary image_load" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <div class="car_photo_btn_text">
                                    <span class="cpb_title">Крыша</span>
                                </div>
                            </div>
                            <input type="hidden" :name="'photo-'+ image_9.number" v-model="files" :id="'photo-'+ image_9.number"/>
                            <input type="file" class="inputfile"
                                   accept="image/*"
                                   :id="'file'+ image_9.number"
                                   style="display: none"
                                   :ref="'file'+ image_9.number"
                                   @change="handleFileUpload('file'+ image_9.number, image_9.number)"
                            />
                        </label>
                    </div>

                    <div class="mb-4">
                        <button class="btn btn-green w-100" :disabled="!is_save" @click="saveStep">Сохранить</button>
                    </div>

                </div>
            </div>
        </div>
    </transition>

</template>

<script>
import Buying from "./Buying";
import axios from "axios";
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "Step_5",
    components: {
        Buying, spinner, BreadCrumbs
    },
    data: () => ({
        step: 'outside',
        back_step: 'service',
        isLoading: false,
        photos: [],
        files: [],
        values: [],
        image_1: [],
        image_2: [],
        image_3: [],
        image_4: [],
        image_5: [],
        image_6: [],
        image_7: [],
        image_8: [],
        image_9: [],
        file: '',
        is_save: false,
        is_loader: [],
        breadCrumbs: true,
        title: "Фото авто",
        back_title: "Этапы приёма",
        link: "",
    }),
    created() {
        this.getPhotos();
        this.getBackLink();
    },
    methods: {
        getBackLink() {
            this.link = this.link = `tradeinlist/${this.$route.params.id}`
        },
        saveStep(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/save/step`, {
                id: this.$route.params.id,
                step_number: 5,
                status: 'accept',
            }, config)

                .then(response =>{
                    if(response.status == 200){

                        this.$router.push(`/buying/outside/${this.$route.params.id}`);
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        async getPhotos() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/tradein/photos/${this.$route.params.id}`, config);
                this.photos = response.data.data.photos;
                this.is_save = response.data.data.is_save;
                this.image_1 = this.photos[1];
                this.image_2 = this.photos[2];
                this.image_3 = this.photos[3];
                this.image_4 = this.photos[4];
                this.image_5 = this.photos[5];
                this.image_6 = this.photos[6];
                this.image_7 = this.photos[7];
                this.image_8 = this.photos[8];
                this.image_9 = this.photos[9];
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        handleFileUpload(ref, number){
            this.is_loader[number] = true;
            this.file = this.$refs[ref].files[0];

             this.getImagePreviews(number);
             //this.addImage(number, this.file);
        },
        addImage(number){
            //this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }, 'content-type': 'multipart/form-data'};
            const formData = new FormData();

            //let q = Array.prototype.slice.call(document.getElementsByName('photo-'+number));

            formData.append('photos', this.file);
            formData.append('number', number);
            formData.append('id', this.$route.params.id);
            axios.post(`/api/admin/tradein/image`, formData, config)
                .then(response => {
                    if (response.status == 200) {
                        this.photos = response.data.data.photos;
                        this.is_save = response.data.data.is_save;
                        this.image_1 = this.photos[1];
                        this.image_2 = this.photos[2];
                        this.image_3 = this.photos[3];
                        this.image_4 = this.photos[4];
                        this.image_5 = this.photos[5];
                        this.image_6 = this.photos[6];
                        this.image_7 = this.photos[7];
                        this.image_8 = this.photos[8];
                        this.image_9 = this.photos[9];
                    }
                    this.is_loader[number] = false;
                })
                .catch(function (error) {
                    //this.isLoading = false;
                    console.error(error);
                });
        },

        getImagePreviews(number) {
            const MAX_WIDTH = 1024;
            const MAX_HEIGHT = 768;

            const config = {
                'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')},
                'content-type': 'multipart/form-data'
            };
            const id = this.$route.params.id;
            let data = [];
            const file = this.file; // get the file
            const blobURL = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onerror = function () {
                URL.revokeObjectURL(this.src);
                // Handle the failure properly
                console.log("Cannot load image");
            };
            img.onload = function () {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                let ndata = canvas.toDataURL('image/jpeg')

                addImage(id, number, ndata, config);
                //document.getElementById('photo-' + parseInt(number)).value = ndata;
            };

            const self = this;

            function addImage(id, number, file, config) {
                //this.isLoading = true;


                axios.post(`/api/admin/tradein/image`, {
                    id: id,
                    number: number,
                    photos: file,
                }, config)
                    .then(response => {
                        if (response.status == 200) {
                            self.photos = response.data.data.photos;
                            self.is_save = response.data.data.is_save;
                            self.image_1 = self.photos[1];
                            self.image_2 = self.photos[2];
                            self.image_3 = self.photos[3];
                            self.image_4 = self.photos[4];
                            self.image_5 = self.photos[5];
                            self.image_6 = self.photos[6];
                            self.image_7 = self.photos[7];
                            self.image_8 = self.photos[8];
                            self.image_9 = self.photos[9];
                        }
                        self.is_loader[number] = false;
                    })
                    .catch(function (error) {
                        //this.isLoading = false;
                        console.error(error);
                    });
            }

            function calculateSize(img, maxWidth, maxHeight) {
                let width = img.width;
                let height = img.height;

                // calculate the width and height, constraining the proportions
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
                return [width, height];
            }
        },
        addStyle(item){
            return `background: url(${item}) center / cover;`
        }
    }
}
</script>

<style scoped>

</style>

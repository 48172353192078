import { VueCookieNext } from 'vue-cookie-next'
import axios from "axios";

export function isLoggedIn() {
    let token = VueCookieNext.getCookie('token')
    return token != null;
}

export function getCookie() {
    return VueCookieNext.getCookie('token');
}




<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">

                <div class="row">
                    <div class="d-flex justify-between flex-wrap my-4">
                        <div class="col-12 col-sm-4 col-lg-3">
                            <button class="btn gray btn_add w-100"
                                    @click="showModal"
                            >Добавить</button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive-md">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-muted">Название</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="list in lists"
                            :key="list.id"
                        >
                            <td>
                                {{ list.name }}
                            </td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                        <li><button type="button" @click="editPoint(list.id)" class="dropdown-item">Редактировать</button></li>
                                        <li><hr class="dropdown-divider"></li>
                                        <li>
                                            <button type="button" @click="deleteBank(list.id)" class="dropdown-item">Удалить</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <transition-group name="list">
                    <div class="modal d-block" v-if="is_modal">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title"></h5>
                                    <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                                </div>
                                <div class="modal-body">
                                    <Form ref="point" @submit="create">
                                        <div class="row mb-3 mt-3">
                                            <label class="col-sm-12 col-form-label required text-start">Название</label>
                                            <div class="col-sm position-relative">
                                                <Field
                                                    v-model="name"
                                                    type="text"
                                                    name="name"
                                                    class="form-control"
                                                    :class="[errors.name.length > 0 ? 'error' : '']"
                                                />
                                                <ErrorMessage name="name" style="color: red;"/>
                                            </div>
                                        </div>
                                        <div class="col-sm position-relative mb-2 mt-2">
                                            <button class="btn btn_vw btn-green">
                                                Сохранить
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :bg="bg"
    />
</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import Modules from "../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import ToastAlert from "../../components/ToastAlert";
import axios from "axios";

export default {
    name: "Index",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, ToastAlert
    },
    data() {
        return {
            message: '',
            is_alert: false,
            id: null,
            name: null,
            errors: {
                name: '',
            },
            actions: [],
            lists: [],
            is_modal: false,
            isLoading: false,
            breadCrumbs: true,
            title: 'Список банков',
            back_title: 'Настройки',
            link: 'admins',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        showModal(){
            this.is_modal = true;
        },
        closeModal(){
            this.is_modal = false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/banks', config);
                this.lists = response.data.data.lists;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        async editPoint(id){
            try {
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/banks/${id}`, config);
                this.name = response.data.data.bank.name;
                this.id = response.data.data.bank.id;
                this.showModal();
            } catch (e) {
                console.log(e);
            }finally {
            }
        },
        create(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            if(this.id == null){
                axios.post('/api/admin/banks', {
                    name: this.name,
                }, config)
                    .then(response => {
                        if(response.status == 200){
                            this.is_alert = true;
                            this.message = 'Успешно сохранено';
                            setTimeout(() => {this.closeAlert(); }, 3000);
                            this.name = null;
                            this.id == null
                            this.closeModal();
                            this.getData();
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        switch (errors.response.status) {
                            case 422:
                                this.$refs.point.setErrors(errors.response.data.errors);
                                this.errors.name = errors.response.data.errors.name ?? 0;
                                break;
                        }
                    });
            }else{
                axios.put(`/api/admin/banks/${this.id}`,{
                    name: this.name,
                }, config)
                    .then(response => {
                        if(response.status == 200){
                            this.is_alert = true;
                            this.message = 'Успешно обновлено';
                            setTimeout(() => {this.closeAlert(); }, 3000);
                            this.name = null;
                            this.id == null
                            this.closeModal();
                            this.getData();
                        }
                    })
                    .catch(errors => {
                        this.isLoading = false;
                        switch (errors.response.status) {
                            case 422:
                                this.$refs.point.setErrors(errors.response.data.errors);
                                this.errors.name = errors.response.data.errors.name ?? 0;
                                break;
                        }
                    });
            }

        },
        deleteBank(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.delete(`/api/admin/banks/${id}`, config)
                .then(response => {
                    if(response.status == 200){
                        this.is_alert = true;
                        this.message = 'Успешно удалено';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.closeModal();
                        this.getData();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">

                    <h5 class="py-2">Заявка на аванс</h5>
                    <div class="item_title mb-2">

                        <!-- Номер, Дата -->
                        <div
                            class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                            <div class="fs-6 fw-bold">№{{ id }}</div>
                            <div>{{ moment(created_at).format("DD.MM.YYYY hh:mm") }}</div>
                        </div>

                        <!-- Сотрудник -->
                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                            <span class="fw-normal">{{ $t("request.worker") }}</span>
                            <span class="text-end fw-semibold">{{ fullname }}</span>
                        </div>

                        <!-- Сумма -->
                        <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                            <span class="fw-normal">{{ $t("request.amount") }}</span>
                            <span class="text-end fw-semibold">{{ cur(payment_amount) }}</span>
                        </div>

                    </div>

                    <!-- Форма -->
                    <Form ref="imprest" @submit="create">
                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.earned_today') }}*</label>
                            <Field
                                v-model="imprest.earned"
                                type="tel"
                                name="earned"
                                class="form-control"
                                :class="[errors.earned.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="earned" style="color: red;"/>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.arrears_today') }}*</label>
                            <Field
                                v-model="imprest.debt"
                                type="tel"
                                name="debt"
                                class="form-control"
                                :class="[errors.debt.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="debt" style="color: red;"/>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{ $t('request.comment') }}</label>
                            <textarea class="form-control" rows="3" v-model="imprest.comment"></textarea>
                        </div>
                        <div class="my-4">
                            <button class="btn btn-green btn_vw">
                                <span class="material-symbols-rounded opacity-50 me-1">check</span>
                                {{ $t('request.button.save') }}
                            </button>
                        </div>
                    </Form>

            </div>
        </div>
    </transition>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import ToastAlert from "../../../components/ToastAlert";
import moment from "moment";

export default {
    name: "ImprestAccauntantPage",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect, ToastAlert
    },
    data(){
        return {
            type: '',
            message: '',
            is_alert: false,
            imprest: {
                earned: null,
                debt: null,
                comment: null,
            },
            errors: {
                earned: '',
                debt: '',
                comment: '',
            },
            id: null,
            fullname: null,
            payment_amount: null,
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.request')+ ' №' + this.$route.params.id,
            back_title: this.$t('request.list_requests'),
            link: 'request/imprest/waiting',
        }
    },
    created(){
        this.getData();
    },
    methods: {
        moment,
        closeAlert(){
            this.is_alert = false;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/imprest/one/${this.$route.params.id}`, config);
                this.list = response.data.data.list;
                this.id = this.list.id;
                this.fullname = this.list.user.surname +' '+ this.list.user.name;
                this.payment_amount = this.list.payment_amount;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        cur(val) {
            if (!val) {
                return "0 KZT";
            }
            const format = new Intl.NumberFormat("ru-Ru", {
                style: "currency",
                currency: "KZT",
                minimumFractionDigits: 0,
            });
            return format.format(parseInt(val));
        },
        create(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/imprest/accountant/store', {
                comment: this.imprest.comment,
                earned: this.imprest.earned,
                debt: this.imprest.debt,
                id: this.$route.params.id,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.is_alert = true;
                        this.message = this.$t('request.information_provided');
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.$router.push('/request/imprest/waiting')
                    }

                })
                .catch(errors => {
                    this.isLoading = false;
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.imprest.setErrors(errors.response.data.errors);
                            this.errors.comment = errors.response.data.errors.comment ?? 0;
                            this.errors.earned = errors.response.data.errors.earned ?? 0;
                            this.errors.debt = errors.response.data.errors.debt ?? 0;
                            break;
                    }
                });
        },
    }
}
</script>

<style scoped>

</style>
